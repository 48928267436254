@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Regular"), local("Cairo-Regular"), url("../sass/fonts/Cairo-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Cairo;
  font-style: normal;
  src: local("Cairo Bold"), local("Cairo-Bold"), url("../sass/fonts/Cairo-Bold.ttf");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 300;
  src: local("lato Light"), local("lato-Light"), url("../sass/fonts/lato/Lato-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 400;
  src: local("lato Regular"), local("lato-Regular"), url("../sass/fonts/lato/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 500;
  src: local("lato Medium"), local("lato-Medium"), url("../sass/fonts/lato/Lato-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 600;
  src: local("lato Semibold"), local("lato-Semibold"), url("../sass/fonts/lato/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: lato;
  font-style: normal;
  font-weight: 700;
  src: local("lato Bold"), local("lato-Bold"), url("../sass/fonts/lato/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../sass/fonts/Montserrat/Montserrat-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../sass/fonts/Montserrat/Montserrat-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url("../sass/fonts/Montserrat/Montserrat-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url("../sass/fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../sass/fonts/Montserrat/Montserrat-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  font-style: normal;
  font-weight: 400;
  src: local("SegoeUI Regular"), local("SegoeUI-Regular"), url("../sass/fonts/segoe/Segoe.ttf");
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  font-style: normal;
  font-weight: 700;
  src: local("SegoeUI Bold"), local("SegoeUI-Bold"), url("../sass/fonts/segoe/Segoe-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  font-style: italic;
  font-weight: 400;
  src: local("SegoeUI Italic"), local("SegoeUI Italic"), url("../sass/fonts/segoe/Segoe-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: SegoeUI;
  font-style: italic;
  font-weight: 700;
  src: local("SegoeUI-Italic-Bold"), local("SegoeUI-Italic-Bold"), url("../sass/fonts/segoe/Segoe-Bold-Italic.ttf");
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../sass/fonts/Poppins/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../sass/fonts/Poppins/Poppins-Regular.ttf");
  font-display: swap;
}

.ant-upload-picture-card-wrapper, .ant-upload.ant-upload-select-picture-card > .ant-upload, .ant-tooltip .ant-tooltip-inner, .admin-modal-wrapper .ant-modal-content .ant-modal-footer, .classMessage_holder .parents-list-wrapper header > div, .classRoom_holder .installments-list .kid_card .kid_holder .page_holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.classMessage_holder .parents-list-wrapper header, .classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type .info, .classMessage_holder .msgs-list-wrapper > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quiz__body {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.primary__btn, .cancel__btn, .secondary__btn, .quiz__type--action button.quiz__action--save, .quiz__type--action button.quiz__action--cancel {
  min-width: 99px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

/************LINKS ********************/
/************ triangle ********************/
/************MEDIA QUERY ********************/
/*****************prfix*************/
/*****************input placeholder*************/
/***************** slide imgs *************/
/************ spacing ********************/
.mr-0 {
  margin-right: 0px;
}

body.rtl .mr-0 {
  margin-left: 0px;
  margin-right: 0;
}

.ml-0 {
  margin-left: 0px;
}

body.rtl .ml-0 {
  margin-right: 0px;
  margin-left: 0;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.pr-0 {
  padding-right: 0px;
}

body.rtl .pr-0 {
  padding-left: 0px;
  padding-right: 0;
}

.pl-0 {
  padding-left: 0px;
}

body.rtl .pl-0 {
  padding-right: 0px;
  padding-left: 0;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.m-0 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}

.p-0 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}

.mr-5 {
  margin-right: 5px;
}

body.rtl .mr-5 {
  margin-left: 5px;
  margin-right: 0;
}

.ml-5 {
  margin-left: 5px;
}

body.rtl .ml-5 {
  margin-right: 5px;
  margin-left: 0;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.pr-5 {
  padding-right: 5px;
}

body.rtl .pr-5 {
  padding-left: 5px;
  padding-right: 0;
}

.pl-5 {
  padding-left: 5px;
}

body.rtl .pl-5 {
  padding-right: 5px;
  padding-left: 0;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.m-5 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.p-5 {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.mr-10 {
  margin-right: 10px;
}

body.rtl .mr-10 {
  margin-left: 10px;
  margin-right: 0;
}

.ml-10 {
  margin-left: 10px;
}

body.rtl .ml-10 {
  margin-right: 10px;
  margin-left: 0;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pr-10 {
  padding-right: 10px;
}

body.rtl .pr-10 {
  padding-left: 10px;
  padding-right: 0;
}

.pl-10 {
  padding-left: 10px;
}

body.rtl .pl-10 {
  padding-right: 10px;
  padding-left: 0;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.m-10 {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.p-10 {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.mr-15 {
  margin-right: 15px;
}

body.rtl .mr-15 {
  margin-left: 15px;
  margin-right: 0;
}

.ml-15 {
  margin-left: 15px;
}

body.rtl .ml-15 {
  margin-right: 15px;
  margin-left: 0;
}

.mx-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pr-15 {
  padding-right: 15px;
}

body.rtl .pr-15 {
  padding-left: 15px;
  padding-right: 0;
}

.pl-15 {
  padding-left: 15px;
}

body.rtl .pl-15 {
  padding-right: 15px;
  padding-left: 0;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.m-15 {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.p-15 {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.mr-20 {
  margin-right: 20px;
}

body.rtl .mr-20 {
  margin-left: 20px;
  margin-right: 0;
}

.ml-20 {
  margin-left: 20px;
}

body.rtl .ml-20 {
  margin-right: 20px;
  margin-left: 0;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pr-20 {
  padding-right: 20px;
}

body.rtl .pr-20 {
  padding-left: 20px;
  padding-right: 0;
}

.pl-20 {
  padding-left: 20px;
}

body.rtl .pl-20 {
  padding-right: 20px;
  padding-left: 0;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.m-20 {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.p-20 {
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.mr-25 {
  margin-right: 25px;
}

body.rtl .mr-25 {
  margin-left: 25px;
  margin-right: 0;
}

.ml-25 {
  margin-left: 25px;
}

body.rtl .ml-25 {
  margin-right: 25px;
  margin-left: 0;
}

.mx-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pr-25 {
  padding-right: 25px;
}

body.rtl .pr-25 {
  padding-left: 25px;
  padding-right: 0;
}

.pl-25 {
  padding-left: 25px;
}

body.rtl .pl-25 {
  padding-right: 25px;
  padding-left: 0;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.m-25 {
  margin-top: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.p-25 {
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
}

.mr-30 {
  margin-right: 30px;
}

body.rtl .mr-30 {
  margin-left: 30px;
  margin-right: 0;
}

.ml-30 {
  margin-left: 30px;
}

body.rtl .ml-30 {
  margin-right: 30px;
  margin-left: 0;
}

.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr-30 {
  padding-right: 30px;
}

body.rtl .pr-30 {
  padding-left: 30px;
  padding-right: 0;
}

.pl-30 {
  padding-left: 30px;
}

body.rtl .pl-30 {
  padding-right: 30px;
  padding-left: 0;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.m-30 {
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
}

.p-30 {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.mr-35 {
  margin-right: 35px;
}

body.rtl .mr-35 {
  margin-left: 35px;
  margin-right: 0;
}

.ml-35 {
  margin-left: 35px;
}

body.rtl .ml-35 {
  margin-right: 35px;
  margin-left: 0;
}

.mx-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.pr-35 {
  padding-right: 35px;
}

body.rtl .pr-35 {
  padding-left: 35px;
  padding-right: 0;
}

.pl-35 {
  padding-left: 35px;
}

body.rtl .pl-35 {
  padding-right: 35px;
  padding-left: 0;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.m-35 {
  margin-top: 35px;
  margin-right: 35px;
  margin-bottom: 35px;
  margin-left: 35px;
}

.p-35 {
  padding-top: 35px;
  padding-right: 35px;
  padding-bottom: 35px;
  padding-left: 35px;
}

.mr-40 {
  margin-right: 40px;
}

body.rtl .mr-40 {
  margin-left: 40px;
  margin-right: 0;
}

.ml-40 {
  margin-left: 40px;
}

body.rtl .ml-40 {
  margin-right: 40px;
  margin-left: 0;
}

.mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.pr-40 {
  padding-right: 40px;
}

body.rtl .pr-40 {
  padding-left: 40px;
  padding-right: 0;
}

.pl-40 {
  padding-left: 40px;
}

body.rtl .pl-40 {
  padding-right: 40px;
  padding-left: 0;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.m-40 {
  margin-top: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-left: 40px;
}

.p-40 {
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.mr-45 {
  margin-right: 45px;
}

body.rtl .mr-45 {
  margin-left: 45px;
  margin-right: 0;
}

.ml-45 {
  margin-left: 45px;
}

body.rtl .ml-45 {
  margin-right: 45px;
  margin-left: 0;
}

.mx-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.pr-45 {
  padding-right: 45px;
}

body.rtl .pr-45 {
  padding-left: 45px;
  padding-right: 0;
}

.pl-45 {
  padding-left: 45px;
}

body.rtl .pl-45 {
  padding-right: 45px;
  padding-left: 0;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.m-45 {
  margin-top: 45px;
  margin-right: 45px;
  margin-bottom: 45px;
  margin-left: 45px;
}

.p-45 {
  padding-top: 45px;
  padding-right: 45px;
  padding-bottom: 45px;
  padding-left: 45px;
}

.mr-50 {
  margin-right: 50px;
}

body.rtl .mr-50 {
  margin-left: 50px;
  margin-right: 0;
}

.ml-50 {
  margin-left: 50px;
}

body.rtl .ml-50 {
  margin-right: 50px;
  margin-left: 0;
}

.mx-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.pr-50 {
  padding-right: 50px;
}

body.rtl .pr-50 {
  padding-left: 50px;
  padding-right: 0;
}

.pl-50 {
  padding-left: 50px;
}

body.rtl .pl-50 {
  padding-right: 50px;
  padding-left: 0;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.m-50 {
  margin-top: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
}

.p-50 {
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.delay-0-half {
  transition-delay: 0.5s !important;
}

.delay-1 {
  transition-delay: 1s !important;
}

.delay-1-half {
  transition-delay: 1.5s !important;
}

.delay-2 {
  transition-delay: 2s !important;
}

.delay-2-half {
  transition-delay: 2.5s !important;
}

.delay-3 {
  transition-delay: 3s !important;
}

.delay-3-half {
  transition-delay: 3.5s !important;
}

.delay-4 {
  transition-delay: 4s !important;
}

.delay-4-half {
  transition-delay: 4.5s !important;
}

.delay-5 {
  transition-delay: 5s !important;
}

.delay-5-half {
  transition-delay: 5.5s !important;
}

.delay-6 {
  transition-delay: 6s !important;
}

.delay-6-half {
  transition-delay: 6.5s !important;
}

.delay-7 {
  transition-delay: 7s !important;
}

.delay-7-half {
  transition-delay: 7.5s !important;
}

.delay-8 {
  transition-delay: 8s !important;
}

.delay-8-half {
  transition-delay: 8.5s !important;
}

.delay-9 {
  transition-delay: 9s !important;
}

.delay-9-half {
  transition-delay: 9.5s !important;
}

.delay-10 {
  transition-delay: 10s !important;
}

.delay-10-half {
  transition-delay: 10.5s !important;
}

.delay-11 {
  transition-delay: 11s !important;
}

.pb-24 {
  padding-bottom: 1.5rem !important;
}

.pt-24 {
  padding-top: 1.5rem !important;
}

.py-24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.d-none {
  display: none;
}

.h-90 {
  height: 90%;
}

.float-unset {
  float: unset !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline-none {
  text-decoration: none !important;
}

.p-relative {
  position: relative !important;
}

.cursor-p {
  cursor: pointer;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.h-auto {
  height: auto !important;
}

.text-nowrap {
  white-space: nowrap;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

.w-100 {
  width: 100% !important;
}

.w_90 {
  width: 90%;
}

.w_47 {
  width: 47%;
}

.w-80 {
  width: 80%;
}

.text_center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

body.rtl .text-right {
  text-align: left !important;
}

.text-left {
  text-align: left !important;
}

body.rtl .text-left {
  text-align: right !important;
}

.border_none {
  border: none !important;
}

.fit-cover {
  object-fit: cover;
}

.visibility-hidden {
  visibility: hidden !important;
}

.ant-pagination-options-quick-jumper input {
  display: none;
}

.mt-70 {
  margin-top: 70px !important;
}

.col-direction {
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.align-baseLine {
  align-items: baseline;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.font-18 {
  font-size: 18px;
}

.font-28 {
  font-size: 28px;
}

.bold {
  font-weight: bold;
}

.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.gray-33 {
  color: #333333;
}

.w-50-100 {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .w-50-100 {
    width: 100% !important;
  }
}

.mx-7 {
  margin: 0 7px;
}

.mx-20 {
  margin: 0 23px;
}

.m-auto {
  margin: auto;
}

.py-20 {
  padding: 20px 0;
}

.p-25 {
  padding: 25px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pr {
  padding-right: 10px !important;
}

body.rtl .pr {
  padding-right: 0 !important;
  padding-left: 10px !important;
}

.my-40 {
  margin: 40px 0;
}

.text-center {
  text-align: center;
}

/*
  Conditionally apply styles based upon child count
*/
.child-equal-wrapper {
  display: flex;
}

.child-equal-wrapper > div {
  flex: 1;
}

.child-equal-wrapper div:first-child:nth-last-child(1),
.child-equal-wrapper div:first-child:nth-last-child(1) ~ div {
  width: 100%;
}

.child-equal-wrapper div:first-child:nth-last-child(2),
.child-equal-wrapper div:first-child:nth-last-child(2) ~ div {
  width: 50%;
}

.child-equal-wrapper div:first-child:nth-last-child(3),
.child-equal-wrapper div:first-child:nth-last-child(3) ~ div {
  width: 33.33333%;
}

.child-equal-wrapper div:first-child:nth-last-child(4),
.child-equal-wrapper div:first-child:nth-last-child(4) ~ div {
  width: 25%;
}

.child-equal-wrapper div:first-child:nth-last-child(5),
.child-equal-wrapper div:first-child:nth-last-child(5) ~ div {
  width: 20%;
}

.child-equal-wrapper div:first-child:nth-last-child(6),
.child-equal-wrapper div:first-child:nth-last-child(6) ~ div {
  width: 16.66667%;
}

.child-equal-wrapper div:first-child:nth-last-child(7),
.child-equal-wrapper div:first-child:nth-last-child(7) ~ div {
  width: 14.28571%;
}

.child-equal-wrapper div:first-child:nth-last-child(8),
.child-equal-wrapper div:first-child:nth-last-child(8) ~ div {
  width: 12.5%;
}

.child-equal-wrapper > div {
  height: 50px;
}

.justify-space-between {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
/*******  created 26/3/2019 ************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: middle;
  font-family: Montserrat, Cairo, sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  font-weight: normal;
}

body {
  direction: ltr;
}

body.rtl {
  direction: rtl;
}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:focus {
  outline: none;
}

input:focus {
  outline: none !important;
}

a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
div::-webkit-scrollbar,
section::-webkit-scrollbarb,
main::-webkit-scrollbar,
ul::-webkit-scrollbar,
.ant-layout::-webkit-scrollbar {
  background-color: #f4f4f4 !important;
  width: 7px !important;
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb,
.ant-layout::-webkit-scrollbar-thumb {
  background-color: #077aa3 !important;
}

.ant-form-item-with-help {
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:default,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.slide-right {
  opacity: 0;
  transform: translateX(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-left {
  opacity: 0;
  transform: translateX(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-top {
  opacity: 0;
  transform: translateY(-50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.slide-bottom {
  opacity: 0;
  transform: translateY(50px);
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.rotate-y {
  transform-style: preserve-3d;
  transform: rotateY(90deg);
  transition: all ease-in-out 0.3s;
}

.animation-active .slide-right {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-left {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-top {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .slide-bottom {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition: all ease-in-out 0.4s;
}

.animation-active .rotate-y {
  transform: rotateY(0);
}

.animation-active .charger-img-holder > div {
  top: 0 !important;
}

.animation-active .rotate-moving {
  animation-name: chargingMove;
  animation-duration: .7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

body.rtl .animation-active .rotate-moving {
  animation-name: chargingMoveRtl;
}

@media only screen and (max-width: 767px) {
  .ant-menu-inline > .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    margin: auto;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1190px;
    margin: auto;
  }
}

@media (min-width: 1300px) {
  .container {
    width: 1280px;
    margin: auto;
  }
}

/***********************headeings*********************/
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  color: #4b4b4b;
  line-height: normal;
}

h1 {
  font-size: 2.1875rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5625rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 0.875rem;
  font-weight: normal;
}

/*************TEXT******************/
.black-text {
  color: #000;
}

.white-text {
  color: #FFF !important;
}

.dark-blue-text {
  color: #00adee;
}

.primary {
  color: #077aa3;
}

.primary-2 {
  color: #00adee;
}

.primary-3 {
  color: #249D45;
}

.primary-4 {
  color: #80D698;
}

.secondary {
  color: #2db2bb;
}

.secondary-2 {
  color: #c30c00;
}

.secondary-3 {
  color: #df5950;
}

.gray {
  color: #DADCE0;
}

.gray-2 {
  color: #CBCBCB;
}

.gray-3 {
  color: #f9f9fc;
}

.gray-4 {
  color: #7e7e7e;
}

.gray-5 {
  color: #656565;
}

.gray-6 {
  color: #4b4b4b;
}

.gray-7 {
  color: #242424;
}

/*disabled link*/
.isdisabled, .primary_link:disabled, .secondary_link:disabled, .Grayscale_link:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

/*backgrounds*/
.bg-white {
  background-color: #FFF;
}

.bg-primary {
  background-color: #077aa3;
}

.bg-primary-2 {
  background-color: #00adee;
}

.bg-primary-3 {
  background-color: #249D45;
}

.bg-primary-4 {
  background-color: #80D698;
}

.bg-secondary {
  background-color: #2db2bb;
}

.bg-secondary-2 {
  background-color: #c30c00;
}

.bg-secondary-3 {
  background-color: #df5950;
}

.bg-gray {
  background-color: #DADCE0;
}

.bg-gray-2 {
  background-color: #CBCBCB;
}

.bg-gray-3 {
  background-color: #f9f9fc;
}

.bg-gray-4 {
  background-color: #7e7e7e;
}

.bg-gray-5 {
  background-color: #656565;
}

.bg-gray-6 {
  background-color: #4b4b4b;
}

.bg-gray-7 {
  background-color: #242424;
}

.primary-fill, .primary-outline, .secondary-fill, .secondary-outline, .grayscale-fill, .grayscale-outline, .grayscale-light-fill, .grayscale-light-outline, .warning-fill, .warning-outline, .info-fill, .info-outline, .gradient-fill, .gradient-light-fill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 33px !important;
  font-size: 13px;
  font-weight: 500;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
}

.sm-btn.primary-fill, .sm-btn.primary-outline, .sm-btn.secondary-fill, .sm-btn.secondary-outline, .sm-btn.grayscale-fill, .sm-btn.grayscale-outline, .sm-btn.grayscale-light-fill, .sm-btn.grayscale-light-outline, .sm-btn.warning-fill, .sm-btn.warning-outline, .sm-btn.info-fill, .sm-btn.info-outline, .sm-btn.gradient-fill, .sm-btn.gradient-light-fill {
  min-height: 24px !important;
  padding: 0 18px;
  font-size: 14px;
  font-weight: normal;
}

.rounded.primary-fill, .rounded.primary-outline, .rounded.secondary-fill, .rounded.secondary-outline, .rounded.grayscale-fill, .rounded.grayscale-outline, .rounded.grayscale-light-fill, .rounded.grayscale-light-outline, .rounded.warning-fill, .rounded.warning-outline, .rounded.info-fill, .rounded.info-outline, .rounded.gradient-fill, .rounded.gradient-light-fill {
  border-radius: 20px;
}

.light-green.primary-fill, .light-green.primary-outline, .light-green.secondary-fill, .light-green.secondary-outline, .light-green.grayscale-fill, .light-green.grayscale-outline, .light-green.grayscale-light-fill, .light-green.grayscale-light-outline, .light-green.warning-fill, .light-green.warning-outline, .light-green.info-fill, .light-green.info-outline, .light-green.gradient-fill, .light-green.gradient-light-fill {
  color: #008539 !important;
  border: 1px solid #008539 !important;
}

.light-green.primary-fill:hover, .light-green.primary-outline:hover, .light-green.secondary-fill:hover, .light-green.secondary-outline:hover, .light-green.grayscale-fill:hover, .light-green.grayscale-outline:hover, .light-green.grayscale-light-fill:hover, .light-green.grayscale-light-outline:hover, .light-green.warning-fill:hover, .light-green.warning-outline:hover, .light-green.info-fill:hover, .light-green.info-outline:hover, .light-green.gradient-fill:hover, .light-green.gradient-light-fill:hover {
  background-color: #008539 !important;
  color: white !important;
}

.light-green-bg.primary-fill, .light-green-bg.primary-outline, .light-green-bg.secondary-fill, .light-green-bg.secondary-outline, .light-green-bg.grayscale-fill, .light-green-bg.grayscale-outline, .light-green-bg.grayscale-light-fill, .light-green-bg.grayscale-light-outline, .light-green-bg.warning-fill, .light-green-bg.warning-outline, .light-green-bg.info-fill, .light-green-bg.info-outline, .light-green-bg.gradient-fill, .light-green-bg.gradient-light-fill {
  background-color: #008539 !important;
}

.light-green-bg.primary-fill:focus, .light-green-bg.primary-outline:focus, .light-green-bg.secondary-fill:focus, .light-green-bg.secondary-outline:focus, .light-green-bg.grayscale-fill:focus, .light-green-bg.grayscale-outline:focus, .light-green-bg.grayscale-light-fill:focus, .light-green-bg.grayscale-light-outline:focus, .light-green-bg.warning-fill:focus, .light-green-bg.warning-outline:focus, .light-green-bg.info-fill:focus, .light-green-bg.info-outline:focus, .light-green-bg.gradient-fill:focus, .light-green-bg.gradient-light-fill:focus {
  color: #fff;
}

.lg-btn.primary-fill, .lg-btn.primary-outline, .lg-btn.secondary-fill, .lg-btn.secondary-outline, .lg-btn.grayscale-fill, .lg-btn.grayscale-outline, .lg-btn.grayscale-light-fill, .lg-btn.grayscale-light-outline, .lg-btn.warning-fill, .lg-btn.warning-outline, .lg-btn.info-fill, .lg-btn.info-outline, .lg-btn.gradient-fill, .lg-btn.gradient-light-fill {
  min-height: 38px !important;
  padding: 0 3.125rem;
  font-size: 16px;
  font-weight: normal;
}

.xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
  min-height: 47px !important;
  padding: 0.75rem 3.25rem;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 1.06;
}

@media only screen and (max-width: 575px) {
  .xlg-btn.primary-fill, .xlg-btn.primary-outline, .xlg-btn.secondary-fill, .xlg-btn.secondary-outline, .xlg-btn.grayscale-fill, .xlg-btn.grayscale-outline, .xlg-btn.grayscale-light-fill, .xlg-btn.grayscale-light-outline, .xlg-btn.warning-fill, .xlg-btn.warning-outline, .xlg-btn.info-fill, .xlg-btn.info-outline, .xlg-btn.gradient-fill, .xlg-btn.gradient-light-fill {
    min-height: 40px !important;
    padding: 0.75rem 1.25rem;
    font-size: 15px;
  }
}

.primary-fill:focus, .primary-outline:focus, .secondary-fill:focus, .secondary-outline:focus, .grayscale-fill:focus, .grayscale-outline:focus, .grayscale-light-fill:focus, .grayscale-light-outline:focus, .warning-fill:focus, .warning-outline:focus, .info-fill:focus, .info-outline:focus, .gradient-fill:focus, .gradient-light-fill:focus {
  outline: none !important;
}

.sharpe.primary-fill, .sharpe.primary-outline, .sharpe.secondary-fill, .sharpe.secondary-outline, .sharpe.grayscale-fill, .sharpe.grayscale-outline, .sharpe.grayscale-light-fill, .sharpe.grayscale-light-outline, .sharpe.warning-fill, .sharpe.warning-outline, .sharpe.info-fill, .sharpe.info-outline, .sharpe.gradient-fill, .sharpe.gradient-light-fill {
  border-radius: 6px;
}

.primary-fill {
  background: #077aa3;
  color: #FFF;
}

.primary-fill:focus {
  background: #077aa3;
  color: #FFF;
}

.primary-fill:active {
  background: #077aa3;
  color: #FFF;
}

.primary-fill:hover {
  background: #077aa3;
  color: #FFF;
  border-color: #077aa3;
}

.primary-outline {
  background-color: #fff;
  color: #077aa3;
  border: 1px solid #077aa3;
  border-radius: 4px;
}

.primary-outline:hover {
  color: #fff;
  background-color: #077aa3;
  border-color: #077aa3;
}

.secondary-fill {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:focus {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:active {
  background: #2db2bb;
  color: #FFF;
}

.secondary-fill:hover {
  background: #2db2bb;
  color: #FFF;
  border-color: #2db2bb;
}

.secondary-outline {
  background-color: #fff;
  color: #2db2bb;
  border: 1px solid #2db2bb;
  border-radius: 4px;
}

.secondary-outline:hover {
  color: #fff;
  background-color: #2db2bb;
  border-color: #2db2bb;
}

.grayscale-fill {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:focus {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:active {
  background: #4b4b4b;
  color: #FFF;
}

.grayscale-fill:hover {
  background: #FFF;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
}

.grayscale-outline {
  background-color: #fff;
  color: #4b4b4b;
  border: 1px solid #4b4b4b;
  border-radius: 4px;
}

.grayscale-outline:hover {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.grayscale-light-fill {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:focus {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:active {
  background: #f9f9fc;
  color: #FFF;
}

.grayscale-light-fill:hover {
  background: #f9f9fc;
  color: #FFF;
  border-color: #f9f9fc;
}

.grayscale-light-outline {
  background-color: #fff;
  color: #f9f9fc;
  border: 1px solid #f9f9fc;
  border-radius: 4px;
}

.grayscale-light-outline:hover {
  color: #fff;
  background-color: #f9f9fc;
  border-color: #f9f9fc;
}

.warning-fill {
  background: #d83025;
  color: #FFF;
}

.warning-fill:focus {
  background: #d83025;
  color: #FFF;
}

.warning-fill:active {
  background: #d83025;
  color: #FFF;
}

.warning-fill:hover {
  background: #d83025;
  color: #FFF;
  border-color: #d83025;
}

.warning-outline {
  background-color: #fff;
  color: #d83025;
  border: 1px solid #d83025;
  border-radius: 4px;
}

.warning-outline:hover {
  color: #fff;
  background-color: #d83025;
  border-color: #d83025;
}

.info-fill {
  background: #d4b425;
  color: #FFF;
}

.info-fill:focus {
  background: #d4b425;
  color: #FFF;
}

.info-fill:active {
  background: #d4b425;
  color: #FFF;
}

.info-fill:hover {
  background: #d4b425;
  color: #FFF;
  border-color: #d4b425;
}

.info-outline {
  background-color: #fff;
  color: #d4b425;
  border: 1px solid #d4b425;
  border-radius: 4px;
}

.info-outline:hover {
  color: #fff;
  background-color: #d4b425;
  border-color: #d4b425;
}

.gradient-fill {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:focus {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:active {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
}

.gradient-fill:hover {
  background: linear-gradient(to left, #008539, #008539 3%);
  color: #FFF;
  border-color: linear-gradient(to left, #008539, #008539 3%);
}

.gradient-light-fill {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:focus {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:active {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
}

.gradient-light-fill:hover {
  background: linear-gradient(89deg, #008539 1%, #5db92c 100%);
  color: #FFF;
  border-color: linear-gradient(89deg, #008539 1%, #5db92c 100%);
}

.remove-icon-btn {
  display: flex;
  align-items: center;
  color: #c30c00 !important;
  background: transparent !important;
  border: 0px;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
}

.Primary_fill {
  background-color: #00adee;
  border: 1px solid #00adee;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.3s;
  cursor: pointer;
  text-transform: capitalize;
}

.Primary_fill[disabled],
.Primary_fill[disabled] {
  background-color: #00adee;
  border: 1px solid #00adee;
  color: #fff;
  opacity: 0.4;
}

.primary__btn {
  background-color: #00678d;
  color: #ffffff;
}

.cancel__btn {
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #ff0000;
}

.secondary__btn {
  background-color: #0793c7;
  color: #fff;
}

@media only screen and (max-width: 399px) {
  .add__quiz--footer button {
    font-size: 13px !important;
  }
}

.filter-messages-wrapper {
  min-width: 170px;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding-top: 14px;
  padding-bottom: 20px;
}

.filter-messages-wrapper .ant-dropdown-menu-group {
  display: flex;
  flex-direction: column;
}

.filter-messages-wrapper .ant-dropdown-menu-group > label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
  padding-left: 10px;
  padding-bottom: 10px;
  color: #303030;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.filter-messages-wrapper .ant-dropdown-menu-group > label.ant-radio-wrapper-checked {
  color: #077aa3;
}

.filter-messages-wrapper .ant-dropdown-menu-group > label:last-of-type, .filter-messages-wrapper .ant-dropdown-menu-group > label:first-of-type {
  padding-bottom: 0;
}

.filter-messages-wrapper .ant-dropdown-menu-group .ant-divider {
  margin: 7px 0;
  margin-bottom: 4px;
}

/******************links***************/
/*primary_link*/
.primary_link {
  color: #077aa3;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.primary_link:hover {
  text-decoration: underline;
}

.primary_link:active, .primary_link:focus {
  text-decoration: none;
}

.primary_link:disabled {
  color: red !important;
}

.primary_link:hover {
  color: #00adee;
}

/*secondary_link*/
.secondary_link {
  color: #2db2bb;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.secondary_link:hover {
  text-decoration: underline;
}

.secondary_link:active, .secondary_link:focus {
  text-decoration: none;
}

.secondary_link:disabled {
  color: red !important;
}

.secondary_link:hover {
  color: #c30c00;
}

/*Grayscale_link*/
.Grayscale_link {
  color: #4b4b4b;
  display: inline-block;
  transition: 0.3s;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

.Grayscale_link:hover {
  text-decoration: underline;
}

.Grayscale_link:active, .Grayscale_link:focus {
  text-decoration: none;
}

.Grayscale_link:disabled {
  color: red !important;
}

.Grayscale_link:hover {
  color: #000;
}

.ant-radio-group-outline .ant-radio-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  color: #242424;
  box-shadow: none !important;
}

.ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #077aa3;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  border-color: #077aa3;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #077aa3;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper {
  direction: rtl;
}

body.rtl .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner:after {
  transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper:hover {
  color: #077aa3;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #077aa3;
}

.ant-radio-group-outline .ant-radio-wrapper:hover .ant-radio-inner:after {
  transform: scale(1);
  opacity: 1;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled .ant-radio-inner {
  background-color: #FFF;
}

.ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper {
  color: #4b4b4b;
  font-size: 15px;
  font-weight: bold;
}

.ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-width: 2px;
  border-color: #242424;
  box-shadow: none !important;
}

.ant-checkbox-wrapper .ant-checkbox-inner.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  border-color: #077aa3;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #077aa3;
  border-color: #077aa3;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #077aa3;
}

.ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #077aa3;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled .ant-checkbox-inner {
  background-color: #FFF;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-checkbox + span {
  color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled:hover .ant-radio-inner:after {
  opacity: 0;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-inner {
  border-radius: 3px;
  border: solid 2px #e2e2e2;
}

.ant-checkbox-wrapper.remove-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #c30c00;
  border-color: #c30c00;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #077aa3;
}

.ant-switch {
  min-width: 50px !important;
  height: 16px !important;
  margin-left: 5px !important;
  background-color: #e5e5e5;
}

body.rtl .ant-switch {
  margin-left: 0 !important;
  margin-right: 12px !important;
}

.ant-switch::after {
  width: 25px;
  height: 25px;
  top: -6px;
  margin-left: -5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #FAFAFA;
}

.ant-switch.ant-switch-checked {
  background-color: #80D698;
}

.ant-switch.ant-switch-checked::after {
  margin-left: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #077aa3;
}

.switch-input-holder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.switch-input-holder span {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
}

.switch-input-holder .ant-form-item {
  display: flex;
  align-items: center;
}

.switch-input-holder .ant-form-item .ant-form-item-label {
  position: static;
}

.switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-right: 15px;
  font-size: 14px;
  min-width: 50px;
  text-align: left;
}

body.rtl .switch-input-holder .ant-form-item .ant-form-item-label label {
  margin-left: 15px;
  margin-right: 0;
  text-align: right;
}

.upload-input-wrapper .ant-form-item-label {
  top: 19px !important;
  left: 30px !important;
  pointer-events: none;
}

body.rtl .upload-input-wrapper .ant-form-item-label {
  right: 30px !important;
  left: auto !important;
}

.upload-input-wrapper .ant-form-item-label label {
  font-size: 17px !important;
  color: #989898 !important;
  pointer-events: none;
}

.upload-input-wrapper .ant-form-item-children > span {
  display: flex;
  flex-direction: column-reverse;
}

body.rtl .upload-input-wrapper .ant-upload-list-item-actions {
  right: 0;
  left: auto;
  transform: translate(-50%, -50%);
}

.upload-input-wrapper .ant-upload {
  width: 100% !important;
  border: none !important;
  background: none !important;
  height: auto !important;
}

.upload-input-wrapper .ant-upload button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 55px;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dadce0 !important;
  padding-right: 0 !important;
}

body.rtl .upload-input-wrapper .ant-upload button {
  padding-right: 10px !important;
}

.upload-input-wrapper .ant-upload button i {
  font-size: 22px;
  padding: 6px;
  background: #e0e0e0;
  border-radius: 50%;
  color: #000000;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item {
  padding: 0;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info {
  display: flex;
  align-items: center;
}

.upload-input-wrapper .ant-upload-list .ant-upload-list-item-info:after {
  top: 8px;
  right: 8px;
}

.upload-input-wrapper .ant-upload-list-item-uploading-text {
  display: none;
}

.upload-input-wrapper .ant-upload-list-item-name {
  font-size: 0;
}

.ant-input-number {
  border: none;
  border-bottom: 1px solid #dadce0;
  border-radius: 0;
  width: 100%;
  margin-top: 20px;
}

.ant-input-number.ant-input-number-focused {
  border-bottom: 1px solid #077aa3;
  outline: none !important;
  box-shadow: none !important;
}

.ant-input-number:hover {
  border-bottom: 1px solid #dadce0;
}

.ant-input-number ::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

.ant-input-number .ant-input-number-input {
  padding: 0 !important;
}

body.rtl .ant-input-number .ant-input-number-input {
  text-align: right !important;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1 !important;
  border: none !important;
}

body.rtl .ant-input-number .ant-input-number-handler-wrap {
  right: unset !important;
  left: 0;
}

.ant-input-number .ant-input-number-handler-down {
  border: none !important;
}

.counter-input-holder {
  display: flex;
  flex-direction: column;
}

.counter-input-holder .counter-header {
  font-size: 14px;
  font-weight: bold;
  color: #5a5a5a;
  margin-bottom: 5px;
}

.counter-input-holder .ant-form-item {
  display: flex !important;
}

.counter-input-holder .ant-form-item .ant-input-number {
  min-height: 55px;
  min-width: 100px;
}

.counter-input-holder .ant-input-number-input {
  height: 100%;
  padding-top: 18px;
  text-align: left;
}

body.rtl .counter-input-holder .ant-input-number-input {
  text-align: right;
}

.counter-input-holder .ant-input-number-input-wrap {
  height: 100%;
  font-size: 16px;
  color: #242424;
}

.counter-input-holder .ant-input-number-handler-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 30px;
  padding-bottom: 8px;
  top: auto;
  bottom: 0;
  right: 0;
  border: 0;
  opacity: 1 !important;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap {
  left: 0;
  right: auto;
}

.counter-input-holder .ant-input-number-handler-wrap span {
  position: relative;
  height: 16px !important;
  width: 16px;
  font-size: 16px;
  border: 0;
  margin-right: 8px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #dadce0;
  border-radius: 2px;
}

.counter-input-holder .ant-input-number-handler-wrap span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-up::after {
  content: "\002B";
}

.counter-input-holder .ant-input-number-handler-wrap span.ant-input-number-handler-down::after {
  content: "\2212";
}

.counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 0;
}

body.rtl .counter-input-holder .ant-input-number-handler-wrap span:last-of-type {
  margin-right: 20px;
  margin-left: 0;
}

.counter-input-holder .ant-input-number-handler-wrap span i {
  display: none !important;
}

.ant-input {
  height: 47px;
  border-radius: 4px;
  border: solid 1px #d4d4d4;
  background-color: transparent !important;
  padding: 0 16px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.7px;
  color: #000000;
}

.ant-input:-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input::-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input:-ms-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input::-webkit-input-placeholder {
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: normal;
  color: #00000080;
}

.ant-input:hover {
  outline: none;
}

.ant-input:focus {
  border-color: #077aa3;
  box-shadow: none;
  transition: 0.3s;
}

.ant-form-item {
  position: relative;
  outline: none;
}

.ant-form-item:focus-within label {
  color: #077aa3 !important;
}

.ant-form-item .ant-form-item-label {
  position: relative;
  line-height: unset;
  top: -6px;
  left: 0px;
  z-index: 1;
  transition: all ease 0.3s;
}

.ant-form-item .ant-form-item-label label {
  font-size: 16px;
  color: #7e7e7e;
  font-weight: normal;
}

.ant-form-item .ant-form-item-label label::before, .ant-form-item .ant-form-item-label label::after {
  display: none;
}

body.rtl .ant-form-item .ant-form-item-label {
  left: auto;
  right: 0px;
}

.ant-form-item .ant-form-item-control {
  outline: none;
}

.ant-form-item.ant-form-item-with-help {
  margin-bottom: 15px;
  color: #123456;
}

.ant-form-item.ant-form-item-with-help .ant-form-item-label label {
  color: #d83025 !important;
}

.ant-input-affix-wrapper,
.ant-form-item-control-wrapper {
  outline: none;
}

.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover,
.has-error .ant-input,
.has-error .ant-input:hover {
  border-bottom: solid 1px #d83025 !important;
  box-shadow: none !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 2px 0px 0 0px;
  text-align: left;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-right-width: 1px !important;
}

.ant-input[disabled] {
  background-color: #f2f2f2;
}

.ant-select .ant-select-selection {
  height: 38px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  color: #242424;
  box-shadow: none;
  margin: 0;
}

.ant-select .ant-select-selection .ant-select-selection__rendered {
  margin: 0;
  padding: 10px;
  line-height: 20px;
  top: -2px;
}

.ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 16px;
  color: #a3a3a3;
  font-weight: 500;
}

.ant-select.ant-select-focused .ant-select-selection {
  box-shadow: none;
  transition: 0.3s;
}

.ant-select.ant-select-auto-complete .ant-select-selection {
  border-bottom: solid 1px #dadce0;
}

.ant-select.ant-select-auto-complete .ant-input {
  border: 0 !important;
  padding: 0 !important;
}

.ant-select.ant-select-auto-complete .ant-select-selection__placeholder {
  margin: 0;
}

.ant-input-search {
  height: 35px;
}

.ant-input-search .ant-input {
  height: 35px;
  background-color: rgba(255, 255, 255, 0.47);
}

.ant-input-search .ant-input:-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-moz-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #949494;
}

.ant-input-search .ant-input-suffix i {
  font-size: 18px;
  color: #077aa3;
}

.ant-upload.ant-upload-select-picture-card {
  display: flex;
  border-radius: 50% !important;
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-select-picture-card img {
  height: 100%;
  border-radius: 50%;
}

.ant-upload.ant-upload-select-picture-card.ant-upload-disabled {
  opacity: 0.3;
}

body.rtl .ant-upload.ant-upload-select-picture-card {
  display: flex;
}

.ant-select-dropdown .ant-select-dropdown-menu {
  /* Track */
  /* Handle */
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.ant-select-dropdown .ant-select-dropdown-menu::-webkit-scrollbar-thumb {
  background: #747474;
  border-radius: 5px;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: 23px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  background-color: #000000d9;
}

.ant-pagination li:hover {
  border-color: #077aa3;
}

.ant-pagination li:hover a {
  color: #077aa3 !important;
  border-color: #077aa3 !important;
}

.ant-pagination .ant-pagination-item-active {
  border-color: #077aa3 !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #077aa3;
}

.ant-pagination .ant-pagination-jump-prev {
  order: -1;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-next {
  order: 999;
  color: #000000;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  display: none;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link-icon,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link-icon {
  opacity: 1;
}

.ant-pagination .ant-pagination-jump-prev .anticon svg,
.ant-pagination .ant-pagination-jump-next .anticon svg {
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-weight: bold;
}

.ant-pagination .ant-pagination-jump-prev:hover .anticon svg,
.ant-pagination .ant-pagination-jump-next:hover .anticon svg {
  color: #077aa3 !important;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-bottom: 1px solid #077aa3 !important;
  transition: 0.3s;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #077aa3;
  border-bottom: 1px solid #077aa3 !important;
  transition: 0.3s;
}

.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #d83025;
  border-bottom: 1px solid #d83025 !important;
  transition: 0.3s;
}

.has-error .ant-select-selection {
  border-color: #d83025 !important;
  border-width: 2px !important;
}

.phone-input-holder .ant-form-item-label {
  height: fit-content;
  position: absolute;
  left: 60px;
}

.phone-input-holder .dlt-phone {
  display: none;
}

.phone-input-holder.has-delete .ant-form-item-children {
  display: flex;
  align-items: center;
}

.phone-input-holder.has-delete .dlt-phone {
  display: flex;
  cursor: pointer;
  margin-right: -20px;
}

.search-wrapper .ant-input-search .ant-input {
  min-width: 250px;
  padding-top: 0 !important;
  border-radius: 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid #b6b6b6;
  padding: 0 4px !important;
}

.search-wrapper .ant-input-search .ant-input:focus, .search-wrapper .ant-input-search .ant-input:hover {
  border: 0 !important;
  border-bottom: 1px solid #077aa3 !important;
}

.search-wrapper .ant-input-search .ant-input:focus + .ant-input-suffix i, .search-wrapper .ant-input-search .ant-input:hover + .ant-input-suffix i {
  color: #077aa3;
  transform: scale(1.1) rotate(2deg);
}

.search-wrapper .ant-input-search .ant-input-suffix {
  right: 0;
}

.search-wrapper .ant-input-search .ant-input-suffix i {
  transition: all ease-in-out 0.3s;
  color: #b6b6b6;
}

.comment-textArea {
  border: solid 0.5px #cacaca !important;
  padding: 4px 11px !important;
}

body.rtl .comment-textArea {
  text-align: right;
}

.comment-textArea.ant-input:focus {
  border: solid 0.5px #cacaca !important;
}

.tel-wrapper {
  margin-bottom: 13px;
}

.tel-wrapper .react-tel-input {
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
  height: 45px;
  outline: 0;
}

.tel-wrapper .react-tel-input .form-control {
  box-shadow: none;
  min-height: 47px !important;
  height: 47px;
}

.tel-wrapper .react-tel-input .flag-dropdown {
  position: static;
  width: 50px;
  height: 30px;
  margin-right: -53px;
  margin-top: 10px;
  z-index: 4;
  display: flex;
  border: none;
  background: transparent;
}

body.rtl .tel-wrapper .react-tel-input .flag-dropdown {
  margin-right: 0 !important;
  margin-left: -53px !important;
}

.tel-wrapper .react-tel-input .flag-dropdown:hover {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .flag-dropdown.open {
  background: transparent !important;
}

.tel-wrapper .react-tel-input .form-control.open {
  z-index: unset !important;
}

.tel-wrapper .country-list {
  width: 100% !important;
  top: 40px;
}

.tel-wrapper .country-list .search {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.tel-wrapper .country-list .search .search-emoji {
  display: none;
}

.tel-wrapper .country-list .country {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5px !important;
  height: 35px;
}

.tel-wrapper .country-list .country .flag {
  margin-left: 10px;
}

body.rtl .tel-wrapper .country-list .country .flag {
  margin-left: 0;
  margin-right: 10px;
}

body.rtl .timeline-due-wrapper .ant-radio-button-wrapper {
  padding: 0px 7px !important;
}

body.rtl .timeline-due-wrapper .ant-radio-button-wrapper:first-child {
  border-left: none !important;
  border-radius: 0 4px 4px 0 !important;
  border-right: 1px solid #d9d9d9 !important;
}

body.rtl .timeline-due-wrapper .ant-radio-button-wrapper:last-child {
  border-right: none !important;
  border-radius: 4px 0 0 4px !important;
}

@media only screen and (max-width: 575px) {
  .ant-calendar-picker-container {
    right: 5px !important;
    left: auto !important;
    top: 13px !important;
  }
  body.rtl .ant-calendar-picker-container {
    left: 5px !important;
    right: auto  !important;
  }
}

@media only screen and (max-width: 350px) {
  .ant-calendar-picker-container .ant-calendar.ant-calendar-range {
    width: 290px !important;
    max-height: 90vh !important;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 575px) {
  body.rtl .ant-calendar-picker-container .ant-calendar.ant-calendar-range {
    width: 310px !important;
  }
}

@media only screen and (max-width: 350px) {
  body.rtl .ant-calendar-picker-container .ant-calendar.ant-calendar-range {
    width: 290px !important;
    max-height: 90vh !important;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 575px) {
  .ant-calendar-picker-container .ant-calendar-date-input-wrap {
    margin-left: 0;
    margin-right: 0 !important;
  }
}

.react-tel-input input[type=text],
.react-tel-input input[type=tel] {
  width: 100% !important;
  min-height: 55px;
  padding-left: 60px !important;
}

.react-tel-input {
  min-height: 55px;
}

.react-tel-input .flag-dropdown {
  min-width: 50px;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 11px;
}

.react-tel-input .flag-dropdown:hover .selected-flag {
  background-color: #eaeaea !important;
}

.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

body.rtl .react-tel-input input[type=text],
body.rtl .react-tel-input input[type=tel] {
  padding-right: 60px !important;
}

.react-tel-input .flag-dropdown.open-dropdown {
  background: #eaeaea !important;
  border-radius: 3px 0 0 0;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lottie-spinner {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  transform: scale(0.8);
}

.croping-modal .ant-modal-close .ant-modal-close-x {
  width: 28px;
  height: 56px;
  font-size: 16px;
  line-height: 34px;
}

.croping-modal.rounded .cropper-view-box,
.croping-modal.rounded .cropper-move {
  border-radius: 50%;
}

.croping-modal.rounded .ant-modal-close {
  top: 0px !important;
}

.croping-modal.rounded .ant-modal-close .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.pac-item {
  text-align: left !important;
}

body.rtl .pac-item {
  text-align: right !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  -webkit-background-color: #888 !important;
  border-radius: 1ex;
  -webkit-border-radius: 1ex;
  box-shadow: 0px 1px 2px #888;
  -webkit-box-shadow: 0px 1px 2px #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

body.hide-scroll::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

header.main-header {
  height: 52px;
  margin-bottom: 28px;
  box-shadow: 0 1px 0 0 rgba(107, 107, 107, 0.16);
  background-color: #00678d;
  outline: 28px solid whitesmoke;
  position: relative;
  z-index: 30;
}

header.main-header .no-internet-message {
  background: #dc4545;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
}

header.main-header .main-menu {
  display: flex;
  align-items: center;
}

header.main-header .main-menu ul {
  display: flex;
  align-items: center;
}

header.main-header .main-menu ul a {
  color: #fff;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid #fff;
  cursor: pointer;
}

header.main-header .main-menu ul a.active {
  padding: 16px;
  background-color: #0083b4;
  border: none;
}

header.main-header .main-menu ul a:last-of-type {
  border-right: 1px solid #fff;
}

header.main-header .main-menu ul a:last-of-type.active {
  border: none;
}

body.rtl header.main-header .main-menu ul a.active {
  padding: 13px;
}

body.rtl header.main-header .main-menu ul a:first-of-type {
  border-right: none;
}

body.rtl header.main-header .main-menu ul a:last-of-type {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

body.rtl header.main-header .main-menu ul a:last-of-type.active {
  border: none;
}

header.main-header .main-menu ul a span {
  font-size: 20px;
  padding: 8px;
}

@media only screen and (max-width: 767px) {
  header.main-header {
    margin-bottom: 0;
    outline: 0;
    z-index: 10;
  }
}

header.main-header .container {
  height: 100%;
}

header.main-header .calendar-icon-res {
  display: none;
}

@media only screen and (max-width: 991px) {
  header.main-header .calendar-icon-res {
    display: block;
  }
  header.main-header .calendar-icon-res ~ span {
    display: none;
  }
}

header.main-header .main-header--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  header.main-header .main-header--content {
    padding: 0 20px;
  }
}

header.main-header .main-header--content .ant-badge-count {
  min-width: 13px;
  height: 13px;
  padding: 0 2px;
  color: #fff;
  font-weight: normal;
  font-size: 8px;
  line-height: 13px;
  background-color: #ff0000;
  border: 0;
}

header.main-header .main-header--content .ant-badge-count p {
  font-size: 8px;
}

header.main-header .main-header--content__action {
  display: flex;
  align-items: center;
}

header.main-header .main-header--comment {
  margin-right: 30px;
}

body.rtl header.main-header .main-header--comment {
  margin-right: 0;
  margin-left: 30px;
}

header.main-header .main-header--notification {
  margin-right: 14px;
}

body.rtl header.main-header .main-header--notification {
  margin-right: 0;
  margin-left: 14px;
}

header.main-header .main-header--lang {
  color: #fff;
  border: none !important;
  border-radius: 4px;
  min-height: 30px;
  outline: 0;
  padding: 20px;
}

header.main-header .main-header--lang span {
  padding: 5px;
}

body.rtl header.main-header .main-header--lang {
  margin-right: 0;
}

header .profile-holder {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  border-radius: 50%;
  margin: 0 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

header .profile-holder.profile-holder-mobile::after {
  display: none;
}

header .profile-holder::after {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

body.rtl header .profile-holder::after {
  right: auto;
  left: -20px;
  border-radius: 50% 0 0 50%;
}

header .profile-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

header .profile-holder i {
  color: #fff;
  font-size: 20px;
  margin-right: -15px;
}

aside.teacher-info-wrapper {
  width: 220px;
  min-width: 220px;
  height: 100%;
  position: sticky;
  top: 0;
}

aside.teacher-info-wrapper > div {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-bottom: 10px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-detailes {
  text-align: center;
  padding-bottom: 18px;
  border-bottom: solid 1px #c0c0c0;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .top-bg {
  height: 58px;
  background-color: #0b85b2;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper--teacher-img {
  width: 77px;
  height: 78px;
  margin: auto;
  margin-top: -39px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #FFF;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper--teacher-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper--teacher-name {
  font-family: SegoeUI;
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  line-height: 1.29;
  margin-top: 5px;
  margin-bottom: 5px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper--profile-link {
  font-size: 14px;
  font-weight: normal;
  color: #9797ac;
  line-height: 1.29;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list {
  height: 210px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  transition: height ease-in-out 0.3s;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list.collapsed {
  height: 0;
  padding: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list.collapsed + .collapse-btn {
  border: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .anticon {
  transform: rotate(180deg);
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .classes-mode {
  display: block;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .classes-mode + p {
  display: none;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list > a {
  display: flex;
  align-items: center;
  height: 55px;
  min-height: 55px;
  padding: 0 18px;
  margin-bottom: 10px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-icon {
  margin-right: 10px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-icon span {
  font-size: 25px;
}

body.rtl aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-icon {
  margin-left: 10px;
  margin-right: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-content {
  margin: 5px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-content h6 {
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: bold;
  color: #00678d;
  line-height: 1.33;
  margin-bottom: 3px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--top .teacher-info-wrapper-classes--list .class-content p {
  font-size: 8px;
  color: #9797ac;
  line-height: 1.25;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a {
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #b9b9b9;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  color: #929191;
  opacity: 0.8;
  text-decoration: none !important;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a.active {
  font-size: 17px;
  font-weight: bold;
  color: #20c0fd;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a.active span:before {
  color: #20c0fd;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a span {
  font-size: 25px;
  margin-right: 8px;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a span:before {
  color: #a8a7a7;
}

body.rtl aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a span {
  margin-left: 8px;
  margin-right: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper--nav ul > a:last-of-type {
  border-bottom: none;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list {
  max-height: 210px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  transition: height ease-in-out 0.3s;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list.collapsed {
  height: 0;
  padding: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list.collapsed + .collapse-btn {
  border: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .anticon {
  transform: rotate(180deg);
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .classes-mode {
  display: block;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list.collapsed + .collapse-btn .classes-mode + p {
  display: none;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list > a {
  display: flex;
  align-items: center;
  height: 55px;
  min-height: 55px;
  padding: 0 18px;
  margin-bottom: 10px;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-icon {
  margin-right: 10px;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-icon span {
  font-size: 25px;
}

body.rtl aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-icon {
  margin-left: 10px;
  margin-right: 0;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-content {
  margin: 5px;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-content h6 {
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: bold;
  color: #00678d;
  line-height: 1.33;
  margin-bottom: 3px;
}

aside.teacher-info-wrapper .teacher-info-wrapper-classes--list .class-content p {
  font-size: 8px;
  color: #9797ac;
  line-height: 1.25;
}

aside.teacher-info-wrapper .collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-top: solid 1px #c0c0c0;
}

aside.teacher-info-wrapper .collapse-btn p {
  font-family: SegoeUI;
  font-size: 14px;
  font-weight: bold;
  color: #6a6767;
  display: flex;
  align-items: center;
}

aside.teacher-info-wrapper .collapse-btn p a {
  display: flex;
  align-items: center;
  height: 55px;
  min-height: 55px;
  padding: 0 18px;
}

aside.teacher-info-wrapper .collapse-btn p a .class-icon {
  margin-right: 10px;
}

aside.teacher-info-wrapper .collapse-btn p a .class-icon span {
  font-size: 25px;
}

aside.teacher-info-wrapper .collapse-btn p a .class-content {
  margin: 5px;
}

aside.teacher-info-wrapper .collapse-btn p a .class-content h6 {
  font-size: 12px;
  font-weight: bold;
  color: #00678d;
  line-height: 1.33;
  margin-bottom: 3px;
}

aside.teacher-info-wrapper .collapse-btn p a .class-content p {
  font-size: 8px;
  color: #9797ac;
  line-height: 1.25;
}

aside.teacher-info-wrapper .collapse-btn .classes-mode {
  display: none;
}

aside.teacher-info-wrapper .collapse-btn .anticon {
  font-size: 10px;
  margin: 0 9px;
  margin-top: 5px;
  transition: height ease-in 0.3s;
}

@media only screen and (max-width: 991px) {
  aside.teacher-info-wrapper {
    display: none;
  }
}

.ant-table-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mid-grid .ant-table-body {
  height: 400px;
  overflow: auto;
}

.dashboard-wrapper .more-icon-holder i {
  font-size: 28px;
  cursor: pointer;
  color: #818385;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 25%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  justify-content: flex-end !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/station/fuel.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/station/car.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/station/car-out.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(../../resources/images/station/flag.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td .no-charger-out {
  color: #474747;
  font-size: 16px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2) {
  text-align: right !important;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  color: #008539;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(4) {
  color: #c30707;
}

.dashboard-wrapper .stations-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 25%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 24%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div, .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div {
  justify-content: flex-end;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/customers/user.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: left !important;
  color: #000000;
}

body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(2), body.rtl .dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:nth-child(3) {
  text-align: right !important;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 24%;
  text-align: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder {
  display: flex;
  align-items: center;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder {
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .customer-holder .img-holder img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #008539;
  text-transform: capitalize;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .customers-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  width: 5%;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type div::after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  content: url(../../resources/images/customers/user.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  content: url(../../resources/images/customers/credit-card.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(5) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-body tr > td:last-of-type {
  width: 5%;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th.ant-table-selection-column {
  padding: 15px;
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:last-of-type {
  padding: 0 45px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) {
  width: 19%;
  text-align: center;
  line-height: 1.31;
  padding-right: 15px !important;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter {
  display: block;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:not(.ant-table-selection-column) .ant-table-column-sorter::after {
  content: '';
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  content: url(../../resources/images/customers/email.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(3) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  content: url(../../resources/images/customers/phone.svg);
  margin-right: 10px;
}

body.rtl .dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(4) div:after {
  margin-right: 0;
  margin-left: 10px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:first-of-type {
  padding: 15px;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td:not(:first-of-type) {
  width: 19%;
  text-align: center;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder {
  color: #008539;
  text-transform: capitalize;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inactive {
  color: #c30707;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.delivered {
  color: #2d6fbb;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.inprogress {
  color: #bbbb2d;
}

.dashboard-wrapper .contactrequest-wrapper .ant-table-scroll .ant-table-tbody > tr > td .card-status-holder.requested {
  color: #2db2bb;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-thead tr > th:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(1) {
  width: 2%;
}

.masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(2), .masterdata-wrapper .ant-table .ant-table-scroll .ant-table-tbody tr > td:nth-child(3) {
  display: inline-block;
  width: 49%;
  max-width: 49%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) div:after {
  display: none;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll .ant-table-thead tr > th:nth-child(2) {
  padding: 12px 30px !important;
}

.dashboard-wrapper .cards-wrapper .ant-table-scroll tr > td:nth-child(2) {
  padding: 12px 30px !important;
}

.admin-modal-wrapper .ant-modal {
  width: 90% !important;
  max-width: 90%;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 639px) {
  .admin-modal-wrapper .ant-modal {
    width: 100% !important;
    max-width: 100%;
  }
}

.admin-modal-wrapper .ant-modal-content {
  height: 92%;
  width: 90%;
  margin: auto;
  top: 4%;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-close-x {
  color: #d83025;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #00adee;
}

body.rtl .admin-modal-wrapper .ant-modal-content .ant-modal-header {
  text-align: right !important;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-body {
  border-radius: 0;
  height: calc(100% - 50px - 90px);
  overflow: auto;
  padding: 0;
}

.admin-modal-wrapper .ant-modal-content .ant-modal-footer {
  justify-content: flex-end;
}

.admin-modal-wrapper .ant-form {
  height: 100%;
}

.admin-modal-wrapper .Personal-info-wrapper {
  height: auto;
  overflow: auto;
  padding-top: 0 !important;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper {
  padding: 0px 50px;
}

.admin-modal-wrapper .Personal-info-wrapper h2 {
  font-weight: 400;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse {
  background-color: transparent;
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header {
  border: 0;
  box-shadow: none;
  background: transparent;
  font-size: 11px;
  font-weight: bold;
  color: #00adee;
  width: fit-content;
  padding-left: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header p {
  width: fit-content;
  font-size: 11px;
  font-weight: bold;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  left: auto;
  right: 0;
  font-size: 9px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-item {
  border: 0;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content {
  border: 0px;
}

.admin-modal-wrapper .Personal-info-wrapper .ant-collapse .ant-collapse-content-box {
  padding: 0;
}

body.rtl .admin-modal-wrapper .Personal-info-wrapper .ant-switch {
  margin-right: 15px;
}

.admin-modal-wrapper .rules-wrapper {
  background-color: #f8f8f8;
  height: 100% !important;
  min-height: 450px;
}

body.rtl .admin-modal-wrapper .rules-wrapper {
  padding: 2%;
}

.admin-modal-wrapper .rules-wrapper h6 {
  color: #008539;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}

.admin-modal-wrapper .rules-wrapper .remove-icon-btn {
  font-size: 11px;
  font-weight: normal;
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper {
  height: 300px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .Privileges-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper {
  height: 220px;
  border: solid 0.8px #cacaca;
  background-color: #ffffff;
  padding: 10px 14px;
  overflow: auto;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox + span {
  color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group > .ant-checkbox-wrapper {
  margin-bottom: 5px;
  color: #404040;
  font-size: 15px;
  font-weight: normal;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .content .ant-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d83025;
  border-color: #d83025;
}

.admin-modal-wrapper .rules-wrapper .admins-preview-wrapper .admins-preview-placeholder {
  text-align: center;
  font-size: 12px;
  color: #a4a4a4;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .ant-form-item .ant-form-item-label label {
  pointer-events: none;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 5px 30px;
  cursor: pointer;
  border-top: 1px solid #cacaca;
}

body.rtl .admin-modal-wrapper .rules-wrapper .action-btn-holder {
  flex-direction: row-reverse;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder p {
  font-size: 16px;
  font-weight: 500;
  color: #474747;
}

.admin-modal-wrapper .rules-wrapper .action-btn-holder .add-btn {
  color: #008539;
}

.admin-modal-wrapper .rules-wrapper .ant-select-selection--multiple .ant-select-selection__rendered ul > li:not(:last-of-type) {
  height: 0;
  margin-top: 0;
  line-height: 0;
  width: 0;
  opacity: 0;
  display: none;
}

.admin-modal-wrapper .rules-wrapper .error_holder {
  position: relative;
}

.admin-modal-wrapper .rules-wrapper .error_holder .ant-form-explain {
  position: absolute;
  top: -15px;
}

.admin-modal-wrapper .remove_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.admin-modal-wrapper .remove_holder h6 {
  padding-bottom: 0;
}

.admin-modal-wrapper .popup-medium {
  height: 70%;
  width: 50% !important;
}

.admin-modal-wrapper .select-admin-dropdown .ant-form-item-label label {
  font-size: 15px;
  font-weight: 400;
  color: #c4c4c4;
}

.modal-global-style .ant-modal-header {
  padding: 26px 55px;
}

.modal-global-style .ant-modal-title {
  font-size: 1.5625rem;
  font-weight: bold;
  color: #008539;
  font-weight: 600;
}

body.rtl .modal-global-style .ant-modal-close {
  right: unset !important;
}

.modal-global-style .ant-modal-close .ant-modal-close-x {
  color: #d83025;
}

.modal-global-style .ant-modal-footer {
  border: none !important;
}

.modal-global-style .ant-modal-footer button + button {
  margin-left: 12px;
}

body.rtl .modal-global-style .ant-modal-footer button + button {
  margin-left: 0 !important;
  margin-right: 32px;
}

.modal-global-style .ant-modal-header {
  border: none !important;
}

.modal-global-style .ant-modal-header .anticon {
  color: #c30707;
}

body.rtl .modal-global-style .ant-modal-header {
  text-align: right !important;
}

.role-holder .ant-modal-body h2 {
  font-weight: normal;
}

.ant-transfer .ant-transfer-list-search {
  padding-top: 0 !important;
  height: 32px;
}

.preview-licenses-modal {
  display: flex;
  align-items: center;
}

.preview-licenses-modal .ant-modal {
  top: 0;
}

.preview-licenses-modal .ant-modal-body {
  padding: 30px;
}

.preview-licenses-modal .ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #d83025;
  font-size: 18px;
}

.add-admin-holder .ant-modal-header {
  padding-bottom: 10px;
}

.demo-wrapper {
  width: 85%;
  margin: auto;
}

.demo-wrapper > section {
  margin-top: 25px;
}

.demo-wrapper > section > h3 {
  margin-bottom: 20px;
}

.demo-wrapper .color-palette-wrapper .content {
  display: flex;
}

.demo-wrapper .color-palette-wrapper .content .panel {
  display: flex;
  width: 100%;
  border: 0;
  box-shadow: none;
}

.demo-wrapper .color-palette-wrapper .content .panel .sample {
  width: 190px;
  margin-left: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.demo-wrapper .color-palette-wrapper .content .panel .sample:first-child {
  margin-left: 0px;
}

.demo-wrapper .color-palette-wrapper .color-text {
  min-height: 90px;
  background: #52565e;
  color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.demo-wrapper .color-palette-wrapper .color-text p {
  display: flex;
  flex-wrap: wrap;
}

.demo-wrapper .color-palette-wrapper .color-text p span {
  cursor: pointer;
  font-size: 12px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split {
  display: flex;
  height: 150px;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:first-of-type {
  border-radius: 4px 0 0 0;
  width: 100%;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split > div:last-of-type {
  border-radius: 0 4px 0 0;
}

.demo-wrapper .color-palette-wrapper .color-swatch-split-2 {
  width: 45px;
}

.assignment-card {
  display: flex;
  margin: 0 5px;
  cursor: pointer;
}

.assignment-card .assignment-card-details {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  margin-inline-end: 13px;
  padding: 8px 14px 8px 12px;
}

.assignment-card .assignment-card-details__teacher-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  min-height: 45px;
  min-width: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: none;
  border: solid 0.5px #707070;
}

.assignment-card .assignment-card-details__teacher-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-fit: contain;
}

@media only screen and (max-width: 575px) {
  .assignment-card .assignment-card-details__teacher-img {
    display: none;
  }
}

.assignment-card .teacher-summary {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-inline-start: 5px;
}

.assignment-card .teacher-summary .assignment-card-details__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action {
  display: flex;
  align-items: center;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action h4 {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.14;
  color: #868ea4;
  margin-inline-end: 10px;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action img {
  margin-right: 15px;
}

body.rtl .assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--action img {
  margin-left: 15px;
  margin-right: 0;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--media i {
  margin-right: 12px;
}

.assignment-card .teacher-summary .assignment-card-details__info .assignment-card-details__info--media i:last-of-type {
  margin-right: 0;
}

.assignment-card .teacher-summary .assignment-card-details__subject {
  font-size: 14px;
  font-weight: 600;
  color: #2c363f;
}

.assignment-card .teacher-summary .assignment-card-details__subject span {
  font-weight: 400;
  margin: 0 5px;
}

.assignment-card .teacher-summary .assignment-card-description {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.42;
  color: #57595a;
  margin-top: 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.assignment-card .assignment-status {
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  min-width: 100px;
}

.assignment-card .assignment-status span {
  font-size: 13px;
  font-weight: 500;
  color: #11b74f;
  margin-top: 10px;
}

.assignment-card .assignment-status.assignment-status--uncomplated {
  opacity: 0.81;
}

.assignment-card .assignment-status.assignment-status--uncomplated span {
  font-size: 11px;
  color: #b6bbc8;
}

.course-card {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: solid 0.5px #d3d3d3;
  background-color: #ffffff;
  padding: 8px 8px 4px 15px;
  border-left: 6px solid #00adee;
  height: 145px;
}

body.rtl .course-card {
  padding: 8px 15px 20px 8px;
  border-right: 6px solid #00adee;
  border-left: solid 0.5px #d3d3d3;
}

.course-card .course-card__header p {
  margin: 5px 0;
  font-size: 12px;
  font-weight: 700;
}

.course-card .course-card__header span {
  margin-right: 5px;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  color: #00678d;
}

@media only screen and (max-width: 575px) {
  .course-card .course-card__header span {
    flex-direction: column;
    word-break: break-word;
  }
}

body.rtl .course-card .course-card__header span {
  margin-right: 0;
  margin-left: 5px;
}

.course-card .course-card__header .course-card__header--class {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-card .course-card__header .course-card__header--class span {
  font-size: 13px;
  font-weight: bold;
  color: #2c363f;
}

.course-card .course-card__content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 120px;
  overflow-y: auto;
}

.course-card .course-card__content > div {
  display: flex;
  align-items: flex-start;
}

.course-card .course-card__content > div .img-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  min-height: 26px;
  min-width: 26px;
  max-width: 26px;
  max-height: 26px;
  border-radius: 50%;
  padding: 0px;
  box-shadow: none;
  border: solid 0.5px #707070;
}

.course-card .course-card__content > div .img-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.course-card .course-card__content > div .course-card__content--detailes {
  display: flex;
  flex-direction: column;
}

.course-card .course-card__content > div .course-card__content--teacher-name {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
}

.course-card .course-card__content > div .course-card__content--update-time {
  font-size: 12px;
  font-weight: normal;
  color: #00678d;
}

.course-card .course-card__content > div .course-card__content--update-time span {
  font-weight: 300;
}

.course-card .course-card__content article {
  font-size: 11px;
  font-weight: normal;
  color: #3c3c38;
  word-break: break-word;
  margin-top: 2px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.course-card .course-card__footer {
  display: flex;
  justify-content: space-between;
}

.course-card .course-card__footer .ant-divider-vertical {
  top: 0;
  height: 20px;
  color: #f9f9fc;
}

.course-card .course-card__footer .course-footer-date {
  font-size: 10px;
  color: #8e96aa;
}

.course-card .course-card__footer .course-footer-date i {
  margin-right: 3px;
  font-size: 12px;
}

body.rtl .course-card .course-card__footer .course-footer-date i {
  margin-left: 3px;
  margin-right: 0;
}

.course-card .course-card__footer .course-footer-files {
  display: flex;
}

.course-card .course-card__footer .course-footer-files img {
  cursor: pointer;
}

body.rtl .course-card .course-card__footer .course-footer-files img {
  margin-right: 0;
}

.course-edit-icon {
  margin-right: 10px;
}

body.rtl .course-edit-icon {
  margin-left: 10px;
  margin-right: 0;
}

.course-delete-icon {
  margin-right: 10px;
}

body.rtl .course-delete-icon {
  margin-left: 10px;
  margin-right: 0;
}

.float-btn {
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.46);
  background-color: #00678d;
  border-radius: 50%;
  position: fixed;
  bottom: 15px;
  right: 8px;
  z-index: 5;
  border: none;
  display: none;
  align-items: center;
  justify-content: center;
}

body.rtl .float-btn {
  left: 8px;
  right: auto;
}

.float-btn img {
  width: 30px;
}

.float-btn:focus {
  outline: none;
  background-color: #00678d;
}

.float-btn:active {
  background-color: #00678d;
}

@media only screen and (max-width: 991px) {
  .float-btn {
    display: flex;
  }
}

.classStory__overlay {
  background: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  visibility: visible;
  opacity: 0.5;
  transition: visibility 1s, opacity 0.4s linear;
}

.classStory__overlay.none {
  opacity: 0;
  visibility: hidden;
}

.storyInfo-responsive-wrapper {
  position: fixed;
  z-index: 99;
  top: 63px;
  bottom: 65px;
  right: 20px;
  flex-direction: column-reverse;
  visibility: visible;
  opacity: 1;
  transition: visibility 1s, opacity 0.4s linear;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  -webkit-overflow-scrolling: touch;
}

.storyInfo-responsive-wrapper.none {
  opacity: 0;
  visibility: hidden;
}

.storyInfo-responsive {
  width: 220px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #00678d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 15px;
  padding-left: 25px;
  margin: 10px 0px;
}

body.rtl .storyInfo-responsive {
  padding-right: 25px;
  padding-left: 0;
}

.storyInfo-responsive .storyInfo-responsive__title {
  height: 60px;
  width: 115px;
  border-radius: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin-right: -1px;
}

@media only screen and (max-width: 479px) {
  .storyInfo-responsive .storyInfo-responsive__title {
    height: 60px;
  }
}

.storyInfo-responsive .storyInfo-responsive__title .type-blue {
  width: 9px;
  height: 9px;
  background-color: #00adee;
  border-radius: 50%;
}

.storyInfo-responsive .storyInfo-responsive__title p {
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  margin-left: 4px;
}

body.rtl .storyInfo-responsive .storyInfo-responsive__title p {
  margin-right: 4px;
  margin-left: 0;
}

.storyInfo-responsive .storyInfo-responsive__details p {
  font-size: 12px;
  letter-spacing: 0.28px;
  color: white;
}

.storyInfo-responsive-overlay.show {
  position: fixed;
  z-index: 5;
  background: #0000004d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.attachment-details-holder {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 70vh;
  max-width: 100%;
}

.attachment-details-holder.flex {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 70vh;
    max-width: 100%;
  }
}

.attachment-details-holder .contnet-download {
  width: 200px !important;
  flex-basis: 100%;
  display: flex;
  position: relative;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .contnet-download {
    width: 90% !important;
  }
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .contnet-download {
    margin: 0px 10px;
  }
}

.attachment-details-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.attachment-details-holder .contnet-download .photo-video-download {
  width: 100%;
  height: 100px;
  cursor: pointer;
  position: relative;
}

.attachment-details-holder .contnet-download .photo-video-download img {
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-icon {
  transform: translate(-50%, -50%);
}

.attachment-details-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.attachment-details-holder .download-info {
  display: flex;
  width: 200px;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .download-info {
    width: 100%;
  }
}

.attachment-details-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .download-info p {
    font-size: 12px;
    color: #3c3c38;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.attachment-details-holder .download-info div {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-details-holder .download-info div img {
  width: 9px;
  height: 9px;
}

.media_name {
  display: none;
}

@media only screen and (max-width: 991px) {
  .media_name {
    padding: 20px;
    margin: 10px;
    color: #313131;
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .media_name {
    padding: 20px;
    margin: 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    text-align: center;
  }
}

.video-modal .video-react-video {
  pointer-events: none;
}

.video-modal .ant-modal-close-x {
  width: 44px;
  height: 44px;
  line-height: 37px;
}

.video-modal .ant-modal-close {
  border-radius: 50%;
  color: #222;
}

@media only screen and (max-width: 991px) {
  .video-modal .ant-modal-close {
    top: 13px;
    right: 15px;
    background: #707070 !important;
    border-radius: 50%;
    color: #f9f9fc;
  }
}

.photo-video-holder {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
  max-width: 100%;
}

@media only screen and (max-width: 991px) {
  .photo-video-holder {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    max-width: 100%;
  }
}

.photo-video-holder .contnet-download {
  height: 121px;
  padding: 10px;
  display: flex;
  position: relative;
  text-align: center;
  margin: 10px;
}

.photo-video-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.photo-video-holder .contnet-download .photo-video-download {
  width: 350px;
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .photo-video-holder .contnet-download .photo-video-download {
    width: 200px;
  }
}

.photo-video-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.photo-video-holder .contnet-download .photo-video-icon {
  transform: translate(-50%, -50%);
}

.photo-video-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.photo-video-holder .download-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
}

.photo-video-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .photo-video-holder .download-info p {
    font-size: 12px;
    color: #3c3c38;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.photo-video-holder .download-info div {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment_list_wraper .second-part {
  width: 100%;
}

.attachment_list_wraper .second-part .header {
  justify-content: flex-end;
}

/* ended here */
.badge-num {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  line-height: 20px;
  background-color: #e22020;
  font-size: 11px;
  font-weight: normal;
  color: #ffffff;
  border-radius: 50%;
}

.student-breadcrumb span {
  cursor: pointer;
}

.student-breadcrumb .opened-page {
  font-size: 16px;
  font-weight: 600;
  color: #0793c7;
}

.student-breadcrumb .opened-page.no-separator + .ant-breadcrumb-separator {
  display: none;
}

.student-breadcrumb .ant-breadcrumb-separator {
  font-size: 20px;
  font-weight: 900;
}

.student-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .student-pagination {
    margin-bottom: 20px;
  }
}

.downloads-holder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
  gap: 29px;
}

.downloads-holder .contnet-download {
  width: 183px;
  height: 121px;
  position: relative;
  text-align: center;
}

.downloads-holder .contnet-download .poster {
  width: 100%;
  max-width: 145px;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.downloads-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.downloads-holder .download-info {
  display: flex;
  align-items: center;
  padding-top: 10px;
  margin-inline-end: 37px;
}

.downloads-holder .download-info p {
  font-size: 12px;
  color: #3c3c38;
  margin-inline-end: 30px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.downloads-holder .download-info div {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .upload-errors {
  background: #fff;
  color: #d83025;
}

.attachment-holder .attachment-holder__actions .upload-errors p {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .upload-errors .anticon-info-circle {
  vertical-align: middle;
}

.second-part {
  padding: 0 21px;
}

.second-part .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.second-part .header .title {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  padding-top: 0;
}

.second-part .download-link {
  font-size: 14px;
  font-weight: 600;
  color: #077aa3;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 300px;
  line-height: 160px;
  display: flex !important;
  overflow: hidden;
  overflow-x: hidden;
}

.files-uploaded-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 12px;
}

.files-uploaded-wrapper .file-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  max-width: 230px;
  border-radius: 6px;
  border: solid 1px #d2dade;
  background-color: #ffffff;
  padding: 6px 4px;
  margin-inline-end: 10px;
  margin-bottom: 10px;
}

.files-uploaded-wrapper .file-card .file-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: none;
  background-color: #ededed;
}

.files-uploaded-wrapper .file-card .file-type img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  object-fit: contain;
}

.files-uploaded-wrapper .file-card .file-info {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-uploaded-wrapper .file-card .file-info p {
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files-uploaded-wrapper .file-card .file-info span {
  font-size: 12px;
  font-weight: normal;
  color: #acb3c5;
}

.files-uploaded-wrapper .file-card i {
  color: #acb3c5;
}

.attachment-holder__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

@media only screen and (max-width: 767px) {
  .attachment-holder__list {
    grid-template-columns: 1fr;
  }
}

.attachment__holder--data > li {
  height: 53px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  position: relative;
}

.attachment__holder--data .file__name--modal {
  display: flex;
  align-items: center;
  height: 100%;
}

.attachment__holder--data .remove-btn {
  display: block;
  line-height: 0.8;
  position: absolute;
  right: 7px;
  top: 15px;
  font-size: 31px;
  color: #c3b1b1;
  cursor: pointer;
  font-weight: 300;
}

body.rtl .attachment__holder--data .remove-btn {
  right: auto;
  left: 7px;
}

.attachment__holder--data .ant-progress-text {
  position: absolute;
  top: -19px;
  right: 30px;
  width: 15px;
  font-size: 10px;
}

body.rtl .attachment__holder--data .ant-progress-text {
  width: auto;
  right: auto;
  left: 20px;
}

.attachment__holder--data .ant-progress-outer {
  padding-right: 0;
}

body.rtl .attachment__holder--data .ant-progress-outer {
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
}

.attachment__holder--data .progress-container {
  display: flex;
  position: absolute;
  bottom: -9px;
  width: 100%;
}

.attachment__holder--data .attachment--file__name {
  font-size: 9px;
  font-weight: normal;
  line-height: 1.29;
  color: #2a7d9c;
  margin: 0;
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.attachment__holder--data .file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
  width: 50px;
  border-radius: 4px;
}

.upload__files--container .upload-area-holder {
  border: none;
}

.upload__files--container .upload-area-holder .ant-upload {
  flex-direction: column;
}

.upload-area-holder {
  height: 57px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  margin-bottom: 10px;
}

.upload-area-holder .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.upload-area-holder .ant-upload > span {
  font-size: 9px;
  font-weight: normal;
  color: #929191;
}

.upload-area-holder .ant-upload > button {
  width: 108px;
  height: 29px;
  border-radius: 4px;
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 11px;
  color: #9c9595;
}

.upload-area-holder.quiz_uploader--style {
  height: auto;
  border-radius: 0;
  border: none;
  padding: 0;
  margin-bottom: 0;
}

.upload-area-holder.quiz_uploader--style .ant-upload > button {
  border-right: 1px solid #cccccc !important;
  margin-right: 27px !important;
}

.progress-viewer-container {
  position: absolute;
  bottom: 0;
  left: calc(50% - 263px);
  width: 526px;
  height: 86px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: solid 1px #707070;
  background-color: #00678d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 28px;
}

.progress-viewer-container .ant-progress .ant-progress-bg {
  background: #11b74f;
}

.progress-viewer-container .ant-progress .ant-progress-text {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .progress__modal--container .ant-modal {
    width: 100% !important;
  }
}

.progress__modal--container .ant-modal-header {
  border-bottom: none;
  padding-top: 25px;
}

.progress__modal--container .ant-modal-header .ant-modal-title {
  font-size: 22px;
  font-weight: normal;
  color: #464a53;
}

.progress__modal--container .ant-modal-footer {
  border-top: none;
  margin: auto;
  margin-top: 20px;
  width: 95%;
}

.progress__modal--container .modal-footer {
  display: flex;
  justify-content: flex-end;
}

.progress__modal--container .modal-footer .footer-actions {
  align-self: flex-start;
}

._1lB9c {
  display: none !important;
}

._1dpop {
  background: #0793c7 !important;
}

.current-uploads-container {
  display: flex;
  flex-direction: column;
}

.current-uploads-container .upload__files--container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 2px #cccccc;
  margin: 40px 0 20px 0;
}

.current-uploads-container .upload__files--dim {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-uploads-container .upload__files--dim .ant-form-item {
  margin-bottom: 0;
}

.current-uploads-container .upload-area-holder {
  margin-bottom: 0;
}

.current-uploads-container .current__uploaded--files {
  width: 100%;
}

.current-uploads-container .current__uploaded--files .current__files--details {
  margin-bottom: 40px;
  position: relative;
}

.current-uploads-container .current__uploaded--files .current__files--details:last-child {
  margin-bottom: 0;
}

.current-uploads-container .current__uploaded--files .current__files--details p {
  font-size: 16px;
  font-weight: normal;
  color: #464a53;
}

.current-uploads-container .current__uploaded--files .current__files--details .current__files--actions {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: -22px;
  right: 0;
}

.current-uploads-container .current__uploaded--files .current__files--details .current__files--actions button {
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.current-uploads-container .current__uploaded--files .current__files--details .current__files--actions button.cancel-btn {
  color: #00678d;
}

.current-uploads-container .current__uploaded--files .current__files--details .current__files--actions button.pause-btn {
  color: #eca918;
}

.current-uploads-container .current__uploaded--files .current__files--details .ant-progress-outer {
  padding: 0 !important;
}

.current-uploads-container .current__uploaded--files .current__files--details .ant-progress-text {
  position: absolute;
  bottom: -20px;
  left: 0;
  margin-left: 0 !important;
  font-size: 16px;
  font-weight: normal;
  color: #464a53;
}

.current-uploads-container .current__uploaded--files .current__files--details .complete__file--status {
  position: absolute;
  bottom: -3px;
  left: 0;
  margin-left: 0 !important;
  font-size: 16px;
  font-weight: normal;
  color: #464a53;
}

.overlay {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 52px;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 52px);
  z-index: 5555;
  background-color: rgba(92, 92, 92, 0.7);
}

.sidemenu-container {
  position: fixed;
  top: 0px;
  right: -220px;
  height: 100%;
  width: 220px;
  background: #eff0f6;
  z-index: 9999;
  transition: all 0.5s ease;
  overflow-y: auto;
}

@media only screen and (max-width: 479px) {
  .sidemenu-container {
    height: 100vh;
  }
}

.sidemenu-container.show {
  right: 0;
  transition: all 0.5s ease;
}

.sidemenu-container .view-profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #00678d;
  padding: 35px;
}

.sidemenu-container .view-profile-card h6,
.sidemenu-container .view-profile-card a {
  color: #fff;
}

.sidemenu-container .view-profile-card h6 {
  font-size: 12px;
}

.sidemenu-container .view-profile-card .teacher-info-wrapper--teacher-img img {
  width: 50px;
  height: 50px;
  margin: 12px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}

.sidemenu-container .teacher-info-wrapper--nav ul {
  display: flex;
  flex-direction: column;
}

.sidemenu-container .teacher-info-wrapper--nav ul a {
  font-size: 16px;
  color: #283e4a;
  padding: 20px;
}

.sidemenu-container .teacher-info-wrapper--nav ul a.active {
  color: #64cdf8;
  background-color: #00678d14;
}

.sidemenu-container .teacher-info-wrapper--nav ul a span {
  margin-right: 10px;
}

.audio-recorder-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.audio-recorder-wrapper audio {
  align-self: center;
  margin-top: 40px;
  margin-bottom: -110px;
  width: 500px;
}

.audio-recorder-wrapper .audio-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.audio-recorder-wrapper .audio-actions img {
  width: 100px;
  position: absolute;
  cursor: pointer;
  top: -25px;
}

.audio-recorder-wrapper .audio-actions button {
  position: absolute;
  width: 50px;
  height: 50px;
}

.rtl .current__files--details .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
}

.ant-select-search--inline {
  top: auto !important;
}

.upload_modal .ant-modal-footer {
  border-top: none;
}

body.rtl .upload_modal .ant-modal-footer {
  border-top: none;
}

.ck-read-only {
  border: none !important;
  pointer-events: all !important;
}

.ck-read-only figure figcaption {
  display: none !important;
}

@font-face {
  font-family: 'teacher-portal';
  src: url("../sass/fonts/teacher-portal/teacher-portal.eot?mtzeb1");
  src: url("../sass/fonts/teacher-portal//teacher-portal.eot?mtzeb1#iefix") format("embedded-opentype"), url("../sass/fonts/teacher-portal//teacher-portal.ttf?mtzeb1") format("truetype"), url("../sass/fonts/teacher-portal/teacher-portal.woff?mtzeb1") format("woff"), url("../sass/fonts/teacher-portal/teacher-portal.svg?mtzeb1#teacher-portal") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'teacher-portal' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subject:before {
  content: "\e909";
  color: #a8a7a7;
}

.icon-class:before {
  content: "\e90a";
  color: #a8a7a7;
}

.icon-calendar:before {
  content: "\e900";
}

.icon-timetable:before {
  content: "\e901";
}

.icon-exam:before {
  content: "\e902";
}

.icon-attendance:before {
  content: "\e903";
}

.icon-homework:before {
  content: "\e904";
}

.icon-class-story:before {
  content: "\e905";
}

.icon-messages:before {
  content: "\e906";
}

.icon-school-timeline:before {
  content: "\e907";
}

.icon-locale:before {
  content: "\e908";
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  transform: rotate(0) !important;
}

footer.web-footer * {
  font-family: Pridi;
}

footer.web-footer .footer-top-wrapper {
  padding: 11% 13% 5% 13%;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  position: relative;
}

footer.web-footer .footer-top-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.67);
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  left: 0;
}

footer.web-footer .footer-top-wrapper .logo-holder {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .logo-holder {
    justify-content: center;
  }
}

footer.web-footer .footer-top-wrapper .content {
  position: relative;
  z-index: 1;
  display: flex;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content {
    flex-direction: column;
  }
  footer.web-footer .footer-top-wrapper .content i {
    display: block !important;
    transform: rotate(-90deg);
  }
}

footer.web-footer .footer-top-wrapper .content > div {
  flex: 1;
}

footer.web-footer .footer-top-wrapper .content > div h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  white-space: nowrap;
}

footer.web-footer .footer-top-wrapper .content > div h4 i {
  display: none;
  transition: all ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  transition: max-height ease 0.4s;
}

footer.web-footer .footer-top-wrapper .content > div .links-holder * {
  margin-bottom: 8px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content > div .links-holder {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease 0.4s;
    margin-top: 14px;
    margin-bottom: 12px;
  }
}

footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.71;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-top-wrapper .content > div a, footer.web-footer .footer-top-wrapper .content > div address {
    font-weight: normal;
  }
}

footer.web-footer .footer-top-wrapper .content > div.active i {
  transition: all ease 0.4s;
  transform: rotate(0);
}

footer.web-footer .footer-top-wrapper .content > div.active .links-holder {
  transition: max-height ease 0.4s;
  max-height: 180px;
  height: auto;
}

footer.web-footer .footer-bottom-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 0.35px;
  color: #ffffff;
  height: 50px;
  background-color: #242430;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  footer.web-footer .footer-bottom-wrapper {
    font-size: 13px;
  }
}

#root {
  height: 100%;
}

.auth_bg {
  height: 100%;
  background-color: #ffffff;
}

.auth_bg .signIn-content-wrapper {
  width: 40%;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

body.rtl .auth_bg .signIn-content-wrapper {
  margin-right: auto;
  transform: scaleX(-1) translateY(-50%);
}

.auth_bg .signIn-content-wrapper .logo-img {
  max-width: 280px;
}

.auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 52px;
}

body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-right: 52px;
  padding-left: 16px;
}

@media only screen and (max-width: 575px) {
  .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 45px;
  }
  body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-right: 45px;
    padding-left: 16px;
  }
}

.auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input-prefix {
  left: 20px;
  font-size: 16px;
}

body.rtl .auth_bg .signIn-content-wrapper .ant-input-affix-wrapper .ant-input-prefix {
  left: auto;
  right: 20px;
}

.auth_bg.website-auth-wrapper .overlay {
  opacity: 0.48;
}

.auth_bg.website-auth-wrapper .center_div .logo-img {
  display: none;
}

.auth_bg.website-auth-wrapper .center_div .title {
  font-size: 23px;
}

.auth_bg.website-auth-wrapper .center_div .sammary {
  padding-top: 22px;
}

.auth_bg.website-auth-wrapper .center_div .login-form-forgot {
  font-size: 13px;
  text-decoration: none;
  font-weight: normal;
}

.auth_bg.website-auth-wrapper .center_div .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .auth_bg.website-auth-wrapper .center_div .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-label label {
  display: none;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control {
  position: relative;
}

.auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  content: url("../../resources/images/website/icon/password.svg");
  position: absolute;
  top: 6px;
  left: 10px;
}

body.rtl .auth_bg.website-auth-wrapper.rest-password-wrapper .ant-form-item .ant-form-item-control::before {
  right: 10px;
  left: auto;
}

@media only screen and (max-width: 479px) {
  .auth_bg.website-auth-wrapper.rest-password-wrapper .center_div .title {
    font-size: 20px !important;
  }
}

.center_div {
  position: relative;
  background: #fff;
  width: 85%;
  height: 90%;
  margin: auto;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 3px 80px 0 rgba(0, 0, 0, 0.1);
  background: #fff url("../../resources/images/student/login-new-bg.png") no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 575px) {
  .center_div {
    background: #fff;
  }
}

body.rtl .center_div {
  transform: scaleX(-1);
}

.center_div .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  background-color: #000000;
  display: none;
}

.center_div .title {
  padding-top: 25px;
  font-size: 19px;
  font-weight: normal;
  text-align: center;
  color: #000000;
}

.center_div .login-form-forgot {
  text-decoration: underline;
}

@media only screen and (max-width: 991px) {
  .center_div {
    width: 90%;
    padding: 50px 25px;
    background-position: center;
  }
}

@media only screen and (max-width: 767px) {
  .center_div .signIn-content-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .center_div {
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .center_div .overlay {
    display: none;
  }
  .center_div .signIn-content-wrapper {
    width: 90%;
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    margin: auto;
  }
}

.pt-2 {
  padding-top: 20px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-4 {
  padding-bottom: 40px;
}

.mr-2 {
  margin-right: 20px;
}

body.rtl .mr-2 {
  margin-left: 20px;
}

.title {
  font-size: 25px;
  font-weight: bold;
  color: #4b4b4b;
  padding-top: 14px;
}

.lang_style {
  font-size: 16px;
  color: #077aa3;
  text-transform: capitalize;
}

.rem_color {
  color: #077aa3 !important;
}

.sammary {
  font-size: 14px;
  color: #565656;
  line-height: 1.21;
  padding-top: 10px;
}

.size17 {
  font-size: 17px;
  color: #565656;
  line-height: 1.24;
}

.black {
  color: #000;
}

.graytitle {
  color: #a3a3a3;
  font-size: 45px;
}

.ligt_black {
  color: #242424;
}

.weight-600 {
  font-weight: 600;
}

.font-19 {
  font-size: 19px;
}

.ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 15px;
}

.rtl .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 15px !important;
  right: auto !important;
}

.withOut_icons .ant-form-item-children-icon {
  display: none;
}

.reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 30px;
}

body.rtl .reset_holder .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: auto !important;
  left: 30px !important;
}

.ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0px;
}

body.rtl .ant-form-item-control.has-error .ant-form-explain {
  padding-left: 0 !important;
  padding-right: 0px !important;
}

.teacher-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.teacher-wrapper > header {
  flex-shrink: 0;
}

.teacher-wrapper > main {
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;
}

.teacher-wrapper > main.correction {
  overflow-y: hidden;
}

.class-room-wrapper > .container {
  display: flex;
}

.timeline-classes-wrapper > .container {
  display: flex;
}

.timeline-classes-wrapper .timeline-content-wrapper {
  width: 100%;
  margin: 0 30px 0 20px;
}

.timeline-classes-wrapper .timeline-content-wrapper.timeline__calender {
  width: calc(100% - 530px);
}

@media only screen and (max-width: 1023px) {
  .timeline-classes-wrapper .timeline-content-wrapper.timeline__calender {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-classes-wrapper .timeline-content-wrapper {
    margin: 0;
  }
}

.timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 20px 21px 24px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b9b9b9;
}

.timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .file-upload img {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .file-upload {
    display: none;
  }
}

.timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post-content {
  display: flex;
  align-items: center;
}

.timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post--avatar {
  margin-right: 18px;
}

body.rtl .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post--avatar {
  margin-right: 0;
  margin-left: 18px;
}

.timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post--text {
  font-size: 14px;
  color: #b0b6bb;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper {
    box-shadow: none;
    border: 0;
    padding: 8px 16px;
  }
  .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post-content {
    border-radius: 8px;
    border: solid 0.8px #d4d4e1;
    height: 47px;
    width: 100%;
    padding: 0 0 0 14px;
  }
  .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post-content .timeline-post--avatar {
    margin-right: 6px;
  }
  body.rtl .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post-content .timeline-post--avatar {
    margin-left: 6px;
    margin-right: 0;
  }
  .timeline-classes-wrapper .timeline-content-wrapper .timeline-post-wrapper .timeline-post-content .timeline-post--text {
    color: #d4d4e1;
  }
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b9b9b9;
  background-color: #ffffff;
  padding: 16px 0;
  padding-bottom: 0;
  margin-bottom: 15px;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .story-card-auther {
  display: flex;
  align-items: center;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .anticon-ellipsis {
  font-size: 26px;
  font-weight: bold;
  color: #7e8a91;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 15px 0 30px;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card header h6 {
  font-family: SegoeUI;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  color: #000000;
  margin-bottom: 4px;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card header time {
  font-size: 12px;
  color: #9797ac;
  line-height: 1.25;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .story-card-auther--img {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

body.rtl .timeline-classes-wrapper .timeline-content-wrapper .story-card .story-card-auther--img {
  margin-left: 10px;
  margin-right: 0;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .story-card-auther--img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .content .story-card-image {
  width: 100%;
  height: 310px;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .content .story-card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card .content p {
  font-size: 14px;
  color: #000000;
  line-height: 1.71;
  padding: 0 24px 0 24px;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card footer {
  text-align: center;
  font-size: 14px;
  color: #777777;
  padding: 15px 0;
}

.timeline-classes-wrapper .timeline-content-wrapper .story-card footer img {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .timeline-classes-wrapper .timeline-content-wrapper .story-card {
    box-shadow: none;
    border: 0;
  }
}

.timeline-due-wrapper {
  width: 315px;
  min-width: 315px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #dadada;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  height: calc(100vh - 95px);
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 991px) {
  .timeline-due-wrapper {
    transform: translateX(115%);
    position: absolute;
    z-index: 8;
    top: 60px;
    right: 20px;
    left: 20px;
    width: auto;
    bottom: 10px;
    height: auto;
    overflow: auto;
    transition: all ease-in 0.4s;
  }
  .timeline-due-wrapper.show {
    transform: translateX(0);
  }
}

.timeline-due-wrapper .ant-select .ant-select-selection {
  height: auto;
  border-radius: 4px;
  padding: 4px !important;
  font-size: 14px;
}

.timeline-due-wrapper .ant-fullcalendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}

.timeline-due-wrapper .ant-fullcalendar-selected-day .ant-fullcalendar-value,
.timeline-due-wrapper .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.timeline-due-wrapper .ant-fullcalendar-today .ant-fullcalendar-value,
.timeline-due-wrapper .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.timeline-due-wrapper .ant-fullcalendar-value {
  width: 34px !important;
  height: 34px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-due-wrapper .teacher-timeline {
  padding: 10px;
  max-height: inherit;
  overflow: auto;
  max-height: inherit;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header > div {
  display: flex;
  align-items: center;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header p {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-left: 7px;
}

body.rtl .timeline-due-wrapper .teacher-timeline .teacher-timeline--header p {
  margin-right: 7px;
  margin-left: 0;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header .ant-switch {
  background-color: #d4d4e1;
  min-width: 25px !important;
  height: 14px !important;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header .ant-switch::after {
  background-color: #7e8a91;
  width: 16px;
  height: 16px;
  top: -2px;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header .ant-switch.ant-switch-checked {
  background-color: rgba(7, 147, 199, 0.29);
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline--header .ant-switch.ant-switch-checked::after {
  background-color: #0793c7;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type {
  display: flex;
  align-items: center;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type time {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: #9596aa;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type .type-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #00adee;
  border-radius: 50%;
  padding: 3px;
  margin-left: 30px;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type .type-img::before {
  content: '';
  width: 20px;
  height: 1px;
  display: inline-block;
  position: absolute;
  left: -25px;
  background-color: #e8e8e8;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type.teacher-timeline-card--type__exam .type-img {
  background-color: #ffb300;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--type.teacher-timeline-card--type__exam + .teacher-timeline-card--divider {
  background-color: #ffb300;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--divider {
  height: 45px;
  width: 2px;
  background-color: #00adee;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info h5 {
  font-size: 14px;
  font-weight: 500;
  color: #283e4a;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info h6 {
  display: flex;
  align-items: center;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info .teacher-timeline-card--info__division {
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0.23px;
  color: #00678d;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info .teacher-timeline-card--info__class {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: center;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info .teacher-timeline-card--info__class::before {
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: block;
  margin-right: 4px;
  background-color: #00adee;
}

body.rtl .timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info .teacher-timeline-card--info__class::before {
  margin-left: 4px;
  margin-right: 0;
}

.timeline-due-wrapper .teacher-timeline .teacher-timeline-card--info time {
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 0.23px;
  color: #556066;
}

.timeline-due-wrapper .teacher-timeline .category-card {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.timeline-due-wrapper .teacher-timeline .category-card.category-card--exam .category-info {
  background-color: #ffb30014 !important;
  border: solid 0.5px #ffb300;
}

.timeline-due-wrapper .teacher-timeline .category-card .time-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 15px;
}

.timeline-due-wrapper .teacher-timeline .category-card .time-holder time {
  font-size: 9px;
  font-weight: 500;
  font-weight: normal;
  letter-spacing: 0.21px;
  color: #9596aa;
}

.timeline-due-wrapper .teacher-timeline .category-card .category-info {
  display: flex;
  align-items: center;
  width: 194px;
  height: 49px;
  border-radius: 6px;
  border: solid 0.5px #00678d;
  background-color: #00678d33;
  padding: 4px 10px;
}

.timeline-due-wrapper .teacher-timeline .category-card .category-info--content {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}

body.rtl .timeline-due-wrapper .teacher-timeline .category-card .category-info--content {
  margin-left: 0;
  margin-right: 4px;
}

.timeline-due-wrapper .teacher-timeline .category-card .category-info--content .category-info--name {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  color: #283e4a;
}

.timeline-due-wrapper .teacher-timeline .category-card .category-info--content .category-info-division {
  font-size: 8px;
  letter-spacing: 0.18px;
  color: #00678d;
  margin: 3px 0;
}

.timeline-due-wrapper .teacher-timeline .category-card .category-info--content .category-info--time {
  font-size: 8px;
  font-weight: 300;
  letter-spacing: 0.18px;
  color: #556066;
}

.post-modal-wrapper {
  width: 620px !important;
}

@media only screen and (max-width: 767px) {
  .post-modal-wrapper {
    width: auto !important;
  }
}

.post-modal-wrapper .ant-modal-header {
  border-bottom: 0;
}

.post-modal-wrapper .ant-modal-title {
  display: flex;
  align-items: center;
}

.post-modal-wrapper .ant-modal-title .post-auther--info h6 {
  font-size: 11px;
  font-weight: 500;
  color: #000000;
}

.post-modal-wrapper .ant-modal-title .post-auther--info p {
  font-size: 11px;
  font-weight: 500;
  color: #737373;
  line-height: 1.27;
}

.post-modal-wrapper .ant-modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input {
  border: 0;
  resize: none;
  font-size: 14px;
  line-height: 1.71;
  color: #000000;
  padding: 0;
  padding-right: 2px;
  /* Track */
  /* Handle */
}

.post-modal-wrapper .ant-modal-body textarea.ant-input:-moz-placeholder {
  font-size: 14px;
  color: #b2b2bc;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input::-moz-placeholder {
  font-size: 14px;
  color: #b2b2bc;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input:-ms-input-placeholder {
  font-size: 14px;
  color: #b2b2bc;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #b2b2bc;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input::-webkit-scrollbar {
  width: 8px;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.post-modal-wrapper .ant-modal-body textarea.ant-input::-webkit-scrollbar-thumb {
  background: #00678d;
}

.post-modal-wrapper .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 10px 27px 10px 26px;
}

.post-modal-wrapper .post-btn {
  width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #00678d;
  border-radius: 6px;
  font-size: 11px;
  font-weight: 500;
  color: #ffffff;
}

.post-modal-wrapper .attachment-wrapper,
.post-modal-wrapper .post-action {
  display: flex;
  align-items: center;
}

.post-modal-wrapper .attachment-wrapper .text-counter,
.post-modal-wrapper .post-action .text-counter {
  font-size: 13px;
  color: #283e4a;
}

.post-modal-wrapper .attachment-wrapper--type {
  display: flex;
  align-items: center;
  border-right: 1px solid #d8d4e1;
  padding-right: 8px;
  margin-right: 8px;
}

body.rtl .post-modal-wrapper .attachment-wrapper--type {
  border-left: 1px solid #d8d4e1;
  border-right: 0;
  padding-left: 8px;
  margin-left: 8px;
  padding-right: 0;
  margin-right: 0;
}

.post-modal-wrapper .attachment-wrapper--type.attachment-wrapper--type__file {
  border: 0 !important;
}

.post-modal-wrapper .attachment-wrapper--type__text {
  font-size: 12px;
  color: #283e4a;
  margin-left: 6px;
}

body.rtl .post-modal-wrapper .attachment-wrapper--type__text {
  margin-right: 6px;
  margin-left: 0;
}

.post-modal-wrapper .images-preview-holder {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(94px, 94px));
  grid-template-rows: 80px;
}

.post-modal-wrapper .images-preview-holder .images-preview--card {
  border: 1px solid #b2b2bc;
  border-radius: 4px;
  position: relative;
}

.post-modal-wrapper .images-preview-holder .images-preview--card:after {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.22);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  transition: all ease-in 0.3s;
}

.post-modal-wrapper .images-preview-holder .images-preview--card .close-image {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  z-index: 11;
}

body.rtl .post-modal-wrapper .images-preview-holder .images-preview--card .close-image {
  left: 4px;
  right: auto;
}

.post-modal-wrapper .images-preview-holder .images-preview--card img,
.post-modal-wrapper .images-preview-holder .images-preview--card video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.post-modal-wrapper .images-preview-holder .images-preview--card .ant-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in 1s;
  transition-delay: 1s;
}

.post-modal-wrapper .images-preview-holder .images-preview--card .ant-progress.ant-progress-status-success {
  opacity: 0;
}

.post-modal-wrapper .images-preview-holder .images-preview--card:hover:after {
  opacity: 1;
  visibility: visible;
}

.post-modal-wrapper .images-preview-holder .images-preview--card__upload {
  border: 1px dashed #b2b2bc;
  cursor: pointer;
  border-radius: 4px;
}

.attachment_uploader_confirm .ant-modal-confirm-body .ant-modal-confirm-title,
.attachment_uploader_confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  /* .clone_submit{
			margin: 0px 5px !important;
		} */
  /* .ant-modal-content{
			.ant-modal-confirm-btns{
				.ant-modal-body{
					.ant-modal-confirm-body-wrapper{
						.ant-btn-primary{
								margin: 0px 5px !important;
						}
					}
					
				}
				
			}
		} */
}

body.rtl .attachment_uploader_confirm .ant-modal-confirm-body .ant-modal-confirm-title, body.rtl
.attachment_uploader_confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  text-align: right;
}

.story-list-wrapper .story-card .content {
  margin-bottom: 20px;
}

.story-list-wrapper .story-card .content .ant-carousel .slick-slide {
  justify-content: center;
}

.story-list-wrapper .story-card .content .ant-carousel .slick-dots {
  bottom: -23px;
}

.story-list-wrapper .story-card .content .ant-carousel .slick-dots li button {
  background: #00678d;
}

.story-list-wrapper .story-card .content .ant-carousel .slick-dots li.slick-active button {
  background: #00678d;
}

.story-list-wrapper .story-card .content .ant-carousel .slick-prev {
  border-radius: 12px;
  left: 18px;
  z-index: 4;
  width: 35px;
  height: 26px;
  font-size: 27px;
  font-weight: bold;
  line-height: 30px;
  color: #00678d;
}

body.rtl .story-list-wrapper .story-card .content .ant-carousel .slick-prev {
  right: 18px;
  left: auto;
  transform: rotate(180deg);
}

.story-list-wrapper .story-card .content .ant-carousel .slick-next {
  color: #00678d;
  border-radius: 12px;
  right: 18px;
  /* z-index: 5; */
  width: 35px;
  height: 26px;
  font-size: 27px;
  font-weight: bold;
  line-height: 30px;
}

body.rtl .story-list-wrapper .story-card .content .ant-carousel .slick-next {
  right: auto;
  left: 18px;
  transform: rotate(180deg);
}

.exam_list_container {
  margin: 10px 20px;
  width: 98%;
  height: 64vh;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .exam_list_container {
    width: unset;
  }
}

@media only screen and (max-width: 1199px) {
  .exam_list_container {
    height: calc(100vh - 325px);
    padding-right: 10px;
  }
  body.rtl .exam_list_container {
    padding-right: 0;
    padding-left: 10px;
  }
}

.error-screen-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: calc(100vh - 60px);
}

.error-screen-wrapper .logo-holder {
  width: 280px;
}

.bg_color {
  background: #F5F5F5;
}

.p-2 {
  padding: 20px;
}

.Error_holder {
  width: 80%;
  margin: 0 auto;
}

.error_num {
  color: #a3a3a3;
  font-size: 11.625rem;
  font-weight: bold;
}

.not_found {
  color: #a3a3a3;
  font-size: 3.9375rem;
  font-weight: bold;
}

.error_text {
  color: #a3a3a3;
  font-size: 1.125rem;
}

.pb-3 {
  padding-bottom: 30px;
}

@media screen and (max-width: 545px) {
  .d-none {
    display: none;
  }
  .custom_width {
    width: 90% !important;
  }
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #077aa3;
  padding-top: 28px;
  padding-left: 16px;
}

.add_School .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #077aa3;
  border-radius: 0;
}

.add_School .ant-collapse > .ant-collapse-item:last-of-type {
  margin-bottom: 60px;
}

.add_School .ant-collapse-content {
  border-top: 1px solid #077aa3;
}

.add_School .ant-collapse-content.ant-collapse-content-active {
  overflow: visible;
}

.add_School .ant-collapse-content .ant-collapse-content-box {
  padding: 16px 0;
}

.add_School .ant-collapse-item.ant-collapse-item-active {
  border-bottom: none !important;
}

.add_School .ant-collapse {
  border: none;
  background-color: transparent;
  width: 80%;
  margin: auto;
}

.add_School .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 60%;
  right: 16px;
  left: unset !important;
}

.add_School .small {
  font-weight: 500;
  color: #989797;
  line-height: 2.18;
}

.add_School .handel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}

.add_School .image_map {
  width: 100%;
  backdrop-filter: blur(29.1px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  height: 227px;
}

.add_School .mt-1 {
  margin-top: 10px;
}

.add_School .counter-input-holder .ant-form-item .ant-input-number {
  min-width: 160px !important;
}

.payment_modal .radio_holders .ant-radio-group {
  width: 100%;
}

.payment_modal .radio_holders .ant-radio-group > div {
  display: flex;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .payment_modal .radio_holders .ant-radio-group > div {
    flex-direction: column;
  }
}

.payment_modal .radio_holders .Dayes_holder {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
}

.payment_modal .radio_holders .Dayes_holder input {
  color: #077aa3;
}

.payment_modal .radio_holders .Dayes_holder .payment_span {
  left: 63px;
  top: 9px;
  position: absolute;
}

.payment_modal .radio_holders .Dayes_holder .ant-form-item {
  margin-bottom: 0;
}

.payment_modal .day_input {
  max-height: 30px;
  max-width: 100px;
  padding: 5px !important;
}

.payment_modal .ant-tabs-nav-container {
  overflow: visible;
}

.payment_modal .ant-tabs-nav {
  width: 100%;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab {
  width: 50%;
  margin: 0 !important;
  text-align: center;
  color: #077aa3;
  font-weight: bold;
  font-size: 1.375rem;
  background-color: #ededed;
  padding: 19px 16px;
}

.payment_modal .ant-tabs-nav .ant-tabs-ink-bar {
  display: none !important;
}

.payment_modal .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #FFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.payment_modal .two_inputs_holder {
  border: solid 1px #dadce0 !important;
  border-radius: 4px;
  width: 130px;
}

.payment_modal .two_inputs_holder input {
  border: none;
  width: 40px;
}

.payment_modal .two_inputs_holder input:hover {
  outline: none !important;
  border: none !important;
}

.payment_modal .payment_span2 {
  left: 90px !important;
  top: 6px !important;
}

.fixed_width {
  width: 150px;
}

@media only screen and (max-width: 767px) {
  .fixed_width {
    width: 120px;
  }
}

.change_input {
  height: 30px;
  width: 50px;
  padding: 4px !important;
}

.payment-holder {
  width: 85%;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .payment-holder {
    width: 95%;
  }
}

.payment-holder h4 {
  color: #242424;
  padding: 0 0 38px 0;
}

body.rtl .payment-holder h4 {
  text-align: right;
}

.payment-holder h6 {
  color: #242424;
  padding: 0 0 38px 0;
  font-size: 0.875rem;
}

body.rtl .payment-holder h6 {
  text-align: right;
}

.payment_table .ant-table-body {
  margin: 0 !important;
}

.payment_table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.payment_table .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal .ant-modal-header {
  border: none;
}

.manage_student_modal .ant-modal-title {
  color: #656565;
  font-size: 1.5rem;
  font-weight: bold;
}

.manage_student_modal .ant-row > div {
  background: transparent;
  border: 0;
}

.manage_student_modal .gutter-box {
  padding: 5px 0;
}

.manage_student_modal .ant-col-10 {
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.16);
  padding: 10px;
  line-height: 1.8;
  height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box {
  display: flex;
  align-items: center;
  border: solid 1px #dadce0;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 !important;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  padding: 10px;
  width: 50%;
  min-height: 80px;
}

.manage_student_modal .ant-col-14 .gutter-box .border_right {
  border-right: 1px solid #dadce0;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
}

.manage_student_modal .ant-col-14 .gutter-box .small_div p {
  margin-bottom: 10px;
}

.manage_student_modal .ant-col-14 .gutter-box .ant-input {
  height: auto;
  padding: 6px 10px !important;
  font-size: 12px;
  color: #077aa3;
  border: none;
}

.manage_student_modal .ant-col-14 .plus {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding: 0;
  border: none;
}

.manage_student_modal .line_span {
  display: block;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 20px 0;
}

.manage_student_modal .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.manage_student_modal tr:nth-child(even) {
  background-color: #f8f8f8;
}

.manage_student_modal .ant-table-thead {
  background-color: #dadce0;
}

.manage_student_modal td, .manage_student_modal th {
  padding: 3px 8px !important;
  text-align: center !important;
}

.manage_student_modal .btn-60 {
  margin-right: 10px;
}

body.rtl .manage_student_modal .btn-60 {
  margin-left: 10px;
  margin-right: 0px;
}

.up {
  color: #077aa3;
  position: relative;
}

.up::before {
  content: "+";
  position: absolute;
  left: -10px;
  color: #077aa3;
}

.down {
  color: #2db2bb;
  position: relative;
}

.down::before {
  content: "-";
  position: absolute;
  left: -10px;
  color: #2db2bb;
}

.evictive_date {
  border: solid 1px #dadce0;
  border-radius: 4px;
}

.green_label {
  color: #077aa3;
}

@media only screen and (max-width: 991px) {
  body,
  html {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  body,
  html {
    font-size: 14px !important;
  }
}

.show-in-ipad {
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .show-in-ipad {
    display: flex !important;
  }
}

.hide-in-ipad {
  display: flex !important;
}

@media only screen and (max-width: 991px) {
  .hide-in-ipad {
    display: none !important;
  }
}

.montserrat-font * {
  font-family: Pridi, Cairo, sans-serif;
}

body.rtl .montserrat-font * {
  font-family: Cairo, Pridi, sans-serif;
}

.montserrat-font .ant-form-explain {
  font-size: 10px;
}

.montserrat-font .ant-input:-moz-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input:-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-moz-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input::-moz-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input:-ms-input-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input:-ms-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-input::-webkit-input-placeholder {
  font-family: Pridi;
  font-size: 17px;
  font-weight: 400;
  color: #989898;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .montserrat-font .ant-input::-webkit-input-placeholder {
    font-size: 13px;
  }
}

.montserrat-font .ant-upload-list-picture-card .ant-upload-list-item-error {
  border: none;
}

html[lang="ar"] {
  font-size: 14px !important;
}

.rtl {
  direction: rtl;
}

.rtl caption {
  text-align: right;
}

.rtl .react-tel-input .selected-flag .arrow {
  left: auto;
  right: 20px;
}

.rtl .react-tel-input .selected-flag {
  padding: 0 8px 0 0px;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-left-enter.move-left-enter-active,
.rtl .move-left-appear.move-left-appear-active {
  animation-name: antMoveleftIn;
  animation-play-state: running;
}

.rtl .move-left-leave.move-left-leave-active {
  animation-name: antMoveleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .move-left-enter,
.rtl .move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .move-right-enter.move-right-enter-active,
.rtl .move-right-appear.move-right-appear-active {
  animation-name: antMoverightIn;
  animation-play-state: running;
}

.rtl .move-right-leave.move-right-leave-active {
  animation-name: antMoverightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .move-right-enter,
.rtl .move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@keyframes antMoveleftIn {
  0% {
    transform-origin: 100% 0;
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes antMoveleftOut {
  0% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes antMoverightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0;
    transform: translateX(0%);
  }
}

@keyframes antMoverightOut {
  0% {
    transform-origin: 100% 0;
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform-origin: 100% 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-left-enter.slide-left-enter-active,
.rtl .slide-left-appear.slide-left-appear-active {
  animation-name: antSlideleftIn;
  animation-play-state: running;
}

.rtl .slide-left-leave.slide-left-leave-active {
  animation-name: antSlideleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-left-enter,
.rtl .slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .slide-right-enter.slide-right-enter-active,
.rtl .slide-right-appear.slide-right-appear-active {
  animation-name: antSliderightIn;
  animation-play-state: running;
}

.rtl .slide-right-leave.slide-right-leave-active {
  animation-name: antSliderightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .slide-right-enter,
.rtl .slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rtl .slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes antSlideleftIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
}

@keyframes antSlideleftOut {
  0% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8);
  }
}

@keyframes antSliderightIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
}

@keyframes antSliderightOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8);
  }
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-left-enter.zoom-left-enter-active,
.rtl .zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomleftIn;
  animation-play-state: running;
}

.rtl .zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomleftOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-left-enter,
.rtl .zoom-left-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}

.rtl .zoom-right-enter.zoom-right-enter-active,
.rtl .zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomrightIn;
  animation-play-state: running;
}

.rtl .zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomrightOut;
  animation-play-state: running;
  pointer-events: none;
}

.rtl .zoom-right-enter,
.rtl .zoom-right-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.rtl .zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@keyframes antZoomleftIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomleftOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8);
  }
}

@keyframes antZoomrightIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}

@keyframes antZoomrightOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8);
  }
}

.rtl .ant-alert-icon {
  top: 12.5px;
  right: 16px;
  position: absolute;
}

.rtl .ant-alert-close-icon {
  font-size: 12px;
  position: absolute;
  left: 16px;
  top: 8px;
  line-height: 22px;
  overflow: hidden;
  cursor: pointer;
}

.rtl .ant-alert-close-text {
  position: absolute;
  left: 16px;
}

.rtl .ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 24px;
}

.rtl .ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  font-size: 14px;
}

.rtl .ant-anchor {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  padding-right: 2px;
}

.rtl .ant-anchor-wrapper {
  background-color: #fff;
  overflow: auto;
  padding-right: 4px;
  margin-right: -4px;
}

.rtl .ant-anchor-ink {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
}

.rtl .ant-anchor-ink-ball {
  right: 50%;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  margin-right: 0;
  margin-left: 0;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}

.rtl .ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: right;
}

.rtl .ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 0 !important;
}

.rtl .ant-select-arrow {
  left: 11px;
  right: auto;
}

.rtl .ant-select-selection__clear {
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  opacity: 0;
  position: absolute;
  left: 11px;
  right: unset;
  z-index: 1;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-select-selection-selected-value {
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 0;
}

.rtl .ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background: #f5f5f5;
  color: #aaa;
  padding-left: 10px;
}

.rtl .ant-select-selection__rendered {
  margin-right: 11px;
  margin-left: 11px;
}

.rtl .ant-select-sm .ant-select-selection__clear,
.rtl .ant-select-sm .ant-select-arrow {
  left: 8px;
}

.rtl .ant-select-selection__placeholder,
.rtl .ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 0;
  left: 9px;
  color: #bfbfbf;
  line-height: 20px;
  height: 20px;
  max-width: 100%;
  margin-top: -10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.rtl .ant-select-search__field__placeholder {
  right: 12px;
}

.rtl .ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  right: -9999px;
  white-space: pre;
  pointer-events: none;
}

.rtl .ant-select-search--inline > i {
  float: left;
}

.rtl .ant-select-selection--multiple .ant-select-search--inline {
  float: right;
  position: static;
  width: auto;
  padding: 0;
  max-width: 100%;
}

.rtl .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 5px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-right: 6px;
}

.rtl .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  cursor: default;
  float: right;
  margin-left: 4px;
  max-width: 99%;
  position: relative;
  overflow: hidden;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 10px 0 20px;
}

.rtl .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-left: 20px;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter {
  right: auto;
  left: 35px;
}

.rtl .ant-select-dropdown {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  margin: 0;
  padding: 0;
  list-style: none;
  direction: rtl;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 1050;
  top: -9999px;
  position: absolute;
  outline: none;
  font-size: 14px;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomleft {
  animation-name: antSlideUpIn;
}

.rtl .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topleft,
.rtl .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topleft {
  animation-name: antSlideDownIn;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomleft {
  animation-name: antSlideUpOut;
}

.rtl .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topleft {
  animation-name: antSlideDownOut;
}

.rtl .ant-select-dropdown-menu {
  outline: none;
  margin-bottom: 0;
  padding-right: 0;
  list-style: none;
  max-height: 250px;
  overflow: auto;
}

.rtl .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-right: 20px;
}

.rtl .ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E632";
  color: transparent;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333) rotate(0deg);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  font-weight: bold;
  text-shadow: 0 0.1px 0, -0.1px 0 0, 0 -0.1px 0, 0.1px 0;
}

.rtl .ant-input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-input-group > [class*="col-"] {
  padding-left: 8px;
}

.rtl .ant-input-group > [class*="col-"]:last-child {
  padding-left: 0;
}

.rtl .ant-input-group .ant-input {
  float: right;
  width: 100%;
  margin-bottom: 0;
}

.rtl .ant-input-group-addon > i:only-child:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.rtl .ant-input-group > .ant-input:first-child,
.rtl .ant-input-group-addon:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-input-group-addon:first-child {
  border-left: 0;
}

.rtl .ant-input-group-addon:last-child {
  border-right: 0;
}

.rtl .ant-input-group > .ant-input:last-child,
.rtl .ant-input-group-addon:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.rtl .ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  width: 100%;
  float: right;
}

.rtl .ant-input-group.ant-input-group-compact > * {
  border-radius: 0;
  border-left-width: 0;
  vertical-align: top;
  float: none;
  display: inline-block;
}

.rtl .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
  border-radius: 0;
  border-left-width: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:first-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .has-success.has-feedback .ant-form-item-children-icon,
.rtl .has-warning.has-feedback .ant-form-item-children-icon,
.rtl .has-error.has-feedback .ant-form-item-children-icon,
.rtl .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.rtl .ant-input-group.ant-input-group-compact > *:last-child,
.rtl .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.rtl .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.rtl .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.rtl .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.rtl .ant-form-item-control.has-error .ant-form-explain {
  text-align: right;
}

.rtl .ant-input-affix-wrapper .ant-input-prefix {
  left: auto;
  right: 12px;
}

.rtl .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  right: auto;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-right: 30px;
}

.rtl .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-left: 30px;
}

.rtl .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
  left: 12px;
}

.rtl .ant-input-search > .ant-input-suffix > .ant-input-search-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input {
  padding-left: 46px;
}

.rtl .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
  left: 0;
}

.rtl .ant-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:hover > a:only-child:after,
.rtl .ant-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:active > a:only-child:after,
.rtl .ant-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn.disabled > a:only-child:after,
.rtl .ant-btn[disabled] > a:only-child:after,
.rtl .ant-btn.disabled:hover > a:only-child:after,
.rtl .ant-btn[disabled]:hover > a:only-child:after,
.rtl .ant-btn.disabled:focus > a:only-child:after,
.rtl .ant-btn[disabled]:focus > a:only-child:after,
.rtl .ant-btn.disabled:active > a:only-child:after,
.rtl .ant-btn[disabled]:active > a:only-child:after,
.rtl .ant-btn.disabled.active > a:only-child:after,
.rtl .ant-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-left-color: #40a9ff;
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-left-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-left-color: #d9d9d9;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
}

.rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
}

.rtl .ant-btn-ghost > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:hover > a:only-child:after,
.rtl .ant-btn-ghost:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost:active > a:only-child:after,
.rtl .ant-btn-ghost.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-ghost.disabled > a:only-child:after,
.rtl .ant-btn-ghost[disabled] > a:only-child:after,
.rtl .ant-btn-ghost.disabled:hover > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:hover > a:only-child:after,
.rtl .ant-btn-ghost.disabled:focus > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:focus > a:only-child:after,
.rtl .ant-btn-ghost.disabled:active > a:only-child:after,
.rtl .ant-btn-ghost[disabled]:active > a:only-child:after,
.rtl .ant-btn-ghost.disabled.active > a:only-child:after,
.rtl .ant-btn-ghost[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:hover > a:only-child:after,
.rtl .ant-btn-dashed:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed:active > a:only-child:after,
.rtl .ant-btn-dashed.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-dashed.disabled > a:only-child:after,
.rtl .ant-btn-dashed[disabled] > a:only-child:after,
.rtl .ant-btn-dashed.disabled:hover > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:hover > a:only-child:after,
.rtl .ant-btn-dashed.disabled:focus > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:focus > a:only-child:after,
.rtl .ant-btn-dashed.disabled:active > a:only-child:after,
.rtl .ant-btn-dashed[disabled]:active > a:only-child:after,
.rtl .ant-btn-dashed.disabled.active > a:only-child:after,
.rtl .ant-btn-dashed[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #fff;
  opacity: 0.35;
  content: "";
  border-radius: inherit;
  z-index: 1;
  transition: opacity 0.2s;
  pointer-events: none;
  display: none;
}

.rtl .ant-btn .anticon {
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-btn.ant-btn-loading:before {
  display: block;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 29px;
  pointer-events: none;
  position: relative;
}

.rtl .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -14px;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-right: 24px;
}

.rtl .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-right: -17px;
}

.rtl .ant-btn-group .ant-btn + .ant-btn,
.rtl .ant-btn + .ant-btn-group,
.rtl .ant-btn-group span + .ant-btn,
.rtl .ant-btn-group .ant-btn + span,
.rtl .ant-btn-group + .ant-btn,
.rtl .ant-btn-group + .ant-btn-group {
  margin-right: -1px;
}

.rtl .ant-btn-group > .ant-btn:first-child,
.rtl .ant-btn-group > span:first-child > .ant-btn {
  margin-right: 0;
}

.rtl .ant-btn-group > .ant-btn:first-child:not(:last-child),
.rtl .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-btn-group > .ant-btn:last-child:not(:first-child),
.rtl .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-btn-group > .ant-btn-group {
  float: right;
}

.rtl .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding-left: 8px;
}

.rtl .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding-right: 8px;
}

.rtl .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-btn:focus > span,
.rtl .ant-btn:active > span {
  position: relative;
}

.rtl .ant-btn > .anticon + span,
.rtl .ant-btn > span + .anticon {
  margin-right: 8px;
}

.rtl .ant-btn-clicked:after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: inherit;
  border: 0 solid #077aa3;
  opacity: 0.4;
  animation: buttonEffect 0.4s;
  display: block;
}

.rtl .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
.rtl .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-btn-two-chinese-chars > * {
  letter-spacing: 0.34em;
  margin-left: -0.34em;
}

@keyframes buttonEffect {
  to {
    opacity: 0;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border-width: 6px;
  }
}

.rtl .ant-back-top {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 10;
  position: fixed;
  left: 100px;
  bottom: 50px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .rtl .ant-back-top {
    left: 60px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-back-top {
    left: 20px;
  }
}

.rtl .ant-badge-status-processing:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #077aa3;
  content: "";
  animation: antStatusProcessing 1.2s infinite ease-in-out;
}

.rtl .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-right: 8px;
}

.rtl .ant-breadcrumb-link > .anticon + span {
  margin-right: 4px;
}

.rtl .ant-fullcalendar-month-select {
  margin-right: 5px;
}

.rtl .ant-fullcalendar-header {
  padding: 11px 0 11px 16px;
  text-align: left;
}

.rtl .ant-fullcalendar-header .ant-select-dropdown {
  text-align: right;
}

.rtl .ant-fullcalendar-header .ant-radio-group {
  margin-right: 8px;
  text-align: right;
}

.rtl .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-fullcalendar-content {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: -9px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-right: 16px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  text-align: right;
  margin: 0 4px;
  display: block;
  color: rgba(0, 0, 0, 0.65);
  height: 116px;
  padding: 4px 8px;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  text-align: left;
  padding-left: 12px;
  padding-bottom: 5px;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  text-align: left;
  background: transparent;
  width: auto;
}

.rtl .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  height: 90px;
  overflow-y: auto;
  position: static;
  width: auto;
  right: auto;
  bottom: auto;
}

.rtl .ant-radio-wrapper {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-left: 8px;
  cursor: pointer;
}

.rtl .ant-radio-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #077aa3;
  content: "";
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-radio-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  border-color: #d9d9d9;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-radio-inner:after {
  position: absolute;
  width: 8px;
  height: 8px;
  right: 3px;
  top: 3px;
  border-radius: 4px;
  display: table;
  border-top: 0;
  border-right: 0;
  content: " ";
  background-color: #077aa3;
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.rtl .ant-radio-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.rtl span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-radio-button-wrapper {
  margin: 0;
  height: 32px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-right: 0;
  border-top-width: 1.02px;
  background: #fff;
  padding: 0 15px;
  position: relative;
}

.rtl .ant-radio-button-wrapper > .ant-radio-button {
  margin-right: 0;
  display: block;
  width: 0;
  height: 0;
}

.rtl .ant-radio-button-wrapper:not(:first-child)::before {
  content: "";
  display: block;
  top: 0;
  right: -1px;
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #d9d9d9;
}

.rtl .ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

.rtl .ant-card-extra {
  float: left;
  padding: 17.5px 0;
  text-align: left;
  margin-right: auto;
}

.rtl .ant-card-grid {
  border-radius: 0;
  border: 0;
  box-shadow: -1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, -1px 1px 0 0 #e8e8e8, -1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  width: 33.33%;
  float: right;
  padding: 24px;
  transition: all 0.3s;
}

.rtl .ant-card-actions > li {
  float: right;
  text-align: center;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-card-actions > li:not(:last-child) {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-card-padding-transition .ant-card-extra {
  transition: left 0.3s;
}

.rtl .ant-card-meta-avatar {
  padding-left: 16px;
  float: right;
}

.rtl .ant-card-loading-block {
  display: inline-block;
  margin: 5px 0 0 2%;
  height: 14px;
  border-radius: 2px;
  background: linear-gradient(-90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  animation: card-loading 1.4s ease infinite;
  background-size: 600% 600%;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  margin: 0;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background: #fafafa;
  margin-left: 2px;
  padding: 0 16px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  line-height: 38px;
}

.rtl .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
  font-size: 12px;
  margin-right: 3px;
  margin-left: -5px;
  overflow: hidden;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  height: 14px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  border-left: 0;
  border-radius: 0 4px 4px 0;
  margin-left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab {
  border-right: 0;
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-card.ant-tabs-right > .ant-tabs-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}

.rtl .ant-tabs-ink-bar {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 1px;
  box-sizing: border-box;
  height: 2px;
  background-color: #077aa3;
  transform-origin: 100% 0;
}

.rtl .ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-tabs-tab-prev-icon,
.rtl .ant-tabs-tab-next-icon {
  font-style: normal;
  font-weight: bold;
  font-variant: normal;
  line-height: inherit;
  vertical-align: baseline;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
  text-transform: none;
}

.rtl .ant-tabs-tab-prev {
  right: 0;
}

.rtl .ant-tabs-nav {
  box-sizing: border-box;
  padding-right: 0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  margin: 0;
  list-style: none;
  display: inline-block;
}

.rtl .ant-tabs-nav .ant-tabs-tab:last-child {
  margin-left: 0;
}

.rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-left: 8px;
}

.rtl .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  will-change: margin-left;
  transition: margin-right 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-ink-bar {
  width: 2px;
  right: auto;
  height: auto;
  top: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
  float: right;
  border-left: 1px solid #e8e8e8;
  margin-left: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: left;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-container {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-ink-bar {
  left: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar {
  float: left;
  border-right: 1px solid #e8e8e8;
  margin-right: -1px;
  margin-bottom: 0;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-container {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-bar .ant-tabs-ink-bar {
  right: 1px;
}

.rtl .ant-tabs-vertical.ant-tabs-right > .ant-tabs-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-tabs-left .ant-tabs-ink-bar-animated,
.rtl .ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .no-flex > .ant-tabs-content-animated,
.rtl .ant-tabs-no-animation > .ant-tabs-content-animated,
.rtl .ant-tabs-vertical > .ant-tabs-content-animated {
  transform: none !important;
  margin-right: 0 !important;
}

.rtl .ant-carousel .slick-track {
  position: relative;
  right: 0;
  top: 0;
  display: block;
}

.rtl [dir="rtl"] .ant-carousel .slick-slide {
  float: left;
}

.rtl .ant-carousel .slick-prev {
  right: -25px;
}

.rtl .ant-carousel .slick-prev:before {
  content: "\2190";
}

.rtl .ant-carousel .slick-next {
  left: -25px;
}

.rtl .ant-carousel-vertical .slick-dots {
  width: 3px;
  bottom: auto;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}

.rtl .ant-cascader-picker-label {
  position: absolute;
  right: 0;
  height: 20px;
  line-height: 20px;
  top: 50%;
  margin-top: -10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  padding: 0 12px;
}

.rtl .ant-cascader-picker-clear {
  opacity: 0;
  position: absolute;
  left: 12px;
  z-index: 2;
  background: #fff;
  top: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
  width: 12px;
  height: 12px;
  margin-top: -6px;
  line-height: 12px;
  cursor: pointer;
  transition: color 0.3s ease, opacity 0.15s ease;
}

.rtl .ant-cascader-picker-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 12px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  margin-top: -6px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.25);
}

.rtl .ant-cascader-picker-small .ant-cascader-picker-clear,
.rtl .ant-cascader-picker-small .ant-cascader-picker-arrow {
  left: 8px;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomleft {
  animation-name: antSlideUpIn;
}

.rtl .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topleft,
.rtl .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topleft {
  animation-name: antSlideDownIn;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomleft {
  animation-name: antSlideUpOut;
}

.rtl .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topleft {
  animation-name: antSlideDownOut;
}

.rtl .ant-cascader-menu {
  display: inline-block;
  vertical-align: top;
  min-width: 111px;
  height: 180px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid #e8e8e8;
  overflow: auto;
}

.rtl .ant-cascader-menu:last-child {
  border-left-color: transparent;
  margin-left: -1px;
  border-radius: 4px 0 0 4px;
}

.rtl .ant-cascader-menu-item-expand {
  position: relative;
  padding-left: 24px;
}

.rtl .ant-cascader-menu-item-expand:after {
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\E61F";
  display: inline-block;
  font-size: 12px;
  font-size: 8px \9;
  transform: scale(0.66667) rotate(0deg);
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: 12px;
}

.rtl .ant-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #077aa3;
  content: "";
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-checkbox-wrapper + span,
.rtl .ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
  margin-right: 0px;
}

.rtl .ant-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-row {
  position: relative;
  margin-right: 0;
  margin-left: 0;
  height: auto;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}

.rtl .ant-col-1,
.rtl .ant-col-xs-1,
.rtl .ant-col-sm-1,
.rtl .ant-col-md-1,
.rtl .ant-col-lg-1,
.rtl .ant-col-2,
.rtl .ant-col-xs-2,
.rtl .ant-col-sm-2,
.rtl .ant-col-md-2,
.rtl .ant-col-lg-2,
.rtl .ant-col-3,
.rtl .ant-col-xs-3,
.rtl .ant-col-sm-3,
.rtl .ant-col-md-3,
.rtl .ant-col-lg-3,
.rtl .ant-col-4,
.rtl .ant-col-xs-4,
.rtl .ant-col-sm-4,
.rtl .ant-col-md-4,
.rtl .ant-col-lg-4,
.rtl .ant-col-5,
.rtl .ant-col-xs-5,
.rtl .ant-col-sm-5,
.rtl .ant-col-md-5,
.rtl .ant-col-lg-5,
.rtl .ant-col-6,
.rtl .ant-col-xs-6,
.rtl .ant-col-sm-6,
.rtl .ant-col-md-6,
.rtl .ant-col-lg-6,
.rtl .ant-col-7,
.rtl .ant-col-xs-7,
.rtl .ant-col-sm-7,
.rtl .ant-col-md-7,
.rtl .ant-col-lg-7,
.rtl .ant-col-8,
.rtl .ant-col-xs-8,
.rtl .ant-col-sm-8,
.rtl .ant-col-md-8,
.rtl .ant-col-lg-8,
.rtl .ant-col-9,
.rtl .ant-col-xs-9,
.rtl .ant-col-sm-9,
.rtl .ant-col-md-9,
.rtl .ant-col-lg-9,
.rtl .ant-col-10,
.rtl .ant-col-xs-10,
.rtl .ant-col-sm-10,
.rtl .ant-col-md-10,
.rtl .ant-col-lg-10,
.rtl .ant-col-11,
.rtl .ant-col-xs-11,
.rtl .ant-col-sm-11,
.rtl .ant-col-md-11,
.rtl .ant-col-lg-11,
.rtl .ant-col-12,
.rtl .ant-col-xs-12,
.rtl .ant-col-sm-12,
.rtl .ant-col-md-12,
.rtl .ant-col-lg-12,
.rtl .ant-col-13,
.rtl .ant-col-xs-13,
.rtl .ant-col-sm-13,
.rtl .ant-col-md-13,
.rtl .ant-col-lg-13,
.rtl .ant-col-14,
.rtl .ant-col-xs-14,
.rtl .ant-col-sm-14,
.rtl .ant-col-md-14,
.rtl .ant-col-lg-14,
.rtl .ant-col-15,
.rtl .ant-col-xs-15,
.rtl .ant-col-sm-15,
.rtl .ant-col-md-15,
.rtl .ant-col-lg-15,
.rtl .ant-col-16,
.rtl .ant-col-xs-16,
.rtl .ant-col-sm-16,
.rtl .ant-col-md-16,
.rtl .ant-col-lg-16,
.rtl .ant-col-17,
.rtl .ant-col-xs-17,
.rtl .ant-col-sm-17,
.rtl .ant-col-md-17,
.rtl .ant-col-lg-17,
.rtl .ant-col-18,
.rtl .ant-col-xs-18,
.rtl .ant-col-sm-18,
.rtl .ant-col-md-18,
.rtl .ant-col-lg-18,
.rtl .ant-col-19,
.rtl .ant-col-xs-19,
.rtl .ant-col-sm-19,
.rtl .ant-col-md-19,
.rtl .ant-col-lg-19,
.rtl .ant-col-20,
.rtl .ant-col-xs-20,
.rtl .ant-col-sm-20,
.rtl .ant-col-md-20,
.rtl .ant-col-lg-20,
.rtl .ant-col-21,
.rtl .ant-col-xs-21,
.rtl .ant-col-sm-21,
.rtl .ant-col-md-21,
.rtl .ant-col-lg-21,
.rtl .ant-col-22,
.rtl .ant-col-xs-22,
.rtl .ant-col-sm-22,
.rtl .ant-col-md-22,
.rtl .ant-col-lg-22,
.rtl .ant-col-23,
.rtl .ant-col-xs-23,
.rtl .ant-col-sm-23,
.rtl .ant-col-md-23,
.rtl .ant-col-lg-23,
.rtl .ant-col-24,
.rtl .ant-col-xs-24,
.rtl .ant-col-sm-24,
.rtl .ant-col-md-24,
.rtl .ant-col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-col-1,
.rtl .ant-col-2,
.rtl .ant-col-3,
.rtl .ant-col-4,
.rtl .ant-col-5,
.rtl .ant-col-6,
.rtl .ant-col-7,
.rtl .ant-col-8,
.rtl .ant-col-9,
.rtl .ant-col-10,
.rtl .ant-col-11,
.rtl .ant-col-12,
.rtl .ant-col-13,
.rtl .ant-col-14,
.rtl .ant-col-15,
.rtl .ant-col-16,
.rtl .ant-col-17,
.rtl .ant-col-18,
.rtl .ant-col-19,
.rtl .ant-col-20,
.rtl .ant-col-21,
.rtl .ant-col-22,
.rtl .ant-col-23,
.rtl .ant-col-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-push-24 {
  right: 100%;
}

.rtl .ant-col-pull-24 {
  left: 100%;
}

.rtl .ant-col-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-push-21 {
  right: 87.5%;
}

.rtl .ant-col-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-push-18 {
  right: 75%;
}

.rtl .ant-col-pull-18 {
  left: 75%;
}

.rtl .ant-col-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-push-15 {
  right: 62.5%;
}

.rtl .ant-col-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-push-12 {
  right: 50%;
}

.rtl .ant-col-pull-12 {
  left: 50%;
}

.rtl .ant-col-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-push-9 {
  right: 37.5%;
}

.rtl .ant-col-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-push-6 {
  right: 25%;
}

.rtl .ant-col-pull-6 {
  left: 25%;
}

.rtl .ant-col-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-push-3 {
  right: 12.5%;
}

.rtl .ant-col-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-offset-0 {
  margin-right: 0;
}

.rtl .ant-col-xs-1,
.rtl .ant-col-xs-2,
.rtl .ant-col-xs-3,
.rtl .ant-col-xs-4,
.rtl .ant-col-xs-5,
.rtl .ant-col-xs-6,
.rtl .ant-col-xs-7,
.rtl .ant-col-xs-8,
.rtl .ant-col-xs-9,
.rtl .ant-col-xs-10,
.rtl .ant-col-xs-11,
.rtl .ant-col-xs-12,
.rtl .ant-col-xs-13,
.rtl .ant-col-xs-14,
.rtl .ant-col-xs-15,
.rtl .ant-col-xs-16,
.rtl .ant-col-xs-17,
.rtl .ant-col-xs-18,
.rtl .ant-col-xs-19,
.rtl .ant-col-xs-20,
.rtl .ant-col-xs-21,
.rtl .ant-col-xs-22,
.rtl .ant-col-xs-23,
.rtl .ant-col-xs-24 {
  float: right;
  flex: 0 0 auto;
}

.rtl .ant-col-xs-push-24 {
  right: 100%;
}

.rtl .ant-col-xs-pull-24 {
  left: 100%;
}

.rtl .ant-col-xs-offset-24 {
  margin-right: 100%;
}

.rtl .ant-col-xs-push-23 {
  right: 95.83333333%;
}

.rtl .ant-col-xs-pull-23 {
  left: 95.83333333%;
}

.rtl .ant-col-xs-offset-23 {
  margin-right: 95.83333333%;
}

.rtl .ant-col-xs-push-22 {
  right: 91.66666667%;
}

.rtl .ant-col-xs-pull-22 {
  left: 91.66666667%;
}

.rtl .ant-col-xs-offset-22 {
  margin-right: 91.66666667%;
}

.rtl .ant-col-xs-push-21 {
  right: 87.5%;
}

.rtl .ant-col-xs-pull-21 {
  left: 87.5%;
}

.rtl .ant-col-xs-offset-21 {
  margin-right: 87.5%;
}

.rtl .ant-col-xs-push-20 {
  right: 83.33333333%;
}

.rtl .ant-col-xs-pull-20 {
  left: 83.33333333%;
}

.rtl .ant-col-xs-offset-20 {
  margin-right: 83.33333333%;
}

.rtl .ant-col-xs-push-19 {
  right: 79.16666667%;
}

.rtl .ant-col-xs-pull-19 {
  left: 79.16666667%;
}

.rtl .ant-col-xs-offset-19 {
  margin-right: 79.16666667%;
}

.rtl .ant-col-xs-push-18 {
  right: 75%;
}

.rtl .ant-col-xs-pull-18 {
  left: 75%;
}

.rtl .ant-col-xs-offset-18 {
  margin-right: 75%;
}

.rtl .ant-col-xs-push-17 {
  right: 70.83333333%;
}

.rtl .ant-col-xs-pull-17 {
  left: 70.83333333%;
}

.rtl .ant-col-xs-offset-17 {
  margin-right: 70.83333333%;
}

.rtl .ant-col-xs-push-16 {
  right: 66.66666667%;
}

.rtl .ant-col-xs-pull-16 {
  left: 66.66666667%;
}

.rtl .ant-col-xs-offset-16 {
  margin-right: 66.66666667%;
}

.rtl .ant-col-xs-push-15 {
  right: 62.5%;
}

.rtl .ant-col-xs-pull-15 {
  left: 62.5%;
}

.rtl .ant-col-xs-offset-15 {
  margin-right: 62.5%;
}

.rtl .ant-col-xs-push-14 {
  right: 58.33333333%;
}

.rtl .ant-col-xs-pull-14 {
  left: 58.33333333%;
}

.rtl .ant-col-xs-offset-14 {
  margin-right: 58.33333333%;
}

.rtl .ant-col-xs-push-13 {
  right: 54.16666667%;
}

.rtl .ant-col-xs-pull-13 {
  left: 54.16666667%;
}

.rtl .ant-col-xs-offset-13 {
  margin-right: 54.16666667%;
}

.rtl .ant-col-xs-push-12 {
  right: 50%;
}

.rtl .ant-col-xs-pull-12 {
  left: 50%;
}

.rtl .ant-col-xs-offset-12 {
  margin-right: 50%;
}

.rtl .ant-col-xs-push-11 {
  right: 45.83333333%;
}

.rtl .ant-col-xs-pull-11 {
  left: 45.83333333%;
}

.rtl .ant-col-xs-offset-11 {
  margin-right: 45.83333333%;
}

.rtl .ant-col-xs-push-10 {
  right: 41.66666667%;
}

.rtl .ant-col-xs-pull-10 {
  left: 41.66666667%;
}

.rtl .ant-col-xs-offset-10 {
  margin-right: 41.66666667%;
}

.rtl .ant-col-xs-push-9 {
  right: 37.5%;
}

.rtl .ant-col-xs-pull-9 {
  left: 37.5%;
}

.rtl .ant-col-xs-offset-9 {
  margin-right: 37.5%;
}

.rtl .ant-col-xs-push-8 {
  right: 33.33333333%;
}

.rtl .ant-col-xs-pull-8 {
  left: 33.33333333%;
}

.rtl .ant-col-xs-offset-8 {
  margin-right: 33.33333333%;
}

.rtl .ant-col-xs-push-7 {
  right: 29.16666667%;
}

.rtl .ant-col-xs-pull-7 {
  left: 29.16666667%;
}

.rtl .ant-col-xs-offset-7 {
  margin-right: 29.16666667%;
}

.rtl .ant-col-xs-push-6 {
  right: 25%;
}

.rtl .ant-col-xs-pull-6 {
  left: 25%;
}

.rtl .ant-col-xs-offset-6 {
  margin-right: 25%;
}

.rtl .ant-col-xs-push-5 {
  right: 20.83333333%;
}

.rtl .ant-col-xs-pull-5 {
  left: 20.83333333%;
}

.rtl .ant-col-xs-offset-5 {
  margin-right: 20.83333333%;
}

.rtl .ant-col-xs-push-4 {
  right: 16.66666667%;
}

.rtl .ant-col-xs-pull-4 {
  left: 16.66666667%;
}

.rtl .ant-col-xs-offset-4 {
  margin-right: 16.66666667%;
}

.rtl .ant-col-xs-push-3 {
  right: 12.5%;
}

.rtl .ant-col-xs-pull-3 {
  left: 12.5%;
}

.rtl .ant-col-xs-offset-3 {
  margin-right: 12.5%;
}

.rtl .ant-col-xs-push-2 {
  right: 8.33333333%;
}

.rtl .ant-col-xs-pull-2 {
  left: 8.33333333%;
}

.rtl .ant-col-xs-offset-2 {
  margin-right: 8.33333333%;
}

.rtl .ant-col-xs-push-1 {
  right: 4.16666667%;
}

.rtl .ant-col-xs-pull-1 {
  left: 4.16666667%;
}

.rtl .ant-col-xs-offset-1 {
  margin-right: 4.16666667%;
}

.rtl .ant-col-push-0 {
  right: auto;
}

.rtl .ant-col-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-push-0 {
  right: auto;
}

.rtl .ant-col-xs-pull-0 {
  left: auto;
}

.rtl .ant-col-xs-offset-0 {
  margin-right: 0;
}

@media (min-width: 576px) {
  .rtl .ant-col-sm-1,
  .rtl .ant-col-sm-2,
  .rtl .ant-col-sm-3,
  .rtl .ant-col-sm-4,
  .rtl .ant-col-sm-5,
  .rtl .ant-col-sm-6,
  .rtl .ant-col-sm-7,
  .rtl .ant-col-sm-8,
  .rtl .ant-col-sm-9,
  .rtl .ant-col-sm-10,
  .rtl .ant-col-sm-11,
  .rtl .ant-col-sm-12,
  .rtl .ant-col-sm-13,
  .rtl .ant-col-sm-14,
  .rtl .ant-col-sm-15,
  .rtl .ant-col-sm-16,
  .rtl .ant-col-sm-17,
  .rtl .ant-col-sm-18,
  .rtl .ant-col-sm-19,
  .rtl .ant-col-sm-20,
  .rtl .ant-col-sm-21,
  .rtl .ant-col-sm-22,
  .rtl .ant-col-sm-23,
  .rtl .ant-col-sm-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-sm-push-24 {
    right: 100%;
  }
  .rtl .ant-col-sm-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-sm-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-sm-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-sm-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-sm-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-sm-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-sm-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-sm-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-sm-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-sm-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-sm-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-sm-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-sm-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-sm-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-sm-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-sm-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-sm-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-sm-push-18 {
    right: 75%;
  }
  .rtl .ant-col-sm-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-sm-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-sm-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-sm-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-sm-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-sm-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-sm-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-sm-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-sm-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-sm-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-sm-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-sm-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-sm-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-sm-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-sm-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-sm-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-sm-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-sm-push-12 {
    right: 50%;
  }
  .rtl .ant-col-sm-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-sm-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-sm-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-sm-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-sm-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-sm-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-sm-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-sm-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-sm-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-sm-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-sm-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-sm-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-sm-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-sm-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-sm-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-sm-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-sm-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-sm-push-6 {
    right: 25%;
  }
  .rtl .ant-col-sm-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-sm-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-sm-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-sm-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-sm-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-sm-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-sm-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-sm-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-sm-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-sm-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-sm-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-sm-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-sm-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-sm-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-sm-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-sm-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-sm-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-push-0 {
    right: auto;
  }
  .rtl .ant-col-sm-pull-0 {
    left: auto;
  }
  .rtl .ant-col-sm-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .rtl .ant-col-md-1,
  .rtl .ant-col-md-2,
  .rtl .ant-col-md-3,
  .rtl .ant-col-md-4,
  .rtl .ant-col-md-5,
  .rtl .ant-col-md-6,
  .rtl .ant-col-md-7,
  .rtl .ant-col-md-8,
  .rtl .ant-col-md-9,
  .rtl .ant-col-md-10,
  .rtl .ant-col-md-11,
  .rtl .ant-col-md-12,
  .rtl .ant-col-md-13,
  .rtl .ant-col-md-14,
  .rtl .ant-col-md-15,
  .rtl .ant-col-md-16,
  .rtl .ant-col-md-17,
  .rtl .ant-col-md-18,
  .rtl .ant-col-md-19,
  .rtl .ant-col-md-20,
  .rtl .ant-col-md-21,
  .rtl .ant-col-md-22,
  .rtl .ant-col-md-23,
  .rtl .ant-col-md-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-md-push-24 {
    right: 100%;
  }
  .rtl .ant-col-md-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-md-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-md-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-md-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-md-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-md-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-md-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-md-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-md-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-md-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-md-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-md-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-md-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-md-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-md-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-md-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-md-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-md-push-18 {
    right: 75%;
  }
  .rtl .ant-col-md-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-md-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-md-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-md-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-md-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-md-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-md-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-md-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-md-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-md-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-md-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-md-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-md-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-md-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-md-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-md-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-md-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-md-push-12 {
    right: 50%;
  }
  .rtl .ant-col-md-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-md-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-md-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-md-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-md-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-md-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-md-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-md-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-md-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-md-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-md-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-md-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-md-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-md-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-md-order-8 {
    order: 8;
  }
  .rtl .ant-col-md-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-md-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-md-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-md-push-6 {
    right: 25%;
  }
  .rtl .ant-col-md-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-md-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-md-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-md-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-md-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-md-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-md-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-md-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-md-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-md-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-md-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-md-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-md-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-md-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-md-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-md-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-md-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-push-0 {
    right: auto;
  }
  .rtl .ant-col-md-pull-0 {
    left: auto;
  }
  .rtl .ant-col-md-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .rtl .ant-col-lg-1,
  .rtl .ant-col-lg-2,
  .rtl .ant-col-lg-3,
  .rtl .ant-col-lg-4,
  .rtl .ant-col-lg-5,
  .rtl .ant-col-lg-6,
  .rtl .ant-col-lg-7,
  .rtl .ant-col-lg-8,
  .rtl .ant-col-lg-9,
  .rtl .ant-col-lg-10,
  .rtl .ant-col-lg-11,
  .rtl .ant-col-lg-12,
  .rtl .ant-col-lg-13,
  .rtl .ant-col-lg-14,
  .rtl .ant-col-lg-15,
  .rtl .ant-col-lg-16,
  .rtl .ant-col-lg-17,
  .rtl .ant-col-lg-18,
  .rtl .ant-col-lg-19,
  .rtl .ant-col-lg-20,
  .rtl .ant-col-lg-21,
  .rtl .ant-col-lg-22,
  .rtl .ant-col-lg-23,
  .rtl .ant-col-lg-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-lg-push-24 {
    right: 100%;
  }
  .rtl .ant-col-lg-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-lg-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-lg-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-lg-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-lg-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-lg-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-lg-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-lg-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-lg-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-lg-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-lg-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-lg-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-lg-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-lg-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-lg-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-lg-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-lg-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-lg-push-18 {
    right: 75%;
  }
  .rtl .ant-col-lg-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-lg-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-lg-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-lg-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-lg-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-lg-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-lg-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-lg-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-lg-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-lg-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-lg-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-lg-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-lg-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-lg-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-lg-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-lg-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-lg-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-lg-push-12 {
    right: 50%;
  }
  .rtl .ant-col-lg-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-lg-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-lg-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-lg-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-lg-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-lg-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-lg-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-lg-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-lg-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-lg-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-lg-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-lg-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-lg-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-lg-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-lg-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-lg-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-lg-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-lg-push-6 {
    right: 25%;
  }
  .rtl .ant-col-lg-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-lg-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-lg-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-lg-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-lg-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-lg-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-lg-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-lg-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-lg-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-lg-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-lg-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-lg-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-lg-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-lg-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-lg-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-lg-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-lg-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-push-0 {
    right: auto;
  }
  .rtl .ant-col-lg-pull-0 {
    left: auto;
  }
  .rtl .ant-col-lg-offset-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .ant-col-xl-1,
  .rtl .ant-col-xl-2,
  .rtl .ant-col-xl-3,
  .rtl .ant-col-xl-4,
  .rtl .ant-col-xl-5,
  .rtl .ant-col-xl-6,
  .rtl .ant-col-xl-7,
  .rtl .ant-col-xl-8,
  .rtl .ant-col-xl-9,
  .rtl .ant-col-xl-10,
  .rtl .ant-col-xl-11,
  .rtl .ant-col-xl-12,
  .rtl .ant-col-xl-13,
  .rtl .ant-col-xl-14,
  .rtl .ant-col-xl-15,
  .rtl .ant-col-xl-16,
  .rtl .ant-col-xl-17,
  .rtl .ant-col-xl-18,
  .rtl .ant-col-xl-19,
  .rtl .ant-col-xl-20,
  .rtl .ant-col-xl-21,
  .rtl .ant-col-xl-22,
  .rtl .ant-col-xl-23,
  .rtl .ant-col-xl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xl-offset-0 {
    margin-right: 0;
  }
  .rtl .ant-col-xl-order-0 {
    order: 0;
  }
}

@media (min-width: 1600px) {
  .rtl .ant-col-xxl-1,
  .rtl .ant-col-xxl-2,
  .rtl .ant-col-xxl-3,
  .rtl .ant-col-xxl-4,
  .rtl .ant-col-xxl-5,
  .rtl .ant-col-xxl-6,
  .rtl .ant-col-xxl-7,
  .rtl .ant-col-xxl-8,
  .rtl .ant-col-xxl-9,
  .rtl .ant-col-xxl-10,
  .rtl .ant-col-xxl-11,
  .rtl .ant-col-xxl-12,
  .rtl .ant-col-xxl-13,
  .rtl .ant-col-xxl-14,
  .rtl .ant-col-xxl-15,
  .rtl .ant-col-xxl-16,
  .rtl .ant-col-xxl-17,
  .rtl .ant-col-xxl-18,
  .rtl .ant-col-xxl-19,
  .rtl .ant-col-xxl-20,
  .rtl .ant-col-xxl-21,
  .rtl .ant-col-xxl-22,
  .rtl .ant-col-xxl-23,
  .rtl .ant-col-xxl-24 {
    float: right;
    flex: 0 0 auto;
  }
  .rtl .ant-col-xxl-push-24 {
    right: 100%;
  }
  .rtl .ant-col-xxl-pull-24 {
    left: 100%;
  }
  .rtl .ant-col-xxl-offset-24 {
    margin-right: 100%;
  }
  .rtl .ant-col-xxl-push-23 {
    right: 95.83333333%;
  }
  .rtl .ant-col-xxl-pull-23 {
    left: 95.83333333%;
  }
  .rtl .ant-col-xxl-offset-23 {
    margin-right: 95.83333333%;
  }
  .rtl .ant-col-xxl-push-22 {
    right: 91.66666667%;
  }
  .rtl .ant-col-xxl-pull-22 {
    left: 91.66666667%;
  }
  .rtl .ant-col-xxl-offset-22 {
    margin-right: 91.66666667%;
  }
  .rtl .ant-col-xxl-push-21 {
    right: 87.5%;
  }
  .rtl .ant-col-xxl-pull-21 {
    left: 87.5%;
  }
  .rtl .ant-col-xxl-offset-21 {
    margin-right: 87.5%;
  }
  .rtl .ant-col-xxl-push-20 {
    right: 83.33333333%;
  }
  .rtl .ant-col-xxl-pull-20 {
    left: 83.33333333%;
  }
  .rtl .ant-col-xxl-offset-20 {
    margin-right: 83.33333333%;
  }
  .rtl .ant-col-xxl-push-19 {
    right: 79.16666667%;
  }
  .rtl .ant-col-xxl-pull-19 {
    left: 79.16666667%;
  }
  .rtl .ant-col-xxl-offset-19 {
    margin-right: 79.16666667%;
  }
  .rtl .ant-col-xxl-push-18 {
    right: 75%;
  }
  .rtl .ant-col-xxl-pull-18 {
    left: 75%;
  }
  .rtl .ant-col-xxl-offset-18 {
    margin-right: 75%;
  }
  .rtl .ant-col-xxl-push-17 {
    right: 70.83333333%;
  }
  .rtl .ant-col-xxl-pull-17 {
    left: 70.83333333%;
  }
  .rtl .ant-col-xxl-offset-17 {
    margin-right: 70.83333333%;
  }
  .rtl .ant-col-xxl-push-16 {
    right: 66.66666667%;
  }
  .rtl .ant-col-xxl-pull-16 {
    left: 66.66666667%;
  }
  .rtl .ant-col-xxl-offset-16 {
    margin-right: 66.66666667%;
  }
  .rtl .ant-col-xxl-push-15 {
    right: 62.5%;
  }
  .rtl .ant-col-xxl-pull-15 {
    left: 62.5%;
  }
  .rtl .ant-col-xxl-offset-15 {
    margin-right: 62.5%;
  }
  .rtl .ant-col-xxl-push-14 {
    right: 58.33333333%;
  }
  .rtl .ant-col-xxl-pull-14 {
    left: 58.33333333%;
  }
  .rtl .ant-col-xxl-offset-14 {
    margin-right: 58.33333333%;
  }
  .rtl .ant-col-xxl-push-13 {
    right: 54.16666667%;
  }
  .rtl .ant-col-xxl-pull-13 {
    left: 54.16666667%;
  }
  .rtl .ant-col-xxl-offset-13 {
    margin-right: 54.16666667%;
  }
  .rtl .ant-col-xxl-push-12 {
    right: 50%;
  }
  .rtl .ant-col-xxl-pull-12 {
    left: 50%;
  }
  .rtl .ant-col-xxl-offset-12 {
    margin-right: 50%;
  }
  .rtl .ant-col-xxl-push-11 {
    right: 45.83333333%;
  }
  .rtl .ant-col-xxl-pull-11 {
    left: 45.83333333%;
  }
  .rtl .ant-col-xxl-offset-11 {
    margin-right: 45.83333333%;
  }
  .rtl .ant-col-xxl-push-10 {
    right: 41.66666667%;
  }
  .rtl .ant-col-xxl-pull-10 {
    left: 41.66666667%;
  }
  .rtl .ant-col-xxl-offset-10 {
    margin-right: 41.66666667%;
  }
  .rtl .ant-col-xxl-push-9 {
    right: 37.5%;
  }
  .rtl .ant-col-xxl-pull-9 {
    left: 37.5%;
  }
  .rtl .ant-col-xxl-offset-9 {
    margin-right: 37.5%;
  }
  .rtl .ant-col-xxl-push-8 {
    right: 33.33333333%;
  }
  .rtl .ant-col-xxl-pull-8 {
    left: 33.33333333%;
  }
  .rtl .ant-col-xxl-offset-8 {
    margin-right: 33.33333333%;
  }
  .rtl .ant-col-xxl-push-7 {
    right: 29.16666667%;
  }
  .rtl .ant-col-xxl-pull-7 {
    left: 29.16666667%;
  }
  .rtl .ant-col-xxl-offset-7 {
    margin-right: 29.16666667%;
  }
  .rtl .ant-col-xxl-push-6 {
    right: 25%;
  }
  .rtl .ant-col-xxl-pull-6 {
    left: 25%;
  }
  .rtl .ant-col-xxl-offset-6 {
    margin-right: 25%;
  }
  .rtl .ant-col-xxl-order-6 {
    order: 6;
  }
  .rtl .ant-col-xxl-push-5 {
    right: 20.83333333%;
  }
  .rtl .ant-col-xxl-pull-5 {
    left: 20.83333333%;
  }
  .rtl .ant-col-xxl-offset-5 {
    margin-right: 20.83333333%;
  }
  .rtl .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-4 {
    right: 16.66666667%;
  }
  .rtl .ant-col-xxl-pull-4 {
    left: 16.66666667%;
  }
  .rtl .ant-col-xxl-offset-4 {
    margin-right: 16.66666667%;
  }
  .rtl .ant-col-xxl-push-3 {
    right: 12.5%;
  }
  .rtl .ant-col-xxl-pull-3 {
    left: 12.5%;
  }
  .rtl .ant-col-xxl-offset-3 {
    margin-right: 12.5%;
  }
  .rtl .ant-col-xxl-push-2 {
    right: 8.33333333%;
  }
  .rtl .ant-col-xxl-pull-2 {
    left: 8.33333333%;
  }
  .rtl .ant-col-xxl-offset-2 {
    margin-right: 8.33333333%;
  }
  .rtl .ant-col-xxl-push-1 {
    right: 4.16666667%;
  }
  .rtl .ant-col-xxl-pull-1 {
    left: 4.16666667%;
  }
  .rtl .ant-col-xxl-offset-1 {
    margin-right: 4.16666667%;
  }
  .rtl .ant-col-push-0 {
    right: auto;
  }
  .rtl .ant-col-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-push-0 {
    right: auto;
  }
  .rtl .ant-col-xxl-pull-0 {
    left: auto;
  }
  .rtl .ant-col-xxl-offset-0 {
    margin-right: 0;
  }
}

.rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
  right: 16px;
}

.rtl .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomright,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomleft,
.rtl .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 12px;
  top: 50%;
  margin-top: -7px;
  line-height: 14px;
  font-size: 12px;
  transition: all 0.3s;
  user-select: none;
}

.rtl .ant-calendar-picker-small .ant-calendar-picker-clear,
.rtl .ant-calendar-picker-small .ant-calendar-picker-icon {
  left: 8px;
}

.rtl .ant-calendar {
  position: relative;
  outline: none;
  width: 280px;
  border: 1px solid #fff;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  line-height: 1.5;
}

.rtl .ant-calendar.ant-calendar-range {
  width: 400px !important;
}

.rtl .ant-calendar-header .ant-calendar-prev-century-btn,
.rtl .ant-calendar-header .ant-calendar-prev-decade-btn,
.rtl .ant-calendar-header .ant-calendar-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-header .ant-calendar-next-century-btn,
.rtl .ant-calendar-header .ant-calendar-next-decade-btn,
.rtl .ant-calendar-header .ant-calendar-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-header .ant-calendar-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-header .ant-calendar-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  margin-left: 5px;
  padding-right: 5px;
}

.rtl .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
  content: " ";
  position: absolute;
  top: -1px;
  right: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid #bcbcbc;
  border-radius: 2px;
}

.rtl .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rtl .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rtl .ant-calendar .ant-calendar-clear-btn {
  display: none;
  position: absolute;
  left: 5px;
  text-indent: -76px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
.rtl .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
}

.rtl .ant-calendar-range-left {
  float: right;
}

.rtl .ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-left: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-right {
  float: left;
}

.rtl .ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-right: 1.5px solid #e8e8e8;
}

.rtl .ant-calendar-range-middle {
  position: absolute;
  right: 50%;
  width: 20px;
  margin-right: -132px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: -118px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  margin-right: -12px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-right: 0;
}

.rtl .ant-calendar-range .ant-calendar-input,
.rtl .ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  padding: 4px 11px;
  width: 100%;
  height: 32px;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  height: 24px;
  border: 0;
  box-shadow: none;
  padding-right: 0;
  padding-left: 0;
}

.rtl .ant-calendar-range .ant-calendar-in-range-cell:before {
  content: "";
  display: block;
  background: #e6f7ff;
  border-radius: 0;
  border: 0;
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: 0;
  left: 0;
}

.rtl div.ant-calendar-range-quick-selector {
  text-align: right;
}

.rtl div.ant-calendar-range-quick-selector > a {
  margin-left: 8px;
}

.rtl .ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-left: 8px;
}

.rtl .ant-calendar-time-picker-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  background-clip: padding-box;
  line-height: 1.5;
  overflow: hidden;
  width: 100%;
}

.rtl .ant-calendar-time-picker-select {
  float: right;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  height: 226px;
}

.rtl .ant-calendar-time-picker-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-calendar-time-picker-select:last-child {
  border-left: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer-btn {
  text-align: left;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: right;
  margin: 0;
}

.rtl .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-calendar-month-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:after,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel {
  position: absolute;
  top: 1px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  background: #fff;
  outline: none;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:after {
  content: "\2039";
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:after {
  content: "\203A";
}

.rtl .ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  border-radius: 4px;
  outline: none;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  right: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:after,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:after {
  content: "\AB";
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  left: 7px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  right: 29px;
}

.rtl .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  left: 29px;
}

.rtl .ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: right;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  right: -2px;
}

.rtl .ant-time-picker-panel-clear-btn {
  position: absolute;
  left: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}

.rtl .ant-time-picker-panel-select {
  float: right;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  max-height: 192px;
}

.rtl .ant-time-picker-panel-select:first-child {
  border-right: 0;
  margin-right: 0;
}

.rtl .ant-time-picker-panel-select:last-child {
  border-left: 0;
}

.rtl .ant-time-picker-panel-select li {
  list-style: none;
  box-sizing: content-box;
  margin: 0;
  padding: 0 12px 0 0;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: right;
  cursor: pointer;
  user-select: none;
  transition: background 0.3s;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomright,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomleft,
.rtl .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-time-picker-icon {
  position: absolute;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  left: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px;
}

.rtl .ant-time-picker-small .ant-time-picker-icon {
  left: 7px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-left-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin: 16px 0;
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:before {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 95%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right:after {
  content: "";
  display: table-cell;
  position: relative;
  top: 50%;
  width: 5%;
  border-top: 1px solid #e8e8e8;
  transform: translateY(50%);
}

.rtl .ant-divider-horizontal.ant-divider-with-text-right-inner-text {
  display: inline-block;
  padding: 0 10px;
}

.rtl .ant-dropdown-menu {
  text-align: right;
}

.rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  left: 8px;
}

.rtl .ant-dropdown-menu-submenu-title {
  padding-left: 26px;
}

.rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  top: 0;
  right: 100%;
  position: absolute;
  min-width: 100%;
  margin-right: 4px;
  transform-origin: 100% 0;
}

.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomright,
.rtl .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomright {
  animation-name: antSlideUpIn;
}

.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topright,
.rtl .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topright {
  animation-name: antSlideDownIn;
}

.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomleft,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.rtl .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomright {
  animation-name: antSlideUpOut;
}

.rtl .ant-pagination.mini .ant-pagination-prev,
.rtl .ant-pagination.mini .ant-pagination-next {
  transform: rotate(180deg);
}

.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topleft,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.rtl .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topright {
  animation-name: antSlideDownOut;
}

.rtl .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-form-item-required:before {
  display: inline-block;
  margin-left: 4px;
  content: "*";
  font-family: SimSun;
  line-height: 1;
  font-size: 14px;
  color: #f5222d;
}

.rtl .ant-form-item-label {
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.rtl .ant-form-text {
  display: inline-block;
  padding-left: 8px;
}

.rtl form .has-feedback .ant-input {
  padding-left: 24px;
}

.rtl form .has-feedback > .ant-select .ant-select-arrow,
.rtl form .has-feedback > .ant-select .ant-select-selection__clear,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  left: 28px;
}

.rtl form .has-feedback > .ant-select .ant-select-selection-selected-value,
.rtl form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-left: 42px;
}

.rtl form .has-feedback .ant-cascader-picker-arrow {
  margin-left: 17px;
}

.rtl form .has-feedback .ant-cascader-picker-clear {
  left: 28px;
}

.rtl form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  left: 28px;
}

.rtl form .has-feedback .ant-calendar-picker-icon,
.rtl form .has-feedback .ant-time-picker-icon,
.rtl form .has-feedback .ant-calendar-picker-clear,
.rtl form .has-feedback .ant-time-picker-clear {
  left: 28px;
}

.rtl form .ant-radio-inline,
.rtl form .ant-checkbox-inline {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
  margin-right: 8px;
}

.rtl form .ant-radio-inline:first-child,
.rtl form .ant-checkbox-inline:first-child {
  margin-right: 0;
}

.rtl form .ant-checkbox-vertical,
.rtl form .ant-radio-vertical {
  display: block;
}

.rtl form .ant-checkbox-vertical + .ant-checkbox-vertical,
.rtl form .ant-radio-vertical + .ant-radio-vertical {
  margin-right: 0;
}

.rtl form .ant-input-number + .ant-form-text {
  margin-right: 8px;
}

.rtl .ant-input-group-wrap .ant-select-selection {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .ant-input-group-wrap .ant-select-selection:hover {
  border-color: #d9d9d9;
}

.rtl .ant-input-group-wrap .ant-select-selection--single {
  margin-right: -1px;
  height: 40px;
  background-color: #eee;
}

.rtl .ant-input-group-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-right: 8px;
  padding-left: 25px;
}

.rtl .ant-form-vertical .ant-form-item-label,
.rtl .ant-col-24.ant-form-item-label,
.rtl .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  margin: 0;
  display: block;
  text-align: right;
  line-height: 1.5;
}

@media (max-width: 575px) {
  .rtl .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
  .rtl .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 767px) {
  .rtl .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 991px) {
  .rtl .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1199px) {
  .rtl .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

@media (max-width: 1599px) {
  .rtl .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: right;
    line-height: 1.5;
  }
}

.rtl .ant-form-inline .ant-form-item {
  display: inline-block;
  margin-left: 16px;
  margin-bottom: 0;
}

.rtl .has-success.has-feedback .ant-form-item-children:after,
.rtl .has-warning.has-feedback .ant-form-item-children:after,
.rtl .has-error.has-feedback .ant-form-item-children:after,
.rtl .is-validating.has-feedback .ant-form-item-children:after {
  position: absolute;
  top: 50%;
  left: 0;
  visibility: visible;
  pointer-events: none;
  width: 32px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  text-align: center;
  font-size: 14px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  font-family: "anticon";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  z-index: 1;
}

.rtl .ant-input-number-handler-up-inner,
.rtl .ant-input-number-handler-down-inner {
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 12px;
  user-select: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: all 0.1s linear;
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  transform: scale(0.58333) rotate(0deg);
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-layout-sider-right {
  order: 1;
}

.rtl .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  left: -36px;
  text-align: center;
  width: 36px;
  height: 42px;
  line-height: 42px;
  background: #001529;
  color: #fff;
  font-size: 18px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.rtl .ant-list-pagination {
  margin-top: 24px;
  text-align: left;
}

.rtl .ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}

.rtl .ant-list-item-meta-avatar {
  margin-left: 16px;
}

.rtl .ant-list-item-action {
  font-size: 0;
  flex: 0 0 auto;
  margin-right: 48px;
  padding: 0;
  list-style: none;
}

.rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-item-action-split {
  background-color: #e8e8e8;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 14px;
}

.rtl .ant-list-vertical .ant-list-item-extra {
  margin-right: 58px;
}

.rtl .ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}

.rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
}

.rtl .ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
  padding-right: 24px;
  padding-left: 24px;
}

.rtl .ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}

.rtl .ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}

@media screen and (max-width: 768px) {
  .rtl .ant-list-item-action {
    margin-right: 24px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
  }
}

@media screen and (max-width: 480px) {
  .rtl .ant-list-item-action {
    margin-right: 12px;
  }
  .rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 0;
  }
}

.rtl .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  right: 50%;
  margin: -10px;
}

.rtl .ant-spin-blur:after {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  transition: all 0.3s;
  z-index: 10;
}

.rtl .ant-spin-dot i:nth-child(1) {
  right: 0;
  top: 0;
}

.rtl .ant-spin-dot i:nth-child(2) {
  left: 0;
  top: 0;
  animation-delay: 0.4s;
}

.rtl .ant-spin-dot i:nth-child(3) {
  left: 0;
  bottom: 0;
  animation-delay: 0.8s;
}

.rtl .ant-spin-dot i:nth-child(4) {
  right: 0;
  bottom: 0;
  animation-delay: 1.2s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}

.rtl .ant-pagination-item {
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-left: 8px;
  margin-right: 0;
  font-family: Arial;
  outline: 0;
}

.rtl .ant-pagination-prev,
.rtl .ant-pagination-next,
.rtl .ant-pagination-jump-prev,
.rtl .ant-pagination-jump-next {
  margin-left: 8px;
  margin-right: 0;
  transform: scale(-1);
}

.rtl .ant-pagination-options {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.rtl .ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  margin-left: 8px;
  height: 24px;
}

.rtl .ant-pagination-simple .ant-pagination-simple-pager input {
  margin-left: 8px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 0 6px;
  height: 100%;
  text-align: center;
  transition: border-color 0.3s;
}

.rtl .ant-pagination.mini .ant-pagination-jump-prev,
.rtl .ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  line-height: 24px;
  margin-left: 0;
}

.rtl .ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
}

.rtl .ant-mention-dropdown {
  right: -9999px;
}

.rtl .ant-menu-item .anticon,
.rtl .ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-left: 10px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: 50%;
  left: 16px;
  right: auto;
  width: 10px;
}

.rtl .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 34px;
  padding-right: 16px;
}

.rtl .ant-tooltip-placement-right,
.rtl .ant-tooltip-placement-rightTop,
.rtl .ant-tooltip-placement-rightBottom {
  padding-right: 8px;
}

.rtl .ant-tooltip-placement-left,
.rtl .ant-tooltip-placement-leftTop,
.rtl .ant-tooltip-placement-leftBottom {
  padding-left: 8px;
}

.rtl .ant-tooltip-inner {
  text-align: right;
}

.rtl .ant-tooltip-placement-top .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-topleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-topright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-tooltip-placement-right .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  right: 3px;
  border-width: 5px 0 5px 5px;
  border-left-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-left .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.rtl .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  left: 3px;
  border-width: 5px 5px 5px 0;
  border-right-color: rgba(0, 0, 0, 0.75);
}

.rtl .ant-tooltip-placement-bottom .ant-tooltip-arrow {
  right: 50%;
  margin-right: -5px;
}

.rtl .ant-tooltip-placement-bottomleft .ant-tooltip-arrow {
  right: 16px;
}

.rtl .ant-tooltip-placement-bottomright .ant-tooltip-arrow {
  left: 16px;
}

.rtl .ant-message {
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  right: 0;
  pointer-events: none;
}

.rtl .ant-message .anticon {
  margin-left: 8px;
  font-size: 16px;
  top: 1px;
  position: relative;
}

.rtl .ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.rtl .ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  left: 0;
  right: auto;
  top: 0;
  z-index: 10;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  transition: color 0.3s;
  color: rgba(0, 0, 0, 0.45);
  outline: 0;
  padding: 0;
}

.rtl .ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: left;
  border-radius: 0 0 4px 4px;
}

.rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(0, 0, 0, 0.65);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}

.rtl .ant-confirm-body .ant-confirm-content {
  margin-right: 38px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}

.rtl .ant-confirm-body > .anticon {
  font-size: 22px;
  margin-left: 16px;
  float: right;
}

.rtl .ant-confirm .ant-confirm-btns {
  margin-top: 24px;
  float: left;
}

.rtl .ant-confirm .ant-confirm-btns button + button {
  margin-right: 8px;
  margin-bottom: 0;
}

.rtl .ant-notification {
  margin-left: 24px;
}

.rtl .ant-notification-topleft,
.rtl .ant-notification-bottomleft {
  margin-right: 24px;
  margin-left: 0;
}

.rtl .ant-notification-topleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.rtl .ant-notification-topleft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.rtl .ant-notification-bottomleft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationleftFadeIn;
}

.rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-left: 24px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 16px;
  margin-right: 24px;
  margin-bottom: 4px;
}

.rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  font-size: 14px;
}

.rtl .ant-notification-notice-icon {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  margin-right: 4px;
}

.rtl .ant-notification-notice-close {
  position: absolute;
  left: 22px !important;
  text-align: right;
  right: auto;
  top: 16px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}

.rtl .ant-notification-notice-btn {
  float: left;
  margin-top: 16px;
}

.rtl .ant-modal-confirm-btns {
  float: left;
}

.rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-left: 16px;
  margin-right: 0;
}

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    right: 384px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes NotificationleftFadeIn {
  0% {
    opacity: 0;
    left: 384px;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.rtl .ant-popover {
  right: 0;
  text-align: right;
}

.rtl .ant-popover-placement-top,
.rtl .ant-popover-placement-topleft,
.rtl .ant-popover-placement-topright {
  padding-bottom: 9px;
}

.rtl .ant-popover-placement-right,
.rtl .ant-popover-placement-rightTop,
.rtl .ant-popover-placement-rightBottom {
  padding-right: 9px;
}

.rtl .ant-popover-placement-bottom,
.rtl .ant-popover-placement-bottomleft,
.rtl .ant-popover-placement-bottomright {
  padding-top: 9px;
}

.rtl .ant-popover-placement-left,
.rtl .ant-popover-placement-leftTop,
.rtl .ant-popover-placement-leftBottom {
  padding-left: 9px;
}

.rtl .ant-popover-message-title {
  padding-right: 22px;
}

.rtl .ant-popover-buttons {
  text-align: left;
  margin-bottom: 4px;
}

.rtl .ant-popover-buttons button {
  margin-right: 8px;
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  bottom: 6px;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-topleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-topright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  box-shadow: 1px -1px 4px rgba(0, 0, 0, 0.06);
}

.rtl .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  right: 50%;
  transform: translateX(50%) rotate(-45deg);
}

.rtl .ant-popover-placement-bottomleft > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}

.rtl .ant-popover-placement-bottomright > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
}

.rtl .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
}

.rtl .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}

.rtl .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}

.rtl .ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
}

.rtl .ant-progress-show-info .ant-progress-outer {
  padding-left: calc(2em + 8px);
  margin-left: calc(-2em - 8px);
}

.rtl .ant-progress-success-bg {
  background-color: #52c41a;
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-progress-text {
  word-break: normal;
  width: 2em;
  text-align: right;
  font-size: 1em;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  line-height: 1;
}

.rtl .ant-progress-status-active .ant-progress-bg:before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 10px;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
}

.rtl .ant-progress-circle .ant-progress-text {
  right: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
}

.rtl .ant-rate-star {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  transition: all 0.3s;
  color: inherit;
  cursor: pointer;
}

.rtl .ant-rate-star-first {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.rtl .ant-rate-text {
  margin-right: 8px;
  display: inline-block;
  font-size: 14px;
}

.rtl .ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-bottom: -7px;
}

.rtl .ant-slider-vertical .ant-slider-mark {
  top: 0;
  right: 12px;
  width: 18px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  white-space: nowrap;
}

.rtl .ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}

.rtl .ant-slider-vertical .ant-slider-dot {
  top: auto;
  right: 2px;
  margin-bottom: -4px;
}

.rtl .ant-slider-mark {
  position: absolute;
  top: 14px;
  right: 0;
  width: 100%;
  font-size: 14px;
}

.rtl .ant-slider-dot {
  position: absolute;
  top: -2px;
  margin-right: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e8e8e8;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rtl .ant-slider-dot:first-child {
  margin-right: -4px;
}

.rtl .ant-slider-dot:last-child {
  margin-right: -4px;
}

.rtl .ant-steps-item-icon {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  font-size: 16px;
  margin-left: 8px;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.rtl .ant-steps-item-tail {
  position: absolute;
  right: 0;
  width: 100%;
  top: 12px;
  padding: 0 10px;
}

.rtl .ant-steps-item-title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  padding-left: 16px;
  position: relative;
  line-height: 32px;
}

.rtl .ant-steps-item-title:after {
  content: "";
  height: 1px;
  width: 9999px;
  background: #e8e8e8;
  display: block;
  position: absolute;
  top: 16px;
  right: 100%;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 16px;
  white-space: nowrap;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 32px;
  top: 0;
  right: 0.5px;
  width: 32px;
  height: 32px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-left: 12px;
}

.rtl .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-left: 0;
}

.rtl .ant-steps-small .ant-steps-item-title {
  font-size: 14px;
  line-height: 24px;
  padding-left: 12px;
}

.rtl .ant-steps-vertical .ant-steps-item-icon {
  float: right;
  margin-left: 16px;
}

.rtl .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  width: 1px;
  padding: 38px 0 6px;
}

.rtl .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 30px 0 6px;
}

@media (max-width: 480px) {
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: right;
    margin-left: 16px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 30px 0 6px;
  }
  .rtl .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-title {
    line-height: 24px;
  }
}

.rtl .ant-steps-label-vertical .ant-steps-item-tail {
  padding: 0 24px;
  margin-right: 48px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-right: 36px;
}

.rtl .ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}

.rtl .ant-steps-label-vertical .ant-steps-item-description {
  text-align: right;
}

.rtl .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
}

.rtl .ant-steps-dot .ant-steps-item-icon {
  padding-left: 0;
  width: 8px;
  height: 8px;
  line-height: 8px;
  border: 0;
  margin-right: 67px;
  background: transparent;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
  width: 100%;
  height: 100%;
}

.rtl .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
  content: "";
  background: rgba(0, 0, 0, 0.001);
  width: 60px;
  height: 32px;
  position: absolute;
  top: -12px;
  right: -26px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-top: 8px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
  margin: 0;
  right: -9px;
  top: 2px;
  padding: 22px 0 4px;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
}

.rtl .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
}

.rtl .ant-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  text-align: right;
  border-radius: 4px 4px 0 0;
}

.rtl .ant-table-thead > tr > th {
  text-align: right;
}

.rtl .ant-table-thead > tr > th .anticon-filter,
.rtl .ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  right: auto;
  left: 0;
  margin-right: 0px;
  vertical-align: text-bottom;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  text-align: center;
}

.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.rtl .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 20px !important;
  padding-left: 30px !important;
}

.rtl .ant-table-thead > tr > th .ant-table-column-sorter + .anticon-filter {
  margin-right: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:first-child {
  border-top-right-radius: 4px;
}

.rtl .ant-table-thead > tr:first-child > th:last-child {
  border-top-left-radius: 4px;
}

.rtl .ant-table-footer:before {
  content: "";
  height: 1px;
  background: #fafafa;
  position: absolute;
  top: -1px;
  width: 100%;
  right: 0;
}

.rtl .ant-table.ant-table-bordered .ant-table-title {
  border: 1px solid #e8e8e8;
  padding-right: 16px;
  padding-left: 16px;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column-custom {
  padding-right: 16px;
  padding-left: 0;
}

.rtl .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.rtl .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-left: 0;
}

.rtl .ant-table-loading .ant-table-spin-holder {
  height: 20px;
  line-height: 20px;
  right: 50%;
  top: 50%;
  margin-right: -30px;
  position: absolute;
}

.rtl .ant-table-column-sorter {
  position: relative;
  margin-right: 8px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.45);
}

.rtl .ant-table-column-sorter-up:after,
.rtl .ant-table-column-sorter-down:after {
  position: absolute;
  content: "";
  height: 30px;
  width: 14px;
  right: 0;
}

.rtl table.ant-table-fixed {
  width: auto;
}

.rtl .ant-table-bordered .ant-table-header > table,
.rtl .ant-table-bordered .ant-table-body > table,
.rtl .ant-table-bordered .ant-table-fixed-left table,
.rtl .ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-left: 0;
  border-bottom: 0;
}

.rtl .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.rtl .ant-table-bordered .ant-table-thead > tr > th,
.rtl .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-placeholder .anticon {
  margin-left: 4px;
}

.rtl .ant-table-pagination.ant-pagination {
  float: left;
  display: flex;
  flex-direction: row-reverse;
  direction: ltr;
}

.rtl .ant-table-filter-dropdown {
  min-width: 96px;
  margin-right: -8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-left: 0;
}

.rtl .ant-table-filter-dropdown-link.confirm {
  float: right;
}

.rtl .ant-table-filter-dropdown-link.clear {
  float: left;
}

.rtl .ant-table-selection-select-all-custom {
  margin-left: 4px !important;
}

.rtl .ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-right: -30px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-left: 8px;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-right {
  position: absolute;
  top: 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  border-radius: 0;
}

.rtl .ant-table-fixed-left table,
.rtl .ant-table-fixed-right table {
  width: auto;
  background: #fff;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.rtl .ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}

.rtl .ant-table-fixed-left {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}

.rtl .ant-table-fixed-left .ant-table-body-inner {
  margin-left: -20px;
  padding-left: 20px;
}

.rtl .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-left: 0;
}

.rtl .ant-table-fixed-left,
.rtl .ant-table-fixed-left table {
  border-radius: 0 4px 0 0;
}

.rtl .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-left-radius: 0;
}

.rtl .ant-table-fixed-right {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}

.rtl .ant-table-fixed-right,
.rtl .ant-table-fixed-right table {
  border-radius: 4px 0 0 0;
}

.rtl .ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}

.rtl .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-right-radius: 0;
}

.rtl .ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}

.rtl .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}

.rtl .ant-table-small.ant-table-bordered {
  border-left: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-content {
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-footer:before {
  display: none;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
}

.rtl .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child,
.rtl .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-left: none;
}

.rtl .ant-tag {
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.rtl .ant-tag .anticon-cross {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  transform: scale(0.83333) rotate(0deg);
  cursor: pointer;
  margin-right: 3px;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
}

.rtl .ant-timeline-item-tail {
  position: absolute;
  right: 4px;
  top: 0.75em;
  height: 100%;
  border-right: 2px solid #e8e8e8;
}

.rtl .ant-timeline-item-head-custom {
  right: 5px;
  width: auto;
}

.rtl .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #e8e8e8;
  display: none;
}

.rtl .ant-transfer-list-search-action {
  color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 28px;
  line-height: 32px;
  text-align: center;
}

.rtl .ant-transfer-list-header {
  right: 0;
  width: 100%;
}

.rtl .ant-transfer-list-header-title {
  position: absolute;
  left: 12px;
}

.rtl .ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  width: 100%;
}

.rtl .ant-transfer-list-content-item > span {
  padding-left: 0;
}

.rtl .ant-transfer-list-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.rtl .ant-select-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
}

.rtl .ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
}

.rtl .ant-select-tree-checkbox-inner:after {
  right: 4.57142857px;
  border-right: 0;
}

.rtl .ant-select-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
  content: " ";
  transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-select-tree-checkbox-wrapper + span,
.rtl .ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-select-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-select-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-checkbox-checked:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid #077aa3;
  content: "";
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  visibility: hidden;
}

.rtl .ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  transition: all 0.3s;
}

.rtl .ant-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(0);
  position: absolute;
  right: 4.57142857px;
  top: 1.14285714px;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.rtl .ant-tree-checkbox-input {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rtl .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  content: " ";
  transform: scale(1);
  position: absolute;
  right: 2.42857143px;
  top: 5.92857143px;
  width: 9.14285714px;
  height: 1.14285714px;
}

.rtl .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  transform: rotate(-45deg) scale(1);
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-right: 0;
  content: " ";
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.rtl .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-right: 8px;
}

.rtl .ant-tree-checkbox-wrapper + span,
.rtl .ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}

.rtl .ant-tree-checkbox-group-item {
  display: inline-block;
  margin-left: 8px;
}

.rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0;
}

.rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-right: 0;
}

.rtl .ant-tree li span.ant-tree-icon_loading {
  position: absolute;
  right: 0;
  top: 1px;
  background: #fff;
  transform: translateX(100%);
  transition: all 0.3s;
}

.rtl .ant-tree-icon__open {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree-icon__close {
  margin-left: 2px;
  vertical-align: top;
}

.rtl .ant-tree.ant-tree-show-line li:not(:last-child):before {
  content: " ";
  width: 1px;
  border-right: 1px solid #d9d9d9;
  height: 100%;
  position: absolute;
  right: 12px;
  margin: 22px 0;
}

.rtl .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #d9d9d9;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-left: 8px;
  margin-bottom: 8px;
  display: table;
}

.rtl .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 22px;
  width: 100%;
  display: none;
}

.rtl .ant-upload-list-item .anticon-cross {
  left: 4px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}

.rtl .ant-upload-list-item-error .anticon-cross {
  opacity: 1;
  color: #f5222d !important;
}

.rtl .ant-upload-list-item-progress {
  line-height: 0;
  font-size: 14px;
  position: absolute;
  width: 100%;
  bottom: -12px;
  padding-right: 26px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  right: 8px;
  text-align: center;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-icon,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 36px;
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -18px;
  margin-right: -18px;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-name,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 8px 0 0;
  line-height: 44px;
  transition: all 0.3s;
  padding-right: 48px;
  padding-left: 8px;
  max-width: 100%;
  display: inline-block;
  box-sizing: border-box;
}

.rtl .ant-upload-list-picture .ant-upload-list-item-progress,
.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 56px;
  margin-top: 0;
  bottom: 14px;
  width: calc(100% - 24px);
}

.rtl .ant-upload-list-picture .anticon-cross,
.rtl .ant-upload-list-picture-card .anticon-cross {
  position: absolute;
  left: 8px;
  top: 8px;
  line-height: 1;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item {
  float: right;
  width: 104px;
  height: 104px;
  margin: 0 0 8px 8px;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s;
}

.rtl .ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  bottom: 32px;
}

.bg_gray {
  padding: 20px 5%;
}

.profile_container {
  background: #fff;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  text-align: left !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  text-align: right !important;
}

@media only screen and (max-width: 991px) {
  .profile_container .personal-info-input {
    flex-direction: column;
  }
  .profile_container .personal-info-input > div {
    flex: 1;
    margin-right: 0 !important;
    width: 100%;
  }
  .profile_container .personal-info-input > div.pt-3 {
    padding-top: 20px;
  }
  .profile_container .personal-info-input > div:not(:last-of-type) .ant-form-item {
    margin-bottom: 10px;
  }
  .profile_container .personal-info-input > div:last-of-type .ant-form-item {
    margin-bottom: 20px;
  }
}

.profile_container .react-tel-input .selected-flag .flag {
  right: 18px !important;
}

@media only screen and (max-width: 639px) {
  .profile_container .flex-center-between {
    display: block !important;
  }
  .profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
    padding-left: 40px !important;
  }
  body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
    padding-left: 0px !important;
    padding-right: 40px !important;
  }
}

.profile_container .react-tel-input input[type=text], .profile_container .react-tel-input input[type=tel] {
  padding-left: 40px !important;
}

body.rtl .profile_container .react-tel-input input[type=text], body.rtl .profile_container .react-tel-input input[type=tel] {
  padding-left: 0px !important;
  padding-right: 40px !important;
}

@media only screen and (max-width: 360px) {
  .profile_container .has-success.has-feedback .ant-form-item-children-icon svg, .profile_container .has-warning.has-feedback .ant-form-item-children-icon svg, .profile_container .has-error.has-feedback .ant-form-item-children-icon svg, .profile_container .is-validating.has-feedback .ant-form-item-children-icon svg {
    right: 18px !important;
    left: unset !important;
  }
}

.profile_container .profile_title {
  font-size: 24px;
  font-weight: bold;
  color: #242424;
}

.profile_container .personal-info-input > div {
  flex: 1;
}

.profile_container .personal-info-input > div:not(:last-of-type) {
  margin-right: 40px;
}

body.rtl .profile_container .personal-info-input > div:not(:last-of-type) {
  margin-left: 40px !important;
  margin-right: 0;
}

.profile_container .avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
}

.profile_container .avatar-upload .avatar-edit {
  position: absolute;
  right: 43px;
  z-index: 1;
  top: 10px;
}

.profile_container .avatar-upload .avatar-edit input {
  display: none;
}

.profile_container .avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all .2s ease-in-out;
}

.profile_container .avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.profile_container .avatar-upload .avatar-edit input + label:after {
  content: url(../../resources/images/camera.svg);
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.profile_container .avatar-upload .avatar-preview {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.profile_container .avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile_container #imagePreview {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: -6px;
  top: -5px;
}

.profile_container .profile_subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #242424;
  display: inline-block;
  white-space: nowrap;
  margin-right: 10px;
}

body.rtl .profile_container .profile_subtitle {
  margin-left: 10px;
  margin-right: 0 !important;
}

.profile_container hr {
  width: 83%;
  margin-left: auto;
  display: inline-block;
}

.profile_container .subtitle_holder {
  display: flex;
  align-items: baseline;
}

.profile_container .w-35 {
  width: 35%;
  min-width: 248px;
}

.profile_container .pt-3 {
  padding-top: 30px;
}

.profile_container .line {
  height: 1px;
  background: #dddddd;
  width: 100%;
}

.profile_container .align_center {
  display: flex;
  align-items: center;
}

.profile_container .deactivate_modal .ant-modal-footer {
  border: none;
}

.profile_container .deactivate_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.profile_container .deactivate_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.profile_container .deactivate_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.profile_container .ant-upload.ant-upload-select-picture-card img {
  height: 100px !important;
}

.changPass_modal .ant-modal-header {
  display: flex !important;
  justify-content: center !important;
  border: none !important;
}

.changPass_modal .ant-modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #242424;
}

.changPass_modal .ant-modal-content {
  padding: 50px 80px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .changPass_modal .ant-modal-content {
    padding: 50px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .changPass_modal .ant-modal-content {
    padding: 30px 10px;
  }
  .changPass_modal .ant-modal-content .ant-modal-body {
    padding-bottom: 0;
  }
}

.changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px;
}

body.rtl .changPass_modal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: auto !important;
}

.changPass_modal .ant-modal-footer {
  border: none;
}

.changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  font-size: 11px !important;
  line-height: 1.2;
  padding-top: 6px;
}

body.rtl .changPass_modal .ant-form-item-control.has-error .ant-form-explain {
  line-height: 1 !important;
}

.changPass_modal .has-success.has-feedback .ant-form-item-children-icon, .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  top: 62% !important;
  right: 3px !important;
}

body.rtl .changPass_modal .has-success.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-warning.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .has-error.has-feedback .ant-form-item-children-icon, body.rtl .changPass_modal .is-validating.has-feedback .ant-form-item-children-icon {
  right: auto !important;
  left: 3px;
}

body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
  white-space: unset !important;
  bottom: -25px !important;
}

@media only screen and (max-width: 360px) {
  body.rtl .changPass_modal .ant-form-item-control .ant-form-explain {
    bottom: -29px !important;
    font-size: 10px !important;
  }
}

.changPass_modal .ant-form-item {
  margin-bottom: 45px !important;
}

.timer_holder {
  position: relative;
  display: flex;
  justify-content: center;
}

.timer_holder #countdown {
  position: relative;
  margin: auto;
  height: 80px;
  width: 80px;
  text-align: center;
}

.timer_holder #countdown-number {
  height: 20px;
}

.timer_holder svg {
  position: absolute;
  top: 0;
  width: 80px;
  height: 80px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.timer_holder svg .dinamic_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #fc5646;
  fill: none;
  animation: countdown 60s linear 1 backwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 186px;
  }
}

.timer_holder svg .static_circle {
  stroke-dasharray: 186px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 3px;
  stroke: #b7b7b7;
  fill: #f2f2f2;
  background: #f2f2f2 !important;
}

.timer_holder .contant_holder {
  color: #565656;
  display: inline-block;
  line-height: 60px;
  position: relative;
  z-index: 1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.password_changed .ant-modal-header {
  display: none;
}

.password_changed .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.password_changed .ant-modal-footer {
  border: none;
  display: flex;
  justify-content: center;
}

.password_changed .ant-modal-footer > div > :first-child {
  display: none;
}

.password_changed .ant-modal-footer .ant-btn-primary {
  background: #077aa3;
}

.change_password .ant-modal-header {
  display: none;
}

.change_password .ant-modal-content {
  padding: 5% 5%;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.change_password .ant-modal-footer {
  border: none;
}

.change_password .ant-modal-footer .ant-btn-primary {
  background: #077aa3;
}

.mail {
  color: #174586;
  font-size: 15px;
  font-weight: 500;
}

.size20 {
  font-size: 19px;
  font-weight: 500;
}

.natificatin_holder .ant-table-pagination.ant-pagination {
  display: none;
}

.natificatin_holder .ant-checkbox-wrapper {
  display: none;
}

.natificatin_holder .ant-table-thead > tr > th {
  background: #fff;
}

.natificatin_holder .ant-table-thead > tr > th.ant-table-selection-column, .natificatin_holder .ant-table-tbody > tr > td.ant-table-selection-column {
  display: none;
}

.natificatin_holder .ant-table-thead > tr:first-child > th:last-child > :first-child {
  display: flex;
  justify-content: flex-end;
  margin-right: 15%;
  color: #3967a9;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.natificatin_holder .notifi {
  color: #242424;
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.natificatin_holder .min {
  font-size: 10px;
  font-weight: bold;
  color: #565656;
}

.natificatin_holder .unread {
  width: 10px;
  height: 10px;
  background-color: #3967a9;
  border-radius: 50%;
  position: absolute;
  left: -15px;
  top: 2px;
}

.natificatin_holder .ant-table-row {
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder .ml-2 {
  margin-left: 20px;
}

.natificatin_holder tr td {
  padding: 0;
}

.natificatin_holder tr .notify-row {
  padding: 16px;
  background-color: rgba(57, 103, 169, 0.09);
}

.natificatin_holder tr .notify-row.seen {
  background-color: #ffffff;
}

.natificatin_holder tr .notify-row.seen .notifi .unread {
  display: none;
}

.natificatin_holder .space_between {
  display: flex;
  justify-content: space-between;
}

.h-100 {
  height: 100%;
}

.small_space_r {
  margin-right: 10px;
}

body.rtl .small_space_r {
  margin-left: 10px;
  margin-right: 0 !important;
}

.add-station-modal .ant-modal-footer {
  padding: 20px 74px !important;
}

.add-station-modal .ant-modal-footer > div button:first-of-type:hover, .add-station-modal .ant-modal-footer > div button:first-of-type:focus, .add-station-modal .ant-modal-footer > div button:first-of-type:active {
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
}

.add-station-modal .ant-modal-body {
  padding: 24px 75px !important;
}

@media only screen and (max-width: 575px) {
  .add-station-modal .ant-modal-body {
    padding: 24px !important;
  }
}

.add-station-modal .ant-select.ant-select-open .ant-select-arrow svg {
  color: #444;
}

.add-station-modal .map-wrapper > div > div {
  width: 90% !important;
  position: relative !important;
}

@media only screen and (max-width: 575px) {
  .add-station-modal .map-wrapper > div > div {
    width: 100% !important;
    margin-bottom: 30px;
  }
}

.add-station-modal .map-wrapper > div > div > div {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  border-radius: 8px;
}

.add-station-modal .map-wrapper .ant-form-item .ant-input {
  font-weight: 600;
}

.add-station-modal .map-wrapper .ant-form-item .ant-form-item-label label {
  color: #4b4b4b !important;
  font-weight: normal;
}

.add-station-modal .map-wrapper input {
  position: absolute;
  top: 11px;
  left: 9px;
  border-radius: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.09);
  border: none;
  padding: 5px 10px;
  width: 60%;
}

.add-station-modal .map-wrapper input::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 16px;
}

body.rtl .add-station-modal .map-wrapper input {
  right: 9px;
  left: auto;
}

.add-station-modal .editble-grid-wrapper {
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
}

.add-station-modal .editble-grid-wrapper.isEmpty {
  min-height: 200px;
}

.add-station-modal .editble-grid-wrapper .ant-table-thead > tr > th {
  background: transparent;
  text-align: center;
  border-bottom: 1px solid #CBCBCB !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: transparent;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th, .add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #CBCBCB !important;
  padding: 8px !important;
  font-weight: bold;
}

.add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-thead > tr > th:last-child, .add-station-modal .editble-grid-wrapper .ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table table {
  border: none !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.add-station-modal .editble-grid-wrapper th {
  font-size: 15px;
  font-weight: 500;
  color: #7e7e7e;
}

.add-station-modal .editble-grid-wrapper td {
  text-align: center !important;
  color: #404040;
}

.add-station-modal .editble-grid-wrapper td a {
  margin: 0px 11px;
}

.add-station-modal .editble-grid-wrapper td a i {
  font-size: 18px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection {
  border: none !important;
  padding: 0 !important;
  height: 20px;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-arrow {
  top: 87%;
}

.add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-right: 44px !important;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-select .ant-select-selection .ant-select-selection-selected-value {
  padding-left: 44px !important;
}

.add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  top: 107px !important;
  border: none;
}

body.rtl .add-station-modal .editble-grid-wrapper .ant-table-placeholder {
  border: none;
}

.pricing-settings-wrapper {
  margin: 25px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.pricing-settings-wrapper.form-editable .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 1.5px solid #cbcbcb;
}

.pricing-settings-wrapper .grid-holder {
  padding: 10px 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.pricing-settings-wrapper .grid-holder > div {
  min-height: 60px;
  text-align: center;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1.5px solid #cbcbcb;
  border-right: 1.5px solid #cbcbcb;
  position: relative;
}

body.rtl .pricing-settings-wrapper .grid-holder > div {
  border-right: 0;
  border-left: 1.5px solid #cbcbcb;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(1), .pricing-settings-wrapper .grid-holder > div:nth-child(4), .pricing-settings-wrapper .grid-holder > div:nth-child(7) {
  color: #25ae81;
  text-align: left;
  justify-content: flex-start;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(-n + 3) {
  color: #404040;
}

.pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-right: 0;
}

body.rtl .pricing-settings-wrapper .grid-holder > div:nth-child(3n) {
  border-left: 0;
}

.pricing-settings-wrapper .grid-holder > div:nth-last-child(-n + 3) {
  border-bottom: 0;
}

.pricing-settings-wrapper .grid-holder .ant-form-item {
  margin-bottom: 0;
  width: 150px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number {
  position: relative;
  border: 0;
  margin: 0;
  opacity: 0.42;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled {
  background-color: #fff;
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number.ant-input-number-disabled:after {
  opacity: 1;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number input[disabled] {
  background-color: #fff;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: "L.E";
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  background-color: #fff;
}

body.rtl .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number:after {
  content: 'ج.م';
  right: auto;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number + .warning {
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d83025;
  font-size: 14px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span i {
  color: #000;
  font-size: 18px;
}

.pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-down-disabled i, .pricing-settings-wrapper .grid-holder .ant-form-item .ant-input-number .ant-input-number-handler-wrap span.ant-input-number-handler-up-disabled i {
  color: #b5b5b5;
}

.pricing-settings-wrapper .grid-holder .edit-btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

body.rtl .pricing-settings-wrapper .grid-holder .edit-btn {
  right: auto;
  left: 0;
}

body.rtl .ant-popover {
  right: unset !important;
}

/* .ant-table-thead > tr, .ant-table-tbody > tr{
    background-color:green($color: #000000);
}
.ant-table-thead > tr{
    background-color: white; 

}
.ant-table-tbody > tr {
    background-color: white;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #cf2525;
} */
.table-striped-rows tr:nth-child(2n) td {
  background-color: #fbfbfb;
}

.table-striped-rows thead {
  background-color: white;
}

.fontStyle {
  color: #838383;
  cursor: pointer;
}

.fontStyle.absent:hover {
  color: #ff0000;
}

.fontStyle.present:hover {
  color: #00678d;
}

.pdfButton {
  padding: 5px 20px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: 0 !important;
}

.pdfButton > span > img {
  padding-right: 5px;
  margin-right: 5px;
  width: 24px;
  height: 23px;
}

.avatarImage {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.ant-switch.ant-switch-checked.customSwitch {
  background-color: #2a7d9c;
  /*   min-width:40px !important;
    height:25px !important; */
}

.ant-switch.customSwitch {
  background-color: #ff0000;
  /*  min-width:40px  !important;
    height:25px  !important; */
}

.ant-switch.customSwitch::after {
  background-color: #ffffff;
  /*  top: -6px;
        margin-left: -5px; */
}

.attendanceCustomDivider {
  background-color: red;
  font-size: 25px;
}

.ant-calendar-header .ant-calendar-prev-century-btn, .ant-calendar-header .ant-calendar-next-century-btn, .ant-calendar-header .ant-calendar-prev-decade-btn, .ant-calendar-header .ant-calendar-next-decade-btn, .ant-calendar-header .ant-calendar-prev-month-btn, .ant-calendar-header .ant-calendar-next-month-btn, .ant-calendar-header .ant-calendar-prev-year-btn, .ant-calendar-header .ant-calendar-next-year-btn {
  position: initial;
}

.ant-input {
  direction: ltr;
}

.responsive_holder .responsive_holder--list {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 14px;
  padding: 11px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.responsive_holder .responsive_holder--list .responsive_list--left .ant-checkbox-wrapper .ant-checkbox:not(.ant-checkbox-checked).ant-checkbox-inner {
  width: 13px;
  height: 13px;
  border: solid 0.5px #707070;
  border-color: #707070 !important;
  background-color: #ffffff;
}

.responsive_holder .responsive_holder--list .responsive_list--left .ant-checkbox-wrapper span.ant-checkbox-inner {
  width: 13px;
  height: 13px;
  border: solid 0.5px #707070;
}

.responsive_holder .responsive_holder--list .responsive_list--left span img {
  width: 39px;
  height: 38px;
  margin: 0 10px;
}

.responsive_checkAll .ant-checkbox-wrapper .ant-checkbox:not(.ant-checkbox-checked).ant-checkbox-inner {
  width: 13px;
  height: 13px;
  border: solid 0.5px #707070;
  border-color: #707070 !important;
  background-color: #ffffff;
}

.responsive_checkAll .ant-checkbox-wrapper span:last-child {
  font-size: 10px;
  font-weight: normal;
  line-height: 1.3;
  color: #838383;
}

.responsive_checkAll .ant-checkbox-wrapper span.ant-checkbox-inner {
  width: 13px;
  height: 13px;
  border: solid 0.5px #707070;
}

.responsive_btn--holder .pdfButton {
  padding: 5px 7px;
  font-size: 10px;
}

.responsive_btn--holder > .ant-calendar-picker {
  max-width: 120px;
}

.note_cell {
  word-break: break-all;
}

.homework_header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homework_header.correction_bar_header {
  margin-top: 0;
  background-color: whitesmoke;
  z-index: 6;
  position: relative;
  padding: 5px 0px;
}

body.rtl .homework_header.correction_bar_header {
  margin-left: unset;
}

@media only screen and (max-width: 1023px) {
  .homework_header {
    flex-direction: column;
  }
}

.homework_header .homework_word {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .homework_header .homework_word {
    padding-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
}

.homework_header .homework_word h6 {
  margin-top: 5px;
  color: #2a7d9c;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 27px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
}

@media only screen and (max-width: 1023px) {
  .homework_header .homework_word h6 {
    font-size: 16px;
    padding-left: 10px;
  }
}

.homework_header .date_add_homework {
  display: flex;
  margin: 5px;
}

@media only screen and (max-width: 1023px) {
  .homework_header .date_add_homework {
    margin-top: 20px;
    flex-direction: column-reverse;
    width: 100%;
    margin: 5px 0px;
  }
}

.homework_header .date_add_homework .datepicker_homework {
  width: 150px;
  background-color: white;
  margin: auto 10px;
}

.homework_header .date_add_homework .title_filter.ant-input {
  width: 161px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
}

.homework_header .date_add_homework .rangeDatepicker_homework {
  width: 250px;
  background-color: white;
  margin: auto 10px;
}

.homework_header .date_add_homework .rangeDatepicker_homework .ant-input.ant-calendar-picker-input {
  padding: 0 !important;
  height: 36px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 1023px) {
  .homework_header .date_add_homework .rangeDatepicker_homework {
    margin: unset;
    width: unset;
  }
  body.rtl .homework_header .date_add_homework .rangeDatepicker_homework {
    margin-left: 0px;
  }
}

.homework_header .date_add_homework .rangeDatepicker_homework .ant-calendar-range-picker-separator {
  vertical-align: initial;
}

@media only screen and (max-width: 399px) {
  .homework_header .date_add_homework .rangeDatepicker_homework .ant-calendar-picker-clear {
    margin: -10px -10px 0px 0px;
  }
}

.homework_header .date_add_homework .add_homework_button {
  height: 36px;
  width: 162px;
  background-color: #00678d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homework_header .date_add_homework .add_homework_button.fetch {
  background-color: #169dce !important;
}

.homework_header .date_add_homework .add_homework_button img {
  margin: 0 5px;
}

@media only screen and (max-width: 1023px) {
  .homework_header .date_add_homework .add_homework_button span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1023px) {
  .homework_header .date_add_homework .add_homework_button {
    flex: 0 0 45%;
    height: 36px;
  }
}

.homework_correction_switch {
  padding: 5px;
}

.homework_correction_switch .switch_title {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  color: #207a9b;
  border-bottom: 1px solid #207a9b;
}

.homework_correction_switch .ant-switch {
  height: 20px !important;
  width: 30px !important;
  min-width: unset !important;
}

.homework_correction_switch .ant-switch::after {
  width: 21px;
  height: 21px;
  top: -2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  border: solid 0.5px #e5e5e5;
  background-color: #ffffff;
}

.homework_correction_switch .ant-switch.ant-switch-checked {
  background-color: #2a7d9c;
}

.actions_header {
  margin: 10px;
  padding: 0px;
  display: flex;
}

.actions_header li {
  padding: 0px 10px;
  cursor: pointer;
  font-size: 16px;
  color: #767676;
}

.actions_header li.selected {
  color: #0793c7;
  font-size: 16px;
  border-bottom: 2px solid #0793c7;
}

.homework_card_header {
  display: flex;
  align-items: center;
  height: 62px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  margin: 10px 0;
}

@media only screen and (max-width: 1023px) {
  .homework_card_header {
    display: none;
  }
}

.homework_card_header .homework_card_header_item {
  font-size: 12px;
  font-weight: 500;
  color: #00678d;
  border-right: 2px solid #f5f5f5;
  padding: 0px 5px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 12%;
  text-align: center;
}

.homework_card_header .homework_card_header_item.item_big {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 28%;
}

.homework_card_header .homework_card_header_item:last-child {
  border-right: none;
  text-align: unset;
}

.correction_row {
  display: flex;
  margin: 15px 0px;
}

.correction_row p {
  color: #207a9b;
  font-size: 16px;
}

.homework_item {
  margin: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-bottom: 15px;
}

.homework_item .info-container {
  flex: 1;
}

.homework_item .homework_date_day {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  padding: 25px 31px;
}

.homework_item .homework_date_day p {
  padding-top: 3px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .homework_item .homework_date_day p {
    font-size: 12px !important;
  }
}

.homework_item .homework_date_day p.firstline_date {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}

.homework_item .homework_first_row {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  font-size: 14px;
}

.homework_item .homework_first_row p {
  border-bottom: 1px #ffffff solid;
}

.homework_item .homework_first_row .homework_title {
  margin: 10px;
  font-size: 16px;
  font-weight: 600;
}

.homework_item .homework_second_row {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  justify-content: space-between;
}

.homework_item .homework_second_row .homework_details_item_big {
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 28%;
  padding: 0px 5px;
  margin: 10px 3px;
  font-size: 12px;
  border-right: 2px solid #f5f5f5;
  color: #00678d;
}

.homework_item .homework_second_row .homework_details_item {
  font-family: 'Montserrat', sans-serif;
  height: 50px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 12%;
  font-size: 12px;
  padding: 0px 5px;
  color: #00678d;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  justify-content: center;
}

.homework_item .homework_second_row .homework_details_item.bold {
  font-weight: bold;
  color: #000000;
}

.homework_item .homework_second_row .homework_details_item.black {
  color: #2c363f;
}

.homework_item .homework_second_row .homework_details_item.title_blue {
  color: #00678d;
}

.homework_item .homework_second_row .homework_details_item.space-around {
  justify-content: space-around;
}

.homework_item .homework_second_row .homework_details_item.item_big {
  flex-basis: 28%;
}

.homework_item .homework_second_row .homework_details_item span {
  margin: 0px 2px;
}

.homework_item .homework_second_row .homework_details_item .details_button {
  background-color: #00678d;
  font-size: 10px;
  padding: 0px 15px;
  color: #ffffff;
}

.homework_item .homework_second_row .homework_details_item .edit_button {
  border-color: #00678d;
  font-size: 10px;
  padding: 0px 15px;
  background: #fff;
  color: #00678d;
  margin: 0 5px;
}

.homework_item .homework_second_row .homework_details_item .clone_button {
  background-color: #148fbc;
  font-size: 10px;
  padding: 0px 15px;
  color: #ffffff;
}

.homework_item .homework_second_row .homework_details_item .progress_bar_solved.ant-progress-success-bg.ant-progress-bg {
  background-color: #11b74f !important;
}

.homework_item .homework_second_row .homework_details_item:last-child {
  border-right: none;
}

.homework_item .homework_second_row .homework_details_item:first-child {
  display: flex;
  border-right: none;
  color: #ffffff;
  margin: 0px;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 11.5%;
}

.homework_item .homework_second_row .homework_details_item:first-child p:first-child {
  border-bottom: 1px solid #FFF;
}

.homework_item .homework_second_row .homework_details_item:nth-child(2) {
  display: block;
  margin-left: auto;
  text-align: center;
  padding: 15px 1px;
}

.corrected {
  background-color: #38ad63;
}

.corrected.light {
  background-color: #38ad63;
}

.not_corrected {
  background-color: #5498b0;
}

.not_corrected.light {
  background-color: #207a9b;
}

.student_card_header {
  display: flex;
  align-items: center;
  height: 51px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  margin: 10px 0;
  /* .homework_card_header_item.big{
       // padding: 0px 35px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 25%;
        
    } */
}

@media only screen and (max-width: 1023px) {
  .student_card_header {
    flex-wrap: wrap;
    height: 100px;
  }
}

.student_card_header .student_card_header_item {
  z-index: 0;
  font-size: 12px;
  border-right: 2px solid #f5f5f5;
  padding: 0px 5px;
  margin: 10px 3px;
  color: #00678d;
  font-weight: 400;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 14.2%;
  justify-content: center;
  text-align: center;
}

.student_card_header .student_card_header_item p {
  font-size: 12px;
}

.student_card_header .student_card_header_item.noborder-right {
  border-right: unset;
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item {
    border-right: none;
    flex: 0 0 45%;
  }
}

@media only screen and (max-width: 479px) {
  .student_card_header .student_card_header_item .progress__line--homework {
    width: 42px !important;
  }
}

.student_card_header .student_card_header_item .view_responsive_only {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item .view_responsive_only {
    display: inline-block;
  }
}

.student_card_header .student_card_header_item p.ordinary_paragraph {
  font-size: 12px;
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(1) {
    order: 1;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(2) {
    order: 3;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(3) {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(6) {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(4) {
    order: 4;
    flex: 0 0 53%;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(2) {
    order: 4;
    flex: 0 0 40%;
  }
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:nth-of-type(5) {
    order: 2;
  }
}

.student_card_header .student_card_header_item:first-child {
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  flex-basis: 25%;
}

@media only screen and (max-width: 1023px) {
  .student_card_header .student_card_header_item:first-child {
    flex: 0 0 45%;
    justify-content: center;
  }
}

.student_card_header .student_card_header_item:last-child {
  border-right: none;
}

.details_button {
  background-color: #00678d;
  font-size: 10px;
  padding: 0px 15px;
  color: #ffffff;
}

span.done_homework {
  color: #2dc308;
}

span.uncompleated_homework {
  color: #ffb300;
}

span.not_viewed_homework {
  color: #9797ac;
}

p.progress_paragraph {
  padding-left: 5px;
  display: flex;
  padding-top: 8px;
}

body.rtl p.progress_paragraph {
  padding-left: 0;
  padding-right: 5px;
}

@media only screen and (max-width: 479px) {
  p.progress_paragraph {
    margin-top: -4px !important;
  }
  body.rtl p.progress_paragraph {
    margin-top: -8px !important;
  }
  body.rtl p.progress_paragraph:last-of-type {
    margin-top: -2px !important;
  }
  p.progress_paragraph:last-of-type {
    margin-top: -2px !important;
  }
}

p.not_corrected_p {
  font-size: 8px;
  color: #11b74f;
}

p.total_p {
  font-size: 8px;
  color: #00678d;
}

.flex_class {
  display: flex;
}

.lottie-spinner {
  top: calc(50% - 1px);
  left: calc(50% - 100px);
}

@media only screen and (max-width: 1023px) {
  .lottie-spinner {
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
}

@media only screen and (max-width: 575px) {
  .lottie-spinner {
    top: calc(50% + 150px);
    left: calc(50% - 100px);
  }
}

.homework_item_responsive {
  margin: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 135px;
  margin-bottom: 15px;
}

.homework_item_responsive .homework_date_due_date {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
}

.homework_item_responsive .homework_date_due_date .homework_date_day {
  flex: 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  padding: 11px;
}

.homework_item_responsive .homework_date_due_date .homework_date_day p {
  padding-top: 3px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .homework_item_responsive .homework_date_due_date .homework_date_day p {
    font-size: 12px !important;
  }
}

.homework_item_responsive .homework_date_due_date .homework_date_day p.firstline_date {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}

@media only screen and (max-width: 767px) {
  .homework_item_responsive .homework_date_due_date .homework_date_day {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    padding: 4px 11px;
  }
  .homework_item_responsive .homework_date_due_date .homework_date_day p {
    padding-top: 3px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .homework_item_responsive .homework_date_due_date .homework_date_day p {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 767px) {
  .homework_item_responsive .homework_date_due_date .homework_date_day p.firstline_date {
    border-bottom: 1px solid white;
    padding-bottom: 3px;
  }
}

.homework_item_responsive .homework_details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.homework_item_responsive .homework_details .homework_title {
  margin: 10px;
  font-size: 11px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 95px;
}

.homework_item_responsive .homework_details .homework_division_grade {
  margin: 0px 10px;
  font-size: 9px;
  font-weight: 410;
}

.homework_item_responsive .homework_details .homework_solved {
  display: flex;
  align-items: center;
  margin: 20px 10px 5px 7px;
  font-size: 9px;
  font-weight: 410;
}

@media only screen and (max-width: 767px) {
  .homework_item_responsive .homework_details .homework_solved {
    margin: 10px 10px 5px 7px;
    font-size: 9px;
    font-weight: 410;
  }
}

.homework_item_responsive .homework_details .homework_solved p {
  margin: 5px 15px 0px 0px;
  font-size: 9px;
  font-weight: 410;
}

body.rtl .homework_item_responsive .homework_details .homework_solved p {
  margin: 5px 0 0px 15px;
}

.homework_item_responsive .homework_details .homework_solved p.solved_count {
  margin: 5px 2px 0px 0px;
  font-size: 9px;
  font-weight: 410;
}

body.rtl .homework_item_responsive .homework_details .homework_solved p.solved_count {
  margin: 5px 0 0px 2px;
}

.homework_item_responsive .homework_details .homework_subject_degree {
  display: flex;
  justify-content: space-between;
  color: #2c363f;
  margin: 10px;
  font-size: 11px;
  font-weight: 500;
}

.homework_item_responsive .homework_details .homework_subject_degree .homework_degree {
  display: flex;
  align-items: center;
  color: #00678d;
}

@media only screen and (max-width: 1023px) {
  .student_card_header.header {
    display: none;
  }
}

.ant-popover-inner-content {
  padding: 0;
  min-width: 280px !important;
  max-width: 280px !important;
}

.copy-link-popup {
  display: flex;
}

.copy-link-popup .ant-btn {
  height: 47px;
  border-radius: 0 3px 3px 0;
  width: 110px;
  min-width: 110px;
}

.copy-link-popup .ant-input {
  border-radius: 3px 0 0 3px;
}

.homework_details--footer button {
  width: 34px;
  height: 29px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homework_details--footer button.edit_button {
  background-color: #00678d !important;
  margin: 0px 2px;
}

.homework_details--footer button.delete_button {
  background-color: #ff0000;
}

.homework_degree .ant-badge-not-a-wrapper:not(.ant-badge-status) {
  z-index: 4;
}

.clone_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clone_modal_header .add_classroom {
  margin: 0px 30px;
}

.clone_modal_header .add_classroom p {
  color: #00678d;
  font-size: 13px;
}

.clone_modal_header div {
  display: flex;
}

.clone_modal_header div p {
  font-size: 13px;
  color: #d83025;
  cursor: pointer;
  margin: 0px 5px;
}

@media only screen and (max-width: 575px) {
  .clone_modal_header div p {
    font-size: 10px;
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .clone_modal_header .flex_class {
    margin: 0px 5px;
  }
}

.clone_modal_content {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.clone_modal_content .clone_selected_classes_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.clone_modal_content .clone_selected_classes_item p {
  font-size: 16px;
  color: #00678d;
  font-weight: 600;
  width: 100px;
}

@media only screen and (max-width: 575px) {
  .clone_modal_content .clone_selected_classes_item p {
    font-size: 10px;
    font-weight: unset;
  }
}

.clone_modal_content .clone_selected_classes_item .ant-calendar-picker {
  width: 130px;
}

@media only screen and (max-width: 575px) {
  .clone_modal_content .clone_selected_classes_item .ant-calendar-picker {
    width: 200px;
    font-size: 6px;
  }
}

.clone_modal_content .clone_selected_classes_item .ant-calendar-picker .ant-input {
  height: 30px;
  font-size: 11px;
  border: unset;
  padding: 0px 3px;
}

@media only screen and (max-width: 575px) {
  .clone_modal_content .clone_selected_classes_item .ant-calendar-picker .ant-input {
    font-size: 6px;
  }
}

.clone_modal_content .clone_selected_classes_item .ant-calendar-picker .ant-input::placeholder {
  color: #00678d;
}

@media only screen and (max-width: 575px) {
  .clone_modal_content .clone_selected_classes_item .ant-calendar-picker .ant-input::placeholder {
    font-size: 6px;
  }
}

.clone-error-message {
  color: #d83025;
  margin-top: 2px;
  display: flex;
  justify-content: center;
}

.fetch_modal_container .ant-modal-header {
  border-bottom: unset;
}

.fetch_modal_container .ant-modal-footer {
  border: unset;
}

.fetch_modal_container .ant-modal-footer .ant-btn {
  color: #d83025;
  border: 1px solid #707070;
}

.fetch_modal_container .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #00678d;
  color: #fff;
  border: 1px solid #00678d;
}

.fetch_modal_container .ant-modal-body {
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  margin: 18px;
  height: 350px;
  overflow-y: auto;
}

.fetch_modal_container .fetch-classes {
  display: flex;
}

.fetch_modal_container .fetch-classes .fetch-classes-list {
  box-shadow: 1.5px 2.6px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c3c3c3;
  border-radius: 5px;
  width: 49%;
}

.fetch_modal_container .fetch-classes .fetch-classes-list .ant-select .ant-select-selection {
  font-size: 15px;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .fetch-classes .fetch-classes-list .ant-select .ant-select-selection {
    font-size: 10px;
  }
}

.fetch_modal_container .fetch-classes .fetch-classes-list:first-of-type {
  margin-inline-end: 2%;
}

.fetch_modal_container .fetch-classes .fetch-classes-list .ant-select-selection__placeholder {
  padding: 0 10px;
  font-weight: normal;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .fetch-classes .fetch-classes-list .ant-select-selection__placeholder {
    font-size: 9px;
  }
}

.fetch_modal_container .fetch-homeworks-list {
  box-shadow: 1.5px 2.6px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c3c3c3;
  border-radius: 5px;
  margin-top: 20px;
  height: 270px;
  overflow: auto;
}

.fetch_modal_container .fetch-homeworks-list__filtration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
}

.fetch_modal_container .fetch-homeworks-list__filtration .search_holder {
  width: 45%;
}

.fetch_modal_container .fetch-homeworks-list__filtration .search_holder input::placeholder {
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .fetch-homeworks-list__filtration .search_holder input::placeholder {
    font-size: 8px;
  }
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker {
  display: flex;
  align-items: center;
  width: 52%;
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-range-picker-input::placeholder {
  font-size: 9px;
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-range-picker-separator {
  display: none;
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker p {
  font-size: 13px;
  margin: 0 2px;
  font-weight: 600;
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker .ant-input {
    border: none;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 0px;
    font-size: 10px;
  }
  .fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker .ant-input::placeholder {
    font-size: 9px;
  }
}

@media only screen and (max-width: 350px) {
  .fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker .ant-input {
    border: none;
    border-bottom: 1px solid #c6c6c6;
    border-radius: 0px;
    font-size: 7px;
  }
  .fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker .ant-input::placeholder {
    font-size: 6px;
  }
}

.fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker input {
  border: none;
  border-bottom: 1px solid #c6c6c6;
  border-radius: 0px;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker .ant-calendar-picker input {
    border-bottom: none;
    font-size: 8px;
  }
}

.fetch_modal_container .homeworks-container .ant-empty {
  margin-top: 15px;
}

.fetch_modal_container .homeworks-container__card {
  display: flex;
  align-items: center;
  height: 72px;
  margin: 15px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #a5a2a2;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card {
    height: 87px;
    padding: 10px;
  }
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card .homeworks-container__card-content {
    width: 100%;
  }
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .fetch-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .fetch-title h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding-top: 0;
  padding-inline-start: 20px;
  color: #00678d;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card .homeworks-container__card-content .fetch-title h2 {
    font-size: 12px;
    line-height: unset;
    margin: 0px 10px;
  }
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .fetch-title .info {
  display: flex;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .fetch-title .info li {
  border: none;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info {
  display: flex;
  align-items: center;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info li {
  color: #0b85b2;
  font-size: 12px;
  padding: 10px 20px;
  border-right: 1px solid #cecece;
  height: 20px;
  display: inline-flex;
  align-items: center;
}

body.rtl .fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info li:first-of-type {
  border-right: none;
}

body.rtl .fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info li:last-of-type {
  border-right: 1px solid #cecece;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info li:last-of-type {
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fetch_modal_container .homeworks-container__card .homeworks-container__card-content .info li span {
  font-size: 12px;
  color: #9e9e9e;
  margin-right: 5px;
}

.fetch_modal_container .homeworks-container__card .selected-datepickers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 71px;
  width: 35%;
  background: #167091;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card .selected-datepickers {
    height: 86px;
    width: 50%;
  }
}

.fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker {
  width: 90%;
  margin: 5px;
}

.fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input {
  color: #fff;
  border: none;
  border-bottom: 1px solid #ffffff;
  border-radius: 0;
  height: 15px;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input {
    font-size: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input {
    font-size: 7px;
  }
}

.fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input::placeholder {
  font-size: 12px;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input::placeholder {
    font-size: 10px;
  }
  body.rtl .fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker input::placeholder {
    font-size: 6px;
  }
}

.fetch_modal_container .homeworks-container__card .selected-datepickers .ant-calendar-picker i {
  color: #fff;
  background: transparent;
}

body.rtl .fetch_modal_container .homeworks-container__card .selected-datepickers {
  left: 0;
  right: unset;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.clone_modal_container .ant-modal-header {
  border-bottom: unset;
}

.clone_modal_container .ant-modal-footer {
  border: unset;
}

.clone_modal_container .ant-modal-footer .ant-btn {
  color: #d83025;
  border: 1px solid #707070;
}

.clone_modal_container .ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #00678d;
  color: #fff;
  border: 1px solid #00678d;
}

.clone_modal_container .ant-modal-body {
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
  margin: 18px;
  height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
}

.clone_modal_container .ant-modal-body .search_holder {
  margin-top: -5px;
}

@media only screen and (max-width: 767px) {
  .clone_modal_container .ant-modal-body .search_holder .ant-input::placeholder {
    font-size: 6px;
  }
}

.clone_modal_container .clone_modal_class_item .ant-checkbox-group {
  margin-top: 20px;
}

.clone_modal_container .clone_modal_class_item .ant-checkbox-group-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.clone_modal_container .clone_modal_class_item .search_holder {
  margin-top: -10px;
}

.date_picker_error {
  border: 1px solid #d83025;
  border-radius: 5px;
  padding: 0px 5px;
}

@media only screen and (max-width: 767px) {
  .clone_range_picker .ant-calendar-range {
    width: 95vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.clone_range_picker .ant-calendar-range-picker-separator {
  vertical-align: baseline;
}

@media only screen and (max-width: 767px) {
  .checkbox_fetch_item.ant-checkbox-wrapper {
    top: 9px;
    position: absolute;
    left: 10px;
  }
  body.rtl .checkbox_fetch_item.ant-checkbox-wrapper {
    left: unset;
    right: 10px;
  }
}

.fetch_item_responsive {
  display: flex;
  margin: 5px 0px;
}

.fetch_item_responsive span {
  color: #84878a;
  font-size: 10px;
}

.fetch_item_responsive p {
  color: #2c363f;
  font-size: 10px;
}

.row_responsive_fetch {
  display: flex;
  justify-content: space-between;
}

.responsive_fetch_modal {
  margin: 0px 25px;
}

@media only screen and (max-width: 360px) {
  .fetch-homeworks-list__filtration {
    flex-direction: column;
  }
  .fetch-homeworks-list__filtration .search_holder {
    width: unset !important;
  }
  .fetch-homeworks-list__filtration .fetch-homeworks-list__filtration--datepicker {
    width: unset !important;
  }
}

.paragraph_question_type {
  width: 300px;
  color: #2a7d9c;
  border: none;
  border-bottom: 2px #cccccc solid;
  border-radius: 0px;
}

@media only screen and (max-width: 639px) {
  .paragraph_question_type {
    width: 90vw;
  }
}

body.rtl .paragraph_question_type {
  text-align: right;
}

.paragraph_question_type:required {
  box-shadow: none;
}

.paragraph_question_type:invalid {
  border-bottom: 2px #ff0000 solid;
}

.paragraph_question_type::placeholder {
  color: #2a7d9c;
}

body.rtl .paragraph_question_type::placeholder {
  text-align: right;
}

.radio_item {
  display: flex;
}

.radio_item img {
  margin: 5px 10px 0px 10px;
}

.radio_item img.true_false_icon {
  margin-top: 12px;
}

.radio_item .quiz_radio {
  color: #2a7d9c;
  background-color: inherit !important;
  width: 88%;
  margin: 10px 6px 0px 6px;
  border: none;
  box-shadow: none !important;
  border-radius: 0px !important;
  vertical-align: middle;
  padding: 0;
}

.radio_item .quiz_radio span .true {
  border-bottom: 2px #2a7d9c solid;
}

body.rtl .radio_item .quiz_radio span .true {
  border-bottom: 2px #2a7d9c solid;
}

.radio_item .quiz_radio span .false {
  border-bottom: 2px #ff0000 solid;
  color: #ff0000;
}

body.rtl .radio_item .quiz_radio span .false {
  border-bottom: 2px #ff0000 solid;
}

.radio_item .quiz_radio .radio_button {
  display: flex;
  justify-content: space-between;
}

.quiz_radio::before {
  width: 0px !important;
}

.quiz_radio.true {
  border-bottom: 2px #2a7d9c solid;
}

body.rtl .quiz_radio.true {
  border-bottom: 2px #2a7d9c solid;
}

.quiz_radio.false {
  border-bottom: 2px #ff0000 solid;
  color: #ff0000;
}

body.rtl .quiz_radio.false {
  border-bottom: 2px #ff0000 solid;
}

.quiz_radio.ant-radio-wrapper {
  width: 90%;
  font-weight: 500;
}

.quiz_radio.ant-radio-wrapper::before {
  display: none;
}

.paragraph_question .blur .k-editor {
  height: 100px !important;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px #b1cbd4;
}

.paragraph_question .blur .k-editor.invalid-editor {
  border-bottom-color: #d83025;
}

.paragraph_question .focus .k-editor {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

.error_message {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 2px 0px 0 0px;
  margin: 10px;
}

.quiz__body {
  margin-top: 22px;
  padding: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  position: sticky;
  top: 0;
  z-index: 6;
}

.quiz__body.exam__adding--toolbar::after {
  background: transparent;
}

.quiz__body button {
  border: none;
  outline: 0;
  box-shadow: none;
}

.quiz__body button[disabled] {
  background: transparent;
}

.quiz__body button[disabled]:hover {
  background: transparent;
}

.quiz__body button img {
  margin-right: 13px;
}

body.rtl .quiz__body button img {
  margin-right: 0;
  margin-left: 13px;
}

@media only screen and (max-width: 767px) {
  .quiz__body button img {
    margin-right: 7px;
    max-width: 15px;
  }
  body.rtl .quiz__body button img {
    margin-right: 0;
    margin-left: 7px;
  }
}

.quiz__body button.append__question {
  font-size: 19px;
  font-weight: normal;
  color: #2a7d9c;
}

@media only screen and (max-width: 767px) {
  .quiz__body button.append__question {
    font-size: 12px;
  }
}

.quiz__body button.append__question.append__question--examMode {
  position: relative;
}

.quiz__body button.append__question.append__question--examMode::after {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: -2px;
  width: 1px;
  background: #bcd0d6;
}

body.rtl .quiz__body button.append__question.append__question--examMode::after {
  left: auto;
  right: 0;
}

.quiz__body button.sort__question {
  font-size: 16px;
  font-weight: normal;
  color: #b6b6b6;
}

@media only screen and (max-width: 767px) {
  .quiz__body button.sort__question {
    font-size: 10px;
  }
}

.quiz__body .drag__text {
  display: flex;
}

.quiz__body .drag__text img {
  margin-right: 10px;
}

body.rtl .quiz__body .drag__text img {
  margin-left: 10px;
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .quiz__body .drag__text img {
    width: 15px;
  }
}

.quiz__body .drag__text p {
  font-size: 16px;
  font-weight: normal;
  color: #2a7d9c;
}

@media only screen and (max-width: 767px) {
  .quiz__body .drag__text p {
    font-size: 10px;
  }
}

.quiz__body .quiz__body--reArrange button {
  font-size: 16px;
  font-weight: normal;
  color: #00678d;
}

@media only screen and (max-width: 767px) {
  .quiz__body .quiz__body--reArrange button {
    font-size: 9px;
    padding: 0;
  }
}

.quiz__body .quiz__body--reArrange button:first-child {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #bcd0d6;
  border-radius: 0;
}

@media only screen and (max-width: 767px) {
  .quiz__body .quiz__body--reArrange button:first-child {
    margin-right: 8px;
    padding-right: 8px;
  }
}

body.rtl .quiz__body .quiz__body--reArrange button:first-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #bcd0d6;
}

@media only screen and (max-width: 767px) {
  body.rtl .quiz__body .quiz__body--reArrange button:first-child {
    margin-right: 0;
    padding-right: 0;
    margin-left: 8px;
    padding-left: 8px;
  }
}

.quiz__body .quiz__body--reArrange button:last-child {
  color: #ff0000;
}

@media only screen and (max-width: 767px) {
  .quiz__body.quiz__body--sorting {
    padding: 20px 12px !important;
  }
}

.quiz__type--popUp {
  position: fixed;
  z-index: 6;
  top: 80px;
  max-height: calc(100vh - 80px);
  width: calc(1190px - 256px);
}

@media (max-width: 768px) {
  .quiz__type--popUp {
    width: 91%;
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

@media (min-width: 768px) {
  .quiz__type--popUp {
    width: 750px;
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .quiz__type--popUp {
    width: calc(970px - 256px);
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

@media (min-width: 1300px) {
  .quiz__type--popUp {
    width: calc(1190px - 166px);
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .quiz__type--popUp {
    width: calc(1280px - 347px);
    height: calc(100vh - 120px);
    overflow-y: auto;
  }
}

.quiz__type--popUp::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  content: '';
  opacity: 0.6;
  position: fixed;
  background-color: #000000;
}

.quiz__type--popUp .homework__wrapper--quiz {
  position: relative;
  z-index: 9;
}

.quiz__type--popUp .homework__wrapper--quiz .ant-select-selection__placeholder {
  font-size: 16px !important;
  top: 44%;
}

.quiz__type--popUp .ck.ck-editor__main > .ck-editor__editable {
  min-height: 70px;
  line-height: 1.5;
  color: #000000;
  height: auto;
}

.quiz__type--popUp .ck.ck-editor__main > .ck-editor__editable ul {
  list-style: inside !important;
}

.quiz__type--popUp .ck.ck-editor__main > .ck-editor__editable ol {
  list-style: decimal !important;
  margin-inline-start: 20px;
}

.quiz__type--action {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid #2a7d9c;
  margin-top: 16px;
}

.quiz__type--action button.quiz__action--save {
  background-color: #00678d;
  color: #ffffff;
}

.quiz__type--action button.quiz__action--cancel {
  margin-right: 20px;
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #ff0000;
}

body.rtl .quiz__type--action button.quiz__action--cancel {
  margin-right: 0;
  margin-left: 20px;
}

.appended__quiz {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
  margin-bottom: 23px;
}

.appended__quiz .ck-toolbar {
  border-top: none !important;
}

.appended__quiz .appended__quiz--header {
  display: flex;
  padding: 20px 25px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--header {
    padding: 20px 5px 0;
  }
}

@media only screen and (max-width: 767px) {
  .quiz__sorting--container .appended__quiz .appended__quiz--header {
    padding: 20px 5px;
  }
}

.appended__quiz .appended__quiz--header h4 {
  font-size: 16px;
  font-weight: normal;
  color: #2a7d9c;
  padding-left: 20px;
  width: 27%;
  border-right: 1px solid #bcd0d6;
  margin-right: 35px;
  margin-bottom: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

body.rtl .appended__quiz .appended__quiz--header h4 {
  padding-left: 0;
  padding-right: 20px;
  border-right: none;
  border-left: 1px solid #bcd0d6;
  margin-right: 0;
  margin-left: 35px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--header h4 {
    font-size: 14px;
    padding-left: 10px;
    width: auto;
    max-width: 50%;
    margin-right: 10px;
    padding-right: 6px;
  }
  body.rtl .appended__quiz .appended__quiz--header h4 {
    padding-left: 6px;
    padding-right: 10px;
    margin-right: 0;
    margin-left: 10px;
  }
}

.appended__quiz .appended__quiz--header h4 img {
  margin-right: 25px;
}

body.rtl .appended__quiz .appended__quiz--header h4 img {
  margin-right: 0;
  margin-left: 25px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--header h4 img {
    margin-right: 11px;
    max-width: 15px;
  }
}

.appended__quiz .appended__quiz--header p {
  font-size: 14px;
  font-weight: normal;
  color: #2a7d9c;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.appended__quiz .appended__quiz--header p p {
  margin-bottom: 8px;
}

.appended__quiz .appended__quiz--header p p img {
  max-width: 100% !important;
  max-height: 150px !important;
}

.appended__quiz .appended__quiz--header p iframe {
  display: block;
  max-width: 100% !important;
  max-height: 150px !important;
  height: auto !important;
}

.appended__quiz .appended__quiz--header p iframe * {
  background: transparent;
}

.appended__quiz .appended__quiz--header p.exam__degree--text {
  flex-shrink: 0;
  width: 8%;
  display: flex;
  justify-content: center;
  padding-left: 25px;
  margin-left: 25px;
  border-left: solid 1px #bcd0d6;
  align-items: center;
}

body.rtl .appended__quiz .appended__quiz--header p.exam__degree--text {
  padding-left: 0;
  margin-left: 0;
  border-left: none;
  padding-right: 25px;
  margin-right: 25px;
  border-right: solid 1px #bcd0d6;
}

.appended__quiz .appended__quiz--header p figure {
  max-width: 200px;
  display: inline-block;
  margin: 0 16px;
}

.appended__quiz .appended__quiz--header p figure img {
  width: 100%;
  max-height: 250px;
}

.appended__quiz .appended__quiz--header.completeHeader p {
  line-height: 35px;
  flex-direction: row;
  flex-wrap: wrap;
}

.appended__quiz .appended__quiz--header.completeHeader p img {
  max-width: 100px !important;
  max-height: 30px !important;
  margin: 0 10px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--header.exam--quiz--header {
    flex-wrap: wrap;
  }
  .appended__quiz .appended__quiz--header.exam--quiz--header p.exam__degree--text {
    border-left: none;
    width: 45%;
    border-left: none;
    align-items: unset;
    flex-shrink: 1;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0;
    padding: 0;
  }
  .appended__quiz .appended__quiz--header.exam--quiz--header h4 {
    width: 50%;
  }
  .appended__quiz .appended__quiz--header.exam--quiz--header .exam__degree--text {
    font-size: 11px;
    font-weight: bold;
    color: #2a7d9c;
  }
}

.appended__quiz .appended__quiz--header.exam--quiz--header .header__text--responsive {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  width: 100% !important;
  min-height: 41px;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.appended__quiz .appended__quiz--body {
  display: flex;
  flex-wrap: wrap;
  padding: 22px 45px;
  margin-top: 20px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--body {
    padding: 0 14px 22px 14px;
  }
}

.appended__quiz .appended__quiz--body .true__false {
  font-size: 14px;
  font-weight: normal;
  width: 50%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .appended__quiz .appended__quiz--body .true__false {
    width: 100%;
    margin-top: 25px !important;
  }
  .appended__quiz .appended__quiz--body .true__false:first-child {
    margin-top: 0 !important;
  }
}

.appended__quiz .appended__quiz--body .true__false.true {
  color: #2a7d9c;
}

.appended__quiz .appended__quiz--body .true__false.false {
  color: #ff0000;
}

.appended__quiz .appended__quiz--body .true__false span.correct {
  width: 28px;
  height: 28px;
  background-color: #e5e5e5;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  flex-shrink: 0;
}

body.rtl .appended__quiz .appended__quiz--body .true__false span.correct {
  margin-right: 0;
  margin-left: 22px;
}

.appended__quiz .appended__quiz--body .true__false span.correct.active__answer {
  background: #00ff1a;
}

.appended__quiz .appended__quiz--body .true__false img {
  margin-right: 10px;
}

body.rtl .appended__quiz .appended__quiz--body .true__false img {
  margin-right: 0;
  margin-left: 10px;
}

.appended__quiz .appended__quiz--body .true__false p {
  overflow-wrap: anywhere;
  max-width: 90%;
}

.appended__quiz .appended__quiz--body .connect_quiz--appended .option {
  border-bottom: solid 1px #2a7d9c;
  align-items: flex-end;
}

.appended__quiz .appended__quiz--body .arrange__quiz {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--body .arrange__quiz {
    flex-direction: column;
    align-items: center;
  }
}

.appended__quiz .appended__quiz--body .arrange__quiz ul {
  width: 48%;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--body .arrange__quiz ul {
    width: auto;
  }
}

.appended__quiz .appended__quiz--body .arrange__quiz ul li {
  font-size: 12px;
  font-weight: normal;
  color: #2a7d9c;
  margin-bottom: 20px;
  padding-bottom: 20px;
  color: #2a7d9c;
  border-bottom: 1px solid #2a7d9c;
}

body.rtl .appended__quiz .appended__quiz--body .arrange__quiz ul li {
  padding-left: 0;
  padding-right: 50px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--body .arrange__quiz ul li {
    padding-left: 0;
    padding-right: 0;
  }
}

.appended__quiz .appended__quiz--body .arrange__quiz ul li p {
  overflow-wrap: anywhere;
  margin: 5px;
}

.appended__quiz .appended__quiz--body .arrange__quiz ul .arrange__quiz--header {
  font-size: 16px;
  font-weight: bold;
  color: #0b85b2;
  display: flex;
  color: #2a7d9c;
  border-bottom: none;
  padding: 0 !important;
}

.appended__quiz .appended__quiz--body .arrange__quiz ul .arrange__quiz--header > span {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: #ffffff;
}

body.rtl .appended__quiz .appended__quiz--body .arrange__quiz ul .arrange__quiz--header > span {
  margin-right: 0;
  margin-left: 20px;
}

.appended__quiz .appended__quiz--body .arrange__quiz ul .arrange__quiz--header > span.arrange__quiz--correct {
  background-color: #00ff1a;
}

.appended__quiz .appended__quiz--body .arrange__quiz ul .arrange__quiz--header > span.arrange__quiz--wrong {
  background-color: #e5e5e5;
}

.appended__quiz .appended__quiz--body.connect__quiz--body {
  padding-top: 0;
  margin-top: 0;
}

.appended__quiz .appended__quiz--body figure {
  max-width: 200px;
  display: inline-block;
  margin: 0 10px;
}

.appended__quiz .appended__quiz--body figure img {
  width: 100%;
  max-height: 250px;
}

.appended__quiz .appended__quiz--footer {
  background-color: #f3f3f3;
  padding: 20px 25px;
  padding-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body.rtl .appended__quiz .appended__quiz--footer {
  padding-right: 50px;
  padding-left: 25px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--footer {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .appended__quiz .appended__quiz--footer {
    padding-right: 5px;
    padding-left: 5px;
    justify-content: center;
  }
}

.appended__quiz .appended__quiz--footer button {
  font-size: 14px;
  font-weight: normal;
  color: #2a7d9c;
  background: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--footer button {
    font-size: 12px;
  }
}

@media only screen and (max-width: 479px) {
  .appended__quiz .appended__quiz--footer button {
    font-size: 10px;
  }
}

@media only screen and (max-width: 399px) {
  .appended__quiz .appended__quiz--footer button {
    padding: 0 8px;
  }
}

.appended__quiz .appended__quiz--footer button.quiz__footer--duplicate {
  border-right: 1px solid #b1cbd4;
  padding-right: 22px;
  margin-right: 22px;
}

body.rtl .appended__quiz .appended__quiz--footer button.quiz__footer--duplicate {
  padding-right: 0;
  margin-right: 0;
  padding-left: 22px;
  margin-left: 22px;
  border-right: none;
  border-left: 1px solid #b1cbd4;
}

@media only screen and (max-width: 479px) {
  .appended__quiz .appended__quiz--footer button.quiz__footer--duplicate {
    padding-right: 10px;
    margin-right: 6px;
  }
  .appended__quiz .appended__quiz--footer button.quiz__footer--duplicate body.rtl {
    padding-right: 0;
    margin-right: 0;
    padding-left: 6px;
    margin-left: 6px;
  }
}

.appended__quiz .appended__quiz--footer button.quiz__footer--delete {
  color: #ff0000;
}

.appended__quiz .appended__quiz--footer button img {
  margin-right: 8px;
  max-width: 15px;
}

body.rtl .appended__quiz .appended__quiz--footer button img {
  margin-right: 0;
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .appended__quiz--footer button img {
    margin-right: 5px;
    max-width: 10px;
  }
  body.rtl .appended__quiz .appended__quiz--footer button img {
    margin-right: 0;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 479px) {
  .appended__quiz .appended__quiz--footer button.quiz__footer--edit {
    border-right: 1px solid #b1cbd4;
    margin-right: 12px;
    padding-right: 12px;
  }
  body.rtl .appended__quiz .appended__quiz--footer button.quiz__footer--edit {
    margin-right: 0;
    padding-right: 0;
    margin-left: 12px;
    padding-left: 12px;
  }
}

@media only screen and (max-width: 399px) {
  .appended__quiz .appended__quiz--footer button.quiz__footer--edit {
    border-right: 1px solid #b1cbd4;
    margin-right: 8px;
    padding-right: 8px;
  }
  body.rtl .appended__quiz .appended__quiz--footer button.quiz__footer--edit {
    margin-right: 0;
    padding-right: 0;
    margin-left: 8px;
    padding-left: 8px;
  }
}

.appended__quiz .quiz__section--wrapper .quiz__section--header {
  min-height: 100px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #00adee;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  overflow-y: auto;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header .ck-read-only {
  background: #00adee;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header .ck-read-only span:last-of-type::after {
  display: none;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header p {
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 10px;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header p:last-child {
  margin-bottom: 0;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span {
  color: #fff;
  position: relative;
  flex-shrink: 0;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header {
  width: 20%;
  margin-right: 15px;
}

body.rtl .appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header {
  margin-right: 0;
  margin-left: 15px;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header::after {
  content: '';
  position: absolute;
  right: 0;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background: #bcd0d6;
}

body.rtl .appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header::after {
  left: 0;
  right: auto;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header img {
  margin-right: 10px;
}

body.rtl .appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header img {
  margin-right: 0;
  margin-left: 10px;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type {
  width: 10%;
}

.appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type::after {
  content: '';
  position: absolute;
  left: -20px;
  top: -15px;
  bottom: -15px;
  width: 1px;
  background: #bcd0d6;
}

body.rtl .appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type::after {
  left: auto;
  right: -20px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz .quiz__section--wrapper .quiz__section--header {
    justify-content: space-between;
    padding: 10px 15px;
  }
  .appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header {
    width: 45%;
  }
  .appended__quiz .quiz__section--wrapper .quiz__section--header span.section__header::after {
    display: none;
  }
  .appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type {
    width: 50%;
    text-align: right;
  }
  body.rtl .appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type {
    text-align: left;
  }
  .appended__quiz .quiz__section--wrapper .quiz__section--header span:last-of-type::after {
    top: -5px;
    bottom: -5px;
  }
}

.appended__quiz .quiz__section--wrapper .appended__section--footer {
  background-color: #00adee;
}

.appended__quiz .quiz__section--wrapper .appended__section--footer button {
  color: #fff !important;
}

.correct__span-choice {
  width: 28px;
  height: 28px;
  background-color: #e5e5e5;
  margin-right: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #ffffff;
  flex-shrink: 0;
}

body.rtl .correct__span-choice {
  margin-right: 0;
  margin-left: 22px;
}

.correct__span-choice.active__answer {
  background: #00ff1a;
}

.correct__span-choice.correct__span--student {
  width: 15px;
  height: 15px;
  font-size: 9px;
  margin-right: 10px;
}

.multiChoice .multiChoice__item {
  max-width: 400px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .multiChoice .multiChoice__item {
    max-width: 100%;
  }
}

.multiChoice .multiChoice__item .multiChoice__rong_active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  padding: 0 8px 3px;
  margin-top: 3px;
  margin-left: 10px;
  color: #ff0000;
  opacity: 1;
  position: absolute;
  right: 0;
}

body.rtl .multiChoice .multiChoice__item .multiChoice__rong_active {
  right: unset;
  left: -25px;
}

.add__quiz--footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

@media only screen and (max-width: 479px) {
  .add__quiz--footer {
    flex-direction: column-reverse;
  }
  .add__quiz--footer .quiz__footer--btns {
    margin-bottom: 10px;
  }
}

.add__quiz--footer button:first-child {
  margin-right: 20px;
}

body.rtl .add__quiz--footer button:first-child {
  margin-left: 20px;
  margin-right: 0;
}

.ant-col.ant-col-12 span {
  color: #ff0000;
  cursor: pointer;
  width: 20px;
}

body.rtl .ant-col.ant-col-12 span {
  margin-left: unset;
  margin-right: -10px;
}

.quiz__sorting--container {
  padding-bottom: 65px;
}

.connect_container {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .connect_container .enhanced-input-container.focusResposive {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 22;
  }
}

@media only screen and (max-width: 767px) {
  .hideLine {
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  .homework__body--background .form__row--responsive {
    margin: 0 -20px;
  }
}

@media only screen and (max-width: 767px) {
  .homework__body--background.homework__body--filter .form__row--responsive {
    margin: 0 -10px;
  }
}

.quiz__header--textEditor .enhanced-input-container.focus .k-editor {
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  padding-bottom: 27px;
}

.has-error .ant-select-selection {
  border-color: #d83025 !important;
  border-width: 1px !important;
}

.has-error .ant-input,
.has-error .ant-input:hover,
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d !important;
}

.homework__content--option {
  color: #1b8db7 !important;
}

.homework__filters--startDate .ant-calendar-picker-container {
  left: 0 !important;
  right: auto !important;
  top: 0 !important;
}

body.rtl .homework__filters--startDate .ant-calendar-picker-container {
  right: 0 !important;
  left: auto !important;
}

.header__text p {
  padding-bottom: 5px;
}

.appended__quiz .appended__quiz--body .connect_quiz--appended .option:last-child {
  margin-bottom: 0;
}

.appended__quiz--body .connect_container .options_container .option {
  margin: 5px 5px 0 5px;
  min-height: 61px;
  height: 25%;
  height: 150px;
  overflow-y: auto;
}

.appended__quiz--body .connect_container .options_container .option p {
  overflow-wrap: anywhere;
}

.appended__quiz--body .connect_container .options_container .option * {
  max-height: 150px;
}

@media only screen and (max-width: 767px) {
  .appended__quiz--body .connect_container .options_container .option * {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .progress__modal--container .ant-modal-footer {
    margin-top: 43px;
  }
}

body.rtl .progress__modal--container .ant-modal-footer {
  margin-top: 36px;
}

.quiz__input--style .enhanced-input-container .k-editor .k-editor-content > .k-iframe iframe {
  max-width: 265px;
}

body:-webkit-full-page-media {
  background: transparent;
}

@media only screen and (max-width: 767px) {
  .correct__answer--editor {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 4px solid #0b85b2;
  }
}

.quiz__body--scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 400px);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .quiz__body--scroll {
    max-height: calc(100vh - 450px);
  }
}

.complete_the_following .complete__input--type {
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .complete_the_following .complete__input--type {
    border: 2px #cccccc solid;
    max-width: 90% !important;
    border-radius: 4px;
  }
}

.complete_the_following .delete__span--complete {
  margin: 0 11px;
  min-width: 20px;
  color: #f5222d;
  cursor: pointer;
}

.homework__scroll--connect {
  overflow-y: auto;
  max-height: calc(100vh - 80px);
}

@media only screen and (max-width: 767px) {
  .homework__scroll--connect {
    max-height: calc(100vh - 120px);
    overflow-y: auto !important;
  }
}

.confirm_ok_delete_question {
  background-color: #ff0000 !important;
  color: #ffffff;
  margin: 0px 2px;
  border: none;
}

.confirm_ok_delete_question:hover {
  background-color: #ff0000e0;
  color: #ffffffef;
}

.confirm_cancel_delete_question {
  margin: 0px 2px;
}

/* .quiz_question_header{
	p{
		display: flex !important;
		align-items: center !important;
} 
	.k-editor-content{
		iframe{
			html{
				body{
					.k-content {
						p{
							display: flex !important;
							align-items: center !important;
					}
					}
				}
			}
			
		}
	}
} */
.exam__adding--toolbar {
  position: fixed;
  z-index: 7;
  top: 80px;
  max-height: calc(100vh - 80px);
  width: calc(1190px - 256px);
}

@media (max-width: 768px) {
  .exam__adding--toolbar {
    width: 91%;
  }
}

@media (min-width: 768px) {
  .exam__adding--toolbar {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .exam__adding--toolbar {
    width: calc(970px - 256px);
  }
}

@media (min-width: 1200px) {
  .exam__adding--toolbar {
    width: calc(1190px - 256px);
  }
}

@media (min-width: 1200px) {
  .exam__adding--toolbar {
    width: calc(1280px - 256px);
  }
}

.exams-list-wrapper__header .quiz__body:not(.exam__adding--toolbar) {
  top: 0;
}

.secondary__btn--draft:hover {
  color: #fff !important;
}

.secondary__btn--draft:active {
  color: #fff !important;
}

.reject__for--edit {
  background: #ff0000 !important;
  color: #fff !important;
  flex-shrink: 0;
}

.reject__for--edit:hover {
  color: #fff !important;
}

.reject__for--edit:active {
  color: #fff !important;
}

.correct__answer--editor {
  padding-left: 36px;
}

body.rtl .correct__answer--editor {
  padding-right: 36px;
}

.ck-editor__main {
  /* 	.ck-content {
		max-height: 130px;
		overflow-y: auto;
	} */
}

.react-mathjax-preview-result p:empty {
  display: none;
}

.MathJax span {
  max-width: 100%;
  white-space: pre-wrap;
}

.MathJax nobr .math {
  width: 100% !important;
}

.MathJax nobr .math > span:first-child {
  width: 100% !important;
}

.MathJax nobr .math > span:first-child > span {
  width: 100% !important;
  position: unset !important;
  clip: unset !important;
}

body.rtl .ck-content.ck-editor__editable {
  text-align: right;
}

body.rtl .ck-content.ck-editor__editable p {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .ck.ck-editor__main > .ck-editor__editable .image.ck-widget {
    max-width: 150px;
  }
}

.react-mathjax-preview-result .MathJax {
  display: inline-block;
}

.enhanced-input-container.blur .ck.ck-sticky-panel__content {
  display: none;
}

.enhanced-input-container.focus .ck.ck-sticky-panel__content {
  display: block;
}

.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
  outline-color: transparent !important;
}

.correct__answer--editor .correct__answer--parag {
  max-width: 400px !important;
  padding: var(--ck-spacing-large) 0;
  min-height: 70px !important;
}

.correct__answer--editor .correct__answer--parag .ck.ck-editor {
  width: 100%;
}

.correct__answer--editor .correct__answer--parag .ck-editor__editable {
  min-height: unset !important;
}

.correct__answer--editor .correct__answer--parag #react-mathjax-preview {
  width: 100%;
}

.correct__answer--editor .correct__answer--parag figure {
  max-width: 75% !important;
  max-height: 150px;
}

.correct__answer--editor .correct__answer--parag figure img {
  max-height: 150px;
}

.reArrange_inputs--question .re_arrange--input .enhanced-input-container {
  max-width: 410px;
}

.quiz__type--popUp .ck.ck-editor__main > .ck-editor__editable {
  max-height: 134px;
}

.quiz__type--popUp .ck.ck-editor__main .ck-widget_with-resizer {
  max-width: 75% !important;
  max-height: 150px;
}

.quiz__type--popUp .ck.ck-editor__main .ck-widget_with-resizer img {
  max-height: 150px;
}

.connect-option-ck-editor.large .ck.ck-editor__main > .ck-editor__editable {
  min-height: 140px !important;
  max-height: 140px !important;
}

.connect-option-ck-editor.large .ck.ck-editor__main > .ck-editor__editable p {
  max-height: 20px;
}

.connect-option-ck-editor .ck.ck-editor__main > .ck-editor__editable {
  max-height: 140px !important;
  min-height: 70px;
}

.connect-option-ck-editor .ck.ck-editor__main > .ck-editor__editable p {
  max-height: 20px;
}

.connect-option-ck-editor .ck.ck-editor__main .ck-widget_with-resizer {
  max-width: 75% !important;
  max-height: 150px;
}

.connect-option-ck-editor .ck.ck-editor__main .ck-widget_with-resizer img {
  max-height: 150px;
}

.connect_container {
  display: flex;
  /* max-height: 100vh;
	overflow-y: auto; */
}

.connect_container .options_container {
  width: 17%;
}

.connect_container .options_container.right {
  width: 40%;
}

.connect_container .options_container.left {
  width: 40%;
}

.connect_container .options_container .option {
  color: #2a7d9c;
  margin: 5px 5px 40px 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.connect_container .options_container .option.spacebetween {
  justify-content: space-between;
}

.connect_container .options_container .option span.left_dot_circle,
.connect_container .options_container .option .right_dot_circle {
  /*  width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #2a7d9c;
                cursor: pointer; */
}

.connect_container .options_container .option .option_actions {
  display: flex;
}

.connect_container .options_container .option .option_actions span.left_dot_circle,
.connect_container .options_container .option .option_actions .right_dot_circle {
  /*   width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #2a7d9c;
                    cursor: pointer; */
}

.connect_container .options_container .option .option_actions span.delete_span {
  font-size: 18px;
  color: #ff0000;
  cursor: pointer;
  font-weight: 600;
}

.connect_container .options_container .option .quiz__input--style {
  width: 100%;
}

.connect_container .options_container .option .quiz__input--style .enhanced-input-container {
  width: 100%;
}

.connect_container .re_arrange--input {
  width: unset;
}

.connect_container .re_arrange--input.quiz__input--style.selected .enhanced-input-container .k-editor {
  border: solid 1px #b1cbd4 !important;
  border-radius: 6px;
}

.not_connected {
  opacity: 0.5;
  width: 12px;
  height: 12px;
  background-color: #2a7d9c;
  cursor: pointer;
}

.online_session_container {
  margin: 5px 30px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .online_session_container {
    margin: 5px;
    width: 70vw;
  }
}

@media only screen and (max-width: 991px) {
  .online_session_container {
    margin: 5px;
    width: 95vw;
  }
}

.online_session_container .online_session_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header {
    flex-direction: column;
    align-items: unset;
  }
}

.online_session_container .online_session_header h1 {
  font-family: Montserrat;
  font-size: 27px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #00678d;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header h1 {
    color: #2a7d9c;
    font-size: 14px;
    margin: 30px 10px 20px 10px;
  }
}

.online_session_container .online_session_header .date_create_session {
  margin-right: unset;
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .ant-input-affix-wrapper {
    width: unset;
  }
}

.online_session_container .online_session_header .date_create_session .ant-input-prefix {
  left: 17px;
}

body.rtl .online_session_container .online_session_header .date_create_session .ant-input-prefix {
  right: 17px;
  left: unset;
}

.online_session_container .online_session_header .date_create_session .ant-input {
  width: 143px;
  height: 36px;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .ant-input {
    width: 100%;
    flex-basis: 47%;
  }
}

.online_session_container .online_session_header .date_create_session .ant-input.ant-calendar-picker-input {
  width: 230px;
}

.online_session_container .online_session_header .date_create_session .ant-input.ant-calendar-picker-input .ant-calendar-range-picker-separator {
  padding: 8px 0px;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .ant-input.ant-calendar-picker-input {
    width: 100%;
    font-size: 8px;
  }
  body.rtl .online_session_container .online_session_header .date_create_session .ant-input.ant-calendar-picker-input {
    width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    flex-flow: row wrap;
  }
}

.online_session_container .online_session_header .date_create_session .online_session_picker {
  height: 100%;
  background-color: #fff;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .online_session_picker {
    width: 100%;
    flex-basis: 47%;
    display: unset;
    order: 2;
    margin: 2px 5px;
  }
}

.online_session_container .online_session_header .date_create_session .title_filter .ant-input {
  margin: 0px 10px;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .title_filter .ant-input {
    margin: 0px 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .title_filter {
    width: 100%;
    flex-basis: 47%;
    display: unset;
    order: 3;
    margin: 2px 5px;
  }
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .select_type {
    width: 100%;
    flex-basis: 47%;
    display: unset;
    order: 4;
    margin: 2px 5px;
    height: 36px;
  }
}

.online_session_container .online_session_header .date_create_session .create_session {
  height: 100%;
  margin: 0px 5px;
  background-color: #00678d;
  color: #FFF;
  width: 175px;
  height: 36px;
  font-size: 12px;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_header .date_create_session .create_session {
    width: 100%;
    flex-basis: 47%;
    font-size: 10px;
    order: 1;
    margin: 2px 5px;
  }
}

.online_session_container .online_session_actions_header {
  height: 47px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_actions_header {
    height: 0px;
  }
}

.online_session_container .online_session_actions_header .action_filters {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 1199px) {
  .online_session_container .online_session_actions_header .action_filters {
    display: none;
  }
}

.online_session_container .online_session_actions_header .action_filters .filter_checkbox {
  display: flex;
  margin: 0px 10px;
  font-size: 12px;
}

.online_session_container .online_session_actions_header .action_filters .filter_checkbox .ant-radio-inner {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

body.rtl .online_session_container .online_session_actions_header .action_filters .filter_checkbox .ant-radio-inner {
  width: 17px;
  height: 17px;
  border: .1px solid #d9d9d9;
}

.online_session_container .onlinesessions_table_container {
  margin: 20px 0px;
}

.online_session_container .onlinesessions_table_container .online_sesstion_table {
  background-color: #fff;
}

.online_session_container .onlinesessions_table_container .online_sesstion_table thead {
  background-color: #fff !important;
}

.online_session_container .onlinesessions_table_container .online_sesstion_table .ant-table-thead > tr > th {
  text-align: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.online_session_container .onlinesessions_table_container .online_sesstion_table .ant-table-placeholder {
  margin-top: 115px;
  padding: 0px 50px;
}

.centered {
  text-align: center !important;
}

.title_custom_class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.modal_content .modal_students_search .ant-input {
  padding-top: 0px !important;
}

.modal_content .modal_student {
  display: flex;
  margin: 10px 5px;
  align-items: center;
}

.modal_content .modal_student img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 0px 5px;
}

.modal_content .modal_student p {
  color: #00678d;
}

.modal_content .ok_button {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 639px) {
  .modal_content .ok_button {
    justify-content: center;
  }
}

.modal_content .ok_button button {
  background-color: #00678d;
  color: #FFF;
  padding: 5px 30px;
}

.onlinesession_card_res {
  margin: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 90;
  margin-bottom: 15px;
}

.onlinesession_card_res .onlinesesstion_date_time_container {
  width: 100px;
}

.onlinesession_card_res .onlinesesstion_date_time {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  padding: 30px 30px 33px 30px;
}

.onlinesession_card_res .onlinesesstion_date_time p {
  padding-top: 3px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .onlinesession_card_res .onlinesesstion_date_time p {
    font-size: 12px !important;
  }
}

.onlinesession_card_res .onlinesesstion_date_time p.firstline_date {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
}

.onlinesession_card_res .onlinesesstion_date_time p {
  padding-top: 3px;
  font-size: 9px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
}

.onlinesession_card_res .onlinesesstion_date_time .firstline_date {
  border-bottom: 1px solid white;
  padding-bottom: 3px;
  width: 70px;
  font-size: 13px;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .onlinesession_card_res .onlinesesstion_date_time {
    margin-right: 4px;
  }
  body.rtl .onlinesession_card_res .onlinesesstion_date_time {
    margin-right: 0;
    margin-left: 4px;
  }
}

.onlinesession_card_res .onlinesesstion_details {
  margin: 5px 10px;
  width: 100%;
}

.onlinesession_card_res .onlinesesstion_details .title_cog {
  display: flex;
  justify-content: space-between;
}

.onlinesession_card_res .target_container {
  display: flex;
  margin: 10px 0px;
}

.onlinesession_card_res .target_container p:first-child {
  color: #9797ac;
}

.onlinesession_card_res .subject_status {
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
}

.onlinesession_card_res .subject_status .subject_status_flex {
  display: flex;
  align-items: center;
}

.onlinesession_card_res .subject_status .subject_status_flex p:last-child {
  font-size: 8px;
}

.onlinesession_card_res .subject_status .subject_status_flex p:first-child {
  color: #9797ac;
}

.target_img_total {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.target_img_total img {
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0px -10px 0px 3px;
}

.target_img_total img:last-child {
  margin: 0px !important;
}

.menu_item {
  margin: 0px;
  padding: 0px;
}

.menu_item .ant-dropdown-menu-item-divider {
  margin: 0px;
}

.menu_item img {
  margin: 0px 10px;
}

.table_actions {
  cursor: pointer;
}

.custom_empty_message {
  margin: 50px;
}

.custom_empty_message h5, .custom_empty_message h4 {
  margin-bottom: 10px;
  text-align: center;
  color: #00678d;
}

.custom_empty_message p {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
  color: #00678d;
}

.custom_empty_message img {
  margin: auto;
  width: 60%;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .custom_empty_message img {
    width: 100%;
  }
}

.attention_modal {
  margin: 10px;
}

.attention_modal h5, .attention_modal h4 {
  margin-bottom: 10px;
  text-align: center;
  color: #00678d;
}

.attention_modal p {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
  color: #00678d;
}

.attention_modal img {
  margin: auto;
  width: 60%;
  display: block;
}

@media only screen and (max-width: 1199px) {
  .attention_modal img {
    width: 100%;
  }
}

.attention_modal p {
  font-size: 12px;
}

body.rtl .ant-modal-centered .ant-modal {
  text-align: right;
}

body.rtl .ant-cascader-picker-arrow {
  right: auto;
}

.session__date--table {
  font-size: 10px;
}

.session__date--table img:first-of-type {
  margin-right: 5px;
}

body.rtl .session__date--table img:first-of-type {
  margin-right: 0;
  margin-left: 5px;
}

.session__date--table img:last-of-type {
  margin-right: 5px;
  margin-left: 15px;
}

body.rtl .session__date--table img:last-of-type {
  margin-right: 15px;
  margin-left: 5px;
}

.online__sessions--actions .ant-dropdown-menu-item {
  padding: 10px 12px;
}

.online__sessions--actions .ant-dropdown-menu-item img {
  margin-right: 10px;
}

body.rtl .online__sessions--actions .ant-dropdown-menu-item img {
  margin-right: 0;
  margin-left: 10px;
}

.from_to_date_time {
  font-size: 11px;
}

.time_image {
  margin: 0px 2px 0px 15px;
}

body.rtl .time_image {
  margin: 0px 15px 0px 2px;
}

.ant-drawer > button {
  background: transparent !important;
  color: black !important;
  border: none;
  box-shadow: none;
  outline: none !important;
}

.ant-drawer > button:focus, .ant-drawer > button:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-drawer > button:hover {
  color: black !important;
}

.ant-drawer .empty-state {
  margin-top: 25px;
}

.request-info-wrapper > div > div {
  min-height: 30px;
}

.request-info-wrapper .request-actions-wrapper {
  display: flex;
  justify-content: center;
}

.request-info-wrapper .request-actions-wrapper button {
  padding-left: 40px;
  padding-right: 40px;
  margin: auto 15px;
}

@media only screen and (max-width: 991px) {
  .view-profile-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.view-profile-drawer .ant-drawer-close .anticon {
  color: #c30c00 !important;
}

body.rtl .view-profile-drawer .ant-drawer-close {
  right: unset !important;
  left: 0;
}

.view-profile-drawer .ant-drawer-content-wrapper {
  transition: transform 0.7s ease 0s, width 0.7s ease 0s;
}

.view-profile-drawer .ant-drawer-body {
  padding: 0 !important;
}

body.rtl .view-profile-drawer .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
}

.view-profile-drawer .customer-profile-content .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #eef1f4;
  position: relative;
}

.view-profile-drawer .customer-profile-content .header .user-image {
  width: 87px;
  height: 87px;
  min-width: 87px;
  max-width: 87px;
  min-height: 87px;
  max-height: 87px;
  border-radius: 50%;
}

.view-profile-drawer .customer-profile-content .header .expand {
  position: absolute;
  top: 16px;
  left: 53px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .expand {
  right: 47px;
  left: unset;
}

.view-profile-drawer .customer-profile-content .header .back-button {
  position: absolute;
  top: 17px;
  left: 25px;
  font-size: 17px;
  cursor: pointer;
}

body.rtl .view-profile-drawer .customer-profile-content .header .back-button {
  right: 25px;
  left: unset;
  transform: rotate(180deg);
}

.view-profile-drawer .customer-profile-content .header .name.without-avatar {
  margin-top: 16px;
  font-size: 20px;
  align-self: flex-start;
}

body.rtl .view-profile-drawer .customer-profile-content .ant-tabs-nav-container {
  transform: scaleX(-1) !important;
}

body.rtl .view-profile-drawer .customer-profile-content .content {
  text-align: right;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  padding: 0 12px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-bar {
  text-align: left;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav {
  display: flex;
  align-items: center;
  justify-content: start;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-nav {
  display: inline-flex !important;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab-active {
  color: #2db2bb;
  font-weight: bold;
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  color: #2db2bb;
}

body.rtl .view-profile-drawer .customer-profile-content .content .ant-tabs-nav .ant-tabs-tab {
  transform: scaleX(-1);
}

.view-profile-drawer .customer-profile-content .content .ant-tabs-ink-bar {
  background: #2db2bb !important;
}

.view-profile-drawer .customer-profile-content .content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2db2bb;
  line-height: 1.31;
  padding-bottom: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}

.view-profile-drawer .customer-profile-content .content h4.without-border {
  border-bottom: none;
}

.view-profile-drawer .customer-profile-content .content .buttons-group button:first-of-type {
  margin-inline-end: 10px;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea {
  margin-bottom: 15px;
  overflow-y: scroll;
}

.view-profile-drawer .customer-profile-content .content .comment-textArea p {
  color: #444444;
  font-size: 16px;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  content: url("../../resources/images/icons/email.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(1)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  content: url("../../resources/images/icons/phone.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(2)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  content: url("../../resources/images/icons/user.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(3)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  content: url("../../resources/images/icons/gender.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g1 div:nth-child(4)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(1)::before {
  content: url("../../resources/images/icons/car.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(1)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(2)::before {
  content: url("../../resources/images/icons/battery.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(2)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(3)::before {
  content: url("../../resources/images/icons/driving-license.svg");
  position: absolute;
  left: 6px;
}

body.rtl .view-profile-drawer .customer-profile-content .content .icons-g2 div:nth-child(3)::before {
  right: 8px;
  left: unset !important;
}

.view-profile-drawer .customer-profile-content .content .gray {
  color: #494848;
}

.view-profile-drawer .ant-tabs {
  direction: ltr;
}

.view-profile-drawer .license-img {
  align-items: flex-start;
}

.view-profile-drawer .license-img img {
  width: 130px;
}

.view-profile-drawer .license-img img:first-of-type {
  margin-right: 10px;
}

.view-profile-drawer .license-img img:last-of-type {
  margin-left: 10px;
  width: 17px;
}

.view-profile-drawer .card-status-holder {
  display: flex;
  flex-direction: column;
}

.view-profile-drawer .card-status-holder > label {
  margin-bottom: 15px;
}

.view-profile-drawer .send-comment {
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
}

.view-profile-drawer .send-comment button {
  margin-inline-start: 5px;
}

.view-profile-drawer .min-h-80 {
  min-height: 80px;
}

.view-profile-drawer .ant-row {
  max-height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
  overflow: hidden scroll;
}

.view-profile-drawer .details-wrapper div {
  min-height: 21px;
}

body.rtl .ant-drawer-left .ant-drawer-content-wrapper, body.rtl
.ant-drawer-right .ant-drawer-content-wrapper {
  right: unset;
  left: 0;
}

.ant-drawer-content-wrapper {
  transition: width 0.7s ease 0s;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0 !important;
}

.ant-drawer-mask {
  background-color: transparent !important;
}

.request-card-wrapper * {
  font-family: Pridi;
}

.request-card-wrapper .search-header-wrapper {
  background: #fff;
  background-attachment: fixed;
  background-position: top;
}

.request-card-wrapper > .content {
  padding: 6px 20% 20px 20%;
  margin-top: -30px;
  background-color: #fff;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder {
  background-color: #ffffff;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px 12px;
  margin-bottom: 22px;
  position: relative;
}

.request-card-wrapper > .content .ask-card-sginin-holder h4 {
  font-size: 21px;
  font-weight: normal;
  line-height: 1.19;
  color: #242430;
  margin-bottom: 30px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder h4 {
    font-size: 15px !important;
    padding: 0 12px;
    line-height: 1.4;
    margin-bottom: 10px;
  }
}

.request-card-wrapper > .content .ask-card-sginin-holder .anticon {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #d83025;
  font-size: 25px;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .anticon {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .request-card-wrapper > .content .ask-card-sginin-holder .gradient-fill {
    min-height: 40px !important;
    font-size: 13px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper {
  background-color: #fff;
  padding: 20px 10%;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper {
    padding: 20px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h2 {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.19;
  color: #242430;
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h2 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper h4 {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.19;
  color: #242430;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 479px) {
  .request-card-wrapper > .content .charging-card-form-wrapper h4 {
    font-size: 18px;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .request-reject-reason {
  color: #d83025;
  font-weight: normal;
  text-align: center;
  margin-top: 25px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 40px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item input {
  padding-right: 40px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url("../../resources/images/website/icon/gender.svg");
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

@media only screen and (max-width: 991px) {
  .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
    padding-left: 5px !important;
  }
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 3.2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .personal-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder {
  position: relative;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/car.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/cartype.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/battery.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item.is-hidden {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered,
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-left: 30px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__placeholder, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-select-selection__rendered, body.rtl
.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/license.svg");
  position: absolute;
  top: 23px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .form-holder > .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .car-info-holder .ant-form-extra {
  font-size: 14px;
  line-height: 2.07;
  color: #5d71aa;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-checkbox + span {
  font-weight: normal !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item {
  margin-bottom: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker {
  width: 100%;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-calendar-picker .ant-calendar-picker-icon {
  font-size: 20px;
  right: auto;
  left: 12px;
  display: flex;
  align-items: center;
  width: 20px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  display: flex;
  font-size: 20px;
  left: 0 !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item .ant-input-affix-wrapper .ant-input-prefix {
  right: 0 !important;
  width: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-top: 0 !important;
  height: 42px;
  margin: 0;
  padding-left: 28px !important;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item input {
  padding-right: 28px !important;
  padding-left: 0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper {
  border-bottom: 1px solid #dadce0 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon {
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 14px;
  margin-top: 2px;
  bottom: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon svg {
  display: none;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .anticon:after {
  content: url("../../resources/images/website/icon/gender.svg");
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-form-explain {
  position: absolute;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  width: 100%;
  text-align: left;
  padding-left: 45px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group {
  text-align: right;
  padding-right: 45px;
  padding-left: 0;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  top: 3.2px;
  left: 3.2px;
}

body.rtl .request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio + span {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430 !important;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper {
  font-size: 15px;
  font-weight: normal;
  line-height: 0.8;
  color: #242430;
  display: inline-flex;
  align-items: center;
  margin: 0 30px;
  margin-bottom: 4px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder .ant-form-item.gender-wrapper .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border: solid 0.8px #4e4d4d;
  width: 16px;
  height: 16px;
}

.request-card-wrapper > .content .charging-card-form-wrapper .address-info-holder + div .gradient-light-fill {
  min-height: 40px !important;
  font-size: 13px !important;
}

.user-profile-wrapper {
  background: #f5f5f5;
  height: calc(100% - 70px);
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper {
    height: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.user-profile-wrapper .ant-tabs {
  background: #f5f5f5;
  padding: 32px 6%;
  height: 100%;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs {
    height: unset;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
  margin-right: 15px;
  background: white;
  border: none;
  width: 260px;
  max-width: 260px;
  padding: 22px 0;
}

body.rtl .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
  margin-left: 15px !important;
  margin-right: 0 !important;
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  width: 100%;
}

.user-profile-wrapper .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  width: 4px !important;
}

@media only screen and (max-width: 639px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
    width: 170px !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-left-bar {
    margin-bottom: 20px;
    height: fit-content;
    width: 100% !important;
    max-width: unset !important;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-tab {
  text-align: center !important;
  margin: 0 0 10px 0 !important;
  padding: 11px 24px !important;
  color: #4b4b4b !important;
  font-size: 19px;
  line-height: 0.68;
  font-family: Pridi;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-tab {
    padding: 21px 24px !important;
    font-size: 16px;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-top-bar {
  background: white;
  border-bottom: none;
}

.user-profile-wrapper .ant-tabs .ant-tabs-content {
  background: white;
  border: none;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-content {
    width: 90%;
    margin: auto !important;
    display: block !important;
    height: unset !important;
    overflow-y: unset !important;
  }
}

.user-profile-wrapper .ant-tabs .ant-tabs-nav {
  width: 100%;
}

.user-profile-wrapper .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
  background-color: rgba(92, 174, 48, 0.16);
  color: black !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
    background-color: white !important;
  }
}

@media only screen and (max-width: 767px) {
  .user-profile-wrapper .ant-tabs {
    padding: 32px 5px !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .ant-tabs {
    padding: 10px 0 !important;
  }
}

.user-profile-wrapper .personal-info-holder .border-bottom {
  border-bottom: 1px solid #dadce0;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #242430 !important;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 15px;
  height: 15px;
  border-color: #008539;
}

.user-profile-wrapper .personal-info-holder .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #008539;
}

.user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
}

body.rtl .user-profile-wrapper .personal-info-holder .ant-radio-inner::after {
  right: 2px;
  left: auto;
}

.user-profile-wrapper .personal-info-holder .form-holder {
  position: relative;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .personal-info-holder .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/user.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/user1.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/email.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/phone.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  content: url("../../resources/images/website/icon/gender.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(6)::after {
  content: url("../../resources/images/website/icon/address.svg");
  position: absolute;
  top: 25px;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(4)::after {
  display: none;
}

.user-profile-wrapper .personal-info-holder .form-holder .ant-form-item:nth-child(5)::after {
  top: 17px;
}

@media only screen and (max-width: 399px) {
  .user-profile-wrapper .personal-info-holder .resp-m-0 {
    margin: 0 !important;
  }
  .user-profile-wrapper .personal-info-holder div:nth-child(2) {
    padding: 15px;
  }
}

.user-profile-wrapper ::-webkit-scrollbar-thumb {
  background: transparent !important;
}

.user-profile-wrapper .header {
  position: sticky;
  width: 100%;
  background: white;
  top: 0;
  z-index: 9;
}

.user-profile-wrapper .car-info-tab {
  height: 100%;
}

.user-profile-wrapper .carInfo-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(1) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(2) {
  flex: 1 1 auto;
}

.user-profile-wrapper .carInfo-wrapper > div:nth-child(3) {
  flex: 0 0 auto;
}

.user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 30px;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-select-selection__rendered {
  margin-left: 0 !important;
  margin-right: 30px;
}

.user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .ant-input {
  padding-left: 0 !important;
  padding-right: 30px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  position: relative;
  border-right: solid 0.5px #adadad;
}

body.rtl .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
  border-right: none;
  border-left: solid 0.5px #adadad;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(1)::after {
  content: url("../../resources/images/website/icon/car.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  content: url("../../resources/images/website/icon/cartype.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3)::after {
  content: url("../../resources/images/website/icon/battery.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(4)::after {
  content: url("../../resources/images/website/icon/license.svg");
  position: absolute;
  top: 23px;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(2)::after {
  top: 27px !important;
}

.user-profile-wrapper .carInfo-wrapper .form-holder .col-holder .ant-form-item:nth-child(3) {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .form-holder .col-holder {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 20px;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder {
  border-radius: 4px;
  border: solid 0.5px #adadad;
  margin: 16px 0;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
  color: #5d71aa;
  margin-top: -5px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .dark-blue {
    font-size: 14px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .box-holder .ant-form-item {
  margin-bottom: 5px !important;
}

.user-profile-wrapper .carInfo-wrapper .box-holder .delete-box-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #d83025;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .box-holder .resp-handel {
    padding-left: 0 !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
  border-radius: 0 !important;
  border: dashed 0.5px #707070 !important;
  width: 180px !important;
}

@media only screen and (max-width: 1247px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 104px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 200px !important;
  }
}

@media only screen and (max-width: 767px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 140px !important;
  }
}

@media only screen and (max-width: 399px) {
  .user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card {
    width: 115px !important;
  }
}

.user-profile-wrapper .carInfo-wrapper .license-holder .ant-upload.ant-upload-select-picture-card img {
  border-radius: 0 !important;
}

.user-profile-wrapper .border-shadow {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .request-info .ant-table-wrapper {
    overflow: auto;
  }
  .user-profile-wrapper .request-info .ant-table-wrapper .ant-spin-nested-loading {
    width: max-content;
    min-width: 991px;
    overflow: auto;
  }
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  text-align: center;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td {
  border-left: none !important;
}

.user-profile-wrapper .request-info .inprogress-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px rgba(112, 112, 112, 0.17);
  margin: auto;
}

.user-profile-wrapper .request-info .ant-table-thead > tr > th {
  background: white !important;
  padding: 4px 0 11px;
}

.user-profile-wrapper .request-info .ant-table table {
  border: none !important;
  overflow: hidden;
}

.user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type,
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-right: none !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:last-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
  border-left: none !important;
  border-right: 1px solid #e8e8e8 !important;
}

body.rtl .user-profile-wrapper .request-info .ant-table-bordered .ant-table-thead > tr > th:first-of-type, body.rtl
.user-profile-wrapper .request-info .ant-table-bordered .ant-table-tbody > tr > td:first-of-type {
  border-right: none !important;
}

.user-profile-wrapper .request-info .ant-table-tbody > tr:last-of-type > td {
  border-bottom: none !important;
}

.user-profile-wrapper .request-info .ant-table-content {
  border-radius: 4px;
  border: solid 0.5px #e8e8e8;
  padding: 12px;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  content: url("../../resources/images/website/icon/clock.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  content: url("../../resources/images/website/icon/creditCard.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  content: url("../../resources/images/website/icon/location.svg");
  position: absolute;
  top: 0px;
  left: 25px;
}

body.rtl .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  right: 25px;
  left: unset;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
  top: -4px;
  left: 60px;
}

@media only screen and (max-width: 1023px) {
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) > div::before {
    top: 0px;
    left: 0px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) > div::before {
    top: 0px;
    left: 5px;
  }
  .user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) > div::before {
    top: -4px;
    left: 18px;
  }
}

.user-profile-wrapper .request-info .ant-table-thead tr th > div {
  position: relative;
  font-size: 19px;
  color: #716f6f;
  line-height: 0.68;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(1) {
  width: 30%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(2) {
  width: 20%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(3) {
  width: 40%;
}

.user-profile-wrapper .request-info .ant-table-thead tr th:nth-child(4) {
  width: 10%;
}

.user-profile-wrapper .request-info .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.user-profile-wrapper .request-info .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #f5f5f5;
}

.user-profile-wrapper .request-info tr td:not(:nth-child(3)) {
  font-size: 16px;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.need-action {
  color: #008539;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.new {
  color: #1b6bba;
}

.user-profile-wrapper .request-info tr td > div.card-status-holder.in-progress {
  color: #98681b;
}

.user-profile-wrapper .request-info.no-requests .ant-table-wrapper {
  display: none;
}

.user-profile-wrapper .request-info.no-requests .empty-requests {
  display: block !important;
}

.user-profile-wrapper .request-info.no-requests .empty-requests h3 {
  color: #a3a3a3;
  font-family: Pridi;
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile-wrapper .request-info.no-requests .empty-requests div:nth-child(2) {
    padding: 13px !important;
  }
}

@media only screen and (max-width: 991px) {
  .user-profile-wrapper .request-info .ant-table-pagination.ant-pagination {
    float: left;
    align-self: unset !important;
  }
}

.user-profile-wrapper .request-info .request-info-row-action {
  cursor: pointer;
}

.user-profile-wrapper .request-info .request-info-row-action span {
  height: 25px;
  width: 25px;
  border: 2px solid #70707021;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.user-profile-wrapper .request-info .ant-spin-container {
  display: flex;
  flex-direction: column;
}

.user-profile-wrapper .request-info .ant-spin-container .ant-table-pagination.ant-pagination {
  align-self: center;
}

.cursor {
  cursor: pointer;
}

.cancel-modal p {
  color: #383737;
  line-height: 1.2;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
}

.cancel-modal .anticon {
  color: #d83025;
}

.cancel-modal .ant-modal-body {
  padding: 60px 24px 24px !important;
}

.changePassModal .ant-modal-header {
  border: none;
  text-align: center;
  padding-top: 30px;
}

.changePassModal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #383737;
}

.changePassModal .ant-modal-body {
  padding-top: 0 !important;
}

.changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  right: 35px !important;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper .ant-input-suffix {
  left: 35px !important;
  right: unset !important;
  top: 35px;
}

body.rtl .changePassModal .ant-input-password.ant-input-affix-wrapper + .ant-form-item-children-icon .anticon-close-circle svg {
  top: 18px;
}

.changePassModal .linear-green {
  background: linear-gradient(271deg, #86d958 100%, #008539 0%) !important;
}

.changePassModal .ant-form-item {
  margin-bottom: 5px !important;
}

.changePassModal .ant-modal-close-x {
  color: #d83025;
}

.changePassModal .form-holder {
  position: relative;
}

.changePassModal .form-holder .ant-input {
  padding-left: 30px !important;
}

body.rtl .changePassModal .form-holder .ant-input {
  padding-right: 30px !important;
  padding-left: 0 !important;
}

.changePassModal .form-holder .ant-form-item::after {
  content: url("../../resources/images/website/icon/password.svg");
  position: absolute;
  top: 25px;
}

.fixed-footer {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

@media only screen and (max-width: 575px) {
  .fixed-footer {
    justify-content: center !important;
  }
}

.ant-table-fixed-left {
  left: 12px !important;
  top: 12px !important;
}

.rtl .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  padding-right: 0 !important;
}

.classMessage_holder {
  margin: auto;
  margin-top: 18px;
  margin-bottom: 15px;
  border-radius: 12px;
  border: solid 1px #dddbdb;
  background-color: #ffffff;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .classMessage_holder {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .classMessage_holder .chat_holder {
    height: calc(100vh - 330px) !important;
  }
}

.classMessage_holder .ant-empty {
  position: absolute;
  top: 106px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.classMessage_holder .parents-list-wrapper header {
  height: 46px;
  max-height: 46px;
  padding: 8px 12px 8px 10px;
  border-bottom: solid 1px #dddbdb;
  border-right: solid 1px #dddbdb;
}

body.rtl .classMessage_holder .parents-list-wrapper header {
  border-right: 0;
  border-left: solid 1px #dddbdb;
  margin-left: 0;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search {
  height: 30px;
  width: 100% !important;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input {
  height: 30px;
  padding-top: 0 !important;
  border-radius: 0;
  border: 0 !important;
  outline: 0 !important;
  background-color: #f6f7fc !important;
  padding-left: 30px !important;
  font-size: 14px;
  border-radius: 4px;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input:-moz-placeholder {
  font-size: 12px;
  color: #9d9d9d;
  padding: 0;
  padding-bottom: 3px !important;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input::-moz-placeholder {
  font-size: 12px;
  color: #9d9d9d;
  padding: 0;
  padding-bottom: 3px !important;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input:-ms-input-placeholder {
  font-size: 12px;
  color: #9d9d9d;
  padding: 0;
  padding-bottom: 3px !important;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input::-webkit-input-placeholder {
  font-size: 12px;
  color: #9d9d9d;
  padding: 0;
  padding-bottom: 3px !important;
}

body.rtl .classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input {
  padding-right: 30px !important;
  padding-left: 10px !important;
}

.classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input-suffix {
  left: 8px;
  right: auto;
  z-index: 1;
}

body.rtl .classMessage_holder .parents-list-wrapper header .ant-input-search .ant-input-suffix {
  right: 8px;
  left: auto;
}

.classMessage_holder .parents-list-wrapper header > div img {
  margin-left: 8px;
  cursor: pointer;
}

body.rtl .classMessage_holder .parents-list-wrapper header > div img {
  margin-right: 8px;
  margin-left: 0;
}

.classMessage_holder .parents-list-wrapper .parents-list > div {
  height: 77.5px;
  padding: 13px 7px;
  padding-left: 15px;
  border-bottom: solid 1px #dddbdb;
  cursor: pointer;
}

body.rtl .classMessage_holder .parents-list-wrapper .parents-list > div {
  padding-left: 7px;
  padding-right: 15px;
  margin-left: 0;
}

.classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type {
  width: 100%;
  overflow: hidden;
}

.classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type h6 {
  color: #4e4e4e;
  font-size: 12px;
  font-weight: 500;
}

.classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type time {
  color: #5f5d5d;
  font-size: 10px;
  font-weight: 500;
}

.classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type .parent-name {
  font-size: 11px;
  font-weight: 500;
  color: #000000;
  line-height: 1.4;
}

.classMessage_holder .parents-list-wrapper .parents-list > div > div:last-of-type .msg-hint {
  font-size: 12px;
  color: #aaaaaa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

.classMessage_holder .parents-list-wrapper .unred {
  background: rgba(0, 255, 255, 0.1);
}

.classMessage_holder .msgs-list-wrapper {
  border-left: 1px solid #dddbdb;
}

body.rtl .classMessage_holder .msgs-list-wrapper {
  border-left: none;
  border-right: 1px solid #dddbdb;
}

.classMessage_holder .msgs-list-wrapper > header {
  display: flex !important;
  align-items: center;
  justify-content: unset !important;
  height: 46px;
  max-height: 46px;
  padding: 8px 12px 8px 10px;
  border-bottom: solid 1px #dddbdb;
  color: #4e4e4e;
  font-size: 14px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.classMessage_holder .title {
  font-size: 17px;
  font-weight: bold;
  color: #4e4e4e;
  min-height: 40px;
  align-items: center;
  display: flex;
  padding-top: 0;
  border-bottom: 1px solid #dddbdb;
}

.classMessage_holder .title_B {
  font-size: 17px;
  font-weight: bold;
  color: #4e4e4e;
  min-height: 40px;
  align-items: center;
  display: flex;
  padding-top: 0;
}

.classMessage_holder .font_16 {
  font-size: 16px !important;
}

.classMessage_holder .black {
  color: black !important;
}

.classMessage_holder .scroll {
  overflow: auto;
  max-height: calc(100vh - 230px);
  /* width */
  /* Handle */
}

.classMessage_holder .scroll::-webkit-scrollbar {
  width: 2px !important;
}

.classMessage_holder .scroll::-webkit-scrollbar-thumb {
  background: #0b85b2 !important;
}

.classMessage_holder .flex_center {
  display: flex;
  align-items: center;
}

.classMessage_holder .flex {
  display: flex;
}

.classMessage_holder .w-30 {
  width: 30px;
}

.classMessage_holder .border_r {
  border-right: 1px solid #dddbdb;
}

body.rtl .classMessage_holder .border_r {
  border-right: none !important;
  border-left: 1px solid #dddbdb;
}

.classMessage_holder .font_14 {
  font-size: 14px;
  font-weight: bold;
  color: #4e4e4e;
}

.classMessage_holder .small_t {
  font-size: 13px;
  font-weight: bold;
  color: #4e4e4e;
  line-height: 1.77;
}

.classMessage_holder .small {
  font-size: 12px;
  font-weight: 800;
  color: #000000;
  line-height: 1.77;
}

.classMessage_holder .py-5 {
  padding-left: 5px;
  padding-right: 3px;
}

body.rtl .classMessage_holder .py-5 {
  padding-left: 3px;
  padding-right: 5px;
}

.classMessage_holder .fixed_w70 {
  width: 70px;
}

.classMessage_holder .add_message {
  border-right: 1px solid #dddbdb;
}

body.rtl .classMessage_holder .add_message {
  border-right: none;
  border-left: 1px solid #dddbdb;
}

.classMessage_holder .chat_holder {
  overflow: auto;
  height: calc(100vh - 413px);
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 16px 0 0 0;
  /* Handle */
}

.classMessage_holder .chat_holder .message-container {
  display: flex;
  margin: 27px 0;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-all;
}

.classMessage_holder .chat_holder .message-container .img-time-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.classMessage_holder .chat_holder .message-container .img-time-holder img {
  margin: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.classMessage_holder .chat_holder .message-container .img-time-holder time {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: #5f5d5d;
}

.classMessage_holder .chat_holder .message-container .msg-content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-size: 14px;
  line-height: 1.57;
  color: #4e4e4e;
  background: #d4eeff;
  padding: 13px;
  border-radius: 6px;
  max-width: calc(100% - 104px);
}

.classMessage_holder .chat_holder .message-container.sent_message {
  flex-direction: row-reverse;
  text-align: right;
}

.classMessage_holder .chat_holder .message-container.sent_message .media-grid {
  flex-direction: row-reverse;
}

.classMessage_holder .chat_holder .message-container.sent_message .msg-content {
  color: #fff;
  background: #00adee;
  position: relative;
  margin-right: 15px;
}

body.rtl .classMessage_holder .chat_holder .message-container.sent_message .msg-content {
  margin-right: 0;
  margin-left: 15px;
}

.classMessage_holder .chat_holder .message-container.sent_message .msg-content::after {
  content: '';
  position: absolute;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  height: 19px;
  right: -15px;
  top: 14px;
  width: 15px;
  background: #00adee;
}

body.rtl .classMessage_holder .chat_holder .message-container.sent_message .msg-content::after {
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  right: auto;
  left: -15px;
}

.classMessage_holder .chat_holder .message-container .media-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-image {
  width: 243px;
  height: 162px;
  margin: 9px 12px 45px 27px;
  object-fit: cover;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-image.attachment-image--audio {
  box-shadow: none;
  height: 58px;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 261px;
  height: 62px;
  border-radius: 6px;
  border: solid 0.5px #d0d0d0;
  cursor: pointer;
  margin: 1em;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-file p {
  margin-inline-start: 10px;
  font-weight: bold;
  font-size: 12px;
  color: #434343;
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-file h6 {
  display: block;
  font-size: 8px;
  font-weight: normal;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-file .attachment-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 62px;
  border-radius: 6px;
  background-color: #94cbdc;
}

.classMessage_holder .chat_holder .message-container .media-grid .attachment-file .attachment-icon img {
  width: 34px;
  height: 40px;
}

.classMessage_holder .chat_holder::-webkit-scrollbar {
  width: 2px !important;
}

.classMessage_holder .chat_holder::-webkit-scrollbar-thumb {
  background: #0b85b2 !important;
}

.classMessage_holder .chat_holder .lottie-spinner {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 300px);
  transform: scale(0.8);
}

@media only screen and (max-width: 767px) {
  .classMessage_holder .chat_holder .lottie-spinner {
    left: calc(50% - 400px);
  }
}

@media only screen and (max-width: 639px) {
  .classMessage_holder .chat_holder .lottie-spinner {
    left: calc(50% - 200px);
  }
}

@media only screen and (max-width: 575px) {
  .classMessage_holder .chat_holder {
    padding: 0 10px;
  }
}

.classMessage_holder .chat_holder > div {
  width: 100%;
  word-break: break-word;
  position: relative;
}

.classMessage_holder .chat_holder > div .content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 29px 29px 9px 14px;
}

body.rtl .classMessage_holder .chat_holder > div .content {
  padding: 29px 14px 9px 29px;
}

@media only screen and (max-width: 575px) {
  .classMessage_holder .chat_holder > div .content {
    padding: 9px 0;
  }
  .classMessage_holder .chat_holder > div .content h6 {
    font-size: 13px !important;
  }
  .classMessage_holder .chat_holder > div .content .msg-content {
    font-size: 13px !important;
    line-height: 1.4 !important;
  }
}

.classMessage_holder .chat_holder > div .content > div {
  display: flex;
}

.classMessage_holder .chat_holder > div .content > div .user-chat-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
  margin-right: 12px;
}

body.rtl .classMessage_holder .chat_holder > div .content > div .user-chat-img {
  margin-left: 12px;
  margin-right: 0;
}

.classMessage_holder .chat_holder > div .content > div > div header {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 360px) {
  .classMessage_holder .chat_holder > div .content > div > div header {
    display: block;
  }
}

.classMessage_holder .chat_holder > div .content > div > div header h6 {
  font-size: 14px;
  font-weight: 500;
}

.classMessage_holder .chat_holder > div .content > div > div header h6:first-of-type {
  color: #00adee;
  padding-right: 6px;
}

body.rtl .classMessage_holder .chat_holder > div .content > div > div header h6:first-of-type {
  padding-right: 0;
  padding-left: 6px;
}

.classMessage_holder .chat_holder > div .content > div > div header h6:last-of-type {
  color: #979898;
}

.classMessage_holder .chat_holder > div .content > div > div .msg-content {
  color: #4e4e4e;
  font-size: 14px;
  line-height: 1.21;
  font-weight: normal;
  padding-top: 10px;
  font-weight: 500;
}

.classMessage_holder .chat_holder > div .content time {
  font-size: 10px;
  font-weight: 500;
  color: #5f5d5d;
  padding-top: 3px;
  white-space: nowrap;
}

.classMessage_holder .chat_holder > div.resived_message {
  direction: rtl;
}

.classMessage_holder .chat_holder > div.resived_message .user-chat-img {
  margin-left: 12px;
}

body.rtl .classMessage_holder .chat_holder > div.resived_message .user-chat-img {
  margin-left: 0;
  margin-right: 12px;
}

.classMessage_holder .chat_holder > div.resived_message .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  font-size: 30px;
}

.classMessage_holder .chat_holder > div.resived_message .msg-content {
  margin-left: 15px;
  position: relative;
}

body.rtl .classMessage_holder .chat_holder > div.resived_message .msg-content {
  margin-left: 0;
  margin-right: 15px;
}

.classMessage_holder .chat_holder > div.resived_message .msg-content::after {
  content: '';
  position: absolute;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  height: 19px;
  left: -15px;
  top: 14px;
  width: 15px;
  background: #00adee;
}

body.rtl .classMessage_holder .chat_holder > div.resived_message .msg-content::after {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  right: -15px;
  left: auto;
}

.classMessage_holder .chat_holder > div.sent_message .user-name {
  color: #0f0f0f !important;
}

.classMessage_holder .chat_holder > div.sent_message .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  font-size: 30px;
}

.classMessage_holder .chat_holder .send_message {
  border-radius: 29px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #dddbdb;
  padding: 20px 35px;
  width: 70%;
  margin: auto;
  margin-top: 100px;
  text-align: center;
}

.classMessage_holder .chat_holder .empty-message {
  width: 50%;
  border-radius: 12px;
  border: solid 2px #dddbdb;
}

.classMessage_holder .chat_holder .empty-message > div {
  color: #4e4e4e;
  font-size: 15px;
  line-height: 1.2;
}

.classMessage_holder .chat_holder .empty-message > div:first-of-type {
  font-weight: 500;
}

.classMessage_holder .chat_holder .empty-message > div:first-of-type img {
  width: 18px;
}

.classMessage_holder .chat_holder .empty-message > div:last-of-type {
  font-weight: 900;
}

.classMessage_holder .chat_holder .group-msg-divider {
  margin: 0;
}

.classMessage_holder .chat_holder .group-msg-divider .ant-divider-inner-text {
  padding: 0 8px;
  color: #8d8d8d;
  font-size: 12px;
  font-weight: 300;
}

.classMessage_holder .d-none {
  display: none;
}

@media only screen and (max-width: 767px) {
  .classMessage_holder .msg-contact-info {
    font-size: 15px;
  }
  .classMessage_holder .msg-contact-info .title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .classMessage_holder .mesg-tilte {
    order: -1;
  }
}

.classMessage_holder grammarly-extension {
  display: none;
}

.classMessage_holder .massage_box {
  padding: 14px 20px;
  border-top: solid 1px #dddbdb;
  background-color: #ffffff;
  position: relative;
  max-height: 167px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.classMessage_holder .massage_box textarea {
  padding-top: 0 !important;
  border: none !important;
  margin-top: 0 !important;
  height: 31px;
  min-height: 31px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0 gray;
  line-height: 2.2;
  resize: none;
}

.classMessage_holder .massage_box textarea:-moz-placeholder {
  font-size: 14px;
  color: #a5a5a5;
  line-height: 30px;
}

.classMessage_holder .massage_box textarea::-moz-placeholder {
  font-size: 14px;
  color: #a5a5a5;
  line-height: 30px;
}

.classMessage_holder .massage_box textarea:-ms-input-placeholder {
  font-size: 14px;
  color: #a5a5a5;
  line-height: 30px;
}

.classMessage_holder .massage_box textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #a5a5a5;
  line-height: 30px;
}

.classMessage_holder .massage_box textarea:focus {
  border: none !important;
  outline: none !important;
}

.classMessage_holder .massage_box .attachment-icon {
  cursor: pointer;
  position: absolute;
  right: 16%;
  top: 36%;
}

body.rtl .classMessage_holder .massage_box .attachment-icon {
  right: 81%;
}

.classMessage_holder .massage_box .uploaded__attachment {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox {
  flex-shrink: 0;
  width: 100px;
  height: 85px;
  border-radius: 5px;
  overflow: hidden;
  border: solid 0.5px #d0d0d0;
  margin-right: 10px;
  position: relative;
  margin-bottom: 10px;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox .upload-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox .deleteIcon {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  cursor: pointer;
}

body.rtl .classMessage_holder .massage_box .uploaded__attachment .attachBox .deleteIcon {
  left: 0;
  right: unset;
}

body.rtl .classMessage_holder .massage_box .uploaded__attachment .attachBox {
  margin-right: 0;
  margin-left: 10px;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox img {
  width: 100%;
  height: 100%;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox.attachBox__video .deleteIcon {
  color: #fff;
  z-index: 1;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox.attachBox__files {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox.attachBox__files img {
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox.attachBox__files p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox .video-player-player {
  padding: 0 !important;
  height: 100px !important;
}

.classMessage_holder .massage_box .uploaded__attachment .attachBox .video-player-player .video-react-big-play-button {
  width: 50px;
  left: 25%;
  top: 41%;
  margin-left: 0 !important;
}

.classMessage_holder .massage_box .massage_box--input {
  display: flex;
  align-items: flex-end;
}

.classMessage_holder .massage_box .massage_box--input .attachment-holder .attachment-holder__list,
.classMessage_holder .massage_box .massage_box--input .attachment-holder > button {
  display: none;
}

.classMessage_holder .massage_box button[disabled] {
  background-color: #dadada;
  border: 0;
}

.classMessage_holder .flex_col {
  display: flex;
  flex-direction: column;
}

.classMessage_holder .border_bottom {
  border-bottom: solid 1px #dddbdb;
  width: 100%;
  margin-left: -6px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.classMessage_holder .pr-15 {
  padding-right: 15px;
}

body.rtl .classMessage_holder .pr-15 {
  padding-right: 0;
  padding-left: 15px;
}

.classMessage_holder .msg-active {
  position: relative;
  background-color: #efecec;
  border-bottom: 3px solid #3fc1f2 !important;
  margin-left: 0 !important;
  padding-left: 25px !important;
}

body.rtl .classMessage_holder .msg-active {
  margin-right: 0 !important;
  padding-left: 10px !important;
  padding-right: 25px !important;
}

.classMessage_holder .msg-active h6 {
  color: #000000 !important;
}

.classMessage_holder .msg-active img {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.classMessage_holder .back-msg-btn {
  display: none;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  width: 33px;
  height: 33px;
  align-items: center;
  justify-content: center;
}

body.rtl .classMessage_holder .back-msg-btn img {
  transform: rotate(180deg);
}

@media only screen and (max-width: 767px) {
  .classMessage_holder .back-msg-btn {
    display: flex;
  }
  .classMessage_holder .back-msg-btn + img {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .classMessage_holder.parents-list-active .msgs-list-wrapper {
    opacity: 0;
    height: 0;
    width: 0;
  }
}

@media only screen and (max-width: 767px) {
  .classMessage_holder.msgs-list-active .parents-list-wrapper {
    transition: all 0.6s ease;
    opacity: 0;
    height: 0;
    width: 0;
  }
}

.error-style {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
}

body {
  background-color: #f5f5f3;
}

.empty-message {
  border-radius: 29px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #dddbdb;
  text-align: center;
  width: 70%;
  margin: auto;
  padding: 20px;
}

.empty-message .font-24 {
  font-size: 24px;
  font-weight: 500;
  color: #4e4e4e;
}

@media only screen and (max-width: 479px) {
  .empty-message .font-24 {
    font-size: 14px !important;
  }
}

.empty-message .bold {
  font-weight: 900;
}

.no-messages {
  width: 90%;
  margin: auto;
  text-align: center;
}

.no-messages img {
  width: 45%;
}

.no-messages .empty-msg-title {
  color: #00adee;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  width: 65%;
  margin: auto;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.image-grid > div {
  width: 33.333333333%;
  border-radius: 12px 0 0 0;
  position: relative;
}

.image-grid > div:first-of-type img,
.image-grid > div:first-of-type video {
  border-radius: 12px 0 0 0;
}

.image-grid > div:nth-of-type(3) img,
.image-grid > div:nth-of-type(3) video {
  border-radius: 0 12px 0 0;
}

.image-grid > div:only-child {
  width: 300px;
  max-width: 300px;
  min-width: 134px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  height: 150px !important;
  padding: 0 !important;
  overflow: hidden;
}

.image-grid > div:only-child img,
.image-grid > div:only-child video {
  width: 300px;
  max-width: 300px;
  min-width: 134px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  object-fit: cover;
  height: 150px !important;
  padding: 0 !important;
}

.image-grid > div img,
.image-grid > div video {
  width: 100%;
}

.h-60 {
  height: 60px;
}

.p-1 {
  padding: 0 1px 1px 1px;
}

.isVideo {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.user-header-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

body.rtl .user-header-img {
  margin-right: 0;
  margin-left: 10px;
}

.img-time-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-message-modal .ant-modal-header {
  border-bottom: solid 1px #00adee !important;
}

.new-message-modal .ant-modal-header p {
  color: #00678d;
  font-size: 18px;
  font-weight: bold;
}

.new-message-modal .ant-modal-header .new-message-title {
  display: flex;
  align-items: center;
}

.new-message-modal .ant-modal-header .new-message-title img {
  margin-right: 10px;
}

.new-message-modal .ant-modal-content {
  padding: 12px;
}

.new-message-modal .ant-modal-footer {
  border: none !important;
}

.new-message-modal .new-message-search {
  padding: 5px !important;
  height: unset !important;
}

.new-message-modal .new-message-search .ant-input {
  border: solid 1px #00adee;
  padding: 0 30px !important;
}

.new-message-modal .new-message-search .ant-input-suffix {
  left: 12px;
}

.new-message-modal .list-holder {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.new-message-modal .list-holder:first-of-type {
  margin-top: 20px;
}

.new-message-modal .list-holder-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.new-message-modal .list-holder-name {
  color: #00678d;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}

body.rtl .new-message-modal .list-holder-name {
  margin-left: 0;
  margin-right: 10px;
}

.new-message-modal .list-wrapper {
  max-height: 280px;
  overflow-y: scroll;
  padding: 0 5px;
}

body.rtl .react-viewer-canvas, body.rtl
.react-viewer-close {
  direction: ltr !important;
}

.msg-text-area {
  padding-top: 10px;
}

.message-container {
  flex-wrap: wrap;
}

.message-container .media-grid {
  width: 100%;
}

.unseen__msg {
  background-color: #efecec;
}

.unseen__msg .unseen__msg--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unseen__msg .unseen__msg--container .unseen__msg--new {
  font-size: 9px;
  font-weight: 600;
  color: #00678d;
}

.message-container.resived_message .msg-content {
  margin-left: 15px;
  position: relative;
}

body.rtl .message-container.resived_message .msg-content {
  margin-left: 0;
  margin-right: 15px;
}

.message-container.resived_message .msg-content::after {
  content: '';
  position: absolute;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  height: 19px;
  left: -14px;
  top: 14px;
  width: 15px;
  background: #d4eeff;
}

body.rtl .message-container.resived_message .msg-content::after {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  right: -14px;
  left: auto;
}

.classRoom_holder {
  padding: 40px;
  background-color: #f5f5f3;
  margin-top: 100px;
  height: calc(100vh - 122px);
  overflow: auto;
}

@media only screen and (max-width: 575px) {
  .classRoom_holder {
    padding: 20px;
  }
}

.classRoom_holder .installments-list {
  display: grid;
  grid-column-gap: 37px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
}

@media only screen and (max-width: 1247px) {
  .classRoom_holder .installments-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1023px) {
  .classRoom_holder .installments-list {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 991px) {
  .classRoom_holder .installments-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .classRoom_holder .installments-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .classRoom_holder .installments-list {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}

.classRoom_holder .installments-list .kid_card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 12px;
}

.classRoom_holder .installments-list .kid_card .kid_img {
  align-self: center;
  border-radius: 50%;
  border: 6px solid #f5f5f3;
  margin-bottom: -35px;
  width: 75px;
  height: 75px;
  min-height: 75px;
  z-index: 1;
  position: inherit;
}

.classRoom_holder .installments-list .kid_card .kid_footer {
  background-color: #FFF;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f5f3;
  padding: 5px;
  min-height: 40px;
}

.classRoom_holder .installments-list .kid_card .kid_footer div {
  text-align: center;
  width: 50%;
}

.classRoom_holder .installments-list .kid_card .kid_footer div img {
  border-radius: 50%;
}

.classRoom_holder .installments-list .kid_card .kid_footer div:first-of-type {
  border-right: 1px solid #f5f5f3;
}

body.rtl .classRoom_holder .installments-list .kid_card .kid_footer div:first-of-type {
  border-right: none !important;
  border-left: 1px solid #f5f5f3;
}

.classRoom_holder .installments-list .kid_card .kid_holder {
  height: 100% !important;
  position: relative;
  background-color: #FFF;
  min-height: 70px;
  border-radius: 12px 12px 0 0;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.classRoom_holder .installments-list .kid_card .kid_holder .page_holder {
  width: 31px;
  height: 31px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #ffffff;
  background-color: #f5f5f3;
  position: absolute;
  top: -15px;
  right: -2px;
  border-radius: 50%;
  color: #a5a5a5;
}

body.rtl .classRoom_holder .installments-list .kid_card .kid_holder .page_holder {
  right: auto;
  left: -2px;
}

.classRoom_holder .installments-list .kid_card .kid_holder .active {
  background-color: #93d553;
  color: #FFF;
}

.classRoom_holder .installments-list .kid_card .kid_holder p {
  text-align: center;
  padding: 40px 5px 5px;
  font-weight: 500;
  color: #077aa3;
  word-break: break-all;
}

.classRoom_holder .installments-list .kid_card .bottom-border {
  border-radius: 12px !important;
}

.classRoom_holder .installments-list > .kid_card:first-of-type .kid_holder {
  border-radius: 12px;
}

.classRoom_holder .installments-list > .ant-checkbox-wrapper {
  margin: 0;
}

.classRoom_holder .ant-checkbox {
  top: 1.91em;
}

.classRoom_holder .header {
  text-align: center;
  margin: 0 0 30px 0;
}

.classRoom_holder .header h3 {
  display: inline-block;
  color: #077aa3;
  text-decoration: underline;
}

.classRoom_holder .header .ant-select-selection__rendered {
  line-height: 25px !important;
}

.classRoom_holder .header .ant-select-arrow {
  right: -11px !important;
}

body.rtl .classRoom_holder .header .ant-select-arrow {
  right: unset !important;
  left: 0px !important;
}

.classRoom_holder .ant-checkbox-wrapper {
  height: 100%;
}

.classRoom_holder .ant-checkbox-wrapper:first-of-type .kid_holder {
  border-radius: 12px;
}

.classRoom_holder .ant-checkbox-wrapper .kid_card {
  height: calc(100% - 60px);
}

.classRoom_holder .text-drop-list {
  min-width: 174px !important;
  max-width: 174px !important;
}

.add_page .save_button {
  height: 40px;
  padding: 9px 34px 12px 32px;
  border-radius: 4px;
  background-color: #00678d;
  color: #fff;
}

@media only screen and (max-width: 479px) {
  .add_page .save_button {
    margin: 0 2px;
    padding: 9px 10px 12px;
    width: 72px;
  }
}

.add_page .cancel_button {
  height: 40px;
  margin: 0 22px;
  padding: 9px 26px 12px;
  border-radius: 4px;
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #ff0000;
}

@media only screen and (max-width: 479px) {
  .add_page .cancel_button {
    margin: 0 2px;
    padding: 9px 10px 12px;
    width: 72px;
  }
}

.add_page .time-input-holder {
  height: 35px;
  font-size: 15px;
  position: relative;
}

.add_page .ant-modal-title {
  font-size: 18px !important;
}

.add_page .ant-modal-body {
  padding: 24px 0 0 0;
}

.add_page .ant-tabs {
  min-height: 250px;
}

.add_page .ant-tabs-nav-wrap {
  text-align: center;
}

.add_page .ant-tabs-nav-animated {
  border-radius: 3px;
  overflow: hidden;
}

.add_page .add_page_counter {
  display: inline-block;
  text-align: center;
  width: 125px;
  height: 84px;
  border-radius: 4px;
  border: solid 1px #dddbdb;
  background-color: #fafafa;
  padding: 10px;
  padding: 10px;
  margin-right: 20px;
  position: relative;
  margin-bottom: 15px;
  transition: all 0.5s;
}

.add_page .add_page_counter img {
  max-height: 43px;
}

.add_page .add_page_counter p {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 639px) {
  .add_page .add_page_counter p {
    font-size: 11px;
  }
}

.add_page .add_page_counter .page_holder {
  width: 31px;
  height: 31px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 3px #ffffff;
  background-color: #f5f5f3;
  position: absolute;
  top: -15px;
  right: -2px;
  border-radius: 50%;
  color: #a5a5a5;
  line-height: 26px;
}

body.rtl .add_page .add_page_counter .page_holder {
  left: -2px;
  right: auto;
}

.add_page .add_page_counter .active {
  background-color: #93d553;
  color: #FFF;
}

.add_page .add_page_counter .active_red {
  background-color: #dd8c8c;
  color: #FFF;
}

.add_page .add_page_counter:hover {
  border-color: #077aa3;
  box-shadow: 0 5px 15px 0 rgba(48, 48, 48, 0.2);
}

@media only screen and (max-width: 575px) {
  .add_page .add_page_counter {
    width: 100px;
  }
}

.add_page .diapper-wrapper {
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  margin-right: 20px;
}

.add_page .diapper-wrapper .time-picker-wrapper {
  display: flex;
  flex-direction: column;
  width: 130px;
}

.add_page .diapper-wrapper .time-picker-wrapper .time-holder {
  margin-bottom: 8px;
  position: relative;
}

.add_page .diapper-wrapper .time-picker-wrapper .time-holder .ant-time-picker {
  width: 130px;
}

.add_page .diapper-wrapper .time-picker-wrapper .time-holder .anticon-plus {
  margin-right: 2px;
  margin-left: 2px;
  display: none;
}

.add_page .diapper-wrapper .time-picker-wrapper .time-holder::after {
  content: '\01F550';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

body.rtl .add_page .diapper-wrapper .time-picker-wrapper .time-holder::after {
  right: auto;
  left: 8px;
}

body.rtl .add_page .diapper-wrapper {
  margin-left: 20px;
  margin-right: 0;
}

.add_page .ant-tabs-tabpane {
  padding: 0 50px;
}

body.rtl .add_page .ant-tabs-tabpane {
  padding: 0 50px;
}

@media only screen and (max-width: 575px) {
  .add_page .ant-tabs-tabpane {
    padding: 0 30px !important;
  }
  body.rtl .add_page .ant-tabs-tabpane {
    padding: 0 30px !important;
  }
}

@media only screen and (max-width: 399px) {
  .add_page .ant-tabs-tabpane {
    padding: 0 17px !important;
  }
  body.rtl .add_page .ant-tabs-tabpane {
    padding: 0 17px !important;
  }
}

@media only screen and (max-width: 360px) {
  .add_page .ant-tabs-tabpane {
    padding: 0 1px !important;
  }
  body.rtl .add_page .ant-tabs-tabpane {
    padding: 0 1px !important;
  }
}

.add_page .footer {
  display: flex !important;
  align-items: center;
  border-top: 1px solid #7070702b;
  padding: 0px 25px;
  min-height: 50px;
}

.add_page .footer img {
  margin: 0 10px 0 0;
}

body.rtl .add_page .footer img {
  margin: 0 0px 0 10px;
}

.add_page .footer .ant-col-12 {
  min-height: 50px;
  line-height: 50px;
}

.add_page .footer div:last-of-type {
  border-left: 1px solid #7070702b;
  opacity: 0.5;
  cursor: not-allowed;
}

body.rtl .add_page .footer div:last-of-type {
  border-right: 1px solid #7070702b;
  border-left: none;
}

.add_page.ant-modal-wrap {
  overflow: hidden !important;
}

.add_page .ant-modal-header {
  display: flex;
}

.disabled {
  opacity: 0.5;
}

.attendance {
  border: none !important;
}

.attendance img {
  border-radius: 50%;
}

.attendance_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 4px 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.attendance_footer img {
  border-radius: 50%;
}

.attendance_footer button {
  border-radius: 30px;
  margin: 0 0 0 20px;
}

body.rtl .attendance_footer button {
  margin: 0 20px 0 0px;
}

@media screen and (max-width: 412px) {
  .attendance_footer button {
    margin: 0 0 0 5px;
  }
  body.rtl .attendance_footer button {
    margin: 0 5px 0 0px;
  }
}

@media screen and (max-width: 390px) {
  .attendance_footer button {
    width: 20px !important;
  }
}

@media screen and (max-width: 430px) {
  .attendance_footer .mobile-flex {
    display: flex;
    align-items: center;
  }
  .attendance_footer .mobile-flex button {
    width: 20px;
  }
}

.attendance_footer a {
  color: #00adee;
  font-weight: 500;
  border: none !important;
  background: white;
  margin-right: 10px;
}

body.rtl .attendance_footer a {
  margin-right: 0 !important;
  margin-left: 10px;
}

.attendance_footer div:first-of-type Button:first-of-type {
  margin: 0 20px 0 0;
}

body.rtl .attendance_footer div:first-of-type Button:first-of-type {
  margin: 0 0px 0 20px;
}

body.rtl .class_img_arrow {
  transform: rotate(180deg);
}

.big_holder {
  background-color: #f5f5f3;
  height: 100%;
}

.student-parent-list {
  display: flex;
  flex-direction: column;
  max-height: 280px;
  overflow-y: auto;
}

.student-parent-list .ant-radio-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mesg_send_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mesg_send_holder .parent_msg_holder img {
  width: 38px;
  height: 38px;
  margin: 0 10px 0 0;
}

body.rtl .mesg_send_holder .parent_msg_holder img {
  margin: 0 0 0 10px;
}

@media only screen and (max-width: 767px) {
  .mesg_send_holder .parent_msg_holder span {
    font-size: 10px;
  }
}

.parents-info-list {
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow-y: auto;
  margin-top: 20px;
}

.parents-info-list .parent-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  padding: 5px;
}

.parents-info-list .parent-holder > div {
  display: flex;
  align-items: center;
}

.parents-info-list .parent-holder > div img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.parents-info-list .parent-holder > div p {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.parents-info-list .parent-holder > div span {
  font-size: 14px;
  color: #077aa3;
  font-weight: 500;
  margin: 0 3px;
}

.parent__target--container {
  margin-bottom: 15px;
}

.parent__target--container .send-to-parents {
  border: 1px solid #077aa3;
  border-radius: 6px;
  padding: 7px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 0;
}

.parent__target--container .send-to-parents.parent__target--err {
  border: 1px solid #d83025;
}

.parent__target--container .error-style {
  margin-top: 7px;
}

.send_to {
  margin: 0 0 20px 0;
}

.send_to span:first-of-type {
  color: #077aa3;
  margin: 0 10px 0 0;
}

body.rtl .send_to span:first-of-type {
  margin: 0 0 0 10px;
}

.send_to .ant-tag {
  height: 26px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 12px;
  font-weight: 500;
  padding: 2px 15px;
  background: #fff;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send_to .ant-tag i {
  color: #bf1414;
  font-weight: bold;
}

.cancel {
  color: red;
  height: 30px;
}

.next {
  min-width: 99px;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #00678d;
  color: #ffffff;
}

.disabled_mes {
  background-color: #a5a5a5;
  cursor: not-allowed;
}

.text_form input,
.text_form textarea {
  border: 1px solid #00adee;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.homework_tag {
  display: flex;
}

body.rtl .homework_tag {
  text-align: start !important;
}

.homework_tag .ant-tag {
  padding: 2px 4px;
  margin-bottom: 10px;
}

.home_work_student {
  margin: 0 0 0 20px;
  width: 100%;
}

.home_work_student .ant-row {
  width: 100%;
}

.home_work_student .ant-row span {
  display: inline-block !important;
}

.multi_select_tag {
  padding: 0 !important;
}

.food_pages {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.food_pages > .ant-radio-wrapper ~ p img {
  filter: grayscale(100%) !important;
}

.food_pages > .ant-radio-wrapper:not(:first-of-type).ant-radio-wrapper-checked ~ p {
  color: #077aa3;
}

.food_pages > .ant-radio-wrapper:not(:first-of-type).ant-radio-wrapper-checked ~ p img {
  filter: grayscale(0) !important;
}

.food_pages p {
  min-width: 100px;
}

.food_pages p img {
  width: 28px;
}

@media only screen and (max-width: 479px) {
  .food_pages p {
    min-width: 74px !important;
  }
}

@media only screen and (max-width: 479px) {
  .food_pages label {
    font-size: 12px !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 360px) {
  .food_pages span.ant-radio + * {
    font-size: 10px;
  }
}

.sleep {
  display: flex;
  align-items: flex-start;
}

.sleep img {
  margin: 0 30px 0 0;
}

@media only screen and (max-width: 360px) {
  .sleep img {
    margin: 0 10px 0 0;
  }
}

.sleep .duration_holder {
  margin: 0 0 15px 0;
  position: relative;
  display: flex;
}

.sleep .duration_holder > div:not(.add_more) {
  position: relative;
}

.sleep .duration_holder > div:not(.add_more)::after {
  content: '\01F550';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

body.rtl .sleep .duration_holder > div:not(.add_more)::after {
  right: auto;
  left: 8px;
}

@media only screen and (max-width: 575px) {
  .sleep .duration_holder {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 5px;
  }
  .sleep .duration_holder .time-input-holder {
    margin-bottom: 10px;
  }
  .sleep .duration_holder .add_more {
    left: auto !important;
    right: -68px;
  }
}

.sleep .duration_holder .time-input-holder {
  width: 130px;
  position: relative;
}

.sleep .duration_holder .time-input-holder::after {
  content: '\01F550';
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

body.rtl .sleep .duration_holder .time-input-holder::after {
  right: auto;
  left: 8px;
}

.sleep .mb-20 {
  margin-bottom: 20px;
}

.sleep .add_more {
  position: absolute;
  top: 50%;
  right: -75px;
  font-size: 12px;
  color: #00adee;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .sleep .add_more {
    top: 60px !important;
  }
}

@media only screen and (max-width: 360px) {
  .sleep .add_more {
    left: -54px !important;
  }
}

body.rtl .sleep .add_more {
  right: unset !important;
  left: -82px !important;
}

.sleep .duration {
  margin: 0 10px 0 5px;
  font-size: 12px;
}

body.rtl .sleep .ant-time-picker-icon {
  right: 100px;
  left: unset !important;
}

.white {
  display: none;
}

.ant-tabs-tab-active .white {
  display: inline-block;
}

.ant-tabs-tab-active .blue {
  display: none;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab {
  background: transparent !important;
  border: 1px solid #00adee !important;
  width: auto;
  max-height: 35px;
  line-height: 35px !important;
  margin: 0 !important;
  color: #077aa3;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab-active {
  height: 40px;
  color: #fff !important;
  background: #00adee !important;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab {
  border-radius: 0 !important;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab:first-of-type {
  border-radius: 2px 0px 0px 2px !important;
  border: 1px solid #00adee !important;
}

body.rtl .add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab:first-of-type {
  border-radius: 0px 2px 2px 0px !important;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-bar {
  border: none !important;
}

.add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab:last-of-type {
  border-radius: 0px 2px 2px 0px !important;
}

body.rtl .add_page .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab:last-of-type {
  border-radius: 2px 0px 0px 2px !important;
}

.add_page .ant-tabs-bar {
  border: none !important;
}

.add_page .bages_taps .ant-tabs-nav-animated {
  border-radius: 0 !important;
}

.add_page .bages_taps .ant-tabs-nav-scroll:last-of-type .ant-tabs-tabpane {
  padding: 0;
}

.add_page .bages_taps .ant-tabs-nav-scroll:last-of-type .ant-tabs-tab {
  border: none !important;
  border-radius: 0 !important;
  padding: 0 7px;
  font-weight: bold;
}

body.rtl .add_page .bages_taps .ant-tabs-nav-scroll:last-of-type .ant-tabs-tab {
  border-radius: 0 !important;
}

.add_page .bages_taps .ant-tabs-nav-scroll:last-of-type .ant-tabs-tab-active {
  background-color: transparent !important;
  border-bottom: 3px solid #00adee !important;
  color: #00adee !important;
}

.diaper-holder {
  display: flex;
  flex-wrap: wrap;
}

.diaper-holder .add_page_counter {
  margin-right: 0;
  margin-left: 0;
}

body.rtl .diaper-holder .ant-time-picker-icon, body.rtl
.diaper-holder .ant-time-picker-clear {
  right: unset !important;
}

.diaper-holder .ant-time-picker-panel {
  width: 119px !important;
}

.diaper-holder .ant-time-picker-panel-select {
  width: 39px !important;
}

.diaper-holder .ant-time-picker-input {
  font-size: 13px !important;
  border-radius: 6px;
}

.diaper-holder .ant-time-picker-icon,
.diaper-holder .ant-time-picker-clear {
  right: 8px !important;
}

.setHomework {
  width: 76.9% !important;
}

.setHomework .ant-modal-header {
  border-bottom: none;
}

.setHomework .ant-modal-header .ant-modal-title {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.setHomework .ant-modal-footer {
  border-top: none;
}

body.rtl .setHomework .ant-modal-close {
  left: unset !important;
  right: 1px;
}

.setHomework .hw-details-wrapper {
  border: 1px solid #00adee;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}

.setHomework .hw-details-wrapper:hover {
  border-width: 2px;
}

.setHomework .hw-details-wrapper textarea {
  border: none !important;
  box-shadow: none;
  resize: none;
}

.setHomework .hw-details-wrapper textarea:focus, .setHomework .hw-details-wrapper textarea:active, .setHomework .hw-details-wrapper textarea:hover {
  border: none !important;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  width: 97%;
  margin: auto;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions .attachment-icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #e6e6e6;
  color: #575757;
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions .ant-divider {
  width: 2px;
  height: 27px;
  margin: 0px;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions .upload-errors {
  background: #fff;
  color: #2db2bb;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions .upload-errors p {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__actions .upload-errors .anticon-info-circle {
  vertical-align: middle;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list::-webkit-scrollbar {
  height: 7px;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li {
  display: inline-flex;
  align-items: center;
  width: 224px;
  height: 38px;
  margin: 14px;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  background-color: #efefef;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li img,
.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li span {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  border-radius: 3px;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li span {
  color: #fff;
  background-color: #295396;
  text-align: center;
  font-size: 10px;
  padding: 3px 7px;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li p {
  font-size: 12px;
  width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.setHomework .hw-details-wrapper .attachment-holder .attachment-holder__list li i {
  cursor: pointer;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}

.bages_holder > .ant-tabs-card-content {
  height: 100vh;
  max-height: 300px;
  overflow: auto;
  padding-bottom: 15px;
  padding-top: 12px;
}

@media only screen and (max-width: 399px) {
  .bages_holder > .ant-tabs-card-content {
    padding-left: 15px;
    padding-right: 15px;
    height: calc(100vh - 240px);
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }
}

@media only screen and (max-width: 360px) {
  .bages_holder span.ant-radio + * {
    padding-right: 2px;
    padding-left: 2px;
  }
  body.rtl .bages_holder span.ant-radio + * {
    padding-right: 2px;
    padding-left: 2px;
  }
}

@media only screen and (max-width: 399px) {
  .bages_holder .ant-tabs-nav-wrap:first-of-type .ant-tabs-tab {
    max-height: 30px;
    padding: 0 12px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .bages_holder .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 37px;
  }
  .bages_holder .ant-tabs-tab-prev,
  .bages_holder .ant-tabs-tab-next {
    height: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .bages_holder .ant-tabs-nav-scroll {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 479px) {
  .bages_holder .ant-tabs-nav-scroll::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .bages_holder .ant-tabs-tab-prev,
  .bages_holder .ant-tabs-tab-next {
    display: none !important;
  }
}

@media only screen and (max-width: 479px) {
  .bages_holder .ant-tabs-nav-container-scrolling {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

.withBorder {
  border-radius: 12px !important;
}

.active-bages {
  border-color: #077aa3 !important;
  box-shadow: 0 5px 15px 0 rgba(48, 48, 48, 0.2);
}

.lds-dual-ring {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exams-list-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.exams-list-wrapper .ant-pagination {
  display: flex;
  justify-content: center;
  margin-block-start: 20px;
}

.exams-list-wrapper .exams-list-holder {
  margin: 5px;
  margin-block-start: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 767px) {
  .exams-list-wrapper .exams-list-holder {
    grid-template-columns: 1fr;
  }
}

.exams-list-wrapper .exams-list-holder .ant-card {
  margin: 5px;
}

.exams-list-wrapper .exams-list-holder .ant-card .ant-card-head-title {
  padding: 9px 0;
}

.exams-list-wrapper .exams-list-holder .ant-card .exams-timeline-holder__action {
  display: block;
  margin: auto;
  float: right;
}

.exams-list-wrapper .exams-list-holder .ant-card .exams-timeline-holder__content-description li {
  display: inline-flex;
  padding: 5px;
}

body.rtl .ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
}

.student-result-drawer .ant-drawer-content-wrapper {
  width: auto;
}

.student-result-drawer .ant-drawer-close {
  color: #f80000;
  font-size: 22px;
}

body.rtl .student-result-drawer .ant-drawer-close {
  left: 0;
  right: auto;
}

.student-result-drawer .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
}

.student-result-drawer.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0.43 !important;
  backdrop-filter: blur(10px);
  border: solid 1px #707070;
  background-color: rgba(0, 0, 0, 0.34) !important;
}

.student-result-drawer .ant-drawer-header {
  background-color: rgba(210, 218, 222, 0.35);
}

.student-result-drawer .ant-drawer-body {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.student-result-drawer .ant-drawer-title > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.student-result-drawer .ant-drawer-title h3 {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.student-result-drawer .ant-drawer-title p {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
}

.student-result-drawer .ant-drawer-content-wrapper {
  width: auto !important;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.student-result-drawer .student-total-degree {
  flex: 1;
}

.student-result-drawer .student-total-degree h5 {
  font-size: 14px;
  font-weight: 800;
  color: #2c363f;
  margin-bottom: 20px;
  padding: 0 24px;
}

.student-result-drawer .student-total-degree .material-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .student-total-degree .material-wrapper {
    padding: 0 10px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
  font-size: 19px;
  font-weight: 500;
  color: #4e4e4f;
  border-right: 1px solid #d2dade;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 50px;
  width: 125px;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
    margin: 0 !important;
  }
}

body.rtl .student-result-drawer .student-total-degree .material-wrapper .material-degree h6 {
  margin-right: 0;
  margin-left: 50px;
  border-left: 1px solid #d2dade;
  border-right: 0;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree p {
  font-size: 18px;
  font-weight: normal;
  color: #11b74f;
  width: 100px;
  text-align: center;
  margin-left: 60px;
}

body.rtl .student-result-drawer .student-total-degree .material-wrapper .material-degree p {
  margin-right: 60px;
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree p {
    margin: 0 !important;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress {
  width: 150px !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div {
    flex-direction: column;
    justify-content: center;
  }
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress > div .ant-progress-outer {
    padding: 0 16px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress .ant-progress-inner {
  height: 10px !important;
  border-radius: 3px;
  background-color: #dbe6eb;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress .ant-progress-bg {
  background-color: #11b74f !important;
  height: 10px !important;
  border-radius: 3px !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #2c363f !important;
}

@media only screen and (max-width: 767px) {
  .student-result-drawer .student-total-degree .material-wrapper .material-degree .ant-progress-text {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--VGood .ant-progress .ant-progress-bg {
  background-color: #b7b111 !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--VGood p {
  color: #b7b111;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--good .ant-progress .ant-progress-bg {
  background-color: #ffbc08 !important;
}

.student-result-drawer .student-total-degree .material-wrapper .material-degree.material-degree--good p {
  color: #ffbc08;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper {
  height: 76.5px;
  background-color: rgba(210, 218, 222, 0.19);
  display: flex;
  justify-content: center;
  margin: 25px 0;
  border-top: dashed 1px #d2dade;
  border-bottom: dashed 1px #d2dade;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper h6 {
  font-size: 19px;
  font-weight: bold;
  color: #000000;
  border-right: none;
}

.student-result-drawer .student-total-degree .material-wrapper.total-result-warpper .ant-progress-text {
  font-weight: 800;
}

.student-result-drawer .degree-result-msg {
  height: 100px;
  width: 75%;
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
}

.student-result-drawer .degree-result-msg span {
  font-weight: 800;
  color: #11b74f;
}

.material-degree {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.material-degree h6 {
  font-size: 19px;
  font-weight: 500;
  color: #4e4e4f;
  border-right: 1px solid #d2dade;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 50px;
  width: 125px;
}

@media only screen and (max-width: 767px) {
  .material-degree h6 {
    margin: 0 !important;
  }
}

body.rtl .material-degree h6 {
  margin-right: 0;
  margin-left: 50px;
  border-left: 1px solid #d2dade;
  border-right: 0;
}

.material-degree p {
  font-size: 18px;
  font-weight: normal;
  color: #11b74f;
  width: 100px;
  text-align: center;
  margin-left: 60px;
}

body.rtl .material-degree p {
  margin-right: 60px;
  margin-left: 0;
}

@media only screen and (max-width: 767px) {
  .material-degree p {
    margin: 0 !important;
  }
}

.material-degree .ant-progress {
  width: 150px !important;
}

.material-degree .ant-progress > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .material-degree .ant-progress > div {
    flex-direction: column;
    justify-content: center;
  }
  .material-degree .ant-progress > div .ant-progress-outer {
    padding: 0 16px;
  }
}

.material-degree .ant-progress .ant-progress-inner {
  height: 10px !important;
  border-radius: 3px;
  background-color: #dbe6eb;
}

.material-degree .ant-progress .ant-progress-bg {
  background-color: #11b74f !important;
  height: 10px !important;
  border-radius: 3px !important;
}

.material-degree .ant-progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #2c363f !important;
}

@media only screen and (max-width: 767px) {
  .material-degree .ant-progress-text {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}

.material-degree.material-degree--VGood .ant-progress .ant-progress-bg {
  background-color: #b7b111 !important;
}

.material-degree.material-degree--VGood p {
  color: #b7b111;
}

.material-degree.material-degree--good .ant-progress .ant-progress-bg {
  background-color: #ffbc08 !important;
}

.material-degree.material-degree--good p {
  color: #ffbc08;
}

.notifications-list-wrapper .notifications-list-header {
  position: sticky;
  top: 0;
  background: #f1f4f0;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 2;
  box-shadow: 1px 2px 7px #5555552c;
}

body.rtl .notifications-list-wrapper .notifications-list-header {
  padding-right: 20px;
}

.notifications-list-wrapper .notifications-list-header h3 {
  color: #000000;
  font-weight: 600;
  font-size: 21px;
  padding-inline-start: 9px;
}

.notifications-list-wrapper .notifications-list-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 70px;
  width: 100%;
  height: 55px;
  padding-left: 20px;
  font-size: 17px;
  font-weight: 600;
  background: #fff;
  color: #008539;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  z-index: 5;
}

.notifications-list-wrapper .notifications-holder {
  height: 100vh;
  overflow: scroll;
}

.notifications-list-wrapper .notifications-holder .notification-holder {
  display: flex;
  background-color: #fff;
  height: 101px;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active {
  background-color: #f5faf2;
}

.notifications-list-wrapper .notifications-holder .notification-holder.active .notification-content p {
  font-weight: bold;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-date {
  height: 65px;
  cursor: default;
  align-items: center;
}

.notifications-list-wrapper .notifications-holder .notification-holder.is-last::after {
  display: none;
}

.notifications-list-wrapper .notifications-holder .notification-holder::after {
  content: '';
  display: block;
  position: absolute;
  background: #cfcfcf;
  height: 1px;
  width: calc(100% - 40px);
  margin: auto;
  bottom: 0;
  left: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content {
  display: flex;
  align-items: flex-start;
  position: relative;
  top: 20px;
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.33;
}

@media only screen and (max-width: 991px) {
  .notifications-list-wrapper .notifications-holder .notification-holder .notification-content p {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
  }
}

.notifications-list-wrapper .notifications-holder .notification-holder .notification-content img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-inline-end: 10px;
  box-shadow: 0px 0px 3px 0px #7a7a7a3b;
}

.notifications-list-wrapper .notifications-holder .notification-holder time {
  position: absolute;
  bottom: 5px;
  left: 70px;
  color: #939191;
}

body.rtl .notifications-list-wrapper .notifications-holder .notification-holder time {
  left: 0;
  right: 70px;
}

.notifications-list-wrapper .notifications-holder .notification-holder h4 {
  font-size: 19px;
}

.notifications-list-wrapper .notifications-holder .lottie-spinner {
  top: calc(50% - 100px);
  left: calc(50% - 100px);
}

.notifications-list-wrapper .notifications-holder .ant-empty {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 125px);
  width: 250px;
  height: 100px;
}

.web-notifications .ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.notification-count {
  background: #db4a41;
  width: 27px;
  height: 27px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 25px;
}

body.rtl .student-view .student-view__tabs {
  direction: ltr;
}

.student-view .student-view__tabs .ant-tabs-bar {
  border-bottom: 0;
}

.assigments-tab .calender-show-hide {
  position: relative;
  bottom: -35px;
  z-index: 20;
  width: fit-content;
  margin-left: auto;
}

body.rtl .assigments-tab .calender-show-hide {
  margin-right: auto;
  margin-left: 50px;
}

@media only screen and (max-width: 767px) {
  .assigments-tab .calender-show-hide {
    bottom: 0;
  }
}

.assigments-tab .courses-tab__content {
  margin-top: 0;
}

.assigments-tab .courses-tab__content .ant-tabs-content .tabs-content-wrapper {
  min-height: 45vh;
  height: 60vh;
  position: relative;
  overflow-y: auto;
}

.assigments-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
  font-size: 16px;
  font-weight: 600;
}

body.rtl .assigments-tab .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 10px;
}

@media only screen and (max-width: 379px) {
  .assigments-tab .ant-tabs-nav .ant-tabs-tab {
    font-size: 11px;
    padding: 12px !important;
  }
}

.assigments-tab .assigments-calender {
  margin-top: 60px;
}

body.rtl .assigments-tab .assigments-calender {
  margin-top: 60px;
}

.assigments-tab .assignments-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
  gap: 12px 20px;
}

@media only screen and (max-width: 991px) {
  .assigments-tab .assignments-grid {
    grid-template-columns: 1fr;
  }
}

.display-block {
  display: block !important;
}

.assignment-deatails {
  padding: 0 32px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .assignment-deatails {
    padding: 0 10px;
  }
}

.assignment-deatails .ant-tabs-nav .ant-tabs-tab {
  font-size: 12px;
  font-weight: normal;
}

.assignment-deatails .assignment-info {
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 33px 50px 20px;
}

body.rtl .assignment-deatails .assignment-info {
  padding: 20px 20px 50px 33px;
}

.assignment-deatails .ant-divider {
  margin-bottom: 5px;
}

.assignment-deatails .assignment-info__teacher-questions {
  padding-inline-start: 25px;
}

@media only screen and (max-width: 575px) {
  .assignment-deatails .assignment-info__teacher-questions {
    padding-inline-start: 0;
  }
}

.assignment-deatails .assignment-info__teacher-questions > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.assignment-deatails .assignment-info__teacher-questions > header .teacher-info {
  display: flex;
  align-items: center;
}

.assignment-deatails .assignment-info__teacher-questions > header .teacher-info--name {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-inline-start: 6px;
}

.assignment-deatails .assignment-info__teacher-questions > header .question-time {
  font-size: 14px;
  font-weight: normal;
  color: #00adee;
  display: flex;
  align-items: center;
}

.assignment-deatails .assignment-info__teacher-questions > header .question-time i {
  margin-inline-start: 5px;
}

.assignment-deatails .assignment-info__teacher-questions > header .feedback-badge {
  width: 143px;
  height: 44px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border: solid 0.5px rgba(210, 218, 222, 0.23);
  background-color: rgba(210, 218, 222, 0.23);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  margin-inline-end: -33px;
}

.assignment-deatails .assignment-info__teacher-questions > header .feedback-badge h5 {
  font-size: 15px;
  font-weight: 500;
  color: #3c3c38;
}

.assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question {
  width: 85%;
  max-height: 70vh;
  overflow: auto;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.56;
  color: #3c3c38;
  padding-top: 15px;
  margin-inline-end: auto;
}

@media only screen and (max-width: 991px) {
  .assignment-deatails .assignment-info__teacher-questions .teacher-questions__list .teacher-questions__list--question {
    width: 100%;
  }
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .teacher-questions__list--question {
  color: #767676;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .teacher-info--name {
  color: #0793c7;
  font-weight: normal;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .files-uploaded-wrapper {
  padding: 15px 0;
}

.assignment-deatails .assignment-info__teacher-questions.assignment-info__student-answers .files-uploaded-wrapper .file-card {
  cursor: pointer;
}

.assignment-deatails .assignment-files {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 12px;
}

.assignment-deatails .assignment-files .second-part {
  padding: 0;
}

.assignment-deatails .assignment-files .second-part .downloads-holder {
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 20px 10px;
  max-height: 73vh;
  overflow: auto;
}

.assignment-deatails .assignment-files .second-part .downloads-holder > div {
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assignment-deatails .assignment-files .second-part .download-info {
  margin-inline-end: 0;
  justify-content: space-between;
}

.assignment-deatails .assignment-files .second-part .contnet-download {
  width: auto;
  height: auto;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
}

.assignment-deatails .assignment-files .second-part .contnet-download .poster {
  padding: 10px;
  box-shadow: none;
}

.assignment-deatails .assignment-files .title {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}

.assignment-deatails .reply-holder {
  border: solid 1px #d4d4d4;
  border-radius: 4px;
}

.assignment-deatails .reply-holder .ant-form-item {
  margin-bottom: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner {
  padding: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner .ant-comment-avatar,
.assignment-deatails .reply-holder .ant-comment-inner .ant-comment-content-author {
  margin: 0;
}

.assignment-deatails .reply-holder .ant-comment-inner textarea.ant-input {
  padding: 10px 12px;
  resize: none;
  min-height: 210px;
  height: 210px;
  border: none;
}

.assignment-deatails .reply-holder .textarea-action-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F2;
  padding: 2px 10px;
  height: 48px;
  border-radius: 0 0 4px 4px;
}

.assignment-deatails .reply-holder .textarea-action-btns .textarea-icon {
  width: 38px;
  height: 38px;
  color: #0793c7;
  margin: 2px;
  margin-inline-end: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba(7, 147, 199, 0.11);
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__list {
  margin-top: 0;
  position: absolute;
  top: -60px;
  left: -60px;
  display: none;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__list li {
  width: 200px;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-icon {
  background-color: transparent;
  margin: 2px;
}

.assignment-deatails .reply-holder .textarea-action-btns > div {
  display: flex;
  align-items: center;
}

.assignment-deatails .reply-holder .textarea-action-btns .attachment-holder__actions {
  border: 0;
  height: 38px;
}

.feadback-pass-popover h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  color: #57595a;
}

.feadback-pass-popover .feedback-article {
  border-radius: 6px;
  border: solid 0.5px #d2dade;
  background-color: #ffffff;
  padding: 10px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  color: #6e727d;
  margin: 10px 0;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.feadback-pass-popover .passed-alert {
  font-size: 12px;
  margin-top: 7px;
}

.feadback-pass-popover .passed-alert img {
  margin-inline-start: 0;
  width: 14px;
}

.feadback-pass-popover .fail-alert {
  color: #ee3700;
}

.passed-alert,
.fail-alert {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  color: #11b74f;
}

.passed-alert img,
.fail-alert img {
  margin-inline-start: 20px;
  margin-inline-end: 5px;
}

.fail-alert {
  color: #ee3700;
}

.exams-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.exams-tab .ant-tabs {
  flex: 1;
  margin-left: 35px;
}

.exams-tab .ant-tabs .ant-tabs-content {
  height: 100%;
  min-height: 50vh;
}

.exams-tab .ant-tabs .ant-tabs-content .ant-tabs-tabpane {
  overflow-y: auto;
  height: calc(100% - 35px);
}

@media only screen and (max-width: 575px) {
  .exams-tab .ant-tabs {
    margin: 0 !important;
  }
}

body.rtl .exams-tab .ant-tabs {
  margin-left: 0;
  margin-right: 35px;
}

.exams-tab .ant-tabs .ant-tabs-bar {
  border: none;
}

body.rtl .exams-tab .ant-tabs {
  direction: ltr;
}

@media only screen and (max-width: 479px) {
  body.rtl .exams-tab .ant-tabs-tab {
    transform: scaleX(1);
  }
}

@media only screen and (max-width: 399px) {
  .exams-tab .ant-tabs-tab {
    margin: 0 5px 0 0;
    font-size: 10px;
    padding: 10px 0 !important;
  }
}

@media only screen and (max-width: 479px) {
  .exams-tab .ant-tabs-tab {
    margin: 0 9px 0 0;
    padding: 12px 9px;
  }
}

body.rtl .exams-tab .ant-tabs-nav-scroll {
  display: flex;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 575px) {
  .exams-tab .ant-tabs-nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

body.rtl .exams-tab .ant-timeline-item-content {
  margin-left: 0;
  margin-right: 70px;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 991px) {
  body.rtl .exams-tab .ant-timeline-item-content {
    flex-direction: column;
  }
}

body.rtl .exams-tab .ant-timeline-item-content .exams-timeline-holder__content {
  transform: scaleX(-1);
}

body.rtl .exams-tab .exams-timeline-holder__content-title {
  transform: scaleX(-1);
  justify-content: flex-start;
  flex-direction: row-reverse;
}

body.rtl .exams-tab .exams-timeline-holder__content-title p {
  margin-left: 10px;
  margin-right: 0;
}

body.rtl .exams-tab .exams-timeline-holder__content-title div {
  margin-right: 22px;
  margin-left: 0;
}

body.rtl .exams-tab .exams-timeline-holder__content-description {
  transform: scaleX(-1);
  flex-direction: row-reverse;
}

.exam-schedule-wrapper {
  width: 90%;
  display: block;
  margin: 15px auto;
}

@media only screen and (max-width: 575px) {
  .exam-schedule-wrapper {
    margin-bottom: 40px;
  }
  body.rtl .exam-schedule-wrapper {
    margin: 15px 0 15px 0;
    width: 94%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item {
  margin-block-start: 10px;
  padding: 5px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
  top: 45px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
  left: unset;
  border-width: 2px;
  border-left: 0;
  right: -1px;
}

@media only screen and (max-width: 767px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item .ant-timeline-item-tail {
    display: none;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
  background-color: transparent;
  top: 45px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
  left: unset;
  right: -54px;
}

@media only screen and (max-width: 767px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    top: calc(100% - 109px);
    height: 100%;
    width: 100%;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    width: fit-content;
  }
}

@media only screen and (max-width: 575px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    left: 13px;
    top: 0 !important;
    height: 100%;
    transform: translate(-50%, 0%);
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom {
    left: 20px;
    right: unset;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  background: #0793c7;
  color: #fff;
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    height: 97.5%;
    border-radius: 6px 0 0 6px;
    background: #fff;
    color: #0793c7;
    border: solid 1px #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    border-radius: 0 6px 6px 0;
  }
}

@media only screen and (max-width: 575px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date {
    border-radius: 0;
    height: 100%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date i {
  font-size: 18px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-head-custom .exams-timeline-holder__date p {
  padding-top: 8px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
  margin-left: 70px;
  min-height: 90px;
  background: #fff;
  border-radius: 6px;
  border: solid 1px #f4f4f4;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
}

@media only screen and (max-width: 991px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    flex-direction: column;
    padding-bottom: 17px;
    min-height: 201px;
  }
}

@media only screen and (max-width: 767px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    margin-left: 34px;
    border-radius: 0 6px 6px 0;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    margin-right: 34px;
    border-radius: 6px 0 0 6px;
  }
}

@media only screen and (max-width: 575px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    width: 94%;
    margin-left: 42px !important;
    border-radius: 0;
    top: 0;
  }
}

@media only screen and (max-width: 399px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }
}

@media only screen and (max-width: 379px) {
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content {
    width: 91%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content {
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media only screen and (max-width: 991px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content {
    width: 100%;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title p {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: #0793c7;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 10px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-title div {
  font-size: 13px;
  margin-inline-start: 22px;
  border: 1px solid #444;
  color: #444;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
  margin-top: 20px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 991px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    display: grid;
    grid-template-columns: 60% 40%;
    row-gap: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    grid-template-columns: 100%;
    padding-left: 13px;
  }
  body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description {
    padding-right: 13px;
    padding-left: 0;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li img {
  margin-right: 5px;
}

body.rtl .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li img {
  margin-right: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 399px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exams-timeline-holder__content-description li {
    font-size: 10px;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks {
  display: none !important;
  border: none !important;
  padding: 0 !important;
  min-width: 120px;
}

@media only screen and (max-width: 767px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks {
    display: flex !important;
    align-items: flex-start;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark {
  border: none !important;
  padding: 0 !important;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .student-mark,
.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .slash {
  color: #0793c7;
  font-size: 13px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .exam-mark .exam-full-mark {
  color: #4f6170;
  font-size: 13px;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__content .exam-marks .under-correction {
  color: #0793c7;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action {
  align-self: center;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action .ant-statistic-content {
  font-size: 15px !important;
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action:first-of-type {
  margin-right: 10px;
}

@media only screen and (max-width: 399px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .exams-timeline-holder__action {
    width: 87px;
  }
}

.exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
  align-self: center;
}

@media only screen and (max-width: 991px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .exam-schedule-wrapper .exams-timeline-holder .ant-timeline-item-content .actions-holder {
    display: flex;
  }
}

.instructions-wrapper .instructions__title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 26px;
  padding: 0 24px;
}

body.rtl .instructions-wrapper .instructions__title {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__title {
    font-size: 20px;
  }
}

.instructions-wrapper .instructions__info {
  display: flex;
  align-items: center;
}

.instructions-wrapper .instructions__info:first-of-type {
  margin-right: 50px;
}

body.rtl .instructions-wrapper .instructions__info:first-of-type {
  margin-right: 0;
  margin-left: 50px;
}

.instructions-wrapper .instructions__row {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__row {
    display: block;
  }
  .instructions-wrapper .instructions__row > div {
    margin-bottom: 14px;
  }
}

.instructions-wrapper .instructions__info-label {
  font-size: 14px;
  color: #868ea4;
  margin-right: 10px;
}

body.rtl .instructions-wrapper .instructions__info-label {
  margin-right: 0;
  margin-left: 10px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__info-label {
    font-size: 12px;
  }
}

.instructions-wrapper .instructions__infoBack {
  font-size: 16px;
  font-weight: 500;
  color: #2c363f;
}

body.rtl .instructions-wrapper .instructions__infoBack {
  text-align: right;
}

.instructions-wrapper .instructions__infoBack span:first-of-type {
  font-size: 16px;
  color: #2c363f;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__infoBack span:first-of-type {
    font-size: 14px;
  }
}

.instructions-wrapper .instructions__infoBack span:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: #2c363f;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__infoBack span:nth-child(2) {
    font-size: 14px;
  }
}

.instructions-wrapper .instructions__infoBack span:nth-child(3) {
  font-size: 14px;
  font-weight: 500;
  color: #868ea4;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__infoBack span:nth-child(3) {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .instructions__infoBack {
    font-size: 14px;
  }
}

.instructions-wrapper .info-holder {
  border-bottom: 10px #f9f9fc solid;
  margin-bottom: 20px;
  padding: 0 24px;
}

.instructions-wrapper .instructions__list {
  font-size: 16px;
  line-height: 1.94;
  margin-bottom: 10px;
}

body.rtl .instructions-wrapper .instructions__list {
  text-align: right;
}

.instructions-wrapper .ml-20 {
  margin-left: 20px;
}

.instructions-wrapper .list-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

@media only screen and (max-width: 767px) {
  .instructions-wrapper .list-holder {
    flex-direction: column;
    display: flex !important;
  }
}

.instructions-wrapper .list-holder ol {
  margin-left: 20px;
}

body.rtl .instructions-wrapper .list-holder ol {
  margin-right: 20px;
  margin-left: 0;
}

.instructions-wrapper .ant-modal-body {
  padding: 24px 0;
}

@media only screen and (max-width: 479px) {
  .instructions-wrapper .ant-modal {
    width: 95% !important;
  }
}

@media only screen and (max-width: 379px) {
  .responsive-timeline .ant-timeline-item-head-custom {
    top: 102px !important;
  }
}

.exams-timeline-holder-prev .ant-timeline-item-content {
  margin-left: 70px;
  min-height: 90px;
  display: flex;
  border: none !important;
  background: transparent !important;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

@media only screen and (max-width: 767px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    background: #fff !important;
    border-radius: 0 6px 6px 0;
    border: solid 1px #f4f4f4 !important;
  }
}

.exams-timeline-holder-prev .exams-timeline-holder__content {
  display: flex;
  flex-direction: column;
  width: 90% !important;
  background: #fff;
  border-radius: 6px 0 0 6px;
  border: solid 1px #f4f4f4;
  padding: 15px;
  width: 10%;
  min-width: 90px;
}

body.rtl .exams-timeline-holder-prev .exams-timeline-holder__content {
  border-radius: 0 6px 6px 0;
}

@media only screen and (max-width: 767px) {
  .exams-timeline-holder-prev .exams-timeline-holder__content {
    border: none !important;
    background: transparent !important;
    border-radius: 0;
    width: 100% !important;
    padding: 0;
  }
}

.exams-timeline-holder-prev .exam-marks {
  background: #fff;
  border-radius: 0 6px 6px 0;
  border: solid 1px #f4f4f4;
  padding: 15px;
  width: 10%;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body.rtl .exams-timeline-holder-prev .exam-marks {
  border-radius: 6px 0 0 6px;
}

@media only screen and (max-width: 991px) {
  body.rtl .exams-timeline-holder-prev .exam-marks {
    border-radius: 6px 0 0 6px;
  }
}

@media only screen and (max-width: 767px) {
  .exams-timeline-holder-prev .exam-marks {
    display: none;
  }
}

.exams-timeline-holder-prev .exam-marks .student-mark,
.exams-timeline-holder-prev .exam-marks .slash {
  color: #0793c7;
  font-size: 18px;
}

.exams-timeline-holder-prev .exam-marks .exam-full-mark {
  color: #4f6170;
  font-size: 18px;
}

.exams-timeline-holder-prev .ant-timeline-item-content {
  align-items: stretch !important;
}

@media only screen and (max-width: 991px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    flex-direction: row !important;
    margin-left: 30px !important;
  }
  body.rtl .exams-timeline-holder-prev .ant-timeline-item-content {
    margin-right: 30px !important;
    margin-left: 0;
    flex-direction: row-reverse !important;
  }
}

@media only screen and (max-width: 767px) {
  .exams-timeline-holder-prev .ant-timeline-item-content {
    padding-bottom: 0 !important;
  }
}

.exams-timeline-holder-prev .ant-progress-text {
  display: none;
}

.exams-timeline-holder-prev .ant-progress-outer {
  padding: 0;
  margin: 0;
}

.teacher-exams-container {
  padding-top: 80px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.teacher-exams-container .teacher-exams-container__header {
  width: 100%;
  height: 53px;
  background-color: #ffffff;
  border-bottom: 1px solid #f4f4f7;
  display: flex;
  align-items: center;
  padding-left: 13px;
}

body.rtl .teacher-exams-container .teacher-exams-container__header {
  padding-right: 13px;
  font-size: 18px;
  font-weight: 600;
}

.teacher-exams-container .teacher-exams-holder {
  width: 100%;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding: 12px 12px 0px 0;
  justify-content: space-between;
}

body.rtl .teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header {
  padding: 12px 0 0px 12px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header ul {
  display: flex;
  padding-left: 13px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header ul li {
  cursor: pointer;
  padding: 5px 10px;
  margin: 0px 5px;
  font-size: 16px;
  font-weight: 500;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header ul li.active {
  color: #077aa3;
  border-bottom: 2px solid #077aa3;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header .teacher-exam-holder__filter-button {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header .teacher-exam-holder__filter-button span {
  margin-left: 10px;
  font-size: 14px;
  color: #222222;
}

body.rtl .teacher-exams-container .teacher-exams-holder .teacher-exam-holder__header .teacher-exam-holder__filter-button span {
  margin-left: 0;
  margin-right: 10px;
}

.teacher-exams-container .teacher-exams-holder .ant-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacher-exams-container .teacher-exams-holder .ant-form .ant-select-selection {
  border-radius: 6px;
}

@media only screen and (max-width: 767px) {
  .teacher-exams-container .teacher-exams-holder .ant-form {
    display: block;
  }
}

.teacher-exams-container .teacher-exams-holder .ant-form-inline .ant-form-item {
  margin-right: 30px !important;
}

body.rtl .teacher-exams-container .teacher-exams-holder .ant-form-inline .ant-form-item {
  margin-left: 30px;
  margin-right: 0 !important;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter {
  padding: 14px 18px;
  min-height: 100px;
  background-color: #ffffff;
  margin: 10px 0;
  border-radius: 10px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-form-item .ant-form-item-label {
  position: relative;
  top: unset;
  left: unset;
  width: 100%;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-form-inline .ant-form-item {
  width: 20%;
  min-width: 150px;
}

@media only screen and (max-width: 639px) {
  .teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-form-inline .ant-form-item {
    width: 40%;
  }
}

@media only screen and (max-width: 479px) {
  .teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-form-inline .ant-form-item {
    width: 100%;
  }
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .filter__label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-select-selection-selected-value {
  margin-top: -4px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .filter__clear {
  border: none;
  color: #f9201a;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-form-item-control-wrapper {
  display: block;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .ant-select-selection {
  height: 38px;
}

.teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .filter__inputs {
  width: 80%;
}

@media only screen and (max-width: 767px) {
  .teacher-exams-container .teacher-exams-holder .teacher-exam-holder__filter .filter__inputs {
    width: 100%;
    margin-bottom: 20px;
  }
}

.teacher-exams-container .teacher-exams-holder .grid-holder .grid-action-header {
  display: none;
}

.teacher-exams-container .teacher-exams-holder .grid-holder .ant-table-wrapper.is-empty .ant-table-content {
  min-height: 50vh;
}

.teacher-exams-container .teacher-exams-holder .grid-holder .ant-table-content {
  background-color: #ffffff;
  width: 100.7%;
}

.teacher-exams-container .teacher-exams-holder .grid-holder .ant-table-content .ant-table-body {
  overflow: hidden;
}

.teacher-exams-container .teacher-exams-holder .grid-holder .ant-table-thead > tr > th {
  background: #e6e6ee;
}

.teacher-exams-container .teacher-exams-holder .grid-holder .ant-table-thead > tr > th svg {
  color: #b0b0bf;
}

.teacher-exams-container .ant-table-tbody > tr > td {
  border-bottom: 4px solid #f4f4f7;
}

.teacher-exams-container .ant-table-tbody > tr:last-of-type > td {
  border-bottom: none;
}

.teacher-exams-container .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: solid 1px #d4d4dc;
}

.teacher-exams-container .ant-table-thead > tr > th:last-of-type,
.teacher-exams-container .ant-table-tbody > tr > td:last-of-type {
  display: none;
}

.teacher-exams-container .ant-table-thead > tr > th:first-of-type,
.teacher-exams-container .ant-table-tbody > tr > td:first-of-type {
  display: none;
}

@media only screen and (max-width: 767px) {
  .teacher-exams-container .ant-table-thead > tr > th {
    font-size: 12px;
  }
}

@media only screen and (max-width: 639px) {
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(6),
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(7) {
    display: none;
  }
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(6),
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(7) {
    display: none;
  }
  .teacher-exams-container .ant-table-thead > tr > th:first-of-type {
    width: 10%;
  }
  .teacher-exams-container .ant-table-tbody > tr > td:first-of-type {
    width: 10%;
  }
}

@media only screen and (max-width: 479px) {
  .teacher-exams-container .ant-table-thead > tr > th:last-of-type,
  .teacher-exams-container .ant-table-tbody > tr > td:last-of-type {
    display: table-cell;
  }
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(3),
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(4),
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(5) {
    display: none;
  }
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(3),
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(4),
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(5) {
    display: none;
  }
  .teacher-exams-container .ant-table-thead > tr > th:nth-of-type(2) {
    width: 40%;
  }
  .teacher-exams-container .ant-table-tbody > tr > td:nth-of-type(2) {
    width: 40%;
  }
}

.exam-question-view {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  padding-bottom: 0;
  width: 100%;
  margin-left: 20px;
}

.exam-question-view.back {
  background: transparent;
  box-shadow: unset;
  max-height: unset;
  position: relative;
  z-index: 6;
  padding: 8px 0;
  margin: 0;
  padding-right: 0px;
  height: auto;
  margin-top: -6px;
  margin-bottom: -10px;
}

body.rtl .exam-question-view.back {
  padding-right: unset;
  padding-left: 0px;
}

.exam-question-view .ck-toolbar {
  border: none !important;
}

.exam-question-view .ck.ck-editor__main > .ck-editor__editable {
  height: auto !important;
}

@media only screen and (max-width: 767px) {
  .exam-question-view {
    padding: 14px 16px;
    padding-bottom: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 639px) {
  .exam-question-view {
    display: block;
    height: auto;
  }
}

.exam-question-view .question-view__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  top: 0;
  background: #fff;
  z-index: 55;
  border-bottom: 1px solid #ededed;
}

.exam-question-view .question-view__header strong {
  font-weight: 500;
}

.exam-question-view .question-view__header .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.exam-question-view .question-view__header .title h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: #000000;
}

.exam-question-view .question-view__header .title .exam-time-counter {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
}

body.rtl .exam-question-view .question-view__header .title .exam-time-counter {
  flex-direction: row-reverse;
}

.exam-question-view .question-view__header .title .exam-time-counter span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 39px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header .title .exam-time-counter span {
    width: 32px;
    height: 32px;
    font-size: 17px;
  }
}

.exam-question-view .question-view__header .description {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #2c363f;
  height: auto !important;
}

.exam-question-view .question-view__header .description * {
  height: auto !important;
}

.exam-question-view .question-view__header .description img {
  margin: 10px auto;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header .description {
    font-size: 16px;
    padding: 12px 0px;
  }
}

body.rtl .exam-question-view .question-view__header .description {
  direction: ltr;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header {
    position: static;
    min-height: auto;
    height: auto;
  }
  .exam-question-view .question-view__header .title h4 {
    font-size: 14px;
  }
  .exam-question-view .question-view__header .title time {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  body.rtl .exam-question-view .question-view__header .title time {
    right: auto;
    left: 16px;
  }
}

.exam-question-view .question-view__header .current-question-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-question-view .question-view-content {
  flex: 1;
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view-content {
    overflow: auto;
    padding: 15px 0;
  }
}

.question-view-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 101;
  padding: 15px;
}

.question-view-footer button {
  padding: 0 15px !important;
  flex-direction: row !important;
}

.question-view-footer button:only-child {
  margin-left: auto;
}

body.rtl .question-view-footer button:only-child {
  margin-left: initial;
  margin-right: auto;
}

.question-view-footer button .anticon-loading {
  color: white;
}

@media only screen and (max-width: 767px) {
  .question-view-footer {
    padding: 10px 0;
    background: #fff;
    z-index: 666;
  }
}

.question-view-footer .primary_outline {
  height: 37px;
  border-radius: 6px;
  border: solid 1px #00adee !important;
  min-width: 123px;
}

.question-view-footer .primary_outline span {
  color: #00adee;
  display: flex !important;
  font-size: 16px !important;
  align-items: center;
  justify-content: center;
}

.question-view-footer .primary_fill {
  height: 37px;
  border-radius: 6px;
  background-color: #00adee;
  min-width: 123px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.question-view-footer .primary_fill span {
  color: white;
  display: flex !important;
  font-size: 16px !important;
  align-items: center;
  justify-content: center;
}

.answer-radio-type .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.answer-radio-type .ant-radio-group .ant-radio-wrapper {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: #000000;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.answer-radio-type .ant-radio-group .ant-radio-wrapper p {
  margin-bottom: 0;
  margin-top: 0;
  line-height: normal;
}

.answer-radio-type .ant-radio-group .ant-radio-inner {
  border-width: 1px;
  border-color: #868ea4;
}

.answer-radio-type .radio-style--true .ant-radio-inner {
  border-color: #11b74f;
}

.answer-radio-type .radio-style--true .ant-radio-inner::after {
  background-color: #11b74f;
}

.answer-radio-type .radio-style--false .ant-radio-inner {
  border-color: #ee3700;
}

.answer-radio-type .radio-style--false .ant-radio-inner::after {
  background-color: #ee3700;
}

.answer-checkbox-type .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.answer-checkbox-type .ant-checkbox-group .ant-checkbox-wrapper {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.94;
  color: #000000;
}

.answer-checkbox-type .ant-checkbox-group .ant-checkbox-inner {
  border-width: 1px;
  border-color: #868ea4;
}

.answer-connect-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: ltr;
}

@media only screen and (max-width: 575px) {
  .answer-connect-type {
    grid-template-columns: 35% 35%;
    justify-content: space-between;
  }
}

.answer-connect-type .Close {
  width: 25px;
  height: 25px;
  display: flex;
  visibility: visible;
  position: absolute;
  background-color: #f9201a !important;
  top: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  font-size: 22px !important;
  line-height: 20px;
  color: #fff;
  border-radius: 0 6px 0 5px;
}

.answer-connect-type > div {
  display: grid;
  align-items: center;
  gap: 15px;
  width: fit-content;
}

.answer-connect-type > div > div {
  height: 90px;
  width: fit-content;
  z-index: 111;
  cursor: pointer;
}

.answer-connect-type > div p,
.answer-connect-type > div span {
  font-family: inherit !important;
  font-size: 17px !important;
  margin-bottom: 0 !important;
  font-weight: 500 !important;
  text-align: center;
  white-space: normal;
}

.answer-connect-type .word-list > div {
  width: fit-content;
  position: relative;
}

.answer-connect-type .word-list .inner-html {
  min-width: 88px;
  width: 100px;
  max-width: 188px;
  min-height: 88px;
  border-radius: 6px;
  border: solid 1px #868ea4;
  background-color: transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.answer-connect-type .word-list .inner-html.SelectedClass {
  border: 2px solid #0793c7;
}

.answer-connect-type .word-list .inner-html * {
  pointer-events: none;
}

.answer-connect-type .word-list .word--dot {
  width: 14px;
  height: 14px;
  background-color: #0793c7;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
  cursor: pointer;
}

.answer-connect-type .word-list .word--dot:before {
  display: none;
  content: '';
  width: 15px;
  height: 1px;
  background-color: #0793c7;
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%);
}

.answer-connect-type .word-list .word--dot:after {
  display: none;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0793c7;
  position: absolute;
  top: 50%;
  right: -18px;
  transform: translateY(-50%);
}

body.rtl .answer-connect-type .word-list .word--dot {
  right: auto;
  left: -14px;
}

body.rtl .answer-connect-type .word-list .word--dot:before {
  right: auto;
  left: -14px;
}

body.rtl .answer-connect-type .word-list .word--dot:after {
  right: auto;
  left: -18px;
  border-left: 0;
  border-right: 10px solid #0793c7;
}

.answer-connect-type .imgs-list > div {
  position: relative;
  border-radius: 6px;
  border: solid 1px #868ea4;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.answer-connect-type .imgs-list .inner-html {
  height: 100%;
  width: 100%;
}

.answer-connect-type .imgs-list .inner-html * {
  pointer-events: none;
}

.answer-connect-type .imgs-list .inner-html p {
  height: 100%;
  line-height: 88px !important;
  pointer-events: none;
}

.answer-connect-type .imgs-list .inner-html img {
  pointer-events: none;
}

.answer-connect-type .imgs-list img {
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.answer-complate-type .inner-html > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-complate-type .inner-html > p > span {
  display: flex;
  flex-wrap: wrap;
}

.answer-complate-type .inner-html input.correct-answer {
  border: 2px solid #06d236;
}

.answer-complate-type .inner-html input.wrong-answer {
  border: 2px solid #ee3700;
}

.answer-complate-type .inner-html input.partial-answer {
  border: 2px solid #ffb300;
}

@media only screen and (max-width: 767px) {
  .answer-complate-type {
    overflow-x: auto;
  }
}

.inner-html {
  display: inline-block !important;
  color: black;
  white-space: initial;
}

.inner-html figure img {
  max-height: 200px;
  width: unset !important;
}

.inner-html img {
  margin-top: 5px;
  margin-bottom: 5px;
}

.inner-html-input {
  width: fit-content;
  border: 0;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  padding: 5px;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  max-width: 110px;
}

.assignment {
  display: flex;
}

.assignment .assignment__avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.assignment .assignment__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: #000000;
  margin-left: 13px;
}

.assignment .assignment__details {
  font-size: 12px;
  line-height: 1.75;
  color: #a6afc7;
  margin-left: 13px;
}

.outline_gray {
  width: 78px;
  height: 38px;
  border-radius: 5px;
  border: solid 1px #d4d4e1;
  background-color: #ffffff;
  color: #2c363f;
  font-size: 14px;
  margin-right: 20px;
}

body.rtl .outline_gray {
  margin-left: 20px;
  margin-right: 0;
}

.fill_Primary {
  width: 78px;
  height: 38px;
  border-radius: 5px;
  background-color: #0793c7;
  color: white;
  font-size: 14px;
}

.fill_Primary:hover {
  background-color: #0793c7;
  color: white;
}

.fill_Primary:focus {
  background-color: #0793c7;
  color: white;
}

.radio-answer .correct-answer {
  border-bottom: 1px solid #06d236;
}

.radio-answer .partial-answer {
  border-bottom: 2px solid #ffb300;
}

.radio-answer .correct-mark {
  width: 21px;
  height: 21px;
  background-color: #06d236;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  color: white !important;
  padding: 2px;
}

.radio-answer .ant-checkbox-wrapper {
  width: 95%;
  display: flex;
  align-items: center;
}

.radio-answer .ant-radio-disabled + span,
.radio-answer .ant-checkbox + span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.radio-answer .wrong-answer {
  border-bottom: 1px solid #f9201a;
}

.radio-answer .wrong-mark {
  width: 21px;
  height: 21px;
  background-color: #f9201a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.wrong-mark {
  width: 21px;
  height: 21px;
  background-color: #f9201a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 2px;
}

.correction-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.correction-buttons .correction-button {
  min-width: 130px;
  height: 45px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 10px;
  opacity: 0.8;
}

.correction-buttons .correction-button span:first-of-type {
  width: 21px;
  min-width: 21px;
  height: 21px;
  border-radius: 2px;
  margin-right: 10px;
}

body.rtl .correction-buttons .correction-button span:first-of-type {
  margin-right: 0;
  margin-left: 10px;
}

.correction-buttons .correction-button span {
  font-weight: bold;
}

.correction-buttons .unselected {
  background-color: #f4f4f7;
  color: #283e4a;
}

.correction-buttons .unselected span:first-of-type {
  background-color: #ccced7;
  color: white;
}

body.rtl .correction-buttons .unselected span:first-of-type {
  margin-right: 0;
  margin-left: 10px;
}

.correction-buttons .unselected:hover {
  background-color: #f4f4f7;
}

.correction-buttons .unselected:hover span {
  color: #283e4a;
}

.correction-buttons .unselected:hover span:first-of-type {
  color: white;
}

.correction-buttons .unselected:focus {
  background-color: #f4f4f7;
}

.correction-buttons .unselected:focus span {
  color: #283e4a;
}

.correction-buttons .unselected:focus span:first-of-type {
  color: white;
}

.correction-buttons .ant-btn {
  margin-right: 15px;
}

body.rtl .correction-buttons .ant-btn {
  margin-left: 15px;
  margin-right: 0;
}

.correction-buttons .correct-btn {
  background-color: #06d236;
}

.correction-buttons .correct-btn span:first-of-type {
  background: white;
  color: #06d236;
}

body.rtl .correction-buttons .correct-btn span:first-of-type {
  margin-right: 0;
  margin-left: 10px;
}

.correction-buttons .correct-btn:hover {
  background-color: #06d236;
}

.correction-buttons .correct-btn:hover span {
  color: white;
}

.correction-buttons .correct-btn:hover span:first-of-type {
  color: #06d236;
}

.correction-buttons .correct-btn:focus {
  background-color: #06d236;
}

.correction-buttons .correct-btn:focus span {
  color: white;
}

.correction-buttons .correct-btn:focus span:first-of-type {
  color: #06d236;
}

.correction-buttons .wrong-btn {
  background-color: #ee3700;
}

.correction-buttons .wrong-btn span:first-of-type {
  background: white;
  color: #ee3700;
}

body.rtl .correction-buttons .wrong-btn span:first-of-type {
  margin-right: 0;
  margin-left: 10px;
}

.correction-buttons .wrong-btn:hover {
  background-color: #ee3700;
}

.correction-buttons .wrong-btn:hover span {
  color: white;
}

.correction-buttons .wrong-btn:hover span:first-of-type {
  color: #ee3700;
}

.correction-buttons .wrong-btn:focus {
  background-color: #ee3700;
}

.correction-buttons .wrong-btn:focus span {
  color: white;
}

.correction-buttons .wrong-btn:focus span:first-of-type {
  color: #ee3700;
}

.correction-buttons .Partial-btn {
  background-color: #ffb300;
}

.correction-buttons .Partial-btn span:first-of-type {
  background: white;
  color: #ffb300;
}

body.rtl .correction-buttons .Partial-btn span:first-of-type {
  margin-right: 0;
  margin-left: 10px;
}

.correction-buttons .Partial-btn:hover {
  background-color: #ffb300;
}

.correction-buttons .Partial-btn:hover span {
  color: white;
}

.correction-buttons .Partial-btn:hover span:first-of-type {
  color: #ffb300;
}

.correction-buttons .Partial-btn:focus {
  background-color: #ffb300;
}

.correction-buttons .Partial-btn:focus span {
  color: white;
}

.correction-buttons .Partial-btn:focus span:first-of-type {
  color: #ffb300;
}

.correction-buttons .partial-degree {
  display: flex;
  align-items: center;
  width: 160px;
  height: 45px;
  padding: 5px;
  margin-top: 10px;
}

.correction-buttons .partial-degree .ant-input {
  width: 50%;
  min-width: 50px;
  border-radius: 6px;
  border: solid 1px #87a8bb;
  height: 95%;
}

.correction-buttons .partial-degree .ant-input:focus {
  outline: none;
  border: solid 1px #87a8bb !important;
}

.correction-buttons .partial-degree .partial-mark {
  font-size: 16px;
  font-weight: 500;
  color: #283e4a;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .correction-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }
  .correction-buttons .ant-btn {
    margin: 0;
  }
}

.answer-textArea-type {
  position: relative;
}

.answer-textArea-type .correct-answer {
  border: 2px solid #06d236;
  background: white;
  padding-top: 12px;
}

.answer-textArea-type .correct-answer:hover {
  border: 2px solid #06d236 !important;
}

.answer-textArea-type .wrong-answer {
  border: 2px solid #ee3700;
  background: white;
  padding-top: 12px;
}

.answer-textArea-type .wrong-answer:hover {
  border: 2px solid #ee3700 !important;
}

.answer-textArea-type .partial-answer {
  border: 2px solid #ffb300;
  background: white;
  padding-top: 12px;
}

.answer-textArea-type .partial-answer:hover {
  border: 2px solid #ffb300 !important;
}

.answer-textArea-type .answer-mark {
  width: 21px;
  height: 21px;
  line-height: 20px;
  border-radius: 2px;
  color: white;
  position: absolute;
  right: 15px;
  top: 15px;
  text-align: center;
  padding: 0;
}

.answer-textArea-type .answer-mark.correct {
  background: #06d236;
}

.answer-textArea-type .answer-mark.wrong {
  background: #ee3700;
}

.answer-textArea-type .answer-mark.partial {
  background: #ffb300;
}

body.rtl .answer-textArea-type .answer-mark {
  right: auto;
  left: 15px;
}

.draggable .draggable-answer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.draggable .correct-answer {
  border: 1px solid #06d236 !important;
}

.draggable .correct-answer .corrrect {
  background: #06d236;
}

.draggable .wrong-answer {
  border: 1px solid #ee3700 !important;
}

.draggable .wrong-answer .wrong {
  background: #ee3700;
}

.draggable .partial-answer {
  border: 1px solid #ffb300 !important;
}

.draggable .partial-answer .partial {
  background: #ffb300;
}

.draggable .answer-mark {
  width: 21px;
  height: 21px;
  line-height: 20px;
  border-radius: 2px;
  color: white;
  text-align: center;
  padding: 0;
}

body.rtl .draggable {
  right: auto;
  left: 15px;
}

.draggable .draggable-answer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.draggable .correct-answer {
  border: 1px solid #06d236 !important;
}

.draggable .correct-answer .corrrect {
  background: #06d236;
}

.draggable .wrong-answer {
  border: 1px solid #ee3700 !important;
}

.draggable .wrong-answer .wrong {
  background: #ee3700;
}

.draggable .partial-answer {
  border: 1px solid #ffb300 !important;
}

.draggable .partial-answer .partial {
  background: #ffb300;
}

.finish-popup {
  width: 500px !important;
}

.finish-popup .ant-modal-confirm-body > .anticon {
  display: none;
}

.finish-popup .ant-modal-confirm-body .ant-modal-confirm-content {
  text-align: center;
  font-size: 18px;
  color: black;
}

.finish-popup .ant-modal-body {
  padding: 32px 20px 24px;
}

.finish-popup .degree {
  font-weight: 600;
  color: #0793c7;
}

.finish-popup .total {
  font-weight: 600;
}

@media only screen and (max-width: 575px) {
  .question-footer-res {
    flex-direction: column;
    align-items: flex-start;
  }
  .question-footer-res .finishing-btns {
    margin-top: 20px;
    display: flex;
  }
}

.rearrange_correct_answer {
  border: 1px solid #0793c7;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px;
}

.time__table--wrapper .timetable__subject--select .ant-select .ant-select-selection {
  width: 163px;
  height: 36px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding-left: 12px;
}

.time__table--wrapper .timetable__subject--select .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 12px;
  top: 41%;
}

body.rtl .time__table--wrapper .timetable__subject--select .ant-select .ant-select-selection {
  padding-left: 0;
  padding-right: 12px;
}

.time__table--wrapper .time__table--container {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-top: 22px;
  padding: 25px 0;
}

.time__table--wrapper .time__table--container .time__table--header {
  padding: 0 50px;
}

.time__table--wrapper .time__table--container .time__table--header .rangeDatepicker_timeTable .ant-input {
  border: none;
  max-width: 285px;
}

.time__table--wrapper .time__table--container .time__table--header .rangeDatepicker_timeTable .ant-input .ant-calendar-range-picker-input {
  font-size: 12px;
  font-weight: normal;
  color: #0b85b2;
}

/*************************
 * GRID SCHEDULE LAYOUT
 *************************/
@media screen and (min-width: 700px) {
  .timeTable__data--wrapper {
    display: grid;
    grid-gap: 1em;
    grid-template-rows: [table-days] auto [session-1] 1fr [session-2] 1fr [session-3] 1fr [session-4] 1fr [session-5] 1fr [session-6] 1fr [session-7] 1fr [session-8] 1fr [session-9] 1fr [session-10] 1fr [session-11] 1fr [session-12] 1fr;
    grid-template-columns: [times] 4em [sunday-start] 1fr [sunday-end monday-start] 1fr [monday-end tuesday-start] 1fr [tuesday-end wednsday-start] 1fr [wednsday-end thursday-start] 1fr [thursday-end friday-start] 1fr [friday-end saturday-start];
  }
}

.timeTable__data--wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f5f5;
  /* Small-screen & fallback styles */
  /*************************
 * VISUAL STYLES
 * Design-y stuff ot particularly important to the demo
 *************************/
}

.timeTable__data--wrapper .timeTable__data--sessions {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #00678d;
  grid-column: times;
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeTable__data--wrapper .timeTable__data--sessions span {
  transform: rotate(270deg);
}

.timeTable__data--wrapper .timeTable__data--days {
  display: none;
  /* hidden on small screens and browsers without grid support */
}

@supports (display: grid) {
  @media screen and (min-width: 700px) {
    .timeTable__data--wrapper .timeTable__data--days {
      display: block;
      padding: 10px 5px 5px;
      position: sticky;
      top: 0;
      font-size: 11px;
      font-weight: bold;
      color: #000000;
    }
  }
}

.timeTable__data--wrapper .timeTable__session--data {
  margin-bottom: 1em;
}

@supports (display: grid) {
  @media screen and (min-width: 700px) {
    .timeTable__data--wrapper .timeTable__session--data {
      margin: 0;
    }
  }
}

.timeTable__data--wrapper .timeTable__session--data {
  padding: 0.5em;
  border-radius: 2px;
  font-size: 14px;
  border: solid 1px #efeff4;
  background-color: #ffffff;
}

.timeTable__data--wrapper .session-title,
.timeTable__data--wrapper .session-time,
.timeTable__data--wrapper .session-track,
.timeTable__data--wrapper .session-presenter {
  display: block;
}

.timeTable__data--wrapper .session-title a {
  color: #fff;
  text-decoration-style: dotted;
}

.timeTable__data--wrapper .session-title a:hover {
  font-style: italic;
}

.timeTable__data--wrapper .session-title a:focus {
  outline: 2px dotted rgba(255, 255, 255, 0.8);
}

.timeTable__data--wrapper .track-all {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  color: #000;
  box-shadow: none;
}

.timeTable__data--wrapper .text {
  max-width: 750px;
  font-size: 18px;
  margin: 0 auto 50px;
}

.timeTable__data--wrapper .meta {
  color: #555;
  font-style: italic;
}

.timeTable__data--wrapper .meta a {
  color: #555;
}

.timeTable__data--wrapper hr {
  margin: 40px 0;
}

.courses-page {
  background: white;
  border-radius: 12px;
  height: auto;
  padding: 18px 14px 38px 14px;
  margin: 12px 20px;
}

@media only screen and (max-width: 575px) {
  .courses-page {
    padding: 18px 10px 38px 10px;
    margin: 12px 10px;
  }
}

.courses-header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 15px;
}

.courses-header .courses-header__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #2c363f;
  font-family: Montserrat;
}

.courses-header .courses-header__link {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: #0793c7;
  align-self: flex-end;
}

.filtration-result-wrapper .students-courses, .courses-page .students-courses {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  gap: 29px;
  height: auto !important;
  min-height: auto !important;
}

@media only screen and (max-width: 575px) {
  .filtration-result-wrapper .students-courses, .courses-page .students-courses {
    display: block !important;
  }
}

.courses__list .ant-select-selection__choice {
  display: none;
}

.filtration-result-wrapper {
  padding: 30px 32px;
  background: #f9f9fc;
  position: relative;
}

.filtration-result-wrapper .ant-pagination {
  position: fixed;
  bottom: 0;
  height: 40px;
  background-color: #fff;
  left: 50%;
  padding: 9px 60px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.22);
  margin: 18px auto;
}

.filtration-result-wrapper .students-courses {
  row-gap: 5px;
  max-height: unset !important;
  margin-bottom: 20px;
}

.filtration-result-wrapper .students-courses .student-course__header .student-course__title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.filtration-result-wrapper .students-courses .ant-empty {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 100px);
  margin-top: 0 !important;
}

.students-course {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 11px 20px 14px 20px;
  cursor: pointer;
}

.student-course__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -7px;
  padding-left: 40px;
}

.student-course__header .student-course__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 3.14;
  color: #2c363f;
  display: flex;
  word-break: break-word;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.student-course__header .student-course__title .student-course__subject-name {
  color: #2c363f;
  font-weight: bold;
  margin-inline-end: 5px;
}

.student-course__mark {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.course-teacher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -7px;
}

.course-teacher .course-teacher_info {
  display: flex;
  align-items: center;
}

.course-teacher .course-teacher_info .course-teacher__img {
  width: 34px;
  height: 34px;
  object-fit: cover;
  border-radius: 50%;
  margin-inline-end: 5px;
}

.course-teacher .course-teacher_info .course-teacher__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 3.14;
  color: #8e96aa;
}

.student-course__date-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.student-course__date {
  display: flex;
  align-items: center;
  margin-bottom: -7px;
}

.student-course__date .student-course__date-format {
  font-size: 12px;
  line-height: 3.67;
  color: #acb3c5;
  padding-inline-start: 5px;
  padding-left: 5px;
}

body.rtl .student-course__date .student-course__date-format {
  padding-left: 0;
  padding-right: 5px;
}

.student__select {
  display: flex;
  justify-content: space-between;
}

.student__select .student__select--name {
  font-size: 11px;
  font-weight: bold;
  color: #00678d;
  width: 80px;
}

.student__select .student__select--date input {
  width: 150px;
  height: 20px;
  border: none;
}

.course-description {
  font-size: 12px;
  line-height: 1.8;
  color: #57595a;
  margin: 10px 0;
  width: 75%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  padding-left: 40px;
}

.students-course_type_video {
  background: rgba(0, 173, 238, 0.05);
  border-left: solid 4px #00adee !important;
}

.students-course_type_video .student-course__mark_type_video {
  background-color: #00adee;
}

.students-course_type_audio {
  background: rgba(217, 163, 17, 0.05);
  border-left: solid 4px #d9a311 !important;
}

.students-course_type_audio .student-course__mark_type_audio {
  background-color: #d9a311;
}

.students-course_type_doc {
  background: rgba(41, 83, 150, 0.05);
  border-left: solid 4px #295396 !important;
}

.students-course_type_doc .student-course__mark_type_doc {
  background-color: #295396;
}

.students-course_type_photo {
  background: rgba(254, 120, 142, 0.05);
  border-left: solid 4px #fe788e !important;
}

.students-course_type_photo .student-course__mark_type_photo {
  background-color: #fe788e;
}

.students-course_type_multiple {
  background: rgba(118, 254, 213, 0.05);
  border-left: solid 4px #78fed6 !important;
}

.students-course_type_multiple .student-course__mark_type_multiple {
  background-color: #78fed6;
}

.course-teacher__files-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
}

.course-teacher__file {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 10px;
}

.course-teacher__file_type_video {
  background: rgba(0, 173, 238, 0.25);
}

.course-teacher__file_type_audio {
  background: rgba(217, 163, 17, 0.25);
}

.course-teacher__file_type_doc {
  background: rgba(41, 83, 150, 0.25);
}

.course-teacher__file_type_photo {
  background: rgba(254, 120, 142, 0.25);
}

.course-teacher__file_type_multiple {
  background: rgba(118, 254, 213, 0.25);
}

.courses-tab .students-courses {
  min-height: 476px;
  height: 65vh;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
  column-gap: 29px;
}

@media only screen and (max-width: 767px) {
  .courses-tab .students-courses {
    display: block !important;
  }
}

.courses-tab .students-courses .students-course {
  margin-bottom: 20px;
  height: fit-content;
  min-height: 200px;
}

@supports (height: fit-content) {
  .courses-tab .students-courses .students-course {
    display: table;
  }
}

.courses-tab .students-courses .students-course_type_video {
  border: solid 0.5px #00adee;
}

.courses-tab .students-courses .students-course_type_doc {
  border: solid 0.5px #285295;
}

.courses-tab .students-courses .students-course_type_photo {
  border: solid 0.5px #fe788e;
}

.courses-tab .students-courses .students-course_type_audio {
  border: solid 0.5px #d7a110;
}

.courses-tab .students-courses .students-course_type_multiple {
  border: solid 0.5px #78fed6;
}

.courses-tab .students-courses .ant-empty {
  height: 100px;
  margin-top: 138px;
}

.courses-tab ::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}

.courses-tab ::-webkit-scrollbar {
  width: 0px !important;
}

.courses-tab__header {
  padding: 19px 32px 0px 32px;
  background: #f9f9fc;
  padding-bottom: 10px;
}

@media only screen and (max-width: 991px) {
  .courses-tab__header {
    padding: 19px 10px 0px 10px;
  }
}

.filtration-bar {
  background-color: #fff;
  margin: 10px auto;
  height: 87px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 991px) {
  .filtration-bar {
    height: 140px;
  }
}

@media only screen and (max-width: 639px) {
  .filtration-bar {
    height: auto !important;
  }
}

.filtration-bar .ant-form-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Pridi;
}

@media only screen and (max-width: 991px) {
  .filtration-bar .ant-form-inline {
    flex-direction: column;
    align-items: unset !important;
  }
}

@media only screen and (max-width: 639px) {
  .filtration-bar .ant-form-inline {
    padding: 10px;
  }
}

.filtration-bar .ant-form-inline .filtration__inputs {
  width: 100%;
}

.filtration-bar .ant-form-inline .ant-form-item {
  width: 24%;
  margin-left: 15px;
}

@media only screen and (max-width: 639px) {
  .filtration-bar .ant-form-inline .ant-form-item {
    width: 40%;
    margin-inline-start: 0;
  }
  .filtration-bar .ant-form-inline .ant-form-item:last-of-type {
    width: 90%;
    margin-top: 10px;
  }
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item {
  margin-left: 0;
  margin-right: 15px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper {
  border: 1px solid #d2dade;
  border-radius: 6px;
  width: 100%;
  height: 38px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control {
  line-height: normal;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-select-selection__placeholder, .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-select-selection__placeholder {
  margin-left: 10px;
  margin-top: -11px;
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-select-selection__placeholder, body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-select-selection__placeholder {
  margin-right: 10px;
  margin-left: 0;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search.ant-input-affix-wrapper .ant-input-suffix, .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select.ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
  width: 0;
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search.ant-input-affix-wrapper .ant-input-suffix, body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
  left: unset;
  transform: rotate(90deg);
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-input, .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-input {
  border-bottom: none !important;
  border: none !important;
  padding: 10px !important;
  margin-inline-start: 22px;
  margin-left: 22px;
  width: calc(100% - 22px);
}

body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-input-search .ant-input, body.rtl .filtration-bar .ant-form-inline .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control .ant-select .ant-input {
  margin-left: 0;
  margin-right: 22px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label {
  position: relative;
  top: -4px;
  width: 100%;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper button {
  border: none;
  font-size: 14px;
  padding-inline-end: 0;
}

.filtration-bar .ant-form-inline .ant-form-item .ant-form-item-label label .form-item-label-wrapper button:hover {
  background: transparent;
  color: #d83025;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group {
  margin-block-start: 12px;
  display: flex;
}

@media only screen and (max-width: 991px) {
  .filtration-bar .ant-form-inline .filtration-bar__buttons-group {
    align-self: flex-end;
  }
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button {
  width: 78px;
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button:first-of-type {
  color: #2c363f;
  box-shadow: none;
  background: transparent;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button:first-of-type:hover {
  background: transparent;
  color: #d83025;
}

.filtration-bar .ant-form-inline .filtration-bar__buttons-group button.primary-fill:active, .filtration-bar .ant-form-inline .filtration-bar__buttons-group button.primary-fill:focus {
  background: #077aa3;
}

.filtration-bar.is-hidden {
  display: none;
}

.filter-btn {
  display: flex;
  justify-content: flex-end;
}

.filter-btn button {
  color: #767676;
  font-size: 16px;
  font-weight: normal;
}

.filter-btn button i {
  padding-inline-end: 8px;
}

.d-w-m-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.d-w-m-row .d-w-m-row__title {
  font-size: 24px;
  color: #222222;
  font-weight: normal;
}

.d-w-m-row .d-w-m-row__subtitle {
  font-size: 12px;
  color: #a7a7a7;
}

.d-w-m-row .d-w-m-tabs {
  display: flex;
  align-items: center;
}

.d-w-m-row .d-w-m-tabs .d-w-m-tab {
  font-size: 16px;
  font-weight: normal;
  color: #a7a7a7;
  line-height: 2.75;
  cursor: pointer;
  margin: 0 20px;
}

.d-w-m-row .d-w-m-tabs .active-tab {
  border-radius: 13px;
  border: solid 1px #0793c7;
  color: #0793c7;
  padding: 4px 13px;
  width: 57px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-filter-row {
  display: flex;
}

.tab-filter-row .filter-tabs {
  display: flex;
  visibility: hidden;
  align-items: center;
  margin-bottom: 10px;
}

.tab-filter-row .filter-tabs .filter-tab {
  font-size: 16px;
  color: #767676;
  margin-inline-end: 30px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tab-filter-row .filter-tabs .filter-tab:hover {
  color: #0793c7 !important;
}

.tab-filter-row .filter-tabs .active-tab {
  color: #0793c7 !important;
  border-bottom: 2px solid #0793c7;
}

.courses-tab__content {
  display: flex;
  align-items: center;
  align-items: flex-start;
  padding: 0 35px 20px 27px;
  background: #f9f9fc;
  margin-top: 25px;
  position: relative;
}

.courses-tab__content .students-courses {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 25px 16px;
  border-radius: 10px 0 0 10px;
}

body.rtl .courses-tab__content .students-courses {
  border-radius: 0 10px 10px 0;
}

@media only screen and (max-width: 767px) {
  .courses-tab__content .students-courses {
    padding: 25px 10px;
  }
}

@media only screen and (max-width: 639px) {
  .courses-tab__content {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 991px) {
  .courses-tab__content {
    padding: 0 10px 20px 10px;
  }
}

.courses-tab__content body.rtl .ant-popover {
  left: 30% !important;
}

.popover__header-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.22;
  color: #000000;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popover__header-title .bold {
  font-weight: bold;
  margin-inline-end: 10px;
}

.ant-popover-title {
  border: none;
  min-width: 280px;
  max-width: 280px;
  padding-top: 15px;
}

.popover-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popover__close-icon {
  color: #f80000;
  font-size: 22px;
  cursor: pointer;
}

.popover__content .teacher-info {
  display: flex;
  align-items: flex-start;
}

.popover__content .popover__content-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-inline-end: 10px;
}

.popover__content .popover__content-teacher {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  margin-bottom: 5px;
}

.popover__content .date {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.popover__content .date .date-format {
  font-size: 12px;
  color: #00adee;
  margin-left: 5px;
}

body.rtl .popover__content .date .date-format {
  margin-right: 5px;
  margin-left: 0;
}

.popover__content .date .education {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .popover__content .date .education {
  margin-right: 5px;
  margin-left: 0;
}

.popover__content .icon-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popover__content .icon-holder .icon {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .icon-holder .icon-video:before {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon-video:before {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .icon-holder .icon-doc:before {
  margin-right: 5px;
}

body.rtl .popover__content .icon-holder .icon-doc:before {
  margin-left: 5px;
  margin-right: 0;
}

.popover__content .info-holder {
  width: 100%;
}

.popover__content .content-paragraph {
  font-size: 13px;
  font-weight: normal;
  color: #3c3c38;
  border-top: solid 1px #dfdfdf;
  padding-top: 5px;
  margin-top: 5px;
}

.popover__content .popover__footer {
  padding-top: 15px;
  display: flex;
  justify-content: center;
}

.popover__content .popover__footer .popover__button {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  height: 34px;
  border-radius: 6px;
  background-color: #0793c7;
  padding: 0 60px;
}

.ant-popover-inner-content {
  min-width: 280px;
  max-width: 280px;
}

.course-deatails {
  margin: 0 32px 22px 32px;
  padding: 13px 21px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}

@media only screen and (max-width: 639px) {
  .course-deatails {
    margin: 0 10px 22px 10px;
    padding: 13px 10px;
  }
}

.course-deatails .date {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-inline-end: 25px;
}

.course-deatails .date .date-format {
  font-size: 12px;
  color: #00adee;
  margin-left: 5px;
}

body.rtl .course-deatails .date .date-format {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .date .education {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .course-deatails .date .education {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .date .teacher-name {
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  margin-left: 5px;
}

body.rtl .course-deatails .date .teacher-name {
  margin-right: 5px;
  margin-left: 0;
}

.course-deatails .first-part {
  display: flex;
  justify-content: space-between;
  padding-left: 21;
}

body.rtl .course-deatails .first-part {
  padding-right: 21px;
  padding-left: 0;
}

@media only screen and (max-width: 767px) {
  .course-deatails .first-part {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
}

.course-deatails .pragraph-holder {
  flex-basis: 70%;
  padding-top: 3px;
  margin-left: 25px;
}

body.rtl .course-deatails .pragraph-holder {
  margin-right: 25px;
  margin-left: 0;
}

.course-deatails .teacher-image {
  width: 145px;
  height: 145px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  padding: 2px;
}

.course-deatails .teacher-image img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.course-deatails .content-paragraph {
  font-size: 16px;
  line-height: 1.56;
  color: #3c3c38;
  padding-bottom: 20px;
  padding-top: 20px;
}

.course-deatails .icon-holder {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.course-deatails .icon-holder .icon {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .icon-holder .icon-video:before {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon-video:before {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .icon-holder .icon-doc:before {
  margin-right: 5px;
}

body.rtl .course-deatails .icon-holder .icon-doc:before {
  margin-right: 0;
  margin-left: 5px;
}

.course-deatails .teacher-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course-deatails .teacher-holder .date {
  padding-top: 8px;
}

@media only screen and (max-width: 767px) {
  .course-deatails .teacher-holder {
    margin-bottom: 10px;
  }
}

.course-deatails hr {
  width: 95%;
  border-top: 0.5px solid #f9f9fc;
}

.no-course__image {
  width: 50%;
}

.clear-btn {
  border: none;
  background: transparent;
  color: #d83025 !important;
}

.clear-btn:hover {
  color: #d83025 !important;
  background: transparent !important;
}

.courses-block {
  display: block !important;
  position: relative;
  height: 100%;
  min-height: 400px;
}

.full-border {
  border-radius: 10px !important;
}

@media only screen and (max-width: 639px) {
  .course-fullwidth {
    width: 100% !important;
  }
  .students-courses {
    border-radius: 10px !important;
  }
}

body.rtl .courses-tab .ant-tabs {
  direction: ltr;
}

body.rtl .courses-tab .ant-tabs-nav {
  transform: scaleX(-1);
}

body.rtl .courses-tab .ant-tabs-tab {
  transform: scaleX(-1);
}

body.rtl .courses-tab .ant-tabs-nav-scroll {
  display: flex;
  flex-direction: row-reverse;
}

.customize-calendar {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  margin-inline-start: 2px;
  min-width: 265px;
  height: 60vh;
  min-height: 476px;
  position: relative;
}

body.rtl .customize-calendar {
  border-radius: 10px 0 0 10px;
}

.customize-calendar .ant-fullcalendar-value {
  padding-top: 6px;
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
}

.customize-calendar .ant-fullcalendar {
  border-top: none !important;
  border-bottom: 1px solid #f9f9fc;
}

.customize-calendar .ant-fullcalendar-header {
  display: none;
}

.customize-calendar .calendar-header {
  display: flex;
  padding: 25px 20px 25px 20px;
  align-items: center;
  justify-content: space-around;
}

.customize-calendar .calendar-header__button {
  width: 33px;
  height: 33px;
  border: solid 1px #868ea4;
  border-radius: 50%;
  color: #868ea4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
}

body.rtl .customize-calendar .calendar-header__button {
  transform: rotate(180deg);
}

.customize-calendar .calendar-header__button:focus {
  outline: none !important;
}

.customize-calendar .calendar-current-date {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  font-family: Pridi;
}

.customize-calendar .calendar-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
}

.customize-calendar .calendar-footer__button {
  height: 38px;
  border-radius: 6px;
  background-color: #0793c7;
  padding: 0 43px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.customize-calendar .calendar-footer__button:focus {
  outline: none !important;
}

@media only screen and (max-width: 767px) {
  .customize-calendar {
    margin-bottom: 20px;
  }
}

.calender-show-hide {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
}

body.rtl .calender-show-hide {
  margin-left: 50px;
}

.calender-show-hide .show-hide-arrow {
  width: 22px;
  height: 22px;
  background-color: #e7e9ea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.calender-show-hide .show-hide-arrow img {
  width: 10px;
}

.calender-show-hide .calendar-current-date {
  margin: 0 7px;
  cursor: pointer;
}

.no-calender {
  display: none;
}

.rotate-arrow {
  transform: rotate(180deg);
  transition: transform 0.3s linear;
}

.rotate-arrow-reverse {
  transform: rotate(360deg);
  transition: transform 0.3s linear;
}

@media only screen and (max-width: 639px) {
  .calendar {
    position: absolute !important;
    right: calc((100vw - 340px) / 2);
    top: -15px;
    z-index: 6;
  }
  .calendar .customize-calendar {
    border-radius: 10px !important;
    height: auto;
    min-height: auto;
  }
  .calendar .customize-calendar .calendar-footer {
    min-height: 53px;
  }
}

.feadback-popover .no-feedback {
  text-align: center;
}

.feadback-popover .no-feedback__paragraph {
  opacity: 0.38;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 1.38;
  color: #57595a;
  margin-top: 30px;
}

.schedule-teacher-wrapper {
  padding-top: 25px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  /* start times */
  /* end times */
}

.schedule-teacher-wrapper button {
  height: 30px;
  width: 100px;
  margin: 0 5px;
  background-color: gainsboro;
  font-variant: small-caps;
  font-size: 14px;
  border-radius: 6px;
  box-shadow: 2px 5px #5c5c5c;
}

.schedule-teacher-wrapper button:hover {
  background-color: #949494;
}

.schedule-teacher-wrapper button:active {
  transform: translateY(2px);
}

.schedule-teacher-wrapper .myCourses {
  margin-left: 10px;
}

.schedule-teacher-wrapper .titleStyle {
  color: #2c2e33;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
  margin-bottom: 10px;
}

.schedule-teacher-wrapper .sectionSelectors {
  margin-bottom: 20px;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
}

.schedule-teacher-wrapper .sectionTitle {
  margin-bottom: 20px;
  font-variant: small-caps;
  width: 1100px;
  text-align: center;
  font-size: 22px;
}

.schedule-teacher-wrapper .timetable {
  display: grid;
  grid-gap: 0 10px;
  grid-template-columns: 130px repeat(5, 140px);
  grid-template-rows: repeat(20, 67px);
  background: url(../../resources/images/schedule-bg.svg);
}

.schedule-teacher-wrapper .titleWrapper {
  display: grid;
  grid-column: 1 / 7;
  grid-gap: 10px;
  grid-template-columns: 130px repeat(5, 140px);
  justifytitleWrapper-items: center;
  background-color: #ffffff;
}

.schedule-teacher-wrapper .titleWrapper > p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule-teacher-wrapper .titleWrapper > p.active {
  color: #0793c7;
}

.schedule-teacher-wrapper .timeWrapper {
  display: grid;
  grid-row: 2 / 21;
  grid-gap: 10px;
  grid-template-rows: repeat(20, 58px);
  background-color: #ffffff;
}

.schedule-teacher-wrapper .timeWrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.schedule-teacher-wrapper .timeWrapper > div time {
  font-size: 12px;
  color: #7a8390;
  font-weight: normal;
}

.schedule-teacher-wrapper .timeWrapper > div p {
  font-size: 12px;
  color: rgba(122, 131, 144, 0.55);
  font-weight: normal;
}

.schedule-teacher-wrapper p {
  margin: 0;
  padding: 0;
}

.schedule-teacher-wrapper .tableElement {
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  color: #2c363f;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}

.schedule-teacher-wrapper .tableElement.green {
  border: solid 1px #06d236;
}

.schedule-teacher-wrapper .tableElement.blue {
  border: solid 1px #0793c7;
}

.schedule-teacher-wrapper .tableElement.yellow {
  border: solid 1px #ffb300;
}

.schedule-teacher-wrapper .tableElement span:first-of-type {
  font-size: 10px;
  font-weight: normal;
  color: #91999c;
}

.schedule-teacher-wrapper .tableElement span:last-of-type {
  font-size: 10px;
  font-weight: 500;
  color: rgba(44, 54, 63, 0.68);
}

.schedule-teacher-wrapper .tableElement:hover {
  animation: pulse 0.3s linear 1;
  cursor: pointer;
}

@keyframes pulse {
  50% {
    transform: scale(0.97);
  }
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(2) {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(3) {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(4) {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(5) {
  grid-column: 1 / 2;
  grid-row: 5 / 6;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(6) {
  grid-column: 1 / 2;
  grid-row: 6 / 7;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(7) {
  grid-column: 1 / 2;
  grid-row: 7 / 8;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(8) {
  grid-column: 1 / 2;
  grid-row: 8 / 9;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(9) {
  grid-column: 1 / 2;
  grid-row: 9 / 10;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(10) {
  grid-column: 1 / 2;
  grid-row: 10 / 11;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(11) {
  grid-column: 1 / 2;
  grid-row: 11 / 12;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(12) {
  grid-column: 1 / 2;
  grid-row: 12 / 13;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(13) {
  grid-column: 1 / 2;
  grid-row: 13 / 14;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(14) {
  grid-column: 1 / 2;
  grid-row: 14 / 15;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(15) {
  grid-column: 1 / 2;
  grid-row: 15 / 16;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(16) {
  grid-column: 1 / 2;
  grid-row: 16 / 17;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(17) {
  grid-column: 1 / 2;
  grid-row: 17 / 18;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(18) {
  grid-column: 1 / 2;
  grid-row: 18 / 19;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(19) {
  grid-column: 1 / 2;
  grid-row: 19 / 20;
}

.schedule-teacher-wrapper .timeWrapper div:nth-of-type(20) {
  grid-column: 1 / 2;
  grid-row: 20 / 21;
}

.schedule-teacher-wrapper .timeColumn {
  grid-column: 1 / 2;
}

.schedule-teacher-wrapper .sunday {
  grid-column: 2 / 3;
}

.schedule-teacher-wrapper .monday {
  grid-column: 3 / 4;
}

.schedule-teacher-wrapper .tuesday {
  grid-column: 4 / 5;
}

.schedule-teacher-wrapper .wednesday {
  grid-column: 5 / 6;
}

.schedule-teacher-wrapper .thursday {
  grid-column: 6 / 7;
}

.schedule-teacher-wrapper .eightThirty {
  grid-row-start: 2;
}

.schedule-teacher-wrapper .nineOclock {
  grid-row-start: 3;
}

.schedule-teacher-wrapper .nineThirty {
  grid-row-start: 4;
}

.schedule-teacher-wrapper .tenOclock {
  grid-row-start: 5;
}

.schedule-teacher-wrapper .tenThirty {
  grid-row-start: 6;
}

.schedule-teacher-wrapper .elevenOclock {
  grid-row-start: 7;
}

.schedule-teacher-wrapper .elevenThirty {
  grid-row-start: 8;
}

.schedule-teacher-wrapper .twelveOclock {
  grid-row-start: 9;
}

.schedule-teacher-wrapper .twelveThirty {
  grid-row-start: 10;
}

.schedule-teacher-wrapper .oneOclock {
  grid-row-start: 11;
}

.schedule-teacher-wrapper .oneThirty {
  grid-row-start: 12;
}

.schedule-teacher-wrapper .twoOclock {
  grid-row-start: 13;
}

.schedule-teacher-wrapper .twoThirty {
  grid-row-start: 14;
}

.schedule-teacher-wrapper .threeOclock {
  grid-row-start: 15;
}

.schedule-teacher-wrapper .threeThirty {
  grid-row-start: 16;
}

.schedule-teacher-wrapper .fourOclock {
  grid-row-start: 17;
}

.schedule-teacher-wrapper .fourThirty {
  grid-row-start: 18;
}

.schedule-teacher-wrapper .fiveOclock {
  grid-row-start: 19;
}

.schedule-teacher-wrapper .endNineThirty {
  grid-row-end: 4;
}

.schedule-teacher-wrapper .endTenOclock {
  grid-row-end: 5;
}

.schedule-teacher-wrapper .endTenThirty {
  grid-row-end: 6;
}

.schedule-teacher-wrapper .endElevenOclock {
  grid-row-end: 7;
}

.schedule-teacher-wrapper .endElevenThirty {
  grid-row-end: 8;
}

.schedule-teacher-wrapper .endTwelveOclock {
  grid-row-end: 9;
}

.schedule-teacher-wrapper .endTwelveThirty {
  grid-row-end: 10;
}

.schedule-teacher-wrapper .endOneOclock {
  grid-row-end: 11;
}

.schedule-teacher-wrapper .endOneThirty {
  grid-row-end: 12;
}

.schedule-teacher-wrapper .endTwoOclock {
  grid-row-end: 13;
}

.schedule-teacher-wrapper .endTwoThirty {
  grid-row-end: 14;
}

.schedule-teacher-wrapper .endThreeOclock {
  grid-row-end: 15;
}

.schedule-teacher-wrapper .endThreeThirty {
  grid-row-end: 16;
}

.schedule-teacher-wrapper .endFourOclock {
  grid-row-end: 17;
}

.schedule-teacher-wrapper .endFourThirty {
  grid-row-end: 18;
}

.schedule-teacher-wrapper .endFiveOclock {
  grid-row-end: 19;
}

.schedule-teacher-wrapper .endFiveThirty {
  grid-row-end: 20;
}

.schedule-teacher-wrapper .endSixOclock {
  grid-row-end: 21;
}

.tableElement {
  position: relative;
}

.tableElement .course__details {
  box-shadow: 0 3px 62px 0 rgba(0, 0, 0, 0.36);
  border: solid 1px #0793c7;
  background-color: #ffffff;
  width: 209px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  padding: 14px;
  z-index: 999999999;
}

.tableElement .course__details .course__details--name {
  font-size: 16px;
  font-weight: 500;
  color: #2c363f;
  margin-bottom: 9px;
}

.tableElement .course__details > span {
  font-size: 12px;
  font-weight: normal;
  color: #91999c;
}

.tableElement .course__details .course__details--type {
  font-size: 14px;
  font-weight: 300;
  color: #0793c7;
  margin: 25px 0;
}

.tableElement .course__details .course__details--avatar {
  display: flex;
}

.tableElement .course__details .course__details--avatar span {
  width: 24px;
  font-size: 10px;
  height: 24px;
  border-radius: 50%;
  margin-right: 3px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableElement .course__details .course__details--avatar span:last-child {
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
}

.tableElement .course__details .meeting_start {
  display: block;
  border-radius: 4px;
  background-color: #0793c7;
  margin: 25px 13px 0;
  height: 35px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-wrapper {
  width: calc(100% - 256px);
  margin-left: 35px;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .tab-wrapper {
    margin-left: 0;
    padding: 0 13px;
  }
}

@media only screen and (max-width: 991px) {
  .tab-wrapper {
    margin-left: 0;
    width: 100%;
  }
}

.tab-wrapper.has-quiz {
  width: 100%;
}

body.rtl .tab-wrapper {
  margin-left: 0;
  margin-right: 35px;
}

@media only screen and (max-width: 767px) {
  body.rtl .tab-wrapper {
    margin-right: 0;
    padding: 0 13px;
  }
}

.tab-wrapper .tab-content .ant-table-content {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.tab-wrapper .tab-content .timeline-content-wrapper {
  margin: 0;
  margin-top: 20px;
}

.tab-wrapper .attendance-action-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0 25px 0;
}

.tab-wrapper .attendance-action-holder .ant-calendar-picker-input {
  border: 0 !important;
  height: 37px;
  background-color: #ffffff !important;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.class-navigation-wrapper {
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cccccc;
  border-radius: 6px;
  width: 100%;
  position: relative;
  z-index: 6;
}

@media only screen and (max-width: 639px) {
  .class-navigation-wrapper {
    background-color: whitesmoke;
    box-shadow: none;
    border: none;
  }
}

.class-navigation-wrapper .class-navigation-holder {
  display: flex;
  overflow-x: auto;
}

@media only screen and (max-width: 639px) {
  .class-navigation-wrapper .class-navigation-holder {
    flex-direction: row;
    height: 70px;
  }
}

.class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #00b9ff;
  padding: 0 10px;
  cursor: pointer;
}

.class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item i {
  font-size: 20px;
  margin: 5px 0;
  padding: 10px;
}

@media only screen and (max-width: 1199px) {
  .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item i {
    font-size: 15px;
    margin: 3px 0;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item i {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1199px) {
  .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item p {
    font-size: 13px;
    margin-top: 2px;
  }
}

.class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item:hover, .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item.active {
  color: #095c7a;
  font-weight: bold;
}

@media only screen and (max-width: 639px) {
  .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 128px;
    width: 128px;
    height: 40px;
    background-color: white;
    margin: auto 10px;
    border-radius: 7px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    color: #d0d0d0;
  }
  .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item:hover, .class-navigation-wrapper .class-navigation-holder .class-navigation-holder__item.active {
    color: #00b9ff;
    font-weight: bold;
  }
}

/* .class-navigation-wrapper:first-child{
    div{
        margin-right: auto;
        //margin-left: 10px;
        font-size: 14px;
        @include mq("tablet-qc"){
            font-size: 10px;
        }
       
        
    }
  
    
} */
.division_select .ant-select-selection {
  color: #00678d !important;
}

.class-navigation {
  display: flex;
  margin: auto;
  margin-left: 0px;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .class-navigation {
    display: flex;
    flex-direction: column;
  }
}

.class-navigation .selection-class {
  display: flex;
  flex-direction: column;
  width: 18%;
  justify-content: center;
}

@media only screen and (max-width: 639px) {
  .class-navigation .selection-class {
    width: 100%;
  }
}

.specialNote-modal .ant-modal-content {
  border-radius: 4px;
}

.specialNote-modal .ant-modal-content .modal-title {
  display: flex;
}

.specialNote-modal .ant-modal-content .modal-title h5 {
  color: #00678d;
  margin-left: 5px;
}

body.rtl .specialNote-modal .ant-modal-content .modal-title h5 {
  margin-right: 5px;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-form-item input, .specialNote-modal .ant-modal-content .specialNote-wrapper .ant-form-item .ant-select {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-select .ant-select-selection {
  height: 45px;
  font-size: 14px;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-select .ant-select-selection__choice__content .anticon-plus-circle {
  display: none;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-select .ant-select-selection__placeholder {
  padding: 0 16px;
  font-size: 14px;
  margin-left: 0;
  font-weight: lighter;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-select .ant-select-search--inline {
  margin-top: 0;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .ant-select .ant-select-search--inline input {
  border: none;
  box-shadow: none;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .k-editor {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

.specialNote-modal .ant-modal-content .specialNote-wrapper .k-editor .k-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.student-target-dropdown__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.results {
  padding: 20px;
}

@media only screen and (max-width: 399px) {
  .results {
    padding: 5px;
  }
}

.results .tabs {
  display: flex;
  align-items: center;
}

.results .tab {
  font-size: 16px;
  line-height: 1.75;
  color: #767676;
  margin: 0 13px;
  cursor: pointer;
}

@media only screen and (max-width: 575px) {
  .results .tab {
    font-size: 12px;
  }
}

.results .active {
  color: #0793c7;
  border-bottom: 1px solid #0793c7;
}

.results .results__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .results .results__header {
    padding: 0;
  }
}

.results .results__header .ant-btn > span {
  color: #767676 !important;
  font-size: 16px !important;
  margin: 0 5px !important;
}

.results .results__empty {
  text-align: center;
  display: none;
}

.results .results__empty .title {
  font-size: 24px;
  font-weight: 500;
  color: #868ea4;
}

.results .results__empty .subtitle {
  opacity: 0.75;
  font-size: 17px;
  font-weight: 300;
  color: #868ea4;
  letter-spacing: -0.24px;
}

.results .results__empty .empty__img {
  width: 50%;
  max-width: 350px;
}

.results .result__cards {
  display: flex;
  border: solid 1px #f4f4f4;
  border-radius: 6px;
  overflow: hidden;
  margin: 15px 0;
}

@media only screen and (max-width: 991px) {
  .results .result__cards {
    flex-direction: column;
  }
}

.results .result__cards .student__term {
  width: 106px;
  height: 92px;
  opacity: 0.87;
  border: solid 1px #f4f4f4;
  background-color: #4f6170;
  color: white;
  text-align: center;
  padding: 15px 17px;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .student__term {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
  }
}

.results .result__cards .student__term p:first-of-type {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid white;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .student__term p:first-of-type {
    border-bottom: none;
    border-right: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-right: 5px;
    padding-right: 5px;
  }
  body.rtl .results .result__cards .student__term p:first-of-type {
    border-left: 1px solid white;
    margin-right: 0;
    padding-right: 0;
    margin-left: 5px;
    padding-left: 5px;
    border-right: none;
  }
}

.results .result__cards .student__term p {
  font-size: 11px;
}

.results .result__cards .details-bar {
  display: flex;
  flex: 1 1;
  background: white;
  padding: 10px;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .details-bar {
    flex-direction: column;
  }
}

.results .result__cards .details-bar .details-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.results .result__cards .details-bar .result-holder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  align-items: center;
}

.results .result__cards .details-bar .total-result {
  display: flex;
  width: 30%;
}

@media only screen and (max-width: 1023px) {
  .results .result__cards .details-bar .total-result {
    display: block;
    width: unset;
    margin-right: 15px;
  }
  body.rtl .results .result__cards .details-bar .total-result {
    margin-right: 0;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .results .result__cards .details-bar .total-result {
    display: flex;
    width: 65%;
  }
}

@media only screen and (max-width: 399px) {
  .results .result__cards .details-bar .total-result {
    display: flex;
    width: 90%;
  }
}

.results .result__cards .details-bar .details-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  line-height: 1.5;
  margin-right: 15px;
  margin-left: 0;
}

body.rtl .results .result__cards .details-bar .details-title {
  margin-right: 0;
  margin-left: 15px;
}

.results .result__cards .details-bar .details-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #2c363f;
  line-height: 1.71;
  border-right: solid 1px #f4f4f4;
  padding-right: 15px;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .details-bar .details-subtitle {
    margin: 10px 0;
  }
}

body.rtl .results .result__cards .details-bar .details-subtitle {
  border-left: solid 1px #f4f4f4;
  border-right: none;
  padding-right: 0;
  padding-left: 15px;
}

.results .result__cards .details-bar .details-subtitle:nth-of-type(2) {
  margin: 0 15px;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .details-bar .details-subtitle:nth-of-type(2) {
    margin: 10px 15px;
  }
}

.results .result__cards .details-bar button {
  align-self: center;
}

@media only screen and (max-width: 991px) {
  .results .result__cards .details-bar button {
    margin-top: 10px;
  }
}

.results .courses-tab__header {
  padding: 19px 10px 0px 10px;
}

.classroom-holder .classroom__header {
  display: flex;
  align-items: center;
  padding: 20px 30px 36px 0px;
}

.classroom {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(155px, 155px));
  gap: 18px;
}

@media only screen and (max-width: 639px) {
  .classroom {
    grid-template-columns: repeat(auto-fill, minmax(155px, 130px));
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 360px) {
  .classroom {
    width: unset;
  }
}

.classroom__student {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #c0c0c0;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  height: 145px;
}

.classroom__student .student__img-holder {
  width: 100%;
  height: 78px;
}

.classroom__student .student__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.classroom__student .student__name {
  font-family: SegoeUI;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  line-height: 1.31;
  text-align: center;
  padding: 10px 0;
  border-bottom: solid 1px #c0c0c0;
}

.student__status {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.student__status p {
  font-size: 9px;
  padding: 2px 4px;
}

.student__status .badgs {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  margin: 0 2px;
  flex-basis: 50%;
}

.student__status .badgs:not(:last-of-type) {
  border-right: 1px solid #c0c0c0;
}

body.rtl .student__status .badgs:not(:last-of-type) {
  border-right: none;
  border-left: 1px solid #c0c0c0;
}

.student__status .student__attendance img {
  width: 18.8px;
  height: 18.2px;
  margin: 0px 1px;
}

.student__status .opacity {
  opacity: 0.3;
}

.badgs-modal .ant-modal {
  width: 80% !important;
}

@media only screen and (max-width: 767px) {
  .badgs-modal .ant-modal {
    width: 97% !important;
    height: 100% !important;
  }
}

.badgs-modal .ant-modal-header {
  border: none;
}

.badgs-modal .ant-modal-footer {
  border: none;
}

.badgs-modal .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #00678d;
}

.badgs-modal .ant-modal-title .modal-icon {
  margin-right: 10px;
}

body.rtl .badgs-modal .ant-modal-title .modal-icon {
  margin-right: 10px;
  margin-left: 0;
}

.badgs-modal .tab-content {
  margin-top: 20px;
  padding: 0 20px;
}

@media only screen and (max-width: 639px) {
  .badgs-modal .tab-content {
    padding: 0 5px;
  }
}

.badgs-modal .ant-modal-body {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

@media only screen and (max-width: 639px) {
  .badgs-modal .ant-modal-body {
    padding: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .badgs-modal .tab-list-item-holder {
    width: 40% !important;
  }
}

@media only screen and (max-width: 767px) {
  .badgs-modal .tab-list {
    justify-content: unset !important;
    overflow-y: scroll;
    /* width */
    /* Track */
    /* Handle */
  }
  .badgs-modal .tab-list::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }
  .badgs-modal .tab-list::-webkit-scrollbar-track {
    background: transparent !important;
    width: 0 !important;
    height: 0;
  }
  .badgs-modal .tab-list::-webkit-scrollbar-thumb {
    background: transparent !important;
    width: 0 !important;
    height: 0;
  }
}

.badgs-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 170px));
  gap: 25px;
}

@media only screen and (max-width: 639px) {
  .badgs-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 479px) {
  .badgs-list {
    grid-template-columns: 1fr;
  }
}

.badgs-list .badgs-list__item {
  border-radius: 4px;
  border: solid 1px #dddbdb;
  background-color: #fafafa;
  height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.badgs-list .badgs-list__title {
  font-size: 20px;
  color: #4e4e4e;
  line-height: 1.2;
  margin-top: 20px;
}

.badgestab-holder .tab-list-item {
  border: none !important;
  width: 50% !important;
}

@media only screen and (max-width: 991px) {
  .badgestab-holder .tab-list-item {
    width: 100% !important;
  }
}

.badgestab-holder .tab-list-active {
  color: #00adee !important;
  background: none !important;
  border-bottom: 1px solid #00adee !important;
}

.food-tab {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.food-tab .meal-img {
  margin-right: 10px;
}

body.rtl .food-tab .meal-img {
  margin-right: 0;
  margin-left: 10px;
}

.food-tab > .ant-radio-wrapper ~ p img {
  filter: grayscale(100%) !important;
}

.food-tab > .ant-radio-wrapper:not(:first-of-type).ant-radio-wrapper-checked ~ p {
  color: #00adee;
}

.food-tab > .ant-radio-wrapper:not(:first-of-type).ant-radio-wrapper-checked ~ p img {
  filter: grayscale(0) !important;
}

.food-tab p {
  min-width: 100px;
  font-size: 16px;
  font-weight: bold;
  color: #4e4e4e;
}

.food-tab p img {
  width: 28px;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper {
  direction: ltr;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body.rtl .clock-modal-warapper .ant-modal-body .react-timekeeper {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-minute-wrapper {
  width: fit-content;
  margin: 0;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-minute-wrapper span {
  font-size: 25px;
  font-weight: 600;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-minute-wrapper span.css-3u52cg-TopBar {
  color: #00adee;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-colon {
  font-size: 27px;
  font-weight: 600;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-hour-wrapper span {
  font-size: 25px;
  font-weight: 600;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .react-timekeeper__tb-hour-wrapper span.css-1uynwhj-TopBar {
  color: #00adee;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__top-bar .css-664x9x-TopBar {
  font-size: 11px;
  font-weight: 600;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__clock-hand {
  stroke: #00adee;
}

.clock-modal-warapper .ant-modal-body .react-timekeeper__hand-circle-center {
  fill: #00adee;
}

.clock-modal-warapper .ant-modal-body .css-1r6tdpo-Meridiems .react-timekeeper-button-reset {
  width: 34px;
  height: 34px;
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.clock-modal-warapper .ant-modal-body .clock-footer {
  font-size: 12px;
  line-height: 1.25;
  color: #d01b1b;
  text-align: center;
  padding: 7px 10px;
}

.clock-modal-warapper .ant-modal-body .clock-footer button {
  width: 103px !important;
  min-height: 33px !important;
  max-height: 33px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  margin-top: 8px;
}

body.rtl .clock-modal-warapper .ant-modal-body .clock-footer {
  font-size: 100%;
}

@media only screen and (max-width: 575px) {
  .clock-modal-warapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .clock-modal-warapper .ant-modal-content {
    background-color: transparent;
  }
}

.time-input-holder {
  width: 100%;
  position: relative;
  height: 35px;
  font-size: 15px;
  margin: 10px 0;
  background-color: #fafafa !important;
  color: #8d8d8d !important;
}

.time-input-holder::after {
  content: '\01F550' !important;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

body.rtl .time-input-holder::after {
  right: auto;
  left: 8px;
}

.sleep-tap {
  display: flex;
  align-items: flex-start;
}

.sleep-tap .moon-box {
  width: 170px;
  height: 100px;
  border-radius: 4px;
  border: solid 2px #00adee;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sleep-tap .time-inputs {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .sleep-tap .time-inputs {
    flex-direction: column;
    align-items: normal;
  }
}

.sleep-tap .time-input {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

@media only screen and (max-width: 639px) {
  .sleep-tap .time-input {
    margin: 0 5px;
  }
}

.sleep-tap .time-input__lable {
  font-size: 14px;
  line-height: 1.64;
  margin: 0 15px;
}

.sleep-tap .add-time {
  width: 14px;
  height: 14px;
  background-color: #05ff0d;
  border-radius: 50%;
  margin: 0 10px;
  padding-top: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sleep-tap .add-time-lable {
  font-size: 14px;
  line-height: 1.64;
  color: #a2a2a2;
}

.homework__wrapper .homework_wrapper--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework_wrapper--header {
    flex-wrap: wrap;
  }
}

.homework__wrapper .homework_wrapper--header > h3 {
  font-size: 20px;
  color: #2a7d9c;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

.homework__wrapper .homework_wrapper--header > h3 img {
  margin-right: 10px;
}

body.rtl .homework__wrapper .homework_wrapper--header > h3 img {
  margin-right: 0;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework_wrapper--header > h3 {
    margin-bottom: 21px;
  }
}

.homework__wrapper .homework_wrapper--header .form_holder {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework_wrapper--header .form_holder {
    flex-wrap: wrap;
  }
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters {
  /*  width: 150px;
                margin-left: 14px;
                margin-bottom: 18px; */
  width: 100%;
  margin-bottom: 18px;
  padding: 0px 10px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework_wrapper--header .form_holder .homework__filters {
    margin-left: 0;
    margin-right: 14px;
  }
  .homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-form-explain {
    font-size: 9px !important;
  }
}

body.rtl .homework__wrapper .homework_wrapper--header .form_holder .homework__filters {
  margin-left: 0;
  margin-right: 14px;
}

@media only screen and (max-width: 767px) {
  body.rtl .homework__wrapper .homework_wrapper--header .form_holder .homework__filters {
    margin-right: 0;
    margin-left: 14px;
  }
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select {
  max-height: 33px;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select .ant-select-selection {
  background: #ffffff;
  border-radius: 4px;
  height: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select .ant-select-selection .ant-select-selection__rendered {
  padding: 0 6px;
  height: 100%;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  height: 100%;
  display: flex !important;
  align-items: center;
  font-size: 11px;
  padding-top: 6px;
  font-weight: bold;
  color: #00678d;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 11px;
  font-weight: normal;
  color: #929191;
  margin-left: 10px;
}

body.rtl .homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-select .ant-select-selection .ant-select-selection__placeholder {
  margin-right: 10px;
  margin-left: 0px;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-calendar-picker {
  min-width: unset !important;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-calendar-picker {
    width: 100% !important;
  }
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-calendar-picker input::placeholder {
  font-size: 11px;
  font-weight: normal;
  color: #929191;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input,
.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input-number {
  background-color: #ffffff !important;
  border-radius: 4px;
  font-weight: bold;
  color: #00678d;
  font-size: 11px;
  height: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 1px #b1cbd4;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input .ant-input-number-input-wrap,
.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input-number .ant-input-number-input-wrap {
  height: 100%;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input input,
.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input-number input {
  font-weight: bold;
  color: #00678d;
  font-size: 11px;
  height: 100%;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input input::placeholder,
.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input-number input::placeholder {
  font-size: 11px;
  font-weight: normal;
  color: #929191;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .ant-input-number {
  margin-top: 0px;
  padding: 0px 10px;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters .homework__dropdown--menu {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters.homework__filters--quiz input {
  height: 38px !important;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters.homework__filters--quiz .ant-select {
  max-height: unset !important;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters.homework__filters--quiz .ant-select .ant-select-selection {
  height: 38px !important;
}

.homework__wrapper .homework_wrapper--header .form_holder .homework__filters.homework__filters--quiz .ant-select .ant-select-selection svg {
  fill: #929191 !important;
}

.homework__wrapper .homework__wrapper--body {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 10px 10px 0;
  display: flex;
  align-items: center;
}

.homework__wrapper .homework__wrapper--body .homework__body--left {
  padding-right: 29px;
}

body.rtl .homework__wrapper .homework__wrapper--body .homework__body--left {
  padding-right: 0;
  padding-left: 29px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--body .homework__body--left {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.homework__wrapper .homework__wrapper--body .homework__body--left .k-editor .k-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.homework__wrapper .homework__wrapper--body .homework__body--left .k-editor .k-toolbar::before {
  height: unset;
}

.homework__wrapper .homework__wrapper--body .homework__body--left .k-editor .k-button-icon {
  padding: 0;
  width: calc(1.4285714286em + 13px);
  height: calc(1.4285714286em + 12px);
  opacity: 0.6;
}

.homework__wrapper .homework__wrapper--body .ant-form-item .ant-form-item-label {
  margin-bottom: 8px;
}

.homework__wrapper .homework__wrapper--body .ant-form-item .ant-form-item-label label {
  font-size: 14px;
  font-weight: normal;
  color: #2a7d9c;
}

.homework__wrapper .homework__wrapper--body .homework__form--style .ant-input {
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.homework__wrapper .homework__wrapper--body .homework__form--style .k-editor {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.homework__wrapper .homework__wrapper--body .homework__form--style .k-editor.invalid-editor {
  border-color: #d83025;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--body.homework__wrapper--body {
    padding-top: 18px;
    border-radius: 6px;
  }
}

.homework__wrapper .homework__wrapper--body.homework__body--container {
  border-radius: 0 0 6px 6px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--body.homework__body--container {
    border-radius: 6px;
  }
}

.homework__wrapper .homework__wrapper--body.homework__body--filter {
  border-radius: 6px 6px 0 0;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--body.homework__body--filter {
    padding: 0;
    border-radius: 6px 6px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--body.homework__body--background {
    background: transparent;
    border-radius: 6px;
    box-shadow: none;
  }
}

.homework__wrapper .homework_htmleditor .k-editor-content {
  margin-bottom: 30px;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f4f4f4;
  background-color: #ffffff;
  padding: 25px;
  margin-bottom: 27px;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers .ck-toolbar {
  border: none;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers .ck-editor__main figure {
  max-width: 30%;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 p {
  font-size: 14px;
  font-weight: bold;
  color: #295396;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 p img {
  margin-right: 10px;
  border-radius: 50%;
  width: 33px;
  height: 32px;
}

body.rtl .homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 p img {
  margin-right: 0;
  margin-left: 10px;
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 span {
  font-size: 14px;
  font-weight: bold;
  color: #00678d;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 {
    flex-wrap: wrap;
  }
  .homework__wrapper .homework__wrapper--content .homework__content--asnwers h6 span {
    margin-bottom: 15px;
  }
}

.homework__wrapper .homework__wrapper--content .homework__content--asnwers > div p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #2c363f;
  word-wrap: anywhere;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--radioBtn .ant-radio-group {
    flex-wrap: wrap;
  }
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper {
  border-radius: 6px !important;
  border: solid 1px #ccced7 !important;
  background-color: #f4f4f7 !important;
  padding: 1px 20px;
  margin-right: 21px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper {
    margin-bottom: 15px;
  }
}

body.rtl .homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper {
  margin-right: 0;
  margin-left: 21px;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 6px !important;
  border-left: solid 1px #ccced7 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper .homework__radioBtn--status {
  width: 21px;
  height: 21px;
  border-radius: 4px;
  background-color: #ccced7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper > span:nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #283e4a;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper > span:nth-child(2) .homework__radioBtn--status {
  margin-right: 12px;
}

body.rtl .homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper > span:nth-child(2) .homework__radioBtn--status {
  margin-right: 0;
  margin-left: 12px;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.correct-btn {
  border: none;
  color: white !important;
  box-shadow: none !important;
  background-color: #06d236 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.correct-btn > span:nth-child(2) {
  color: white;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.correct-btn > span:nth-child(2) .homework__radioBtn--status {
  background-color: white;
  color: #06d236 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.incorrect-btn {
  border: none;
  color: white !important;
  box-shadow: none !important;
  background-color: #ff0000 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.incorrect-btn > span:nth-child(2) {
  color: white;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.incorrect-btn > span:nth-child(2) .homework__radioBtn--status {
  background-color: white;
  color: #ff0000 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.partial-btn {
  border: none;
  color: white !important;
  box-shadow: none !important;
  background-color: #ffb300 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.partial-btn > span:nth-child(2) {
  color: white;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.partial-btn > span:nth-child(2) .homework__radioBtn--status {
  background-color: white;
  color: #ffb300 !important;
}

.homework__wrapper .homework__wrapper--radioBtn .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.partial-btn + .partial-input {
  display: flex;
}

.homework__wrapper .attachment_list_wraper {
  display: flex;
  flex-wrap: wrap;
}

.homework__wrapper .attachment_list_wraper .file_attachment {
  display: flex;
  width: 264px;
  border: 1px solid #d2dade;
  border-radius: 5px;
  margin: 10px;
  overflow-x: auto;
  align-items: center;
}

.homework__wrapper .attachment_list_wraper .file_attachment .attachment_icon {
  margin: 10px;
  background-color: whitesmoke;
  border-radius: 50%;
  border-radius: 50%;
  padding: 10px;
  font-size: 15px;
}

.homework__wrapper .attachment_list_wraper .file_attachment .attachment_details {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.homework__wrapper .attachment_list_wraper .file_attachment .attachment_details p:first-child {
  font-size: 12px;
  color: #000000;
}

.homework__wrapper .attachment_list_wraper .file_attachment .attachment_details p:last-child {
  font-size: 12px;
  color: #727783;
  font-weight: 600;
}

.homework__wrapper .attachment_list_wraper .file_attachment .attachment_details p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.homework__wrapper .partial-input {
  width: 150px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #87a8bb;
  display: none;
  align-items: center;
  padding: 5px;
  color: #283e4a;
  font-size: 16px;
  font-weight: 500;
}

.homework__wrapper .partial-input .ant-input {
  border: none;
  height: 40px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .partial-input .ant-input {
    padding-left: 2px;
    padding-right: 10px;
  }
  body.rtl .homework__wrapper .partial-input .ant-input {
    padding-left: 10px;
    padding-right: 2px;
  }
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .partial-input {
    margin-bottom: 15px;
  }
}

.homework__wrapper .homework__wrapper--btns {
  margin: 28px 0 15px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.homework__wrapper .homework__wrapper--btns button {
  min-width: 99px;
  height: 40px;
  padding: 0 10px;
}

.homework__wrapper .homework__wrapper--btns button:first-of-type {
  margin: 0 22px;
}

.homework__wrapper .homework__wrapper--btns button.defaultBtn {
  border-radius: 4px;
  border: solid 1px #707070;
  background-color: #ffffff;
}

.homework__wrapper .homework__wrapper--btns button.btn-secondery {
  border-radius: 6px;
  border: solid 1px #00678d;
  color: #00678d;
  background: transparent;
}

.homework__wrapper .homework__wrapper--btns button.ant-btn-primary {
  border-radius: 4px;
  background-color: #00678d;
  color: #ffffff;
}

.homework__wrapper .correct-btn {
  background-color: #06d236;
  color: white;
}

.homework__wrapper .homework__wrapper--quiz {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 25px 16px 0;
  margin-bottom: 18px;
}

.homework__wrapper .homework__wrapper--quiz .form_holder--quiz .question_title--container {
  padding-right: 35px;
}

body.rtl .homework__wrapper .homework__wrapper--quiz .form_holder--quiz .question_title--container {
  padding-right: 0;
  padding-left: 35px;
}

@media only screen and (max-width: 767px) {
  .homework__wrapper .homework__wrapper--quiz .form_holder--quiz .question_title--container {
    padding-right: 0;
  }
}

.homework__wrapper .homework__wrapper--quiz .form_holder--quiz .homework__filters {
  width: 100%;
}

.homework__wrapper .homework__wrapper--quiz .form_holder--quiz .homework__filters .ant-select .ant-select-selection {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.homework__wrapper .homework__wrapper--quiz .form_holder--quiz .homework__filters .ant-select .ant-select-selection .ant-select-selection__placeholder {
  font-size: 11px;
  font-weight: normal;
  color: #929191;
  margin-left: 18px;
}

body.rtl .homework__wrapper .homework__wrapper--quiz .form_holder--quiz .homework__filters .ant-select .ant-select-selection .ant-select-selection__placeholder {
  margin-left: 0;
  margin-right: 18px;
}

.homework__wrapper .homework__wrapper--quiz .form_holder--quiz .homework__filters .ant-input {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  height: 38px;
}

.homework__wrapper .homework__wrapper--quiz.homework__mobile--quiz {
  text-align: center;
  padding: 8px;
}

.homework__wrapper .homework__wrapper--quiz.homework__mobile--quiz > button {
  border: none;
}

.homework__wrapper .homework__wrapper--quiz.homework__mobile--quiz > button img {
  margin-right: 11px;
}

body.rtl .homework__wrapper .homework__wrapper--quiz.homework__mobile--quiz > button img {
  margin-right: 0;
  margin-left: 11px;
}

.homework__content--option {
  font-size: 11px;
  font-weight: normal;
  color: #383202;
  position: relative;
  padding-left: 30px;
}

.homework__content--option > span {
  display: block;
  color: #1b8db7;
  font-size: 8px;
}

.homework__content--option::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 8px;
  width: 10px;
  height: 1px;
  background: #000000;
}

.ant-btn-primary.homeWork-btn {
  border-radius: 4px;
  background-color: #00678d;
  color: #ffffff;
}

.quiz__header .ant-input {
  height: 45px !important;
  color: #2a7d9c;
}

.quiz__header .ant-select .ant-select-selection {
  height: 45px;
}

.quiz__header .ant-select .ant-select-selection .ant-select-selection__rendered {
  top: 2px;
}

.quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value {
  font-size: 16px;
  font-weight: normal;
  color: #2a7d9c;
  display: flex !important;
  align-items: center;
}

.quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value svg {
  margin-right: 19px;
}

body.rtl .quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value svg {
  margin-right: 0;
  margin-left: 19px;
}

.quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value svg path {
  fill: #2a7d9c;
}

.quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value img {
  margin-right: 19px;
}

body.rtl .quiz__header .ant-select .ant-select-selection .ant-select-selection-selected-value img {
  margin-right: 0;
  margin-left: 19px;
}

.question__type--menu .question__type--option {
  height: 52px;
  border-bottom: solid 1px #b1cbd4;
  display: flex;
  align-items: center;
  color: #00678d;
}

.question__type--menu .question__type--option > img {
  margin-right: 38px;
  margin-left: 26px;
}

body.rtl .question__type--menu .question__type--option > img {
  margin-right: 26px;
  margin-left: 38px;
}

.question__type--menu .question__type--option > svg {
  margin-right: 38px;
  margin-left: 26px;
}

.question__type--menu .question__type--option > svg path {
  fill: #00678d;
}

body.rtl .question__type--menu .question__type--option > svg {
  margin-right: 26px;
  margin-left: 38px;
}

.question__type--menu .question__type--option:last-child {
  border-bottom: none;
}

.quiz__footer {
  padding: 15px 0px;
  border-top: 1px solid #9999998f;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .quiz__footer {
    padding: 25px 0;
  }
}

.quiz__footer .append__question {
  border: none;
  padding-left: 0px;
  background-color: transparent;
}

.quiz__footer .append__question span {
  font-size: 16px;
  font-weight: 500;
  color: #2a7d9c;
  margin-left: 13px;
  border-bottom: 1px solid #2a7d9c;
}

body.rtl .quiz__footer .append__question span {
  margin-right: 13px;
  margin-left: 0;
}

.quiz__footer .quiz__footer--actions {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .quiz__footer .quiz__footer--actions {
    flex-wrap: wrap;
  }
}

.quiz__footer .quiz__footer--actions button {
  border: none;
  border-right: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding-left: 0;
  padding-right: 20px;
  margin-right: 27px;
}

@media only screen and (max-width: 767px) {
  .quiz__footer .quiz__footer--actions button {
    padding-right: 9px;
    margin-right: 13px;
    font-size: 10px;
  }
}

.quiz__footer .quiz__footer--actions button img {
  margin-right: 11px;
  width: 20px;
}

body.rtl .quiz__footer .quiz__footer--actions button img {
  margin-right: 0;
  margin-left: 11px;
}

.quiz__footer .quiz__footer--actions button:last-child {
  border-right: none;
  margin-right: 0;
}

body.rtl .quiz__footer .quiz__footer--actions button {
  padding-left: 20px;
  padding-right: 0;
  margin-right: 0;
  margin-left: 27px;
}

@media only screen and (max-width: 767px) {
  body.rtl .quiz__footer .quiz__footer--actions button {
    padding-left: 9px;
    padding-right: 0;
    margin-left: 13px;
  }
}

.selected-student-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -15px;
}

.selected-student-count p {
  font-size: 11px;
  font-weight: bold;
  color: #2a7d9c;
}

.selected-student-count .student-img-list {
  display: flex;
  align-items: center;
  margin: 0 5px;
}

.selected-student-count .student-img-list img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: -10px;
}

body.rtl .selected-student-count .student-img-list img {
  margin-left: unset !important;
  margin-right: -10px;
}

@media only screen and (max-width: 575px) {
  .date_add_homework .ant-calendar-range-picker-input {
    font-size: 9px;
  }
}

@media only screen and (max-width: 575px) {
  .date_add_homework .ant-calendar-picker-icon {
    font-size: 11px !important;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .date_add_homework .ant-calendar-range-picker-separator {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  body.rtl .date_add_homework .ant-calendar-picker-clear, body.rtl
  .date_add_homework .ant-calendar-picker-icon {
    left: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .date_add_homework .ant-calendar-picker-icon {
    font-size: 9px;
    right: 3px;
  }
}

@media only screen and (max-width: 575px) {
  .ant-calendar-range-left,
  .ant-calendar-range-right {
    float: none !important;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .ant-calendar-range {
    width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .quiz__title--responsive {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    margin: 0 -10px;
  }
}

.k-editor .k-button-icon {
  opacity: 0.6;
}

.homework_header_buttons {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .homework_header_buttons {
    flex-direction: row-reverse;
    margin-bottom: 6px;
    justify-content: space-between;
  }
}

.homework_list_responsive_dropdown_menu.ant-dropdown {
  z-index: 11;
}

.homework_htmleditor .ck.ck-editor__main > .ck-editor__editable {
  height: 200px !important;
  line-height: 1.5;
  color: #000000;
}

.homework_htmleditor .ck.ck-editor__main > .ck-editor__editable ul {
  list-style: inside !important;
}

.homework_htmleditor .ck.ck-editor__main > .ck-editor__editable ol {
  list-style: decimal !important;
  margin-inline-start: 20px;
}

.teacher-modal-wrapper .ant-modal {
  width: 820px !important;
  max-width: 100% !important;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body {
  height: 530px;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body {
    height: 100%;
    overflow: auto;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form {
    height: auto;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form > .ant-row {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form > .ant-row {
    height: auto;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col {
    height: auto;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height {
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.right {
  padding-left: 20px;
}

body.rtl .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.right {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.right {
    padding-left: 0;
    padding-right: 0;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.left {
  padding-right: 20px;
}

body.rtl .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.left {
  padding-left: 20px;
  padding-right: 0;
}

@media only screen and (max-width: 767px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height.left {
    padding-left: 0;
    padding-right: 0;
  }
}

body.rtl .teacher-modal-wrapper .ant-modal .ant-modal-body > .ant-form .ant-col .modal__sections--height {
  padding-right: 0;
  padding-left: 10px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item {
  margin-bottom: 17px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-input {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-input::placeholder {
  font-size: 15px;
  font-weight: normal;
  color: #bebecb;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-select .ant-select-selection {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  height: 38px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  font-weight: 200;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-search--inline {
  height: auto;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-select .ant-select-selection--multiple ul li {
  margin-top: -2px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-select .ant-select-selection__placeholder {
  font-size: 15px;
  font-weight: normal;
  color: #bebecb;
  top: 46%;
  left: 14px;
  right: 14px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-form-item-label {
  top: 0;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-form-item-label label {
  font-size: 17px;
  font-weight: 500;
  color: #00adee;
}

@media only screen and (max-width: 575px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-form-item-label label {
    font-size: 12px;
    padding: 2px 5px;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-switch {
  min-width: 39px !important;
  height: 22px !important;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-switch::after {
  width: 20px;
  height: 20px;
  top: 0px;
  margin-left: 0px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-switch.ant-switch-checked {
  background-color: #2a7d9c;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item.session__recording .ant-switch.ant-switch-checked::after {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  border: solid 0.5px #e5e5e5;
  background-color: #ffffff;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-widget.k-toolbar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: unset;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-widget.k-toolbar .k-tool-group {
  height: 28px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-widget.k-toolbar .k-tool-group .k-button.k-button-icon {
  width: 28px;
  height: 28px;
  padding: 0;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-editor-content::-webkit-scrollbar {
  background-color: #f4f4f4 !important;
  width: 7px !important;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-editor-content::-webkit-scrollbar-thumb {
  background-color: #0793c7 !important;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .online__session--htmleditor .k-editor .k-editor-content > .k-iframe {
  height: 118px !important;
  padding-bottom: 20px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-time-picker {
  width: 170px;
}

@media only screen and (max-width: 399px) {
  .teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-time-picker {
    width: 152px;
  }
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-time-picker .ant-time-picker-input {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  width: 100%;
}

body.rtl .teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item .ant-time-picker .ant-time-picker-icon {
  left: 11px;
  right: auto;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper > .ant-form-item {
  margin-bottom: 0;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment-holder .attachment-holder__list {
  display: grid;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment-holder .attachment-holder__list:empty {
  display: none;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment-holder > button:empty {
  display: none;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment__online--session {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment__online--session span {
  font-size: 17px;
  font-weight: normal;
  color: #2a7d9c;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment__online--session .ant-checkbox-wrapper > span:last-child {
  font-size: 12px;
  font-weight: normal;
  color: #3885a2;
  margin-left: 8px;
}

body.rtl .teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment__online--session .ant-checkbox-wrapper > span:last-child {
  margin-left: 8px;
  margin-right: 8px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .attachment__session--wrapper .attachment__online--session .ant-checkbox-wrapper > span.ant-checkbox .ant-checkbox-inner {
  border: solid 1px #458da8;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .online__session--target {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  height: 38px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .online__session--target h3 {
  font-size: 17px;
  font-weight: normal;
  color: #00adee;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .online__session--target .ant-radio-checked::after {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.22);
  border: solid 2px #00adee;
  background-color: #ffffff;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .online__session--target .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #00678d;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .online__session--target .ant-radio-group-outline .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-checked + span {
  color: #00adee;
  font-size: 16px;
  font-weight: 500;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .k-widget.k-timepicker {
  width: 100% !important;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .k-widget.k-timepicker .k-picker-wrap.k-state-default {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  width: 100%;
  padding: 0 8px;
}

.student__select {
  display: flex;
  justify-content: space-between;
}

.student__select .student__select--name {
  font-size: 11px;
  font-weight: bold;
  color: #00678d;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.student__select .student__select--img {
  cursor: pointer;
}

.student__select .student__select--class {
  font-size: 10px;
  font-weight: normal;
  color: #000000;
}

.student__select .student__select--date input {
  height: 20px;
  border: none;
}

.student__list--warning {
  font-size: 9px;
  font-weight: normal;
  color: #ff0000;
  margin-top: 6px;
  text-align: right;
}

.student__list--warning body.rtl {
  text-align: left;
}

.student__list--warning span {
  color: #00678d;
}

.customStudent__select--name {
  font-size: 11px;
  font-weight: bold;
  color: #00678d;
}

.search_holder .ant-input-search {
  width: 100% !important;
}

.search_holder .ant-input-search .ant-input-wrapper.ant-input-group {
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 4px;
}

.search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input {
  border: none;
  border-radius: 0;
}

.search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon {
  background: transparent;
  position: absolute;
  left: -5px;
  width: 20px;
  top: 2px;
}

body.rtl .search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon {
  left: unset;
  right: -5px;
}

.search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon > button.ant-input-search-button {
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #b6b6b6;
  padding: 0;
}

.student__selected--list {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  padding: 10px 15px 20px 15px;
  max-height: 285px;
  overflow-y: auto;
}

.student__selected--list .student__list--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student__selected--list .student__list--header > span {
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  color: #d83025;
  cursor: pointer;
  padding-top: 10px;
}

.student__selected--list .student__list--header > span img {
  margin-right: 5px;
}

body.rtl .student__selected--list .student__list--header > span img {
  margin-right: 0;
  margin-left: 5px;
}

.student__selected--list .student__list--body {
  padding: 5px 8px;
  margin-top: 26px;
}

.student__selected--list .student__list--body .student__select {
  margin-bottom: 9px;
}

.student__selected--list .empty__student--list {
  min-height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
}

body.rtl .ant-modal-title {
  text-align: right;
}

.online__session--type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  height: 38px;
  align-items: center;
}

.editor__validation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editor__validation p:first-child {
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 2px 0px 0 0px;
  text-align: left;
}

.editor__validation p:last-child {
  font-size: 10px;
}

.student__target--dropDown .ant-select-selection__choice {
  display: none;
}

.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-search {
  margin-top: -4px;
}

body.rtl .ant-checkbox-checked .ant-checkbox-inner {
  background: #0793c7 !important;
}

body.rtl .ant-time-picker-panel-select {
  overflow-y: auto;
}

.online__session--kendoPicker {
  z-index: 1001 !important;
}

.tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-list .tabs__tab {
  width: 30%;
}

.tab-list .tab-list-item {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.83px;
  color: #929191;
  width: fit-content;
  padding: 12px;
  width: 100%;
  text-align: center;
  min-width: 100px;
  cursor: pointer;
  border: solid 1px #bdc5d8;
}

.tab-list .tab-list-active {
  color: white;
  background: #00adee;
  min-width: 100px;
  text-align: center;
}

.tab-list .tab-list-item-holder {
  width: 30%;
}

.multiChoice {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 0.5fr 0.5fr;
  gap: 30px;
  row-gap: 0;
}

@media only screen and (max-width: 767px) {
  .multiChoice {
    grid-template-columns: 1fr;
    flex-flow: column;
  }
}

.multiChoice .multiChoice__item {
  width: 46%;
}

@media only screen and (max-width: 767px) {
  .multiChoice .multiChoice__item {
    width: 88%;
  }
}

.multiChoice .multiChoice__item .ant-checkbox {
  display: none !important;
}

.multiChoice .multiChoice__item .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
}

.multiChoice .multiChoice__item .ant-input {
  height: 35px;
  border: none;
}

.multiChoice .multiChoice__item .multiChoice__input {
  display: flex;
  align-items: flex-start;
}

.multiChoice .multiChoice__item .multiChoice__input > div {
  width: 100%;
}

.multiChoice .multiChoice__item .multiChoice__input > div .k-editor {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #b1cbd4;
}

.multiChoice .multiChoice__item .multiChoice__input > div .k-editor.invalid-editor {
  border-bottom-color: #d83025;
}

.multiChoice .multiChoice__item .multiChoice__input .k-editor-content > .k-iframe {
  height: 100% !important;
}

.multiChoice .multiChoice__item .multiChoice__input .focus .k-editor {
  height: 120px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

.multiChoice .multiChoice__item .multiChoice__input .focus + span {
  display: none;
}

.multiChoice .multiChoice__item .multiChoice__input .focus + span + span {
  display: none;
}

.multiChoice .multiChoice__item .multiChoice__input .blur .k-editor {
  border-bottom: 1px solid #b1cbd4;
  height: 45px !important;
}

.multiChoice .multiChoice__item .multiChoice__input .blur .k-editor.invalid-editor {
  border-bottom-color: #d83025;
}

.multiChoice .multiChoice__item .multiChoice__rong_active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  padding: 0 8px 3px;
  margin-top: 3px;
  margin-left: 10px;
  color: #ff0000;
  opacity: 1;
  z-index: 3;
  right: -35px;
}

body.rtl .multiChoice .multiChoice__item .multiChoice__rong_active {
  margin-left: 0;
  margin-right: 10px;
}

.multiChoice .multiChoice__item .multiChoice__rong_active:hover {
  opacity: 1;
}

.multiChoice.singleChoice .multiChoice__item {
  width: 85%;
  margin-right: 45px;
}

body.rtl .multiChoice.singleChoice .multiChoice__item {
  margin-right: 0;
  margin-left: 45px;
}

@media only screen and (max-width: 767px) {
  .multiChoice.singleChoice .multiChoice__item {
    margin-right: 0;
    margin-left: 0;
  }
}

.multiChoice.singleChoice .multiChoice__item .multiChoice__rong_active {
  right: -50px;
  cursor: pointer;
}

body.rtl .multiChoice.singleChoice .multiChoice__item .multiChoice__rong_active {
  right: auto;
  left: -50px;
}

.multiChoice .ant-checkbox + span {
  width: 95%;
}

.multiChoice .k-editor .k-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 30px;
}

.multiChoice .k-editor .k-toolbar::before {
  height: unset;
}

.multiChoice .k-editor .k-button-icon {
  padding: 0;
  width: calc(1.4285714286em + 13px);
  height: calc(1.4285714286em + 12px);
}

.multiChoice.singleChoice .ant-radio-wrapper {
  line-height: unset;
}

.multiChoice.singleChoice .ant-radio-wrapper .ant-radio {
  position: unset;
}

.multiChoice.singleChoice .ant-radio-wrapper .ant-radio.ant-radio-checked::after {
  display: none !important;
}

.multiChoice.singleChoice .ant-radio-wrapper .ant-radio .ant-radio-input {
  width: 100%;
  height: 100%;
}

.multiChoice.singleChoice .ant-radio-wrapper .ant-radio .ant-radio-inner {
  display: none !important;
}

.multiChoice.singleChoice .multiChoice__input .enhanced-input-container {
  position: relative;
  z-index: 3;
  max-width: 391px;
}

.multiChoice__new {
  display: flex;
  align-items: center;
  margin-top: 35px;
  cursor: pointer;
}

.multiChoice__new .multiChoice__sign {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #00678d;
  margin-right: 10px;
}

body.rtl .multiChoice__new .multiChoice__sign {
  margin-right: 0;
  margin-left: 10px;
}

.multiChoice__new .multiChoice__add {
  color: #a9a5a5;
  font-size: 14px;
  line-height: 1.29;
}

.multiChoice__new .append__question {
  padding: 0;
  height: 36px;
  border: solid 1px #bfbcbc;
  background-color: #ffffff;
  padding: 0 24px;
}

.multiChoice__new .append__question span {
  font-size: 14px;
  font-weight: normal;
  color: #a9a5a5;
}

.enhanced-input-container .k-editor-content > .k-iframe {
  height: 100% !important;
}

.enhanced-input-container .k-editor .k-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 30px;
  padding: 3px 0;
}

@media only screen and (max-width: 767px) {
  .enhanced-input-container .k-editor .k-toolbar {
    height: auto;
  }
}

.enhanced-input-container .k-editor .k-toolbar::before {
  height: unset;
}

.enhanced-input-container .k-editor .k-button-icon {
  padding: 0;
  width: 26px;
  height: calc(1.4285714286em + 12px);
  opacity: 0.6;
}

.focus .k-editor {
  border-radius: 5px;
  height: 145px;
}

.blur .k-editor {
  border-radius: 5px;
  height: 45px !important;
}

.target-student {
  border: none !important;
  background-color: #ffffff !important;
  border: unset;
  height: 31px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-left: 15px;
  font-size: 10px;
  line-height: 1.3;
  color: #929191;
}

body.rtl .target-student {
  margin-left: 0;
  margin-right: 15px;
}

.target-student:focus {
  border: none !important;
}

.target-student-modal .ant-modal-header {
  border: none;
}

.target-student-modal .ant-modal-header .ant-modal-title {
  font-size: 22px;
  color: #464a53;
}

.target-student-modal .ant-modal-footer {
  border: none;
}

.target-student-modal .ant-input-search .ant-input {
  padding-top: 0 !important;
  border: solid 1px #00adee;
}

.target-student-modal .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px !important;
  right: unset !important;
}

.target-student-modal .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-left: 30px;
}

.target-student-modal .student-list {
  margin-top: 15px;
}

.target-student-modal .student-list .student-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.target-student-modal .student-list .student-item .student-info-holder {
  display: flex;
  align-items: center;
}

.target-student-modal .student-list .student-item .student-info-holder .student-img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.target-student-modal .student-list .student-item .student-info-holder .student-name {
  font-size: 14px;
  font-weight: 500;
  color: #00678d;
  margin: 0 16px;
}

.target-student-modal .student-list .student-item .add-student {
  cursor: pointer;
}

.small-paragraph .blur .k-editor {
  height: 100px !important;
  border: none;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
}

.empty-validation {
  display: flex;
  justify-content: flex-end;
  margin: 20px 80px;
  color: red;
}

p.placeholder:first-child::before {
  float: right !important;
}

body.rtl .ant-input {
  text-align: right;
}

body.rtl .ant-calendar-picker-input {
  padding: 0 30px 5px 10px !important;
}

.singleChoice .multiChoice__item .ant-radio-wrapper span:last-child {
  display: flex;
}

.re_arrange .reArrange_inputs--container {
  padding-left: 10px;
}

body.rtl .re_arrange .reArrange_inputs--container {
  padding-left: 0;
  padding-right: 50px;
}

@media only screen and (max-width: 575px) {
  .re_arrange .reArrange_inputs--container {
    padding-right: 0;
    padding-left: 0;
  }
}

.re_arrange .reArrange_inputs--container h4 {
  font-size: 16px;
  font-weight: bold;
  color: #0b85b2;
  margin-bottom: 15px;
}

.quiz__input--style {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  position: relative;
  outline: 0;
}

.quiz__input--style .input_delete--container {
  position: relative;
  width: 100%;
  max-width: 330px;
}

.quiz__input--style .input_delete--container .cLose_icon {
  position: absolute;
  right: 0;
  top: 18px;
  cursor: pointer;
  z-index: 2;
}

body.rtl .quiz__input--style .input_delete--container .cLose_icon {
  right: auto;
  left: 0;
}

.quiz__input--style .ant-input {
  max-width: 330px;
  border: none !important;
  color: #2a7d9c;
  font-size: 12px;
  border-bottom: 1px solid #2a7d9c !important;
  border-radius: 0 !important;
  padding: 0 !important;
}

.quiz__input--style .ant-input::placeholder {
  color: #2a7d9c;
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .quiz__input--style .ant-input {
    max-width: 100%;
  }
}

.quiz__input--style .enhanced-input-container .k-editor .k-toolbar {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.quiz__input--style .enhanced-input-container .k-editor .k-toolbar::before {
  height: unset;
}

.quiz__input--style .enhanced-input-container .k-editor .k-tool-group .k-button span::before {
  font-size: 14px;
}

.quiz__input--style .enhanced-input-container .k-editor .k-editor-content > .k-iframe {
  max-height: 100% !important;
}

.quiz__input--style .enhanced-input-container .k-editor .k-button-icon {
  padding: 0;
  width: 23px;
  height: 20px;
}

@media only screen and (max-width: 575px) {
  .quiz__input--style .enhanced-input-container .k-editor {
    max-width: 100%;
  }
}

.quiz__input--style .enhanced-input-container.blur .k-editor {
  border-radius: 0;
  border: 1px solid transparent;
  border-bottom-color: #b1cbd4;
}

.quiz__input--style .enhanced-input-container.focus .k-editor {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

@media only screen and (max-width: 767px) {
  .quiz__input--style .enhanced-input-container.focus .k-editor {
    padding-bottom: 30px !important;
  }
}

.quiz__input--style .move_icon {
  margin-left: 13px;
  cursor: move;
  flex-shrink: 0;
}

body.rtl .quiz__input--style .move_icon {
  margin-left: 0;
  margin-right: 13px;
}

.quiz__input--style span.cLose_icon {
  margin-top: 12px;
  margin-left: 10px;
}

body.rtl .quiz__input--style span.cLose_icon {
  margin-left: 0;
  margin-right: 10px;
}

.re_arrange--input {
  width: 100%;
}

.re_arrange--input .correct__answer--parag {
  width: 100%;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent #b1cbd4;
  border-image: initial;
  min-height: 45px;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  max-width: 327px;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 154px;
}

.re_arrange--input .correct__answer--parag .ck-editor__main > .ck-editor__editable {
  border: none;
}

.re_arrange--input .correct__answer--parag .ck-toolbar {
  border: none;
}

.re_arrange--input .correct__answer--parag img {
  max-width: 100%;
}

span.cLose_icon {
  cursor: pointer;
}

.k-editor-content {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.k-toolbar {
  z-index: 4;
}

.exam-correction-wrapper {
  height: 100%;
  padding-top: 5px;
  margin: auto;
  height: 81vh;
  overflow: hidden;
}

.exam-correction-wrapper aside {
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 767px) {
  .exam-correction-wrapper aside {
    height: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .exam-correction-wrapper {
    height: 100%;
    overflow: unset;
  }
}

@media only screen and (max-width: 991px) {
  .exam-correction-wrapper {
    width: 95%;
  }
}

.exam-details-container {
  display: flex;
  height: 90%;
}

@media only screen and (max-width: 767px) {
  .exam-details-container {
    flex-direction: column;
    display: block;
  }
}

.exam-details-container .ant-radio-wrapper {
  display: flex;
}

.result-holder {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 14px;
  text-align: center;
  margin-bottom: 16px;
}

.result-holder .ant-divider {
  margin: 6px 0;
  background-color: #f4f4f7;
}

.result-holder .result-holder--title {
  font-size: 18px;
  font-weight: 600;
  color: #2c363f;
  margin-top: 10px;
  margin-bottom: 15px;
}

.result-holder .result-holder--grade {
  font-size: 23px;
  font-weight: 600;
  line-height: 1.04;
  letter-spacing: 2.3px;
  color: #00adee;
  margin-bottom: 20px;
}

.result-holder .result-questions-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .result-holder .result-questions-type {
    display: none;
  }
}

.result-holder .result-questions-type p {
  font-size: 14px;
  font-weight: normal;
  color: #7e8a91;
  display: flex;
  align-items: center;
}

.result-holder .result-questions-type p span {
  color: #283e4a;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.result-holder .result-questions-type p span:before {
  content: "";
  width: 2px;
  height: 13px;
  background-color: #7e8a91;
  display: block;
  margin: 0 5px;
}

.result-holder .ant-collapse .ant-collapse-header {
  padding: 0;
  padding-bottom: 10px;
  text-align: left;
}

.result-holder .ant-collapse .ant-collapse-content-box {
  padding: 0;
}

.result-holder .ant-collapse .ant-collapse-content-box .ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.result-holder .ant-collapse .ant-collapse-content-box .ant-radio-wrapper {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #4f6170;
  display: flex;
  align-items: center;
}

.result-holder .ant-collapse .ant-collapse-content-box .ant-radio-wrapper .ant-radio-inner {
  width: 13px;
  height: 13px;
  border-width: 1px;
}

.result-holder .ant-collapse .ant-collapse-content-box .ant-radio-wrapper .ant-radio-inner::after {
  width: 7px;
  height: 7px;
  top: 2px;
  left: 2px;
  border-radius: 50%;
}

.result-holder .ant-collapse .ant-collapse-content-box .ant-radio-wrapper .ant-radio + span {
  padding-right: 4px;
  padding-left: 4px;
}

.result-holder .ant-collapse .ant-collapse-item {
  border: 0;
}

.result-holder .ant-collapse .ant-collapse-item .anticon {
  transition: all ease 0.3s;
}

.result-holder .switch-student-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-holder .switch-student-holder p {
  font-size: 14px;
  font-weight: normal;
  color: #9797ac;
  margin: 0 15px;
}

.result-holder .switch-student-holder .anticon {
  cursor: pointer;
}

.result-holder .switch-student-holderbody.rtl .result-holder .switch-student-holder {
  direction: ltr;
}

@media only screen and (max-width: 767px) {
  .result-holder .result-holder-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .result-holder .result-holder-mobile .result-holder--grade {
    margin-bottom: 0;
  }
}

.questions-list-holder {
  background-color: #ffffff;
  padding: 14px;
  overflow: auto;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder {
    overflow-y: hidden;
    flex: initial;
    padding: 8px 0 10px 0;
  }
}

.questions-list-holder::-webkit-scrollbar {
  height: 4px !important;
}

.questions-list-holder::-webkit-scrollbar-thumb {
  background: #9797ac !important;
  height: 4px !important;
  border-radius: 2px;
}

.questions-list-holder .ant-steps-item-content {
  min-height: auto;
  width: 100%;
}

.questions-list-holder .ant-steps-item-content .ant-steps-item-title {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .ant-steps-item-content .ant-steps-item-title {
    width: fit-content;
    padding: 0;
    margin: 0;
    margin-inline-start: 6px;
    font-size: 16px;
    margin: 0;
    margin-left: -5px;
  }
  body.rtl .questions-list-holder .ant-steps-item-content .ant-steps-item-title {
    margin-left: 0;
    margin-right: -5px;
  }
  .questions-list-holder .ant-steps-item-content .ant-steps-item-title p {
    width: 100px;
    text-align: center;
    width: auto;
  }
  .questions-list-holder .ant-steps-item-content .ant-steps-item-title::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .ant-steps-item-content {
    display: flex;
  }
}

.questions-list-holder .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  padding: 25px 0 0px;
  left: 13px;
  top: 12px;
}

body.rtl .questions-list-holder .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: auto;
  right: 28px;
}

.questions-list-holder .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #00adee;
}

.questions-list-holder .question-holder {
  display: flex;
  align-items: center;
  justify-content: start;
}

.questions-list-holder .question-holder .question {
  font-size: 16px;
  color: #2c363f;
  flex-basis: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .question-holder .question {
    font-size: 10px;
  }
}

.questions-list-holder .question-holder .degree-holder {
  font-size: 12px;
  font-weight: 500;
  color: #283e4a;
  flex-basis: 25%;
  white-space: nowrap;
}

body.rtl .questions-list-holder .question-holder .degree-holder {
  font-size: 10px;
}

.questions-list-holder .question-holder .question-type {
  font-size: 12px;
  font-weight: normal;
  color: #bcbcd3;
  flex-basis: 35%;
}

body.rtl .questions-list-holder .question-holder .question-type {
  font-size: 10px;
}

.questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content {
  width: auto;
}

.questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: 14px;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .multi-step-holder > .ant-steps-item-container {
    display: flex;
    width: 100%;
  }
  .questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content {
    width: 100%;
  }
  .questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content .ant-steps-item-title {
    width: fit-content;
  }
  .questions-list-holder .multi-step-holder > .ant-steps-item-container > .ant-steps-item-content .ant-steps-item-description {
    max-width: fit-content;
    padding-bottom: 0 !important;
  }
}

.questions-list-holder .multi-step-holder.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon span {
  color: #0793c7;
}

.questions-list-holder .multi-step-holder.ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #00adee;
}

.questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item.ant-steps-item-active {
  background-color: rgba(7, 147, 199, 0.05);
}

.questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item.ant-steps-item-active .question-holder .question {
  font-weight: 500;
  color: #00adee;
}

.questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item .ant-steps-item-container {
  padding-bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item .ant-steps-item-container {
    display: block !important;
  }
}

.questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #06d236;
  border-color: #06d236;
}

.questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon span {
  color: #FFF;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item {
    padding-top: 0;
  }
  .questions-list-holder .multi-step-holder .ant-steps-item-description .ant-steps-item:after {
    top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .multi-step-holder {
    flex: 1 !important;
  }
}

.questions-list-holder .single-step-holder.ant-steps-item.ant-steps-item-active {
  background-color: rgba(7, 147, 199, 0.05);
}

.questions-list-holder .single-step-holder.ant-steps-item.ant-steps-item-active .question-holder .question {
  font-weight: 500;
  color: #00adee;
}

.questions-list-holder .single-step-holder.ant-steps-item .ant-steps-item-container {
  padding-bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .single-step-holder.ant-steps-item .ant-steps-item-container {
    display: block !important;
  }
}

.questions-list-holder .single-step-holder.ant-steps-item.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #06d236;
  border-color: #06d236;
}

.questions-list-holder .single-step-holder.ant-steps-item.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon span {
  color: #FFF;
}

.questions-list-holder .single-step-holder > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .single-step-holder .question-holder .question {
    font-size: 11px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .ant-steps-item {
    overflow: initial;
    display: flex;
    flex-direction: row;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 4px 10px;
    min-height: auto;
    flex: none;
  }
  .questions-list-holder .ant-steps-item.ant-steps-item-process .question {
    color: #0793c7 !important;
    font-weight: 500 !important;
  }
  .questions-list-holder .ant-steps-item:after {
    content: '';
    width: 1px;
    height: 100px;
    background: #0793C7;
    position: absolute;
    right: 0;
    transform: rotate(90deg);
    top: -23px;
    z-index: 0;
    pointer-events: none;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .questions-list-holder .ant-steps-item:after {
    top: -26px;
  }
}

@media only screen and (max-width: 767px) {
  body.rtl .questions-list-holder .ant-steps-item:after {
    right: auto;
    left: 0;
  }
  .questions-list-holder .ant-steps-item:last-child:after {
    display: none;
  }
}

.questions-list-holder .ant-steps-item .ant-steps-item-icon {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #0793c7;
  background-color: #ffffff;
}

.questions-list-holder .ant-steps-item .ant-steps-item-icon span {
  color: #2c363f;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .questions-list-holder .ant-steps-item .ant-steps-item-icon {
    position: relative;
    z-index: 4;
    width: 38px;
    height: 38px;
    min-width: 38px;
    min-height: 38px;
  }
}

@media only screen and (max-width: 479px) {
  .questions-list-holder > .ant-steps {
    display: flex;
    flex: 1;
  }
  .questions-list-holder > .ant-steps .ant-steps-item {
    display: flex;
    flex: 1;
  }
  .questions-list-holder > .ant-steps .ant-steps-item .ant-steps-item-content {
    display: flex;
    min-height: fit-content;
    overflow: initial;
  }
  .questions-list-holder > .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps {
    display: flex;
  }
  .questions-list-holder > .ant-steps .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 100% !important;
    height: 1px !important;
  }
}

.exam-details {
  margin: auto;
  width: 90%;
  padding-top: 65px;
}

.exam-details .ant-table-wrapper {
  margin-top: 2px;
}

.exam-details .grid-action-header {
  display: none;
}

.exam-details .ant-table-placeholder {
  position: relative !important;
  top: unset !important;
  left: unset !important;
  transform: none !important;
}

.exam-details .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  width: 101%;
}

.exam-details .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  width: 101%;
}

.exam-details .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 8px !important;
}

body.rtl .exam-details .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-left: 8px !important;
}

.exam-details .ant-table-filter-icon {
  display: none;
}

.exam-details .ant-checkbox {
  display: none;
}

.exam-details .ant-table-thead > tr > th {
  background: white;
  text-align: center;
  border-bottom: 5px solid #f4f4f4;
  width: 20%;
  font-size: 12px;
  font-weight: 500;
  color: #7e8a91;
}

.exam-details .ant-table-thead > tr > th:first-child {
  width: 0;
}

@media only screen and (max-width: 575px) {
  .exam-details .ant-table-thead > tr > th {
    font-size: 10px;
  }
}

.exam-details .ant-table-tbody > tr > td {
  text-align: center;
  border-bottom: 5px solid #f4f4f4;
}

.exam-details .ant-table-tbody > tr > td:nth-of-type(3) {
  color: #ff0000;
}

.exam-details .done {
  font-size: 14px;
  color: #06d236;
}

.exam-details .incomplete {
  font-size: 14px;
  color: #ffb300;
}

.exam-details .notViewed {
  font-size: 14px;
  color: #9797ac;
}

.exam-details .grade .mark {
  color: #00adee;
  font-size: 16px;
  font-weight: 500;
}

.exam-details .grade .full, .exam-details .grade .slash {
  font-size: 16px;
  font-weight: 500;
  color: #283e4a;
}

.exam-details .grade .no-mark {
  font-size: 14px;
  color: #283e4a;
}

.exam-details__header {
  background: white;
  padding: 16px;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .exam-details__header {
    flex-direction: column;
    padding: 10px;
  }
}

.exam-details__title-holder {
  display: flex;
  align-items: center;
}

.exam-details__title-holder .exam-details__image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.exam-details__title-holder .exam-details__title {
  font-size: 24px;
  font-weight: 500;
  margin-left: 13px;
}

body.rtl .exam-details__title-holder .exam-details__title {
  margin-left: 0;
  margin-right: 13px;
}

.exam-details__details {
  border-right: 1px solid #868ea461;
  padding-right: 50px;
  width: 50%;
}

body.rtl .exam-details__details {
  border-right: none;
  border-left: 1px solid #868ea461;
  padding-right: 0;
  padding-left: 50px;
}

@media only screen and (max-width: 767px) {
  .exam-details__details {
    border-right: none;
    width: 100%;
    border-bottom: 1px solid #868ea461;
    padding-bottom: 10px;
    padding-right: 0;
  }
  body.rtl .exam-details__details {
    border-left: none;
    padding-left: 0;
  }
}

.exam-details__details .exam-details__details_major {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 44px;
  margin-top: 21px;
}

body.rtl .exam-details__details .exam-details__details_major {
  margin-left: 0;
  margin-right: 21px;
}

@media only screen and (max-width: 639px) {
  .exam-details__details .exam-details__details_major {
    margin-left: 0;
  }
  body.rtl .exam-details__details .exam-details__details_major {
    margin-right: 0;
  }
}

.exam-details__details .exam-details__lable {
  font-size: 14px;
  line-height: 1.5;
  color: #868ea4;
}

@media only screen and (max-width: 639px) {
  .exam-details__details .exam-details__lable {
    font-size: 12px;
  }
}

.exam-details__details .exam-details__info {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #2c363f;
}

@media only screen and (max-width: 639px) {
  .exam-details__details .exam-details__info {
    font-size: 12px;
  }
}

.exam-details__col > div {
  margin-bottom: 12px;
}

.exam-details__col.space {
  margin: 44px 0 0 40px;
}

body.rtl .exam-details__col.space {
  margin: 44px 40px 0 0;
}

@media only screen and (max-width: 767px) {
  .exam-details__col.space {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 639px) {
  .exam-details__col.space {
    margin-left: 0;
  }
  body.rtl .exam-details__col.space {
    margin-right: 0;
  }
}

.exam-details__lable_bold {
  font-size: 16px;
  font-weight: bold;
  color: #7e8a91;
  line-height: 1.5;
  margin-right: 15px;
}

body.rtl .exam-details__lable_bold {
  margin-right: 0;
  margin-left: 15px;
}

@media only screen and (max-width: 639px) {
  .exam-details__lable_bold {
    font-size: 12px;
  }
}

.exam-details__info-img {
  margin-left: 10px;
}

body.rtl .exam-details__info-img {
  margin-left: 0;
  margin-right: 10px;
}

.exam-details__list {
  background-color: white;
  margin-top: 15px;
}

.exam-details__list .exam-details__tabs {
  display: flex;
  padding: 15px 5px;
  padding-bottom: 0;
}

.exam-details__list .exam-details__tab {
  font-size: 16px;
  font-weight: 500;
  color: #283e4a;
  line-height: 1.5;
  margin: 0 25px;
  padding-bottom: 10px;
  cursor: pointer;
}

.exam-details__list .active {
  font-weight: 500;
  color: #00adee;
  border-bottom: 2px solid #00adee;
  transition: color 0.3s;
}

.header_checkbox_group {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  justify-content: flex-start;
  align-items: flex-start;
}

body.rtl .header_checkbox_group {
  align-items: baseline;
}

.header_checkbox_group p {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .header_checkbox_group p {
    font-size: 12px;
    margin: 0px 3px;
    font-weight: 700;
  }
}

.header_checkbox_group img {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .header_checkbox_group img {
    display: block;
  }
}

.header_checkbox_group .todo_header_radio_group {
  margin: 0px 20px;
  margin-top: 5px;
}

@media only screen and (max-width: 1199px) {
  .header_checkbox_group .todo_header_radio_group {
    margin: 0px 0px;
  }
}

.header_checkbox_group .todo_header_radio_group label {
  margin: 0px 20px;
  font-weight: 400;
}

@media only screen and (max-width: 639px) {
  .header_checkbox_group .todo_header_radio_group label {
    margin: 0px 10px;
    font-size: 9px;
  }
}

.calender_hedaer_actions {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  height: 43px;
}

@media only screen and (max-width: 1199px) {
  .calender_hedaer_actions {
    flex-direction: column-reverse;
    height: 100%;
  }
}

.calender_hedaer_actions button {
  margin: 0px 2px;
  border-color: #0793c7;
  height: 100%;
}

@media only screen and (max-width: 1199px) {
  .calender_hedaer_actions button {
    width: calc(45vw - 109px);
    height: 43px;
  }
}

@media only screen and (max-width: 991px) {
  .calender_hedaer_actions button {
    width: 43vw;
    height: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .calender_hedaer_actions button {
    font-size: 12px;
    width: 45vw;
    height: 43px;
  }
}

.calender_hedaer_actions button:first-child {
  border-color: #ffb300;
}

.calender_header {
  display: flex;
  align-items: baseline;
}

.calender_header h1 {
  font-size: 29px;
  font-weight: 400;
  margin: 0px 20px 0px 0px;
  color: #000;
}

@media only screen and (max-width: 1199px) {
  .calender_header h1 {
    color: #00678d;
  }
}

body.rtl .calender_header h1 {
  margin: 0px 0px 0px 20px;
}

.calender_header .calendar_timeline {
  display: flex;
}

@media only screen and (max-width: 639px) {
  .calender_header .calendar_timeline {
    display: none;
  }
}

.calender_header .calendar_timeline p {
  font-size: 12px;
}

.calender_header .calendar_timeline p:last-child {
  color: #0793c7;
}

.scheduler-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 0px 0px 35px;
  /* style agenda */
}

body.rtl .scheduler-wrapper {
  margin: 0px 35px 0px 0px;
}

@media only screen and (max-width: 991px) {
  .scheduler-wrapper {
    margin: 0px 10px;
  }
}

.scheduler-wrapper .ant-popover-arrow {
  display: none;
}

.scheduler-wrapper .custom_agenda_view .k-scheduler-agendaday {
  color: #0793c7;
  font-size: 30px;
  line-height: 23px;
}

.scheduler-wrapper .custom_agenda_view .k-scheduler-agendaweek {
  font-weight: bold;
  font-size: 9px;
  line-height: 1px;
}

.scheduler-wrapper .custom_agenda_view .k-scheduler-agendadate {
  font-weight: bold;
  font-size: 9px;
  line-height: 1px;
}

.scheduler-wrapper .custom_agenda_view .k-scheduler-datecolumn {
  background-color: #f8f5f5;
  justify-content: center;
}

.scheduler-wrapper .custom_agenda_view .k-scheduler-layout-flex .k-scheduler-cell {
  border-right: none !important;
}

.scheduler-wrapper .k-scheduler-layout-flex .k-scheduler-cell {
  font-weight: bold;
}

@media only screen and (max-width: 575px) {
  .scheduler-wrapper .k-scheduler-layout-flex .k-scheduler-cell {
    font-size: 8px;
  }
}

.scheduler-wrapper .k-scheduler-cell.k-scheduler-timecolumn {
  min-width: 50px !important;
  width: 100%;
}

.scheduler-wrapper .k-scheduler-toolbar {
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
}

.scheduler-wrapper .k-scheduler-toolbar .k-spacer {
  display: none;
}

.scheduler-wrapper .k-scheduler-toolbar .k-datepicker {
  margin: auto;
  display: flex;
  justify-content: center;
}

.scheduler-wrapper .k-scheduler-toolbar .k-datepicker .k-i-calendar {
  display: none;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button {
  font-weight: 400;
  background-color: transparent;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button.k-state-active {
  color: #074860;
  font-weight: bold;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-views button::before {
  opacity: 0;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button:first-of-type {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #00678d;
  color: #ffffff;
  border-radius: 5px;
  width: 85px;
  height: 27.7px;
  margin: auto 10px;
}

@media only screen and (max-width: 575px) {
  .scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button:first-of-type {
    display: none;
  }
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon {
  background: transparent;
  width: 29px;
  height: 25px;
  margin: auto 5px;
  border-radius: 6px;
  border: solid 1px #b1cbd4;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon::before {
  opacity: 0;
}

.scheduler-wrapper .k-scheduler-toolbar .k-scheduler-navigation .k-button-icon:hover {
  background: transparent;
}

.scheduler-wrapper .k-side-cell {
  background: #f8f5f5;
  color: #0793c7;
}

.scheduler-wrapper .calendar-item-card-agenda-startnow {
  width: -moz-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.scheduler-wrapper .calendar-item-card-agenda {
  width: -moz-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

@media only screen and (max-width: 1023px) {
  .scheduler-wrapper .calendar-item-card-agenda {
    justify-content: center;
  }
}

.scheduler-wrapper .calendar-item-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit;
  color: #000;
  font-weight: bold;
}

.scheduler-wrapper .calendar-item-card .calendar-item-card__title {
  font-size: 10px;
  white-space: nowrap;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .scheduler-wrapper .calendar-item-card .calendar-item-card__title {
    font-size: 7px;
  }
}

.scheduler-wrapper .calendar-item-card.week {
  padding: 20px 15px;
}

.scheduler-wrapper .calendar-item-card.week:nth-child(2) {
  border-bottom: 5px solid #b1cbd4;
}

.scheduler-wrapper .calendar-item-card.week .calendar-item-card__title {
  font-size: 10px;
}

.scheduler-wrapper .calendar-item-card.week .calendar-item-card__desc {
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.scheduler-wrapper .calendar-item-card.day {
  align-items: center;
  justify-content: center;
}

.custom_popover_onlinesession .ant-popover-inner {
  border: #074860 solid 2px;
  width: 186px;
  height: 216px;
  border-radius: 0px;
  padding: 5px;
}

.custom_popover_onlinesession .ant-popover-inner p {
  font-size: 7px;
  text-align: left;
}

body.rtl .custom_popover_onlinesession .ant-popover-inner p {
  text-align: right;
  direction: rtl;
}

.custom_popover_event .ant-popover-inner {
  border: #06d236 solid 2px;
  width: 186px;
  height: 216px;
  border-radius: 0px;
  padding: 5px;
}

.custom_popover_event .ant-popover-inner p {
  font-size: 7px;
}

body.rtl .custom_popover_event .ant-popover-inner p {
  /*   text-align:right;
                direction: rtl; */
}

.custom_popover_timetable .ant-popover-inner {
  border: #0793c7  solid 2px;
  width: 186px;
  height: 216px;
  border-radius: 0px;
  padding: 5px;
}

.custom_popover_timetable .ant-popover-inner p {
  font-size: 7px;
}

body.rtl .custom_popover_timetable .ant-popover-inner p {
  /*   text-align:right;
                direction: rtl; */
}

.ant-popover-title {
  padding: 0px;
  min-width: unset;
  max-width: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-popover-inner-content {
  width: 170px !important;
  min-width: 170px;
  max-width: 170px;
}

.week_item_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media only screen and (max-width: 1199px) {
  .week_item_wrapper {
    padding: 20px 0px;
    font-size: 6px;
  }
}

.week_item_wrapper p {
  font-size: 12px;
}

@media only screen and (max-width: 575px) {
  .week_item_wrapper p {
    font-size: 7px;
  }
}

.courses-wrapper {
  height: 100%;
  padding: 15px 0;
  width: 100%;
}

body.rtl .courses-wrapper .ant-select-selection__clear {
  left: 11px !important;
  right: unset !important;
}

body.rtl .ant-modal-confirm .ant-modal-confirm-btns {
  float: left;
}

body.rtl .ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  margin: 0px 5px !important;
}

.courses-search-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.courses-search-header .target-select-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.courses-search-header .target-select-holder h2 {
  font-size: 27px;
  font-weight: normal;
  line-height: 1.7;
  color: #00678d;
  margin-right: 30px;
}

body.rtl .courses-search-header .target-select-holder h2 {
  margin-left: 30px;
  margin-right: 0;
}

.courses-search-header .target-select-holder form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.courses-search-header .target-select-holder .ant-form-item {
  margin-bottom: 0;
  margin-right: 10px;
}

body.rtl .courses-search-header .target-select-holder .ant-form-item {
  margin-left: 10px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input {
  height: 36px;
  border-radius: 4px;
  border: solid 0.5px #e1e1e1 !important;
  padding-left: 10px !important;
}

body.rtl .courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input {
  padding-right: 10px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input:-moz-placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input::-moz-placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input:-ms-input-placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-cascader-input.ant-input::-webkit-input-placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-calendar-picker {
  background: #fff;
}

.courses-search-header .target-select-holder .ant-form-item .ant-calendar-picker input {
  height: 36px;
  border-radius: 4px;
  border: solid 0.5px #e1e1e1 !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.courses-search-header .target-select-holder .ant-form-item .ant-calendar-picker input::placeholder {
  line-height: 1.5;
}

body.rtl .courses-search-header .target-select-holder .ant-form-item .ant-calendar-picker input {
  padding-right: 10px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-select .ant-select-selection {
  height: 36px;
  border-radius: 4px;
  border: solid 0.5px #e1e1e1 !important;
  padding-top: 4px !important;
  padding-left: 10px !important;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

body.rtl .courses-search-header .target-select-holder .ant-form-item .ant-select .ant-select-selection {
  padding-right: 10px;
}

.courses-search-header .target-select-holder .ant-form-item .ant-select .ant-select-selection__placeholder {
  color: #c6c6c6;
  font-size: 14px;
  font-weight: 300;
  line-height: 2.2;
  height: 25px;
}

@media only screen and (max-width: 1247px) {
  .courses-search-header .target-select-holder .ant-form-item .ant-cascader-picker {
    width: auto !important;
  }
}

.courses-search-header .add-course {
  height: 36px;
  margin-top: 3px;
}

@media only screen and (max-width: 575px) {
  .courses-search-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
  }
  .courses-search-header .ant-row.ant-form-item {
    width: 100%;
    margin-bottom: 10px;
  }
  .courses-search-header .ant-row.ant-form-item .ant-cascader-picker,
  .courses-search-header .ant-row.ant-form-item .ant-select {
    width: 100% !important;
  }
}

.courses-tabs-wrapper {
  height: calc(100vh - 175px);
  overflow: auto;
  padding: 0 25px;
}

@media only screen and (max-width: 991px) {
  .courses-tabs-wrapper {
    height: calc(100vh - 250px);
  }
}

@media only screen and (max-width: 575px) {
  .courses-tabs-wrapper {
    padding: 0 16px;
  }
}

.courses-tabs-wrapper .ant-tabs-content {
  min-height: 50vh;
}

.courses-tabs-wrapper .ant-tabs-bar {
  width: fit-content;
}

.courses-tabs-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
  font-weight: normal;
  line-height: 2.75;
  color: #767676;
  padding: 0 10px;
  margin: 0 20px 0 0;
}

body.rtl .courses-tabs-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 20px;
}

.courses-tabs-wrapper .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  color: #2dbb54;
}

.courses-tabs-wrapper .courses-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 13px 20px;
}

@media only screen and (max-width: 1023px) {
  .courses-tabs-wrapper .courses-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 575px) {
  .courses-tabs-wrapper .courses-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.add-course-wrapper .ant-modal-body {
  height: calc(100% - 50px - 0px) !important;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper {
    height: 100%;
    width: 100%;
  }
}

.add-course-wrapper .form_holder--col > div {
  height: 91% !important;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .form_holder--col > div {
    height: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .form_holder--col:first-of-type > div {
    border-bottom: none !important;
  }
}

.add-course-wrapper .form_holder--col:last-of-type > div {
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .form_holder--col:last-of-type > div {
    border-top: none !important;
    margin-bottom: 65px !important;
  }
}

.add-course-wrapper .ant-form-item {
  margin-bottom: 15px;
}

.add-course-wrapper .add-course-content {
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  width: 95%;
  height: 95%;
  margin: auto;
}

.add-course-wrapper .add-course-content h3 {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 20px;
  text-align: start;
}

.add-course-wrapper .add-course-content .ant-divider {
  height: 100%;
}

.add-course-wrapper .add-course-content .course-details-holder,
.add-course-wrapper .add-course-content .target-teacher-holder {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .add-course-content .course-details-holder,
  .add-course-wrapper .add-course-content .target-teacher-holder {
    width: 100%;
  }
}

.add-course-wrapper .add-course-content .students-target-list {
  height: 180px;
  border-radius: 4px;
  border: solid 0.5px #b7b7b7;
  background-color: #ffffff;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px;
  text-align: start;
}

.add-course-wrapper .add-course-content .students-target-list > .ant-checkbox-wrapper {
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

.add-course-wrapper .add-course-content .students-target-list .ant-checkbox-wrapper {
  display: block;
  margin-inline-start: 0px;
}

.add-course-wrapper .add-course-content .students-target-list .empty-target {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.add-course-wrapper .add-course-content .students-target-list .empty-target p {
  opacity: 0.53;
  font-size: 16px;
  color: #b7b7b7;
  margin-top: 4px;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .add-course-content {
    flex-direction: column;
  }
}

.add-course-wrapper .add-course-content .description-holder .ant-form-item-control.has-error .ant-form-explain {
  position: absolute !important;
  top: 70px;
}

.add-course-wrapper .btns-action-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: -55px;
  margin-right: 29px;
}

body.rtl .add-course-wrapper .btns-action-wrapper {
  margin-left: 29px;
}

@media only screen and (max-width: 767px) {
  .add-course-wrapper .btns-action-wrapper {
    display: flex;
    justify-content: center;
    margin: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  }
}

.croping-modal .ant-modal-close {
  right: 0;
}

.croping-modal .ant-modal-close-icon {
  color: #d83025;
}

.croping-modal .ant-modal-body {
  width: 500px;
  margin: 9px;
}

.croping-modal .ant-modal-body img,
.croping-modal .ant-modal-body video {
  padding: 10px;
  width: 100%;
}

.attachment-error-message {
  margin: 0 !important;
  position: absolute !important;
  font-size: 12px;
  font-weight: bold;
  color: #d83025;
  padding: 4px 0px 0 0px;
  text-align: left;
  width: 250px;
}

.attachment-holder .attachment-holder__actions {
  justify-content: flex-start;
  align-items: center;
  margin: auto;
}

.attachment-holder .attachment-holder__actions .attachment-icon {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #e6e6e6;
  color: #575757;
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .ant-divider {
  width: 2px;
  height: 27px;
  margin: 0px;
}

.attachment-holder .attachment-holder__actions .upload-errors {
  background: #fff;
  color: red;
  margin: auto 5px;
}

.attachment-holder .attachment-holder__actions .upload-errors p {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
}

.attachment-holder .attachment-holder__actions .upload-errors .anticon-info-circle {
  vertical-align: middle;
}

.attachment-holder .attachment-holder__actions .grayscale_outline {
  width: 100px;
  margin-top: 5px;
}

.attachment-holder .attachment-holder__list {
  margin-top: 10px;
  display: grid;
  flex-wrap: wrap;
}

.attachment-holder .attachment-holder__list::-webkit-scrollbar {
  height: 7px;
}

.attachment-holder .attachment-holder__list > li {
  display: inline-flex;
  align-items: center;
  width: 42%;
  height: 38px;
  margin: 10px auto;
  margin-right: 10px;
  border-radius: 5px;
  border: solid 1px #cbc5c5;
  background-color: #ffffff;
}

body.rtl .attachment-holder .attachment-holder__list > li {
  margin-right: 0;
  margin-left: 10px;
}

@media only screen and (max-width: 575px) {
  .attachment-holder .attachment-holder__list > li {
    width: 75%;
  }
}

.attachment-holder .attachment-holder__list > li img,
.attachment-holder .attachment-holder__list > li span {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  border-radius: 3px;
}

.attachment-holder .attachment-holder__list > li span {
  color: #fff;
  background-color: #295396;
  text-align: center;
  font-size: 10px;
  padding: 3px 7px;
}

.attachment-holder .attachment-holder__list > li p {
  font-size: 12px;
  width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-inline-start: 5px;
}

.attachment-holder .attachment-holder__list > li i {
  cursor: pointer;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}

.progress__status--wrap {
  background-color: rgba(10, 10, 10, 0.74);
}

.progress__status--wrap .progress__status--percentage .ant-progress-text {
  color: #ffffff;
}

body.rtl .progress__status--wrap .progress__status--percentage .ant-progress-circle .ant-progress-text {
  right: unset;
}

.progress__status--wrap .ant-modal-body {
  padding-bottom: 0 !important;
}

.progress__status {
  text-align: center !important;
}

.progress__status .ant-modal-footer .btns-action-wrapper .grayscale_outline {
  border: none;
  color: #242424 !important;
}

.progress__status .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.progress__status .ant-modal-content .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding: 0;
}

.progress__status .ant-modal-content .ant-progress.ant-progress-line.ant-progress-small {
  bottom: -6px;
}

.progress__status .progress__status--percentage p {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.23;
  color: #fff;
  margin-bottom: 36px;
  margin-top: 24px;
}

.progress__status .progress__status--percentage p span {
  color: #00a510;
}

.upload-icon {
  background: rgba(0, 0, 0, 0.31);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 135px;
}

.ant-table-scroll {
  min-height: 300px;
}

.teacher-modal-wrapper .ant-modal .ant-modal-body .ant-form .ant-form-item textarea.ant-input {
  height: auto;
  min-height: 135px;
  padding-top: 10px;
}

.teacher-modal-wrapper.add__course--modal .ant-calendar-picker {
  width: 100%;
}

.teacher-modal-wrapper.add__course--modal .attachment__holder--data > li {
  width: 162px;
}

.teacher-modal-wrapper .attachment-holder .attachment-holder__actions {
  display: block !important;
}

.teacher-modal-wrapper .attachment-holder .attachment-holder__actions .upload-area-holder {
  width: 100%;
}

.teacher__classRomm--wrapper .ant-select .ant-select-selection {
  background: #ffffff;
}

.teacher__classRomm--wrapper .ant-select .ant-select-selection .ant-select-selection__rendered {
  padding: 0;
}

.teacher__classRomm--wrapper .ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  font-size: 12px;
  padding-top: 3px;
}

.teacher__classRomm--wrapper .ant-select .ant-select-selection.ant-select-selection--multiple {
  height: 100% !important;
}

.teacher__classRomm--wrapper .ant-select .ant-select-selection.ant-select-selection--multiple .ant-select-selection__rendered {
  height: 100% !important;
}

.teacher__classRomm--wrapper .courses-tabs-wrapper {
  position: relative;
  padding-top: 20px;
}

.teacher__classRomm--wrapper .courses-tabs-wrapper .courses-grid {
  padding-bottom: 55px;
}

.pagination_holder {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  max-height: 52px;
}

.pagination_holder .ant-pagination.edit_pagination {
  /* background: #ffffff;
			   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22) !important;
			*/
  padding: 11px 20px;
  border-radius: 20px;
}

.pagination_holder .ant-pagination.edit_pagination .ant-pagination-item, .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link {
  background-color: #fafafa;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22) !important;
  border: unset;
  color: #2699fb;
  height: 50px;
  width: 50px;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item, .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link {
    height: unset;
    width: unset;
    padding: unset;
  }
}

.pagination_holder .ant-pagination.edit_pagination .ant-pagination-item a, .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link a {
  color: #2699fb;
}

.pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link {
  margin: -9px 0px;
}

body.rtl .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 767px) {
  .pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-link {
    margin: unset;
  }
}

.pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-active {
  background-color: #ffffff;
}

.pagination_holder .ant-pagination.edit_pagination .ant-pagination-item-active a {
  color: #2699fb;
}

.teacher-modal-wrapper .ant-select .ant-select-selection.ant-select-selection--multiple {
  height: 100% !important;
}

.teacher-modal-wrapper .ant-select .ant-select-selection.ant-select-selection--multiple ul li {
  margin-bottom: 11px;
}

.courses-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.courses-empty-state img {
  margin: 20px 0;
  width: 350px;
}

.courses-empty-state h1 {
  font-size: 21px;
  font-weight: normal;
  line-height: 1.19;
  color: #00678d;
  margin-top: 10px;
}

.exams-list-wrapper__header {
  margin: 15px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .exams-list-wrapper__header {
    margin: 0;
    padding: 15px;
    padding-bottom: 0;
  }
}

.exams-list-wrapper__header .header__tabs-holder {
  display: flex;
  justify-content: flex-start;
  margin-top: -34px;
}

@media only screen and (max-width: 1199px) {
  .exams-list-wrapper__header .header__tabs-holder {
    margin-top: 20px;
  }
}

.exams-list-wrapper__header .header__tabs-holder li {
  padding: 5px;
  margin-right: 60px;
  font-weight: bold;
  cursor: pointer;
}

body.rtl .exams-list-wrapper__header .header__tabs-holder li {
  margin-left: 60px;
  margin-right: 0;
}

@media only screen and (max-width: 1199px) {
  .exams-list-wrapper__header .header__tabs-holder li {
    padding: 8px;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .exams-list-wrapper__header .header__tabs-holder li {
    font-size: 12px;
    padding: 0;
    margin: 0 8px;
  }
}

.exams-list-wrapper__header .header__tabs-holder li.active {
  color: #0793c7;
  border-bottom: 1px solid #0793c7;
}

.exams-list-wrapper__header .header__title-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}

@media only screen and (max-width: 1199px) {
  .exams-list-wrapper__header .header__title-holder {
    align-items: center;
  }
}

.exams-list-wrapper__header .header__title-holder p {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #2a7d9c;
  margin-right: 15px;
  margin-bottom: 10px;
}

body.rtl .exams-list-wrapper__header .header__title-holder p {
  margin-right: 0;
  margin-left: 15px;
}

.exams-list-wrapper__header .header__title-holder .online_session_picker {
  height: 100%;
  background-color: #fff;
}

.exams-list-wrapper__header .header__title-holder .create-exam {
  height: 36px;
  margin: 0 15px;
}

body.rtl .exams-list-wrapper__header .header__title-holder .date_create_session {
  margin-right: 16px;
}

.exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker .ant-input.ant-calendar-picker-input {
  width: 231px;
}

@media only screen and (max-width: 1199px) {
  .exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker .ant-input.ant-calendar-picker-input {
    width: 100%;
  }
}

.exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker .ant-input.ant-calendar-picker-input .ant-calendar-range-picker-separator {
  margin-top: 8px;
}

@media only screen and (max-width: 767px) {
  .exams-list-wrapper__header .header__title-holder {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .exams-list-wrapper__header .header__title-holder P {
    width: 100%;
    margin-bottom: 24px;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session {
    flex-wrap: wrap;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session > .ant-input {
    width: 48%;
    margin-right: 10px;
  }
  body.rtl .exams-list-wrapper__header .header__title-holder .date_create_session > .ant-input {
    margin-right: 0;
    margin-left: 10px;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session .ant-select {
    width: 47%;
    margin: 0;
    margin-bottom: 12px;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker {
    display: inline-block;
    flex-basis: 48%;
    margin-right: 10px;
  }
  body.rtl .exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker {
    margin-right: 0;
    margin-left: 10px;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session .online_session_picker .ant-input.ant-calendar-picker-input {
    width: 100%;
  }
  .exams-list-wrapper__header .header__title-holder .date_create_session .create-exam {
    width: 47%;
    margin: 0;
  }
}

.exam_list_item {
  margin: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 90px;
  margin-bottom: 15px;
  padding: 10px;
  flex-direction: column;
  color: #2c363f;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  overflow: unset;
  cursor: pointer;
}

.exam_list_item.rejected {
  border: solid 3px #fc4242;
}

.exam_list_item .first_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.exam_list_item .first_line .flex_row {
  display: flex;
  align-items: center;
}

.exam_list_item .first_line .flex_row img {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-inline-end: 5px;
}

.exam_list_item .first_line .flex_row p {
  font-size: 13px;
  margin: 0px 10px;
  line-height: 20px;
}

.exam_list_item .first_line .flex_row h6 {
  padding: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.exam_list_item .first_line .flex_row .corrector__span {
  position: relative;
  padding-left: 27px;
}

body.rtl .exam_list_item .first_line .flex_row .corrector__span {
  padding-left: 0;
  padding-right: 27px;
}

.exam_list_item .second_line {
  display: flex;
}

.exam_list_item .second_line p {
  border-right: 1px solid #d2dade;
  padding: 0px 20px;
  font-size: 13px;
  font-weight: 600;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .exam_list_item .second_line p {
    padding: 0px 14px;
  }
}

body.rtl .exam_list_item .second_line p {
  border-right: none;
  border-left: 1px solid #d2dade;
}

body.rtl .exam_list_item .second_line p:last-of-type {
  border: none;
}

.exam_list_item .second_line p:last-of-type {
  border-right: none;
}

.exam_list_item_responsive {
  margin: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px !important;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 200px;
  margin-bottom: 15px;
  padding: 10px 20px;
  flex-direction: column;
  position: relative;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .exam_list_item_responsive {
    padding: 10px 11px;
  }
}

.exam_list_item_responsive .flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0px;
}

.exam_list_item_responsive .flex_row h5 {
  font-size: 12px;
}

.exam_list_item_responsive .flex_row.blue p {
  color: #3c8ba7;
}

.exam_list_item_responsive .flex_row span {
  color: #00678d;
  font-size: 12px;
}

.exam_list_item_responsive .flex_row p {
  font-size: 10px;
}

.exam_list_item_responsive .flex_row h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.exam_list_item_responsive .flex_row .corrector__span {
  position: relative;
  font-size: 8px;
  padding-top: 3px;
  padding-left: 13px;
}

body.rtl .exam_list_item_responsive .flex_row .corrector__span {
  padding-left: 0;
  padding-right: 19px;
  margin-left: 8px;
}

.exam_list_item_responsive .property_item {
  display: flex;
  justify-content: flex-start;
  flex-basis: 31%;
  border-right: 1px solid #d2dade;
}

body.rtl .exam_list_item_responsive .property_item {
  border-right: none;
  border-left: 1px solid #d2dade;
}

.exam_list_item_responsive .property_item:last-child {
  border: none !important;
}

.exam_list_item_responsive .property_item p {
  color: #000;
  font-size: 10px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exam_list_item_responsive span {
  margin-right: 5px;
}

.exam_list_item_responsive span img {
  width: 15px;
  height: 15px;
}

.popover_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 15px;
  padding: 15px;
}

.popover_content img {
  width: 46px;
  height: 42px;
}

.exam_list_pagination {
  margin: 5px;
}

.exam_list_pagination .ant-pagination {
  display: flex;
  justify-content: center;
}

.exam_list_pagination .ant-pagination .ant-pagination-item {
  border: none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.exam_list_pagination .ant-pagination .ant-pagination-item a {
  color: #2699fb;
}

.exam_list_pagination .ant-pagination .ant-pagination-item-active {
  background-color: #00adee;
}

.exam_list_pagination .ant-pagination .ant-pagination-item-active a {
  color: #fff;
}

.exam_list_pagination .ant-pagination .ant-pagination-item-link {
  color: #2699fb;
}

.exam-list-wrapper .ant-empty {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.exam-list-wrapper .lottie-spinner {
  position: absolute;
}

.date_create_session {
  margin-bottom: 10px;
  display: flex;
}

@media only screen and (max-width: 1199px) {
  .date_create_session {
    display: flex;
    margin-right: 15px;
  }
}

.date_create_session .ant-input {
  width: 162px;
  height: 36px;
  margin: 1px 15px 2px 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
}

.date_create_session .ant-input.ant-calendar-picker-input {
  width: 222px;
  margin: 0px;
  font-size: 12px;
}

.date_create_session .ant-select {
  width: 162px;
  height: 36px;
  margin: 1px 15px 2px 0;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
}

.date_create_session .ant-select-selection-selected-value {
  font-size: 12px;
  font-weight: normal;
}

.date_create_session .ant-select-selection__placeholder {
  font-size: 12px !important;
  color: #a3a3a3;
  font-weight: 400 !important;
  margin: -8px 10px;
}

.date_create_session .online_session_picker {
  height: 100%;
  background-color: #fff;
  /*  @include mq('phone'){

        } */
}

@media only screen and (max-width: 1199px) {
  .date_create_session .online_session_picker {
    width: 100%;
    flex-basis: 50%;
  }
}

.date_create_session .create_session {
  height: 100%;
  margin: 0px 5px;
}

@media only screen and (max-width: 1199px) {
  .date_create_session .create_session {
    width: 100%;
    flex-basis: 50%;
    background-color: #00678d;
    color: #fff;
    font-size: 10px;
  }
}

.delete-exam-modal .ant-modal-header {
  border-bottom: none;
  text-align: center;
  margin-top: 15px;
}

.delete-exam-modal .ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #464a53;
}

.delete-exam-modal .ant-modal-body {
  padding: 0;
}

.delete-exam-modal .ant-modal-footer {
  text-align: center;
  border-top: none;
  margin-top: 15px;
  padding-bottom: 20px;
}

.delete-exam-modal .training-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-exam-modal .training-modal-content p {
  font-size: 16px;
  line-height: 1.74;
  margin-top: 40px;
  color: #424242;
}

@media only screen and (max-width: 767px) {
  .delete-exam-modal .training-modal-content p {
    font-size: 12px;
  }
}

.training-mode-modal .ant-modal-header {
  border-bottom: none;
}

.training-mode-modal .ant-modal-header .ant-modal-title {
  font-size: 18px;
  color: #464a53;
}

.training-mode-modal .ant-modal-footer {
  border-top: none;
  margin-top: 15px;
  padding-bottom: 20px;
}

.training-mode-modal .training-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-mode-modal .training-modal-content p {
  font-size: 16px;
  line-height: 1.74;
  color: #848484;
}

.training-mode-modal .training-modal-content img {
  margin-top: 40px;
}

.exam_list_correctors .ant-modal-body {
  padding: unset;
}

.exam_list_correctors .ant-modal-footer {
  padding: 0px 25px 10px;
  border: unset;
}

.exam_list_correctors .ant-modal-footer div button {
  width: 100px;
  height: 40px;
  font-size: 16px;
}

.exam_list_correctors .ant-modal-footer div button:first-child {
  color: #ff0000;
  border: solid 1px #ff0000;
}

.exam_list_correctors .ant-modal-footer div button:last-child {
  color: #fff;
  background-color: #00678d;
}

.exam_empty_state {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam_empty_state img {
  width: 500px;
  margin: 0px 20px;
}

@media only screen and (max-width: 991px) {
  .exam_empty_state img {
    width: 100vw;
  }
}

.exam_empty_state p {
  margin: 10px 0px;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #00678d;
}

.exam__action--menu {
  min-width: 160px;
}

.exam-main-toolbar {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 20px 24px;
}

.exam-main-toolbar .collapsed__exam {
  display: flex;
  justify-content: space-between;
}

.exam-main-toolbar .collapsed__exam img {
  cursor: pointer;
}

.exam-main-toolbar .collapsed__exam h3 {
  font-size: 19px;
  font-weight: normal;
  color: #2a7d9c;
}

.exam-main-toolbar .ant-form-item {
  margin-bottom: 16px;
}

.exam-main-toolbar .exam__toolbar--form .ant-input {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
}

.exam-main-toolbar .exam__toolbar--form .ant-calendar-picker {
  width: 100%;
  min-width: unset !important;
  max-width: 100% !important;
}

.exam-main-toolbar .exam__toolbar--form .ant-calendar-picker .ant-calendar-input-wrap {
  display: none;
}

.exam-main-toolbar .exam__toolbar--form .ant-select .ant-select-selection__placeholder {
  font-size: 15px;
  font-weight: normal;
  color: #bebecb;
  top: 46%;
  left: 14px;
  right: 14px;
}

.exam-main-toolbar .exam__toolbar--form .ant-select .ant-select-selection {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  height: 38px;
}

.exam-main-toolbar .exam__toolbar--form .ant-select .ant-select-selection__rendered .ant-select-selection-selected-value {
  font-size: 13px;
  font-weight: normal;
  color: #3b3b3b;
}

.exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch {
  height: 25px !important;
}

@media only screen and (max-width: 767px) {
  .exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch {
    height: 18px !important;
  }
}

.exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch::after {
  top: -1px;
}

@media only screen and (max-width: 767px) {
  .exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch::after {
    width: 18px;
    height: 18px;
  }
}

.exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch.ant-switch-checked {
  background: #00678d;
}

.exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch.ant-switch-checked::after {
  background: #ffffff;
}

@media only screen and (max-width: 767px) {
  .exam-main-toolbar .exam__toolbar--form .exam__switch .ant-form-item-children .ant-switch.ant-switch-checked::after {
    width: 18px;
    height: 18px;
  }
}

.exam-main-toolbar .exam__toolbar--form .exam__switch span {
  font-size: 13px;
  font-weight: normal;
  color: #4b4b4b;
}

@media only screen and (max-width: 767px) {
  .exam-main-toolbar .exam__toolbar--form .exam__switch span {
    font-size: 9px;
  }
}

.exam-main-toolbar .exam__toolbar--form .degree__exam--input .ant-input[disabled] {
  color: #3b3b3b;
}

.exam-main-toolbar .exam__toolbar--form .k-widget.k-timepicker {
  width: 100%;
}

.exam-main-toolbar .exam__toolbar--form .k-widget.k-timepicker .k-picker-wrap.k-state-default {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  width: 100%;
  padding: 0 8px;
}

.exam-main-toolbar .exam__toolbar--form .duration__border--err .k-widget.k-timepicker .k-picker-wrap.k-state-default {
  border: solid 1px #d83025 !important;
}

.exam-main-toolbar .exam__grade--cascader .ant-cascader-picker {
  width: 100%;
}

.exam-main-toolbar .exam__grade--cascader .ant-cascader-picker .ant-input.ant-cascader-input {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  height: 38px;
}

.exam-main-toolbar .exam__grade--cascader .Suprevisor__subject--grade {
  height: 38px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  width: 100%;
  padding: 0 11px;
  cursor: not-allowed;
}

.exam-main-toolbar .exam__time--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 42px;
}

@media only screen and (max-width: 767px) {
  .exam-main-toolbar .exam__time--wrapper {
    flex-wrap: wrap;
  }
}

.exam-main-toolbar .exam__time--wrapper h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4b4b4b;
}

@media only screen and (max-width: 575px) {
  .exam-main-toolbar .exam__time--wrapper h3 {
    font-size: 13px;
  }
}

.exam-main-toolbar .exam__time--wrapper .ant-radio-wrapper {
  font-size: 16px;
  font-weight: 500;
  color: #4b4b4b;
}

@media only screen and (max-width: 575px) {
  .exam-main-toolbar .exam__time--wrapper .ant-radio-wrapper {
    font-size: 13px;
  }
}

.exam-main-toolbar .student__selected--list.exam__class--list {
  overflow-y: unset;
  padding: 10px 0 0 0;
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__list--header {
  padding: 0 15px 0 15px;
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__list--header button {
  font-size: 13px;
  font-weight: normal;
  color: #00678d;
  outline: 0;
  border: none;
  flex-shrink: 0;
}

@media only screen and (max-width: 575px) {
  .exam-main-toolbar .student__selected--list.exam__class--list .student__list--header button {
    font-size: 11px;
  }
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__list--header button img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

body.rtl .exam-main-toolbar .student__selected--list.exam__class--list .student__list--header button img {
  margin-left: 5px;
  margin-right: 0;
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__list--header span {
  font-size: 13px;
  font-weight: normal;
  padding: 0;
}

@media only screen and (max-width: 575px) {
  .exam-main-toolbar .student__selected--list.exam__class--list .student__list--header span {
    font-size: 11px;
  }
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__select .student__select--name {
  width: unset;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.exam-main-toolbar .student__selected--list.exam__class--list .empty__student--list {
  padding: 0 15px 0 15px;
  height: 100%;
  min-height: unset;
}

.exam-main-toolbar .student__selected--list.exam__class--list .student__list--body {
  padding: 5px 15px 20px 15px;
  height: 194px;
  overflow-y: auto;
}

.add__exam--classes {
  width: 610px !important;
  height: 441px !important;
  max-width: 90%;
  transform-origin: 685px 247.3px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.1);
}

.add__exam--classes .exam__modal--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
}

body.rtl .add__exam--classes .exam__modal--title {
  padding-left: 30px;
  padding-right: 0;
}

@media only screen and (max-width: 479px) {
  .add__exam--classes .exam__modal--title {
    flex-direction: column;
  }
}

.add__exam--classes .exam__modal--title p {
  font-size: 18px;
  font-weight: bold;
  color: #00678d;
}

.add__exam--classes .exam__modal--title span {
  font-size: 10px;
  font-weight: 600;
  color: #9797ac;
}

.add__exam--classes .ant-cascader-picker {
  width: 100%;
}

.add__exam--classes .ant-checkbox-group {
  width: 100%;
  height: 206px;
  overflow-y: auto;
}

.add__exam--classes .ant-checkbox-group .ant-checkbox-group-item {
  display: block;
  font-size: 13px;
  font-weight: bold;
  color: #00678d;
  margin-bottom: 15px;
}

.add__exam--classes .ant-checkbox-group .ant-checkbox-group-item > span:first-child {
  margin-right: 38px;
}

body.rtl .add__exam--classes .ant-checkbox-group .ant-checkbox-group-item > span:first-child {
  margin-right: 0;
  margin-left: 38px;
}

.classes__casceder--menu .ant-cascader-menu {
  min-width: 157px;
}

@media only screen and (max-width: 575px) {
  .classes__casceder--menu .ant-cascader-menu {
    min-width: 111px;
  }
}

.exam__quiz--header {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .exam__quiz--header {
    flex-direction: column;
  }
}

.exam__quiz--header .ant-input-number-input-wrap {
  height: 100%;
}

.exam__quiz--header .ant-input-number-input-wrap input {
  height: 100%;
  padding-left: 7px !important;
}

body.rtl .exam__quiz--header .ant-input-number-input-wrap input {
  padding-left: 0 !important;
  padding-right: 7px !important;
}

.exam__quiz--header .input__degree--number {
  width: 122px;
  height: 41px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
  text-align: center;
  color: #4b4b4b;
  font-weight: 500;
  padding-left: 7px !important;
}

.exam__quiz--header .input__degree--number::placeholder {
  font-size: 14px;
  font-weight: normal;
  color: #b4bdc0;
}

body.rtl .exam__quiz--header .input__degree--number {
  margin-right: 10px;
  margin-left: 0;
  padding-left: 0 !important;
  padding-right: 7px !important;
}

@media only screen and (max-width: 767px) {
  .exam__quiz--header .input__degree--number {
    margin-bottom: 18px;
  }
}

.exam__quiz--header .input__degree--number .ant-input-number-input-wrap {
  height: 100%;
}

.exam__quiz--header .input__degree--number .ant-input-number-input-wrap input {
  height: 100%;
}

.assign__teacher--modal .ant-radio-group-outline {
  margin-top: 20px;
}

.assign__teacher--modal .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner {
  width: 14px;
  height: 14px;
  border: solid 1px #00678d;
  background-color: #ffffff;
}

.assign__teacher--modal .ant-radio-group-outline .ant-radio-wrapper .ant-radio-inner::after {
  width: 6px;
  height: 7px;
}

.assign__teacher--modal .ant-radio-group-outline .teacher__options {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #00678d;
}

.assign__teacher--modal .ant-radio-group-outline .teacher__options img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 15px;
}

.assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group {
  border-bottom: none;
}

.assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group input {
  height: 35px;
  border-radius: 6px;
  border: solid 1px #00adee;
  padding-left: 30px;
  padding-right: 10px;
}

body.rtl .assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group input {
  padding-left: 10px;
  padding-right: 30px;
}

.assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon {
  left: 5px;
  width: 20px;
  top: 4px;
}

body.rtl .assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon {
  left: auto;
  right: 5px;
}

.assign__teacher--search .search_holder .ant-input-search .ant-input-wrapper.ant-input-group .ant-input-group-addon button {
  color: #00adee;
}

.assignedto__teacher .assignedto__teacher--name {
  display: flex;
  align-items: center;
}

.assignedto__teacher .assignedto__teacher--name > span {
  font-size: 13px;
  font-weight: 600;
  color: #9797ac;
}

.assignedto__teacher .assignedto__teacher--name > span.status {
  font-weight: normal;
  color: #00adee;
  margin-left: 35px;
  font-weight: 500;
}

.assignedto__teacher .assignedto__teacher--name > span.status.status-4 {
  color: #ff0000;
  cursor: pointer;
}

.assignedto__teacher .assignedto__teacher--name > span.status.status-2 {
  color: #00ff1a;
}

body.rtl .assignedto__teacher .assignedto__teacher--name > span.status {
  margin-left: 0;
  margin-right: 35px;
}

.assignedto__teacher .assignedto__teacher--name p span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #00678d;
  border-bottom: 1px solid #00678d;
}

.assignedto__teacher .assignedto__teacher--name p img {
  width: 30px;
  height: 30px;
  margin-left: 17px;
  margin-right: 11px;
  border-radius: 50%;
}

body.rtl .assignedto__teacher .assignedto__teacher--name p img {
  margin-right: 17px;
  margin-left: 11px;
}

@media only screen and (max-width: 767px) {
  .header__exam--assign {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .header__exam--assign .assignedto__teacher {
    margin-top: 20px;
  }
  .header__exam--assign .assignedto__teacher .assignedto__teacher--name {
    flex-wrap: wrap;
  }
  .header__exam--assign .assignedto__teacher .assignedto__teacher--name > span {
    font-size: 10px;
  }
  .header__exam--assign .assignedto__teacher .assignedto__teacher--name > span.status {
    margin-left: 16px;
  }
  body.rtl .header__exam--assign .assignedto__teacher .assignedto__teacher--name > span.status {
    margin-left: 0;
    margin-right: 16px;
  }
  .header__exam--assign .assignedto__teacher .assignedto__teacher--name p img {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .header__exam--assign .assignedto__teacher .assignedto__teacher--name p span {
    font-size: 12px;
  }
}

.exam_correctors_card {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 20px 24px;
  margin: 10px 0px;
}

.exam_correctors_card footer {
  display: flex;
  justify-content: flex-end;
}

.exam_correctors_card footer button {
  width: 102px;
  height: 40px;
  margin: 15px 2px 0 0px;
  padding: 10px 31px 11px 32px;
  border-radius: 4px;
  background-color: #00678d;
  color: #fff;
}

.exam_correctors_card .correctors_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .exam_correctors_card .correctors_row {
    flex-wrap: wrap;
  }
}

.exam_correctors_card .correctors_row span {
  display: inline-flex;
  align-items: center;
}

.exam_correctors_card .correctors_row span p {
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.74;
  letter-spacing: normal;
  color: #2a7d9c;
  margin: 0px 5px;
}

.exam_correctors_card .correctors_row .correction_mode {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .exam_correctors_card .correctors_row .correction_mode {
    justify-content: space-between;
    width: 100%;
  }
}

.exam_correctors_card .correctors_row .correction_mode .selected_teacher_count {
  margin: 0px 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.69;
  color: #b6b6b6;
}

.exam_correctors_card .correctors_row .correction_mode .ant-select {
  width: 130px;
  height: 31px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #bed2d8;
  background-color: #ffffff;
  margin: 0px 30px;
}

@media only screen and (max-width: 767px) {
  .exam_correctors_card .correctors_row .correction_mode .ant-select {
    width: 180px;
    margin: 0 10px;
  }
}

.exam_correctors_card .correctors_row .correction_mode .ant-select-selection-selected-value {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #00678d;
}

.exam_correctors_card .correctors_row .correction_mode .ant-select-selection__placeholder {
  font-size: 12px;
  padding: 0px 5px;
}

.exam_correctors_card .correctors_row .correction_mode img {
  cursor: pointer;
}

.teachers_questions_list_card {
  margin: 10px 0px;
  height: 270px;
  overflow-y: auto;
  padding: 12px 1.5px 24px 25.7px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #b1cbd4;
}

@media only screen and (max-width: 767px) {
  .teachers_questions_list_card {
    padding: 12px 5px 24px 5px;
  }
}

.teachers_questions_list_card .search_holder {
  width: 212px;
}

@media only screen and (max-width: 767px) {
  .teachers_questions_list_card .search_holder {
    margin: 0 10px;
    width: 125px;
  }
}

.teachers_questions_list_card .flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teachers_questions_list_card .flex_row .actions {
  display: flex;
}

.teachers_questions_list_card .flex_row .actions p {
  margin: 0px 5px;
  cursor: pointer;
}

.teachers_questions_list_card .flex_row .actions p img {
  margin: 0px 2px;
}

.selected_teacher_item {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected_teacher_item .flex_row {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .selected_teacher_item .flex_row {
    width: 60%;
  }
}

.selected_teacher_item .flex_row p {
  margin: 0px 20px;
  color: #00678d;
  font-weight: 500;
}

.selected_teacher_item .flex_row .ant-input-number {
  width: 143px;
  margin-top: unset;
  margin: 0px 10px;
  border-radius: 4px;
  border: solid 1px #b4cdd6;
  margin: 0px 10px;
}

.selected_teacher_item .flex_row .ant-input-number .ant-input-number-input {
  text-align: center;
}

body.rtl .selected_teacher_item .flex_row .ant-input-number .ant-input-number-input {
  text-align: center !important;
}

.selected_teacher_item .flex_row .ant-select {
  width: 231px;
  height: 31px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #bed2d8;
  background-color: #ffffff;
  margin: 0px 40px;
}

@media only screen and (max-width: 767px) {
  .selected_teacher_item .flex_row .ant-select {
    max-width: 100%;
    margin: 0px 5px;
  }
}

.selected_teacher_item .flex_row .ant-select-selection-selected-value {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #00678d;
}

.selected_teacher_item .flex_row .ant-select-selection__placeholder {
  font-size: 12px;
  padding: 0px 5px;
  display: unset;
  margin: -12px 0px;
}

.selected_teacher_item .flex_row img {
  margin: 0px 5px;
  cursor: pointer;
}

.selected_teacher_item .question_title_item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  text-align: left;
  color: #00678d;
}

@media only screen and (max-width: 767px) {
  .selected_teacher_item .question_title_item {
    max-width: 37%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.selected_teacher_item .question_title_item img {
  margin: 0px 5px;
}

.qustion_modal_html .ck-toolbar {
  border: none !important;
}

.qustion_modal_html figure img {
  width: 100% !important;
}

.qustion_modal_html iframe {
  width: 100% !important;
  min-height: 250px;
  max-height: 400px;
  min-width: 320px;
}

.subject__width--dropdown.ant-select-dropdown {
  width: 320px !important;
}

.rtl .ant-calendar-time-picker-select {
  overflow-y: auto !important;
}

.video__player--modal .ant-modal-body {
  padding: 48px 24px 30px;
}

.teachers_questions_list_card .search_holder,
.assign__teacher--search .search_holder {
  margin: 0 22px;
}

.teachers_questions_list_card .ant-checkbox-group .teacher__options,
.assign__teacher--search .ant-checkbox-group .teacher__options {
  font-size: 14px;
  font-weight: 500;
  color: #00678d;
}

.teachers_questions_list_card .ant-checkbox-group .teacher__options img,
.assign__teacher--search .ant-checkbox-group .teacher__options img {
  margin-right: 10px !important;
}

body.rtl .teachers_questions_list_card .ant-checkbox-group .teacher__options img, body.rtl
.assign__teacher--search .ant-checkbox-group .teacher__options img {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.clone_modal_class_item .ant-checkbox-wrapper.classes__name {
  font-size: 13px;
  font-weight: bold;
  color: #00678d;
}

.clone_modal_class_item .ant-checkbox-wrapper.classes__name .ant-checkbox-inner {
  border-color: #00678d;
  border-width: 1px;
}

.clone_modal_class_item .ant-checkbox-wrapper.classes__name span:last-child {
  margin-left: 20px;
}

body.rtl .clone_modal_class_item .ant-checkbox-wrapper.classes__name span:last-child {
  margin-left: 0;
  margin-right: 20px;
}

.clone_modal_class_item .ant-checkbox-wrapper.classes__name.hidden {
  display: none !important;
  margin: 0 !important;
}

.clone_modal_class_item .ant-checkbox-wrapper.classes__name.visible {
  opacity: 1;
  height: auto;
}

.corectors__confirm--modal {
  width: 480px;
  max-width: 90%;
}

.corectors__confirm--modal .ant-modal-body {
  padding: 25px 18px 18px;
}

.corectors__confirm--modal .ant-modal-body .ant-modal-confirm-content {
  margin: 0 !important;
}

.corectors__confirm--modal .ant-modal-body .ant-modal-confirm-content .corectors__confirm {
  margin-top: 30px;
  text-align: center;
}

.corectors__confirm--modal .ant-modal-body .ant-modal-confirm-content .corectors__confirm p {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #424242;
}

.corectors__confirm--modal .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  width: 100%;
  text-align: center;
}

.k-time-list .k-item {
  margin-top: -0.14px;
}

.assigned__del--btn {
  margin-left: 10px;
  cursor: pointer;
}

body.rtl .assigned__del--btn {
  margin-left: 0;
  margin-right: 10px;
}

.correctors__reset--all i {
  margin-right: 5px;
}

body.rtl .correctors__reset--all i {
  margin-right: 0;
  margin-left: 5px;
}

.section__responsive--title {
  padding: 12px;
  background: #fff;
}

.section__responsive--title p {
  font-size: 14px;
  font-weight: normal;
  color: #00adee;
}

.correctors_ckeditor_wrapper figure {
  display: none !important;
}

.correctors_ckeditor_wrapper .ck-toolbar {
  border: none !important;
}

.homework_drawer_selected_students {
  right: -50px;
  position: fixed;
  z-index: 7;
}

body.rtl .homework_drawer_selected_students {
  left: -20px;
  right: auto;
}

.homework_drawer_selected_students button {
  background-color: #00678d;
  color: #fff;
  transform: rotate(90deg);
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 0px;
}

.homework_drawer .ant-drawer-header {
  background-color: #00678d;
  border-radius: 0px;
  height: 53px;
  display: flex;
  align-content: center;
}

.homework_drawer .ant-drawer-header .ant-drawer-title {
  color: #fff;
  display: flex;
  align-items: center;
}

.homework_drawer .homework_drawer_footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  display: flex;
  justify-content: space-around;
}

.homework_drawer .homework_drawer_footer .ant-divider-vertical {
  height: 30px;
}

.homework_drawer .homework_drawer_footer button {
  border: none;
  color: #ff0000;
}

.homework_drawer .homework_drawer_footer button:first-child {
  color: #00678d;
}

.homework_selected_student_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 50px;
}

.homework_selected_student_item .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.homework_selected_student_item img {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin: 0px 5px;
}

.homework_selected_student_item > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: normal;
  color: #00678d;
  margin-left: 12px;
}

body.rtl .homework_selected_student_item > p {
  margin-right: 12px;
  margin-left: 0;
}

.online-session-container {
  padding: 15px;
  width: 100%;
  max-width: calc(100% - 200px);
}

@media only screen and (max-width: 1023px) {
  .online-session-container {
    max-width: unset;
  }
}

.online-session-container .summary__header {
  font-size: 27px;
  font-weight: normal;
  color: #00678d;
  margin-bottom: 16px;
  cursor: pointer;
}

.online-session-container .summary__header i {
  margin-right: 20px;
}

body.rtl .online-session-container .summary__header i {
  margin-left: 20px;
  margin-right: 0;
}

.online-session-container .online-session__info {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 20px 25px;
  margin-bottom: 30px;
}

.online-session-container .online-session__info > h3 {
  font-size: 18px;
  font-weight: 600;
  color: #00678d;
  margin-bottom: 25px;
}

.online-session-container .online-session__info > h3 img:first-child {
  margin-left: 24px;
  margin-right: 12px;
}

body.rtl .online-session-container .online-session__info > h3 img:first-child {
  margin-left: 12px;
  margin-right: 24px;
}

.online-session-container .online-session__info > h3 img:last-child {
  margin-left: 12px;
}

body.rtl .online-session-container .online-session__info > h3 img:last-child {
  margin-left: 0;
  margin-right: 12px;
}

.online-session-container .online-session__info .chapter__grade {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  max-width: 75%;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__info .chapter__grade {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__info .chapter__grade .chapter__grade--data {
    margin-bottom: 20px;
  }
}

.online-session-container .online-session__info .chapter__grade .chapter__grade--data .student__summary--num {
  margin: 0px 20px;
  color: #00678d;
  border-bottom: 1px solid #00678d;
}

.online-session-container .online-session__info .chapter__grade .chapter__grade--data p {
  font-size: 14px;
  font-weight: normal;
  color: #00678d;
}

.online-session-container .online-session__info .chapter__grade .chapter__grade--data p img {
  margin-right: 5px;
  width: 15px;
}

body.rtl .online-session-container .online-session__info .chapter__grade .chapter__grade--data p img {
  margin-left: 5px;
  margin-right: 0;
}

.online-session-container .online-session__info .chapter__grade .chapter__grade--data span {
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  margin-left: 15px;
}

body.rtl .online-session-container .online-session__info .chapter__grade .chapter__grade--data span {
  margin-left: 0;
  margin-right: 15px;
}

.online-session-container .online-session__info > p {
  font-size: 16px;
  font-weight: normal;
  color: #3c3c38;
  max-width: 75%;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__info > p {
    font-size: 13px;
    max-width: 100%;
    width: 100%;
  }
}

.online-session-container .online-session__attachments .attachments__tabs {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs {
    max-width: 100%;
    overflow-x: auto;
  }
}

.online-session-container .online-session__attachments .attachments__tabs li {
  font-size: 16px;
  font-weight: normal;
  padding: 20px 25px;
  color: #00678d;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 6px 6px 0px 0px;
}

.online-session-container .online-session__attachments .attachments__tabs li.active {
  background: #fff;
  font-weight: bold;
  box-shadow: 1px -4px 5px 0 rgba(0, 0, 0, 0.16);
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs li {
  margin-right: 0;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs li {
    font-size: 14px;
    padding: 15px 12px;
    margin-bottom: 16px;
    border-radius: 6px;
  }
  .online-session-container .online-session__attachments .attachments__tabs li.active {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
}

.online-session-container .online-session__attachments .attachments__tabs--content {
  padding: 30px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  max-width: 100%;
  overflow: auto;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs--content {
    padding: 15px;
  }
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student {
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border: solid 1px #d4d4e1;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead {
  table-layout: fixed;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead tr {
  display: table;
  width: calc(100% - 0px);
  table-layout: fixed;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead tr th {
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #464a53;
  border-right: 1px solid #d4d4e1;
  text-align: center;
  width: 34.78%;
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead tr th {
  border-left: 1px solid #d4d4e1;
  border-right: none;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead tr th:first-child {
  width: 39.78%;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student thead tr th:last-child {
  width: 25%;
  border: none !important;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody {
  display: block;
  max-height: 350px;
  overflow-y: auto;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td {
  height: 50px;
  border-top: 1px solid #d4d4e1;
  border-right: 1px solid #d4d4e1;
  width: 35%;
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td {
  border-left: 1px solid #d4d4e1;
  border-right: none;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td:first-child {
  width: 40%;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td:last-child {
  width: 25%;
  border-left: none !important;
  border-right: none !important;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td > div {
  text-align: center;
  padding: 0 15px;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--name {
  text-align: left;
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--name {
  text-align: right;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--name img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--name img {
  margin-left: 10px;
  margin-right: 0;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--name span {
  font-size: 12px;
  font-weight: 500;
  color: #00678d;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--class img {
  margin-right: 5px;
}

body.rtl .online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--class img {
  margin-right: 0;
  margin-left: 5px;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--class span {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
}

.online-session-container .online-session__attachments .attachments__tabs--content .attendes__student tbody tr td .attendes__student--status span {
  font-size: 10px;
  font-weight: normal;
  color: #2a7d9c;
}

.online-session-container .online-session__attachments .attachments__tabs--content .recording-tab {
  display: flex;
  align-items: center;
  text-align: center;
}

.online-session-container .online-session__attachments .attachments__tabs--content .recording-tab .video-react {
  width: 300px !important;
  height: 260px !important;
  margin-right: 25px;
  flex-shrink: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg {
    margin-bottom: 10px;
  }
}

.online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg h3 {
  font-size: 14px;
  font-weight: 600;
  color: #00678d;
  margin-bottom: 7px;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg h3 {
    font-size: 11px;
  }
}

.online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg p {
  font-size: 14px;
  font-weight: normal;
  color: #4e4e4e;
}

@media only screen and (max-width: 767px) {
  .online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg p {
    font-size: 11px;
  }
}

.online-session-container .online-session__attachments .attachments__tabs--content .chat__Container .chat__Container--msg.right {
  text-align: right;
}

.online-session-container .online-session__attachments .attendes__student--responsive {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f4f4f4;
  background-color: #ffffff;
  padding: 11px;
  margin-bottom: 11px;
  display: flex;
  align-items: center;
}

.online-session-container .online-session__attachments .attendes__student--responsive > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

body.rtl .online-session-container .online-session__attachments .attendes__student--responsive > img {
  margin-right: 0;
  margin-left: 10px;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details {
  width: 100%;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details h3 {
  font-size: 12px;
  font-weight: 500;
  color: #00678d;
  margin-bottom: 7px;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p {
  display: flex;
  justify-content: space-between;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p img {
  margin-right: 5px;
}

body.rtl .online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p img {
  margin-right: 0;
  margin-left: 5px;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p > span:first-child {
  font-size: 11px;
  font-weight: 300;
  color: #000000;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p > span:first-child img {
  width: 10px;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p > span:last-child {
  font-size: 10px;
  font-weight: normal;
  color: #ff1010;
}

.online-session-container .online-session__attachments .attendes__student--responsive .attendes__responsive--details p > span:last-child img {
  width: 12px;
}

.chat__Container--empty {
  padding: 15px;
  text-align: center;
}

.chat__Container--empty p {
  font-size: 19px;
  font-weight: 300;
  margin-top: 20px;
  color: #455a64;
}

@media only screen and (max-width: 767px) {
  .chat__Container--empty {
    padding: 0;
  }
}

.video-modal .video-react-video {
  pointer-events: none;
}

.video-modal .ant-modal-close-x {
  width: 44px;
  height: 44px;
  line-height: 37px;
}

.video-modal .ant-modal-close {
  border-radius: 50%;
  color: #222;
}

@media only screen and (max-width: 991px) {
  .video-modal .ant-modal-close {
    top: 13px;
    right: 15px;
    background: #707070 !important;
    border-radius: 50%;
    color: #f9f9fc;
  }
}

.attachment-details-holder {
  overflow-y: auto;
  overflow-x: auto;
  max-height: 70vh;
  max-width: 100%;
}

.attachment-details-holder.flex {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 70vh;
    max-width: 100%;
  }
}

.attachment-details-holder .contnet-download {
  width: 200px !important;
  flex-basis: 100%;
  display: flex;
  position: relative;
  text-align: center;
  margin: 10px;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .contnet-download {
    width: 90% !important;
  }
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .contnet-download {
    margin: 0px 10px;
  }
}

.attachment-details-holder .contnet-download .poster {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(3px);
  opacity: 0.8;
  object-fit: contain;
}

.attachment-details-holder .contnet-download .photo-video-download {
  width: 100%;
  height: 100px;
  cursor: pointer;
  position: relative;
}

.attachment-details-holder .contnet-download .photo-video-download img {
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-download video {
  position: relative;
  object-fit: cover;
}

.attachment-details-holder .contnet-download .photo-video-download::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.26);
}

.attachment-details-holder .contnet-download .photo-video-icon {
  transform: translate(-50%, -50%);
}

.attachment-details-holder .contnet-download .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.attachment-details-holder .download-info {
  display: flex;
  width: 200px;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .download-info {
    width: 100%;
  }
}

.attachment-details-holder .download-info p {
  font-size: 11px;
  color: #3c3c38;
  width: 120px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

@media only screen and (max-width: 991px) {
  .attachment-details-holder .download-info p {
    font-size: 12px;
    color: #3c3c38;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }
}

.attachment-details-holder .download-info div {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-details-holder .download-info div img {
  width: 9px;
  height: 9px;
}

.supervisor_correctors_header {
  height: 157px;
  width: 100%;
  margin: 0 1px 15px 0;
  padding: 14px 39px 30px 15px;
  background-color: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header {
    background-color: #f5f5f5;
    height: 180px;
    padding: 15px 5px;
  }
}

.supervisor_correctors_header .details_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 40px;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header .details_section {
    flex-direction: row;
    margin: 0px 5px;
  }
}

.supervisor_correctors_header .details_section .details_second_line .flex_basis_span {
  flex-basis: 37%;
}

.supervisor_correctors_header div {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div {
    flex-direction: column;
    margin: 10px 0px;
  }
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div div img {
    display: none;
  }
}

.supervisor_correctors_header div div h5 {
  margin: 0px 5px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div div h5 {
    color: #00678d;
    font-size: 16px;
  }
}

.supervisor_correctors_header div div .class_details {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #7e8a91;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div div .class_details {
    display: none;
  }
}

.supervisor_correctors_header div span {
  display: flex;
  align-items: center;
}

.supervisor_correctors_header div span p {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2c363f;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div span p {
    font-size: 10px;
    margin: 5px 0px;
  }
}

.supervisor_correctors_header div span p:first-child {
  color: #868ea4;
  font-size: 14px;
  font-weight: normal;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header div span p:first-child {
    font-size: 8px;
  }
}

.supervisor_correctors_header .first_line {
  margin: 0px 0px;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .supervisor_correctors_header .first_line {
    flex-direction: row;
  }
}

.supervisor_correctors_header .first_line button {
  width: 162px;
  height: 39px;
  margin: 0 0 16px 0px;
  padding: 12px 23px 11px 24px;
  border-radius: 4px;
  background-color: #00678d;
  color: #ffffff;
  font-size: 13px;
}

.supervisor_actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 15px 1px 3px 1px;
  padding: 15px 235.2px 0px 21.6px;
  background-color: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .supervisor_actions {
    background-color: #f5f5f5;
    margin: 0px;
    padding: 0px;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

body.rtl .supervisor_actions {
  padding: 15px 21.6px 0px  235.2px;
}

@media only screen and (max-width: 1023px) {
  body.rtl .supervisor_actions {
    height: 65px;
  }
}

.supervisor_actions p {
  cursor: pointer;
  font-weight: 500;
}

@media only screen and (max-width: 1023px) {
  .supervisor_actions p {
    background-color: #ffffff;
    margin: 0px 20px;
    padding: 0px 10px;
    width: 155px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
  }
}

.supervisor_actions .active_action_item {
  border-bottom: 1px solid #00adee;
  color: #00adee;
  height: 35px;
}

@media only screen and (max-width: 1023px) {
  .supervisor_actions .active_action_item {
    height: 43px;
  }
}

.supervisor_tab_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 0px 1px;
  padding: 15px  0px;
  background-color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .supervisor_tab_header {
    display: none;
  }
  body.rtl .supervisor_tab_header {
    display: none;
  }
}

.supervisor_tab_header p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #7e8a91;
}

.supervisor_desktop_item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 0px 1px;
  padding: 15px  0px;
  background-color: #ffffff;
  text-align: center;
  margin: 1px 1px;
}

.supervisor_responsive_item {
  height: 107px;
  margin: 11px 18px 8px 20px;
  padding: 9px 14px 20px 12px;
  background-color: #ffffff;
}

.supervisor_responsive_item.bigger_card {
  height: 135px;
}

.supervisor_responsive_item .flex_row {
  display: flex;
  justify-content: space-between;
  margin: 8px 0px;
}

.supervisor_responsive_item .flex_row p {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18;
  letter-spacing: normal;
  color: #7e8a91;
}

.supervisor_responsive_item .flex_row p span {
  font-weight: 800;
  color: #000000;
}

.blue_text {
  color: #00baff !important;
}

.bold_text {
  font-weight: bold;
}

.centered_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect_container_for_correction {
  display: flex;
  width: 100%;
  /* max-height: 100vh;
    overflow-y: auto; */
}

.connect_container_for_correction .options_container {
  flex-basis: 34%;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .options_container {
    flex-basis: 10%;
  }
}

.connect_container_for_correction .options_container.right {
  flex-basis: 33%;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .options_container.right {
    flex-basis: 45%;
  }
}

.connect_container_for_correction .options_container.left {
  flex-basis: 33%;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .options_container.left {
    flex-basis: 45%;
  }
}

.connect_container_for_correction .options_container .option {
  color: #2a7d9c;
  margin: 0 5px 10px 5px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .options_container .option {
    margin: 0px;
    padding: 0px 0px 5px 0px;
  }
}

.connect_container_for_correction .options_container .option span.left_dot_circle,
.connect_container_for_correction .options_container .option .right_dot_circle {
  /*  width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #2a7d9c;
                cursor: pointer; */
}

.connect_container_for_correction .options_container .option .option_actions {
  display: flex;
}

.connect_container_for_correction .options_container .option .option_actions span.left_dot_circle,
.connect_container_for_correction .options_container .option .option_actions .right_dot_circle {
  /*   width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #2a7d9c;
                    cursor: pointer; */
}

.connect_container_for_correction .options_container .option .option_actions span.delete_span {
  font-size: 18px;
  color: #ff0000;
  cursor: pointer;
  font-weight: 600;
}

.connect_container_for_correction .inner-html {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border: solid 1px #868ea4;
  border-radius: 6px;
  background-color: transparent;
  max-height: 140px;
  width: 100%;
  min-height: 50px;
  /*         *{
            pointer-events: none; 
         } */
}

.connect_container_for_correction .inner-html.MathJax {
  min-height: 140px;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .inner-html {
    min-height: 50px;
    max-height: 100px;
    padding: 2px;
  }
  .connect_container_for_correction .inner-html.MathJax {
    min-height: 100px;
  }
}

.connect_container_for_correction .inner-html.SelectedClass {
  border: 2px solid #0793c7;
}

.connect_container_for_correction .inner-html iframe {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .inner-html iframe {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.connect_container_for_correction .inner-html img {
  max-width: 180px;
  max-height: 125px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .inner-html img {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
  }
}

.connect_container_for_correction .ck-editor__main > .ck-editor__editable {
  border: none;
  pointer-events: none;
}

.connect_container_for_correction .ck-editor__main > .ck-editor__editable .ck-toolbar {
  border: none;
}

.connect_container_for_correction .ck-math-widget img {
  pointer-events: none;
  max-height: unset;
  object-fit: unset;
  width: auto;
}

@media only screen and (max-width: 767px) {
  .connect_container_for_correction .ck-math-widget img {
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
  }
}

.connect_container_for_correction .react-mathjax-preview-result {
  padding: 10px;
}

.connect_container_for_correction .react-mathjax-preview-result figure {
  text-align: center;
}

.exam-question-view {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .exam-question-view {
    padding-bottom: 0;
  }
}

.exam-question-view .question-view__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  top: 0;
  background: #fff;
  z-index: 1;
  border-bottom: 1px solid #ededed;
}

.exam-question-view .question-view__header strong {
  font-weight: 500;
}

.exam-question-view .question-view__header .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
}

.exam-question-view .question-view__header .title h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: #000000;
}

.exam-question-view .question-view__header .title .exam-time-counter {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
}

body.rtl .exam-question-view .question-view__header .title .exam-time-counter {
  flex-direction: row-reverse;
}

.exam-question-view .question-view__header .title .exam-time-counter span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 39px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: #0793c7;
  margin: 0 5px;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header .title .exam-time-counter span {
    width: 32px;
    height: 32px;
    font-size: 17px;
  }
}

.exam-question-view .question-view__header .description {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
  color: #2c363f;
  height: auto !important;
}

.exam-question-view .question-view__header .description * {
  height: auto !important;
}

.exam-question-view .question-view__header .description img {
  margin: 10px auto;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header .description {
    font-size: 16px;
    padding: 12px 0px;
  }
}

body.rtl .exam-question-view .question-view__header .description {
  direction: ltr;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view__header {
    position: static;
    min-height: auto;
    height: auto;
  }
  .exam-question-view .question-view__header .title h4 {
    font-size: 14px;
  }
  .exam-question-view .question-view__header .title time {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  body.rtl .exam-question-view .question-view__header .title time {
    right: auto;
    left: 16px;
  }
}

.exam-question-view .question-view__header .current-question-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.exam-question-view .question-view-content {
  flex: 1;
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .exam-question-view .question-view-content {
    padding: 15px 5px;
    overflow: unset;
  }
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-icon {
  align-self: flex-start;
}

@media only screen and (max-width: 767px) {
  .parent-steps-holder > .ant-steps-item .ant-steps-item-icon {
    align-self: center;
  }
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content {
  flex-direction: column;
  width: 100% !important;
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description {
  width: 100%;
}

.parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description .inner-steps-holder > .ant-steps-item .ant-steps-item-icon {
  align-self: center;
}

@media only screen and (max-width: 767px) {
  .parent-steps-holder > .ant-steps-item .ant-steps-item-content .ant-steps-item-description .inner-steps-holder {
    display: inline-flex;
    width: auto;
  }
}

.parent-steps-holder .single-step-holder .ant-steps-item-icon {
  align-self: center !important;
}

@media only screen and (max-width: 767px) {
  .parent-steps-holder .single-step-holder {
    flex-direction: column;
  }
  .parent-steps-holder .single-step-holder::after {
    top: -26px !important;
  }
}

.parent-steps-holder .multi-step-holder {
  background-color: transparent !important;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-icon {
  margin-top: 10px;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content {
  margin-top: 10px;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content > .ant-steps-item-title {
  margin-bottom: 20px;
  margin-top: 6px;
  padding-inline-end: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #0793c7;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content > .ant-steps-item-description {
  padding-bottom: 0;
}

.parent-steps-holder .multi-step-holder > .ant-steps-item-content .ant-steps-item-title {
  padding-inline-end: 0;
}

@media only screen and (max-width: 767px) {
  .parent-steps-holder .multi-step-holder {
    padding: 0 10px;
  }
  .parent-steps-holder .multi-step-holder:after {
    display: none;
  }
  .parent-steps-holder .multi-step-holder .ant-steps-item-description {
    max-width: fit-content;
  }
}

.question-view-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 6;
  padding: 15px;
  flex: 0;
}

@media only screen and (max-width: 767px) {
  .question-view-footer button {
    padding: 0 10px !important;
    margin: 15px 0px !important;
    font-size: 12px !important;
  }
}

.question-view-footer button:only-child {
  margin-left: auto;
}

body.rtl .question-view-footer button:only-child {
  margin-left: initial;
  margin-right: auto;
}

.question-view-footer button.show-answer {
  background-color: #11b74f;
}

.question-view-footer button.show-answer:hover {
  border: 1px solid #11b74f;
}

body.rtl .question-view-footer .next_prev.ant-btn-loading span {
  margin-left: 0px;
  margin-right: 17px;
}

@media only screen and (max-width: 767px) {
  .question-view-footer .next_prev {
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .question-view-footer {
    padding: 10px 0;
    background: #FFF;
    z-index: 666;
  }
}

.correct_answer_div {
  color: #11b74f;
  font-weight: 600;
  padding: 30px 0px;
}

body.rtl .correct_answer_div {
  text-align: right;
}

.correct_answer_div::before {
  content: "✓";
  font-size: 16px;
  font-weight: 700;
  color: #11b74f;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #11b74f;
  margin: 0px 5px;
  vertical-align: middle;
  background-color: #fff;
  text-align: center;
  text-decoration: none !important;
}
